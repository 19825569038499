import { Component, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import icClose from '@iconify/icons-ic/twotone-close';

import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';

import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
import { TaskFilterModel } from './task-filter.model';
import endOfWeek from 'date-fns/endOfWeek'
import { startOfWeek, add, endOfMonth, startOfMonth } from 'date-fns';
import { MatSelectChange } from '@angular/material/select';
import { DefaultTaskFilter } from 'src/app/api/user/roleAttributeTaskerValue.model';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { loadOperationsAction } from 'src/app/api/operations/operations.actions';

@Component({
  selector: 'task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TaskFilterComponent implements OnInit, OnDestroy {

  priority = Honeycomb.Common.Enums.Priority;
  priorityList = Object.entries(Honeycomb.Common.Enums.Priority).filter(e => !isNaN(e[0]as any)).map(e => ({ id: e[0], name: e[1] }));
  baseStatusList = Object.entries(Honeycomb.Common.Enums.TaskState).filter(e => !isNaN(e[0]as any)).map(e => ({ id: e[0], name: e[1] }));
  readList = Object.entries(Honeycomb.Common.Enums.TaskRead).filter(e => !isNaN(e[0]as any)).map(e => ({ id: Number(e[0]), name: e[1] }));
  taskTypeList = Object.entries(Honeycomb.Common.Enums.TaskType).filter(e => !isNaN(e[0] as any))
                                                                .map(e => ({ id: Number(e[0]), name: e[1] }))
                                                                .filter(e => e.id > 0);

  model: TaskFilterModel;
  subscriptions: Array<Subscription> = [];

  operations$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>>;
  operations: Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>;

  statuses$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>> = of([]);
  statuses: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus> = [];

  statusesAll$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>>;

  taskFilters$: Observable<Array<DefaultTaskFilter>>;

  filterForm: FormGroup;

  assignees$: Observable<any> = of([]);

  icClose = icClose;

  @ViewChild('assigneeInput') assigneeInput: ElementRef<HTMLInputElement>;

  constructor(private cd: ChangeDetectorRef,
              private snack: MatSnackBar,
              @Inject('ContactJobController') private jobController: Honeycomb.Tenant.Contact.IService.Controller.JobController,
              @Inject('ContactController') private contactController: Honeycomb.Tenant.Contact.IService.Controller.ContactController,
              // tslint:disable-next-line: max-line-length
              @Inject('TaskStatusController') private taskStatusController: Honeycomb.Tenant.Tasker.IService.Controller.TaskStatusController,
              private popoverRef: PopoverRef<TaskFilterModel>,
              private store: Store<AppState>,
              private trans: TranslateService) {
    this.model = popoverRef.data as any;

    this.filterForm = new FormGroup(
      {
        mainFilter: new FormControl(null, Validators.required),
        priorities: new FormControl(this.model.priorities),
        taskRead: new FormControl(this.model.taskRead),
        taskTypes: new FormControl(this.model.taskTypes),
        operations: new FormControl(this.model.operations),
        taskStatusIDs: new FormControl(this.model.taskStatusIDs),
        locationIDs: new FormControl(this.model.locations || []),
        dateFrom: new FormControl(this.model.dateFrom),
        dateTo: new FormControl(this.model.dateTo),
        assigneeControl: new FormControl('')
      }
    );

    this.operations$ = combineLatest([store.select(s => s.myTasks.filter), store.select(s => s.operations.results)])
    .pipe(
      filter((o, i) => !!o[0] && !!o[1]),
      map(o => {
        return {
          filter: o[0],
          operations: o[1]
        };
      }),
      tap(o => {
        if (!this.model.operations) {
          const preselectedOperations = o.operations.filter(a => this.model.taskTypes.indexOf(a.taskType) > -1).map(a => a.operationID);
          this.filterForm.get('operations').setValue(preselectedOperations);
        }
      }),
      map(o => {
        const opsForType = o.operations.map(a => a).sort((a, b) => a.sortIndex - b.sortIndex);
        return opsForType;
      })
    );

    let opSub = this.operations$.subscribe(o => this.operations = o);
    this.subscriptions.push(opSub);

    this.statusesAll$ = this.taskStatusController.List(null, null);
    this.statuses$ = combineLatest([this.statusesAll$, this.operations$])
      .pipe(
        map(([s, o]) => {
          let filteredStatuses = s.filter((s, i) => true);
          return filteredStatuses;
        }),
      );

    var statSub =  this.statuses$.subscribe(s => this.statuses = statSub);
    this.subscriptions.push(statSub);

    // Load operations if not loaded
    const opsSubsr = store.select(o => o.operations.results).subscribe(o => { if(!o) {
      store.dispatch(loadOperationsAction());
    }});
    this.subscriptions.push(opsSubsr);

    const opsSubs = this.operations$.subscribe();
    this.subscriptions.push(opsSubs);

    const s1 = this.filterForm.get('assigneeControl')
    .valueChanges.pipe(
      //untilDestroyed(this),
      debounceTime(300),
      distinctUntilChanged(),
      filter(value => value !== null && typeof(value) === 'string'),
      map(async (value: any) => {
        if (value.trim().length < 3) {
            this.assignees$ = of([]);
            this.showSnack('tasker.common.min-3-chars');
        } else {
            const found = await this.jobController.JobUsers(1, true, value).toPromise();
            this.assignees$ = of(found);
            if (!found.length) { this.showSnack('tasker.common.no-matching-record'); }
        }
        this.cd.detectChanges();
      })
    )
    .subscribe(_ => null);

    this.subscriptions.push(s1);
  }

  async ngOnInit() {

    if (!!this.model.taskRelationAdditional && this.model.taskRelationAdditional.length > 0) {
      const assignee = this.model.taskRelationAdditional.find(tr => tr.taskRelation === Honeycomb.Common.Enums.TaskRelation.assignee);
      if (assignee) {
          this.filterForm.get('assigneeControl').setValue({
            user: {
              id: assignee.userID,
            }
          });
          const user = await this.contactController.ByUserID(assignee.userID).toPromise();
          this.assigneeInput.nativeElement.value = user.formatedName;
        }
    }

    this.cd.detectChanges();
  }

  cancel() {
    this.popoverRef.close();
  }

  async handleChange() {
  }

  showSnack(message: string, options?: MatSnackBarConfig) {
    this.snack.open(this.trans.instant(message),
        this.trans.instant('tasker.common.close'),
        Object.assign({ duration: 3000 }, options) as MatSnackBarConfig);
  }

  apply() {
    this.model.mainFilter = Honeycomb.Common.Enums.TaskMainFilter.all;
    this.model.priorities = this.filterForm.controls.priorities.value;
    this.model.taskRead = this.filterForm.controls.taskRead.value;
    this.model.taskStatusIDs = this.filterForm.controls.taskStatusIDs.value;
    this.model.operations = this.filterForm.controls.operations.value;
    this.model.locations = this.filterForm.controls.locationIDs.value;
    this.model.dateFrom = this.filterForm.controls.dateFrom.value;
    this.model.dateTo = this.filterForm.controls.dateTo.value;

    if (!!this.model.dateTo) {
      this.model.dateTo.setHours(23, 59, 59, 999);
    }

    this.model.taskTypes = this.filterForm.controls.taskTypes.value;

    if (!!this.filterForm.controls.assigneeControl.value && !!this.filterForm.controls.assigneeControl.value.user) {
      this.model.taskRelationAdditional = [
        {
          taskRelation: Honeycomb.Common.Enums.TaskRelation.assignee,
          userID: this.filterForm.controls.assigneeControl.value.user.id
        }
      ];
    } else {
      this.model.taskRelationAdditional = null;
    }
    this.popoverRef.close(this.model);
  }

  setStatus() {
    this.cd.markForCheck();
  }

  async toggleAllSelection(items: Observable<any> | Array<any>, formGroup: FormGroup, controlName: string, selector?: string) {
    let fn = (o: Array<any>) => {
      if(formGroup.get(controlName).value.length === o.length) { // selected all
        formGroup.get(controlName).patchValue([], { emitEvent: true });
      } else {
        if (!!selector) {
          formGroup.get(controlName).setValue(o.map(p => p[selector]), { emitEvent: true });  
        } else {
          formGroup.get(controlName).setValue(o, { emitEvent: true });
        }
      }
      this.cd.detectChanges();
    }

    if (items instanceof Array) {
      fn(items);
      return;
    }

    (items as Observable<any>).subscribe(o => {
      fn(o);
    });
  }

  public hasAssigned() : boolean {
    return this.filterForm.get("assigneeControl").value;
  }

  dateTimeFilteChange(event: MatSelectChange) {
    let dateNow = new Date();

    if ('this_week' === event.value) {
      this.filterForm.controls.dateTo.setValue(endOfWeek(dateNow, {  weekStartsOn: 1 }));
      this.filterForm.controls.dateFrom.setValue(startOfWeek(dateNow, {  weekStartsOn: 1 }));
    }

    if ('next_week' === event.value) {
      dateNow = add(dateNow, { days: 7 });
      this.filterForm.controls.dateTo.setValue(endOfWeek(dateNow, {  weekStartsOn: 1 }));
      this.filterForm.controls.dateFrom.setValue(startOfWeek(dateNow, {  weekStartsOn: 1 }));
    }

    if ('this_month' === event.value) {
      this.filterForm.controls.dateTo.setValue(endOfMonth(dateNow));
      this.filterForm.controls.dateFrom.setValue(startOfMonth(dateNow));
    }

    if ('next_month' === event.value) {
      dateNow = add(dateNow, { months: 1 });
      this.filterForm.controls.dateTo.setValue(endOfMonth(dateNow));
      this.filterForm.controls.dateFrom.setValue(startOfMonth(dateNow));
    }

    if ('custom' === event.value) {
      this.filterForm.controls.dateTo.setValue(null);
      this.filterForm.controls.dateFrom.setValue(null);
    }
  }

  addAssignee($event: MatAutocompleteSelectedEvent) {
    const val = $event.option.value;
    this.assigneeInput.nativeElement.value = `${val.user.name}`;
  }

  clearAssingneeInput() {
    this.filterForm.get('assigneeControl').setValue(null);
  }

  close() {
    this.popoverRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if (s)  {
        s.unsubscribe();
      }
    });
  }
}
