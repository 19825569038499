<div class="ball-scale-multiple blue task-row" style="margin-top: 30px;" *ngIf="!(ready | async)">
    <div></div>
    <div></div>
    <div></div>
</div>

<ng-container *ngIf="ready | async">
    <button color="primary" mat-button type="button" (click)="sequenceProlong()" *ngxPermissionsOnly="uiRole('sequence-prolong')">{{
        'tasker.my-task-detail.sequence-prolong' | translate }}</button>
    <button color="primary" mat-button type="button" (click)="sequenceDelete()" *ngxPermissionsOnly="uiRole('sequence-delete')">{{
        'tasker.my-task-detail.sequence-delete' | translate }}</button>
    <div *ngFor="let item of filteredArray" class="task-row read-box" [class.unread]="!item.dateRead" [class.read]="!!item.dateRead">
        <task-item [task]="item" (selected)="selected($event)"></task-item>
        <mat-divider></mat-divider>
    </div>
        
    <ng-container>
        <mat-paginator stylePaginator showFirstLastButtons showTotalPages="3" #paginator
            [length]="items.length"
            [pageSize]="defaultRecords"
            [pageSizeOptions]="[10, 20, 50, 100]" (page)="pageEvent = $event; onPaginateChange($event)">
        </mat-paginator>
    </ng-container>
</ng-container>