import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import theme from '../../utils/tailwindcss';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { Observable } from 'rxjs';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { jobSelectAction } from 'src/app/api/user/user.actions';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  theme = theme;
  userJobs$: Observable<Array<Honeycomb.Tenant.Contact.IService.UserJob>>;
  selectedJobID: number;

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,
              private store: Store<AppState>,
              private router: Router,
              private tasksService: TasksService,
              ) {
                this.userJobs$ = store.pipe(select(x => x.user.currentUser != null ? x.user.currentUser.userJobs : null));
              }

  ngOnInit() {
    this.userJobs$.pipe(tap(uj => {
      if (!!uj && uj.length > 0) {
        this.selectedJobID = uj[0].jobID;
        this.tasksService.jobIdChangeEvent = this.selectedJobID;
        this.store.dispatch(jobSelectAction( { jobID: uj[0].jobID }));
      }
    })).subscribe(_ => null);
  }

  async selectedJobChanged($event) {
    this.store.dispatch(jobSelectAction({ jobID: this.selectedJobID }));
    this.tasksService.jobIdChangeEvent = this.selectedJobID;

    if (this.selectedJobID)
    {
      this.tasksService.setDefaultTaskStatuses(this.selectedJobID);
      console.log("jobID: " + this.tasksService.jobIdChangeEvent)

      this.cd.detectChanges();
    }

    this.router.navigate(['/']);
  }

  // showPopover(originRef: HTMLElement) {
  //   this.dropdownOpen = true;
  //   this.cd.markForCheck();

  //   const popoverRef = this.popover.open({
  //     content: ToolbarUserDropdownComponent,
  //     origin: originRef,
  //     offsetY: 12,
  //     position: [
  //       {
  //         originX: 'end',
  //         originY: 'top',
  //         overlayX: 'center',
  //         overlayY: 'bottom'
  //       },
  //       {
  //         originX: 'end',
  //         originY: 'bottom',
  //         overlayX: 'end',
  //         overlayY: 'top',
  //       },
  //     ]
  //   });

  //   popoverRef.afterClosed$.subscribe(() => {
  //     this.dropdownOpen = false;
  //     this.cd.markForCheck();
  //   });
  // }
}
