<div class="dialog-content-wrapper" [class]="data.class" fxLayout="column" fxFlex>
    <mat-toolbar class="mat-primary" fxLayout="row" fxFlex="60px" [color]="data.isError ? 'warn' : null">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{data.title | translate}}</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon fontSet="material-icons-outlined">close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div fxLayout="row" fxLayoutAlign="center center"  *ngIf="data.message && !data.structuredData" fxFlex>
        <h2 style="text-align: center;" [innerHtml]="data.message"></h2>
    </div>

    <div class="structured-data" fxLayout="column" fxLayoutAlign="left center" *ngIf="data.structuredData" fxFlex="1 1 100%">
        <div class="structured-data-row" *ngFor="let str of data.structuredData">
            <div class="title">{{ str.title | translate }}</div>
            <div class="items">
                <span *ngFor="let item of str.items; let last = last">
                    {{ item }}<span *ngIf="!last">, </span>
                </span>
            </div>
        </div>
        <h2>{{ data.message | translate }}</h2>
    </div>
    <div fxLayout="row"  *ngIf="data.inputType !== inputType.none"  fxFlex>
        <form [name]="'inputForm'" class="prompt-form" [formGroup]="form" > 
            <mat-form-field appearance="outline" class="prompt-field">
                <mat-label>{{data.inputTitle | translate}}</mat-label> 
                <!--text-->
                <input *ngIf="data.inputType === inputType.text" matInput formControlName="input"  #promptinput cdkFocusInitial (keydown)="onKeydown($event)">
                <!--number-->
                <input *ngIf="data.inputType === inputType.number" type="number" matInput formControlName="input"  #promptinput cdkFocusInitial (keydown)="onKeydown($event)">
                <!--select-->
                <mat-select *ngIf="data.inputType === inputType.select" formControlName="input"  #promptinput cdkFocusInitial (keydown)="onKeydown($event)">
                    <mat-option *ngFor="let option of data.options" [value]="option.value">
                    {{option.text}}
                    </mat-option>
                </mat-select>    
            </mat-form-field>        
        </form>            
    </div>
    <div fxLayout="row" fxFlex="60px" fxLayoutAlign="center center" fxLayoutGap="10px">
        <button-bar [buttons]="data.buttons" [isError]="data.isError" (buttonClicked)="buttonClicked($event)"></button-bar>
    </div>    
</div>        