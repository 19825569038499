import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSpButtonComponent } from './custom-sp-button.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [CustomSpButtonComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule
  ],
  exports: [CustomSpButtonComponent]
})
export class CustomSpButtonModule {
}
