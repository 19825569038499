<h2 mat-dialog-title>{{ 'tasker.common.notification' | translate }}</h2>
<mat-dialog-content class="mat-typography">

<table mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'tasker.common.name' | translate }} </th>
        <td mat-cell *matCellDef="let row">
             {{row.name}} <br/>
             <span style="font-size: 0.7em;">{{row.description}} </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="bell">
        <th mat-header-cell *matHeaderCellDef><mat-icon [icIcon]="icNotificationsActive" style="margin-bottom: -8px;"></mat-icon> {{ 'tasker.common.bell' | translate }} </th>
        <td mat-cell *matCellDef="let row" style="padding: 0;">
            <ng-container *ngIf="row.bellAvailable">
                <mat-checkbox [(ngModel)]="row.bell" style="padding-left: 3px;"></mat-checkbox>
            </ng-container>
            <ng-container *ngIf="!row.bellAvailable">
                <mat-icon [icIcon]="twotoneIndeterminateCheckBox" style="padding-top: 7px; padding-left: 12px; width: 36px; height: 31px"></mat-icon>
                <!-- <mat-checkbox [indeterminate]="true" disabled></mat-checkbox> -->
            </ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="push">
        <th mat-header-cell *matHeaderCellDef><mat-icon [icIcon]="twoTonePushPin" style="margin-bottom: -8px;"></mat-icon> {{ 'tasker.common.push' | translate }} </th>
        <td mat-cell *matCellDef="let row" style="padding: 0;">
            <ng-container *ngIf="row.pushAvailable">
                <mat-checkbox [(ngModel)]="row.push" style="padding-left: 3px;"></mat-checkbox>
            </ng-container>
            <ng-container *ngIf="!row.pushAvailable">
                <mat-icon [icIcon]="twotoneIndeterminateCheckBox" style="padding-top: 7px; padding-left: 12px; width: 36px; height: 31px"></mat-icon>
                <!-- <mat-checkbox [indeterminate]="true" disabled></mat-checkbox> -->
            </ng-container>
            
        </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef><mat-icon [icIcon]="twoToneEmail" style="margin-bottom: -8px;"></mat-icon> {{ 'tasker.common.email' | translate }} </th>
        <td mat-cell *matCellDef="let row" style="padding: 0;">
            <ng-container *ngIf="row.emailAvailable">
                <mat-checkbox [(ngModel)]="row.email" style="padding-left: 3px;"></mat-checkbox>
            </ng-container>
            <ng-container *ngIf="!row.emailAvailable">
                <mat-icon [icIcon]="twotoneIndeterminateCheckBox" style="padding-top: 7px; padding-left: 12px; width: 36px; height: 31px"></mat-icon>
                <!-- <mat-checkbox [indeterminate]="true" disabled></mat-checkbox> -->
            </ng-container>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['name', 'bell', 'push', 'email']"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'tasker.common.cancel' | translate }}</button>
  <button mat-button (click)="save()" cdkFocusInitial>{{ 'tasker.common.ok' | translate }}</button>
</mat-dialog-actions>