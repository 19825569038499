<div class="dropdown">
  <div class="dropdown-header" fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="1 0 0%" fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
      </div>
      <div class="dropdown-heading">{{ 'tasker.common.columns' | translate }}</div>
    </div>
  </div>

  <div class="dropdown-content">
    <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
      <mat-checkbox *ngFor="let col of model.allColumns" [(ngModel)]="col.visible">{{ col.locKey | translate }}</mat-checkbox>
    </div>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="cancel()">{{ 'tasker.common.cancel' | translate }}</button>
    <button mat-button (click)="apply()">{{ 'tasker.common.apply' | translate }}</button>
  </div>

</div>