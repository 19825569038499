import { Component, ViewEncapsulation, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GetInputModel, GeoCoordinates } from './geo-input.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Globals } from 'src/app/common/globals';

@Component({
  selector: 'geo-input',
  templateUrl: './geo-input.component.html',
  styleUrls: ['./geo-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GeoInputComponent),
    multi: true
  }]
})

export class GeoInputComponent implements ControlValueAccessor {

  // private static readonly INP_GEOLOCATION: string = 'geoLocation';

  private _value: GetInputModel = null;
  private onValueChange: Subject<GetInputModel>;

  geoLocationAvailable: boolean;
  currentPosition: GeoCoordinates;

  private onChange: any;

  //  @Input(GeoInputComponent.INP_GEOLOCATION) set geoLocation(values: string) {
  //   this._value = JSON.parse(values) as GetInputModel;
  //   if (this._value) {
  //     this.onValueChange.next(this._value);
  //   }
  // }

  get value(): GetInputModel {
    return this._value;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private globals: Globals
  ) {
    this.geoLocationAvailable = this.globals.geoLocationAvailable;

    if (!this.geoLocationAvailable) {
      return;
    }

    if (!this.onValueChange) {
      this.onValueChange = new Subject();
      this.onValueChange.subscribe(() => {
      });
    }
  }

  writeValue(value: GetInputModel): void {
    this._value = value;
    this.onValueChange.next(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  toggleChange(event: MatSlideToggleChange) {
    if (!event.checked) {
      this.writeValue(null);
      this.onChange(null);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.currentPosition = {
          accuracy: pos.coords.accuracy,
          altitude: pos.coords.altitude,
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          altitudeAccuracy: pos.coords.altitudeAccuracy,
          heading: pos.coords.heading,
          speed: pos.coords.speed
        };
        const sMap = (window as any).SMap;

        const coords = sMap.Coords.fromWGS84(pos.coords.longitude, pos.coords.latitude);

        const revers = new sMap.Geocoder.Reverse(coords, ((data) => {
          const geoData = data.getResults();
          let address = geoData.label;
          const country = (geoData.items.find(i => i.type === 'coun'));
          if (country) {
            address += ', ' + country.name;
          }
          const resVal = { address, coords: this.currentPosition };
          this.writeValue(resVal)
          this.onChange(resVal);
          this.cd.markForCheck();
        }));
      },
      (err) => {
        console.error(err);
      },
      { enableHighAccuracy: true, maximumAge: 5*60*1000  } as PositionOptions);
  }
}
