import { Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading, NavigationRemoteLink } from '../interfaces/navigation-item.interface';
import { Subject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';

import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { map, filter, tap } from 'rxjs/operators';
import { SectionType, TileDashboardSection } from 'src/app/api/user/roleAttributeTaskerValue.model';
import { dashboardTileArray } from 'src/app/pages/dashboard/dashboard-main/dashboard-tile';
import { isNullOrWhitespace } from 'src/app/common/functions';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public menuItems$: Observable<NavigationItem[]>;

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>
  ) {

    const tiles = dashboardTileArray()

    this.menuItems$ = this.store.pipe(
      select(s => s.user.dashboard),
      filter(s => !!s && !!s.sections),
      map(d => d.sections.filter(s => s.type === SectionType.TileSection)),
      map((d: TileDashboardSection[], i) => {
        const items: NavigationItem[] = [];
        items.push({
          type: 'link',
          label: 'tasker.menu.dashboard',
          route: '/dashboard/main',
          icon: icAssignmentTurnedIn,
          roles: []
        });

        d.forEach(s => {
          s.tileKeys.forEach(k => {
            const dashTile = tiles.find(t => t.tileKey === k.key);
            if (dashTile) {
              if (k.url || dashTile.remoteUrl) {
                items.push({
                  type: 'remoteUrl',
                  remoteUrl: k.url || dashTile.remoteUrl,
                  label: this.translate.instant(k.locKey || dashTile.tileName),
                  icon: dashTile.tileIcon,
                } as NavigationRemoteLink);
              } else {
                items.push({
                  type: 'link',
                  label: dashTile.tileName,
                  route: dashTile.route,
                  remoteUrl: null,
                  icon: dashTile.tileIcon,
                } as NavigationLink);
              }
            }
            else {
              const dashTile = tiles.find(t => t.tileKey === '');
              items.push({
                type: isNullOrWhitespace(k.url) ? 'link' : 'remoteUrl',
                label: this.translate.instant(k.locKey),
                route: dashTile.route,
                remoteUrl: k.url,
                icon: dashTile.tileIcon
              } as NavigationLink);
            }
          });
        });

        return items;
      }));

  }

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  private _itemChangeSubject = new Subject<NavigationItem>();
  itemChange$ = this._itemChangeSubject.asObservable();

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  updateItem(item: NavigationItem) {
    this._itemChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  isRemoteUrl(item: NavigationItem): boolean {
    return item.type === 'remoteUrl';
  }

  fetchItems() {
    const userRoles = this.getUserRoles();
    // this.items = this.getMenuItems().filter(navItem => {
    //   return navItem.roles.length === 0 || navItem.roles.filter(role => userRoles.indexOf(role) > -1).length > 0;
    // });
    return this.getMenuItems();
  }

  private getMenuItems() {
    // return of(this.itemsSource);
    return this.menuItems$;
  }

  getUserRoles() {
    // Mockups
    const userRoles = [
      'CreateTasks',
      'ManageLocations',
      'Communicate',
      'ViewReports'
    ];
    return userRoles;
  }
}
