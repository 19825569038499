import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  delay,
  tap
} from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  pushSubscribeAction,
  pushSubscribeFailedAction,
  pushGetPubKeyAction,
  pushGetPubKeyFailedAction,
  pushGetPubKeyFinishedAction
} from './notification-push.actions';
import { AppState } from 'src/app/app.states';
import { WebNotificationService } from 'src/@vex/services/web-notification.service';

@Injectable()
export class NotificationPushEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: WebNotificationService,
    @Inject('NotificationController')
    private notificationController: Honeycomb.Tenant.Tasker.IService.Controller.NotificationController,
  ) {}

  setPushConnectionStatusEffect = createEffect(() => this.actions$.pipe(
    ofType(pushGetPubKeyAction),
    mergeMap((data) => {
        return this.notificationController.PushPublicKey().pipe(
          map(res => {
            this.store.dispatch(pushSubscribeAction({pubKey: res.pubKey}));
            return pushGetPubKeyFinishedAction({ pubKey: res.pubKey });
          }),
          catchError(error => {
            console.log(error);
            return of(pushGetPubKeyFailedAction({ error }));
          }),
          tap(_ => {
          })
        )
    })
  ));

  // https://blog.angular-university.io/angular-push-notifications/

  pushSubscribeActionEffect = createEffect(() => this.actions$.pipe(
    ofType(pushSubscribeAction),
    withLatestFrom(this.store),
    map(([data, store]) => {

      this.notificationService.subscribeToNotification(data.pubKey);
    })
  ), { dispatch: false });

  pushSubscribeFailedActionEffect = createEffect(() => this.actions$.pipe(
    ofType(pushSubscribeFailedAction),
    withLatestFrom(this.store),
    map(([data, store]) => {
      console.error(data.error, 'WebPush')      
    })
  ), { dispatch: false });

}
