import {
  Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit,
  OnDestroy, ChangeDetectionStrategy, ElementRef, NgZone
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icPerson from '@iconify/icons-ic/twotone-person';
import twotoneMore from '@iconify/icons-ic/twotone-more';
import twotoneRocket from '@iconify/icons-ic/twotone-rocket';
import twotoneTimerOff from '@iconify/icons-ic/twotone-timer-off';
import baselineSort from '@iconify/icons-ic/baseline-sort';
import twotoneSpeakerNotes from '@iconify/icons-ic/twotone-speaker-notes';
import twotoneDescription from '@iconify/icons-ic/twotone-description';
import twotoneLocationOn from '@iconify/icons-ic/twotone-location-on';
import twotoneStore from '@iconify/icons-ic/twotone-store';
import twotoneAssignment from '@iconify/icons-ic/twotone-assignment';
import twotoneAssistant from '@iconify/icons-ic/twotone-assistant';
import twotoneGroup from '@iconify/icons-ic/twotone-group';
import baselineAccessTime from '@iconify/icons-ic/baseline-access-time';
import roundAddCircleOutline from '@iconify/icons-ic/round-add-circle-outline';
import roundContentPaste from '@iconify/icons-ic/round-content-paste';
import roundCancelPresentation from '@iconify/icons-ic/round-cancel-presentation';
import eventRepeat from '@iconify/icons-ic/baseline-event-repeat';

import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, map, take, filter } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import TaskType = Honeycomb.Common.Enums.TaskType;
import TaskRelation = Honeycomb.Common.Enums.TaskRelation;
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import { FileInputComponent } from 'ngx-material-file-input';
import { Globals } from 'src/app/common/globals';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AppState } from 'src/app/app.states';
import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';
import { dialogMyTaskClosedAction } from 'src/app/api/tasks/my-tasks/my-tasks.actions';
import { enumToStrings, isNullOrWhitespace } from 'src/app/common/functions';
import { ConfigService } from 'src/@vex/services/config.service';


@Component({
  selector: 'task-new',
  templateUrl: './task-new.component.html',
  styleUrls: ['./task-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskNewComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('descriptionInput') descriptionInput: CdkTextareaAutosize;
  @ViewChild('inputPhoto') inputPhotoRef: FileInputComponent;
  @ViewChild('inputAttachment') inputAttachmentRef: FileInputComponent;


  @ViewChild('contactInputObserver') contactInputObserver: ElementRef<HTMLInputElement>;
  @ViewChild('contactInputAssignee') contactInputAssignee: ElementRef<HTMLInputElement>;
  @ViewChild('contactInputResponsible') contactInputResponsible: ElementRef<HTMLInputElement>;

  form: FormGroup;
  initialized$: Observable<boolean> = of(false);
  detailError$: Observable<string>;
  creatingTask$: Observable<boolean> = of(false);

  fileUploaded$: Observable<any> = of({});
  fileUploading$ = false;

  locations$: Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort>> = of([]);
  users$: Observable<Array<any>> = of([]);

  contacts: Array<Honeycomb.Tenant.Contact.IService.ContactPublic> = [];
  contacts$: Observable<Array<Honeycomb.Tenant.Contact.IService.ContactPublic>> = of([]);

  operations$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> = of([]);
  selectedOperation: Honeycomb.Tenant.Tasker.IService.Model.Operation;

  taskPhotos = [];
  taskAttachments = [];

  dialogClosedSubscription: Subscription;
  locations: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort> = [];
  subscriptions: Array<Subscription> = [];

  icClose = icClose;
  icPerson = icPerson;
  twotoneMore = twotoneMore;
  twotoneRocket = twotoneRocket;
  twotoneTimerOff = twotoneTimerOff;
  baselineSort = baselineSort;
  baselineAccessTime = baselineAccessTime;
  twotoneSpeakerNotes = twotoneSpeakerNotes;
  twotoneDescription = twotoneDescription;
  twotoneLocationOn = twotoneLocationOn;
  twotoneStore = twotoneStore;
  twotoneAssignment = twotoneAssignment;
  twotoneAssistant = twotoneAssistant;
  twotoneGroup = twotoneGroup;
  roundAddCircleOutline = roundAddCircleOutline;
  roundContentPaste = roundContentPaste;
  roundCancelPresentation = roundCancelPresentation;
  eventRepeat = eventRepeat;

  freeTask = TaskType.freeTask;
  tenantHash: string = null;
  taskRelation = Honeycomb.Common.Enums.TaskRelation;

  configKey = 'tasker.tasks.asignee-selectors';
  config: any;
  loggedUser: any;
  batchSelectors: Array<string> = [];
  agenda: TaskAgenda;
  startDate: Date;
  selectedJobID: number;
  userID: number;
  useTime = false;
  parentTask: Honeycomb.Tenant.Tasker.IService.Model.Task = null;
  defaults: Honeycomb.Tenant.Tasker.IService.Model.Task = null;

  PeriodType = Honeycomb.Common.Enums.PeriodType;
  useRepeats = false;

  DayOfWeek = Honeycomb.Common.Enums.DayOfWeek;

  defaultItemType = 0;
  taskSequenceID: number = null;

  uiroles: string[] = [];
  private roleDebug = [];

  // defaults, read real values from database
  public maxYearsGeneratedr = 5;
  public maxOccurencesGenerated = 999;
  // hardcoded (as in outlook)
  public sequenceEndDaysAdd = 168;

  prescriptions: Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskPrescription[]> = of([]);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    // tslint:disable-next-line: max-line-length
    @Inject('LookupLocationController') private lookupLocationController: Honeycomb.Tenant.LookupTables.IService.LocationsController,
    @Inject('ContactJobController') private jobController: Honeycomb.Tenant.Contact.IService.Controller.JobController,
    @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
    @Inject('ConfigController') private configController: Honeycomb.Tenant.Admin.IService.NamedConfigurationsController,
    // tslint:disable-next-line: max-line-length
    @Inject('FileControllerCustom') private fileController: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
    @Inject('FileController') private fileControllerBase: Honeycomb.Tenant.Tasker.IService.Controller.FileController,
    @Inject('OperationController') private operationController: Honeycomb.Tenant.Tasker.IService.Controller.OperationController,
    @Inject('SequenceController') private sequenceController: Honeycomb.Tenant.Tasker.IService.Controller.SequenceController,
    private dialogRef: MatDialogRef<TaskNewComponent>,
    private translate: TranslateService,
    private configService: ConfigService,
    private store: Store<AppState>,
    private snack: MatSnackBar,
    private trans: TranslateService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private globals: Globals,
    private _ngZone: NgZone) {

    if (!!this.data) {
      this.parentTask = this.data.parentTask;
    }
    if (!this.defaults) {
      this.defaults = this.data.defaults;
    }
    
    if (!!this.data && !!this.data.taskSequenceID) {
      this.defaultItemType = 2;
      this.taskSequenceID = this.data.taskSequenceID;
    }

    store.pipe(select(x => x.user)).subscribe(u => this.uiroles = u.uiroles);

    store.select(s => s.user).pipe(
      tap(u => {
        this.selectedJobID = u.selectedJobID;
        if (!!u.currentUser) {
          this.userID = u.currentUser.userId;
          this.operations$ = this.operationController.ValidForJob(this.selectedJobID, this.configService.getLanguage())
            .pipe(
              map(ops => ops.sort((a, b) => a.sortIndex - b.sortIndex)),
              map(ops => {
                const f = ops.filter((a, b) => !this.parentTask || a.canBeChild);
                return f;
              }));
        }
      }),
      filter(u => !!u.selectedJobLocations /* && u.selectedJobLocations.length > 0 */),
      map(u => u.selectedJobLocations),
      distinctUntilChanged(),
      tap(jl => {
        // OnlyMyLocationsWorkaround is used because specificLocations parameter works as "include" to filter
        // Empty filter means, that no filter is applied
        // so I used that weird placeholder which causes that only JobLocations are returned
        var locationsRequest: Honeycomb.Tenant.LookupTables.IService.Model.LocationRequest = {
          specificLocations: jl,
          specificLocationsFilter: null
        };
        this.locations$ = this.lookupLocationController
          .ListSimpleLong('OnlyMyLocationsWorkaround', null, null, true, null, null, null, null, locationsRequest)
          .pipe(
            tap(l => {
              this.locations = l;
              if (l.length === 1) {
                this.form.get('locationID').setValue(l[0].locationID);
                this.selectedLocation(null, l[0].locationID);
              } else {
                if (this.defaults && this.defaults.locationID) { this.selectedLocation(null, this.defaults.locationID); }
              }
              this.cd.detectChanges();
            }));
      })
    ).subscribe(_ => null);

    this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);

    this.dialogClosedSubscription = dialogRef.beforeClosed().subscribe(_ => {
      this.store.dispatch(dialogMyTaskClosedAction());
    });

    this.subscriptions.push(this.dialogClosedSubscription);
  }

  private setupForm() {

    const todayNow = new Date();

    let assignee = []; let observer = []; let responsible = [];
    if (this.defaults && Array.isArray(this.defaults.taskUsers)) {
      assignee = this.defaults.taskUsers.filter(tu => tu.taskRelation === Honeycomb.Common.Enums.TaskRelation.assignee);
      observer = this.defaults.taskUsers.filter(tu => tu.taskRelation === Honeycomb.Common.Enums.TaskRelation.observer);
      responsible = this.defaults.taskUsers.filter(tu => tu.taskRelation === Honeycomb.Common.Enums.TaskRelation.responsible);
    }

    this.form = this.fb.group({
      taskID: -1,
      name: this.fb.control(this.defaults ? (this.defaults.name || '') : '', Validators.required),
      description: this.fb.control(this.defaults ? (this.defaults.description || '') : ''),
      operationID: this.fb.control(this.defaults ? (this.defaults.operationID || null) : null, Validators.required),
      // tslint:disable-next-line: max-line-length
      priority: this.fb.control(this.defaults ? (this.defaults.priority || Honeycomb.Common.Enums.Priority.normal) : Honeycomb.Common.Enums.Priority.normal),
      locationID: this.fb.control(this.defaults ? (this.defaults.locationID || null) : null),
      address: this.fb.control(''),
      startDate: this.fb.control(this.defaults ? (this.defaults.startDate || todayNow) : todayNow, Validators.required),
      deadline: this.fb.control(this.defaults ? (this.defaults.deadline || null) : todayNow),
      taskStatus: this.fb.control(null),
      newItemType: this.fb.control(this.defaultItemType, [Validators.required]),
      creator: this.fb.array([]),
      contactInputAssignee: this.fb.control(null),
      assignee: this.fb.array(assignee, [Validators.required]),
      contactInputObserver: null,
      observer: this.fb.array(observer),
      contactInputResponsible: null,
      responsible: this.fb.array(responsible),
      sequence: this.fb.group({
        periodType: this.fb.control(Honeycomb.Common.Enums.PeriodType.day, Validators.required),
        periodRepeat: this.fb.control(1),
        weekDayMonday: this.fb.control(false),
        weekDayTuesday: this.fb.control(false),
        weekDayWednesday: this.fb.control(false),
        weekDayThursday: this.fb.control(false),
        weekDayFriday: this.fb.control(false),
        weekDaySaturday: this.fb.control(false),
        weekDaySunday: this.fb.control(false),
        monthRepeatDay: this.fb.control(1),

        yearRepeatDayNr: this.fb.control(1),
        yearRepeatMonthNr: this.fb.control(1),
        yearRepeatDay: this.fb.control(new Date()),

        sequenceStart: this.fb.control(new Date(todayNow.getFullYear(), todayNow.getMonth(), todayNow.getDate()), Validators.required),
        sequenceEnd: this.fb.control(new Date(Date.now() + this.sequenceEndDaysAdd * 24 * 60 * 60 * 1000)),
        useNumberOfRepeats: this.fb.control(false),
        numberOfRepeats: this.fb.control(1)
      })
    });

    this.taskPhotos = [];
    if (this.defaults && this.defaults.taskPhotos && this.defaults.taskPhotos.length > 0) {
      this.defaults.taskPhotos.forEach(async (photo) => {
        var cachedFile = await lastValueFrom(this.fileControllerBase.CloneFile(photo.taskerFileUid));
        this.taskPhotos.push({ recordUid: cachedFile.recordUID });
        this.cd.markForCheck();
        this.cd.detectChanges();
      });
    }

    [this.form.get('contactInputAssignee'),
    this.form.get('contactInputObserver'),
    this.form.get('contactInputResponsible')]
      .forEach(c => c.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(value => value !== null && typeof (value) === 'string' && value !== ''),
        map(async (value: any) => {
          if (value.trim().length < 3) {
            this.users$ = of([]);
            this.showSnack('tasker.common.min-3-chars');
          } else {
            const found = await lastValueFrom(this.jobController.UsersForOperation(
              {
                userID: this.userID,
                jobID: this.selectedJobID,
                operationID: this.form.get('operationID').value,
                treshold: 1,
                search: value,
                includeDeleted: false
              }));
            this.users$ = of(found);
            if (!found.length) {
              this.showSnack('tasker.common.no-matching-record');
            }
          }
          this.cd.detectChanges();
        })).subscribe(_ => null)
      );

    const sub = (this.form.get('sequence') as FormGroup)
      .valueChanges.pipe(
        tap(async (aiForm) => {
          const ai = this.sequencePrepare(aiForm);
          await this.getCalculatedSequences(ai);
          this.cd.detectChanges();
        })
      ).subscribe(_ => null);

    this.subscriptions.push(sub);

    this.cd.detectChanges();
  }

  private async getCalculatedSequences(ai: any) {
    const res = await lastValueFrom(this.sequenceController.GetPrescriptions(ai as Honeycomb.Tenant.Tasker.IService.Model.TaskSequence))
      .catch(_ => []);
    this.prescriptions = of(res);
    if (ai.useNumberOfRepeats) {
      if (res.length > 0) {
        (this.form.get('sequence') as FormGroup).get('sequenceEnd').setValue(res.reverse()[0].taskStart, { emitEvent: false });
      }
    } else {
      (this.form.get('sequence') as FormGroup).get('numberOfRepeats').setValue(res.length, { emitEvent: false });
    }
    this.cd.detectChanges();
  }

  private sequencePrepare(aiForm: any) {
    const ai = Object.assign({}, aiForm);
    if (ai.periodType !== Honeycomb.Common.Enums.PeriodType.day && ai.periodRepeat === -1) {
      ai.periodRepeat = 1;
      (this.form.get('sequence') as FormGroup).get('periodRepeat').setValue(1, { emitEvent: false });
    }

    (ai.yearRepeatDay as Date).setFullYear((ai.sequenceStart as Date).getFullYear()); // set year from start 
    (ai.yearRepeatDay as Date).setMonth(ai.yearRepeatMonthNr - 1); // stupid idea have months 0 indexed

    // Can set 31st of Febuary
    const testMaxDay = new Date((ai.sequenceStart as Date).getFullYear(), (ai.yearRepeatDay as Date).getMonth() + 1, 0);
    if (testMaxDay.getDate() < ai.yearRepeatDayNr) {
      ai.yearRepeatDayNr = testMaxDay.getDate();
      (this.form.get('sequence') as FormGroup).get('yearRepeatDayNr').setValue(testMaxDay.getDate(), { emitEvent: false });
    }
    (ai.yearRepeatDay as Date).setDate(ai.yearRepeatDayNr);
    ai.weekDays =
      ai.weekDayMonday * this.DayOfWeek.monday +
      ai.weekDayTuesday * this.DayOfWeek.tuesday +
      ai.weekDayWednesday * this.DayOfWeek.wednesday +
      ai.weekDayThursday * this.DayOfWeek.thursday +
      ai.weekDayFriday * this.DayOfWeek.friday +
      ai.weekDaySaturday * this.DayOfWeek.saturday +
      ai.weekDaySunday * this.DayOfWeek.sunday;
    if (ai.useNumberOfRepeats) {
      ai.sequenceEnd = null;
    } else {
      ai.numberOfRepeats = null;
    }
    return ai;
  }

  async ngOnInit() {
    this.agenda = this.data.agenda;
    this.useTime = this.defaults && this.data.useTime;

    if (!!this.data.parentTask) {
      var prepared = await lastValueFrom(this.taskController.CreateFollowUp(this.data.parentTask.taskID));
      if (!!prepared && !this.defaults) {
        this.defaults = <any>{};
        Object.assign(this.defaults, prepared);
      }
    }
    
    this.setupForm();

    if (!!this.defaults && !!this.defaults.operationID) {
      this.form.get('operationID').setValue(this.defaults.operationID);
    }

    const cfg = await lastValueFrom(this.configController.GetByNames([this.configKey]));
    if (cfg && cfg[this.configKey]) {
      this.config = cfg[this.configKey];
      this.store.pipe(select(x => x.user), take(1)).subscribe(cu => {
        this.loggedUser = cu;
        const roleId = cu.currentUser.userJobs.find(uj => uj.jobID === cu.selectedJobID).job.roleID;
        const roleName = cu.currentUser.contactRoles.find(r => r.roleID === roleId).name;
        if (this.config[roleName]) {
          this.batchSelectors.push(...this.config[roleName]);
        }
        this.cd.detectChanges();
      });
    }

    await lastValueFrom(this.operations$.pipe(
      filter(ops => Array.isArray(ops)),
      tap(
        ops => {
          if (ops.length > 0) {
            this.selectedOperation = ops.sort((a, b) => a.sortIndex - b.sortIndex)[0];
            if (this.selectedOperation) {
              this.form.get('operationID').setValue(this.selectedOperation.operationID);
            }
            this.cd.detectChanges();
          }
    })));

    const seqForm = this.form.get('sequence');
    if (!!this.taskSequenceID) {
      const prescription = await lastValueFrom(this.sequenceController.Get(this.taskSequenceID));

      seqForm.patchValue({ periodType: prescription.periodType }, { emitEvent: false });
      seqForm.patchValue({ periodRepeat: prescription.periodRepeat }, { emitEvent: false });
      // tslint:disable: no-bitwise
      // tslint:disable: max-line-length
      seqForm.patchValue({ weekDayMonday: (prescription.weekDays & this.DayOfWeek.monday) === this.DayOfWeek.monday }, { emitEvent: false });
      seqForm.patchValue({ weekDayTuesday: (prescription.weekDays & this.DayOfWeek.tuesday) === this.DayOfWeek.tuesday }, { emitEvent: false });
      seqForm.patchValue({ weekDayWednesday: (prescription.weekDays & this.DayOfWeek.wednesday) === this.DayOfWeek.wednesday }, { emitEvent: false });
      seqForm.patchValue({ weekDayThursday: (prescription.weekDays & this.DayOfWeek.thursday) === this.DayOfWeek.thursday }, { emitEvent: false });
      seqForm.patchValue({ weekDayFriday: (prescription.weekDays & this.DayOfWeek.friday) === this.DayOfWeek.friday }, { emitEvent: false });
      seqForm.patchValue({ weekDaySaturday: (prescription.weekDays & this.DayOfWeek.saturday) === this.DayOfWeek.saturday }, { emitEvent: false });
      seqForm.patchValue({ weekDaySunday: (prescription.weekDays & this.DayOfWeek.sunday) === this.DayOfWeek.sunday }, { emitEvent: false });
      seqForm.patchValue({ monthRepeatDay: prescription.monthRepeatDay }, { emitEvent: false });
      seqForm.patchValue({ yearRepeatDayNr: new Date(prescription.yearRepeatDay).getDate() }, { emitEvent: false });
      seqForm.patchValue({ yearRepeatMonthNr: new Date(prescription.yearRepeatDay).getMonth() + 1 }, { emitEvent: false });
      seqForm.patchValue({ yearRepeatDay: new Date(prescription.yearRepeatDay) }, { emitEvent: false });
      seqForm.patchValue({ sequenceStart: new Date(prescription.sequenceStart) }, { emitEvent: false });
      seqForm.patchValue({
        sequenceEnd: !!prescription.sequenceEnd && prescription.numberOfRepeats === null ?
          new Date(prescription.sequenceEnd) : null
      }, { emitEvent: false });
      seqForm.patchValue({ useNumberOfRepeats: !!prescription.numberOfRepeats }, { emitEvent: false });
      seqForm.patchValue({ numberOfRepeats: prescription.numberOfRepeats }, { emitEvent: false });

      // tslint:enable: max-line-length

      // override sequence DB values
      if (!!this.data.sequenceStart) {
        let lastTaskDate = new Date(this.data.sequenceStart);
        lastTaskDate = new Date(lastTaskDate.setDate(lastTaskDate.getDate() + 1));
        if (lastTaskDate > seqForm.get('sequenceStart').value) { // Do not create tasks to past
          seqForm.get('sequenceStart').setValue(lastTaskDate);
        }
        this.getCalculatedSequences(seqForm.value);
      }
    } else { // new sequence, but calc. real number of occurences
      // set numberOfRepeats to 0 to get number of calculated repeats
       seqForm.patchValue({ numberOfRepeats: null }, { emitEvent: false });
       await this.getCalculatedSequences(seqForm.value);
    }

    this.initialized$ = of(true);
    this.cd.markForCheck();
  }

  async ngAfterViewInit() {

  }

  public getPeriodType = () => (this.form.get('sequence') as FormGroup).get('periodType').value;
  public getsequenceEndMin = () => (this.form.get('sequence') as FormGroup).get('sequenceStart').value;
  public getUseNumberOfRepeats = () => (this.form.get('sequence') as FormGroup).get('useNumberOfRepeats').value;

  public clearUsers() {
    this.form.controls.assignee = this.fb.array([]);
  }


  async operationSelected($event: MatSelectChange) {
    const operationID = $event.value;
    await lastValueFrom(this.operations$.pipe(tap(ops => {
      this.selectedOperation = ops.find(o => o.operationID === operationID);
      this.form.get('name').setValue(this.selectedOperation.taskType !== TaskType.freeTask ? this.selectedOperation.name : '');
      this.cd.detectChanges();
    })));
  }

  async save() {

    if (!this.form.valid) {
      this.form.markAsTouched(); // just for a case of submit form before any input change
      return;
    }

    const task = this.form.getRawValue();
    const updateTask = {
      taskID: -1, // otherwise won't deserialize
      operationID: task.operationID,
      parentTaskID: this.parentTask ? this.parentTask.taskID : null,
      parentTaskValueID: this.defaults ? this.defaults.parentTaskValueID : null,
      parentSnapshotInputID: this.defaults ? this.defaults.parentSnapshotInputID : null,
      locationID: task.locationID,
      name: task.name,
      taskStatusID: task.taskStatusID,
      taskStatusBase: Honeycomb.Common.Enums.TaskState.new,
      taskPhotos: this.taskPhotos,
      description: task.description,
      address: task.address,
      startDate: task.startDate,
      deadline: task.deadline,
      priority: task.priority,
      assignee: task.assignee,
      observer: task.observer,
      responsible: task.responsible,
      taskAttachments: this.taskAttachments
    } as Honeycomb.Tenant.Tasker.IService.Model.TaskUpsert;

    this.creatingTask$ = of(true);

    if (this.form.get('newItemType').value === 0) {
      await this.taskController.Insert(updateTask).toPromise();
    } else if (this.form.get('newItemType').value === 1) {
      await this.taskController
        .CreateBatch({ task: updateTask, selectedJobID: this.loggedUser.selectedJobID })
        .toPromise()
        .catch(response => this.showSnack(response.error));
    } else if (this.form.get('newItemType').value === 2) {

      const formSeq = this.form.get('sequence').value;

      const sequence = this.sequencePrepare(formSeq);

      // Prolong sequence, update it for the latest settings
      if (!!this.taskSequenceID) {
        await this.sequenceController.Update(this.taskSequenceID, sequence).toPromise();
        sequence.taskSequenceID = this.taskSequenceID;
      }

      // Create new sequence
      await lastValueFrom(this.taskController
        .CreateSequence({ task: updateTask, sequence }))
        .catch(response => this.showSnack(response.error));
    }
    this.dialogRef.close(true);
    this.creatingTask$ = of(false);
  }

  removeUser(userId, relation: Honeycomb.Common.Enums.TaskRelation) {
    const enumHashMap = enumToStrings(Honeycomb.Common.Enums.TaskRelation);
    const formArray = this.form.get(enumHashMap[relation]) as FormArray;
    const objToRemove = formArray.value.find(u => u.userId === userId);
    const ix = formArray.value.indexOf(objToRemove);
    formArray.removeAt(ix)
    this.form.value[enumHashMap[relation]] = formArray.value;
  }

  uiRole(suffix: string) {

    if (!this.selectedOperation) { return; }

    const reqUiRole = `tasker.task-new.${this.selectedOperation.code}.${suffix}`;

    if (this.roleDebug.findIndex(r => r === reqUiRole) === -1) {
      this.globals.DebugOut('task-new', reqUiRole);
      this.roleDebug.push(reqUiRole);
    }

    return reqUiRole;
  }

  hasPermission(suffix: string) {
    return this.uiroles.indexOf(this.uiRole(suffix)) > -1;
  }

  addUser(relation: Honeycomb.Common.Enums.TaskRelation, event: MatAutocompleteSelectedEvent) {

    const enumHashMap = enumToStrings(Honeycomb.Common.Enums.TaskRelation);
    const taskUsersControl = this.form.controls[enumHashMap[relation]] as FormArray;
    const taskUsers = taskUsersControl.value;
    const userIDs = new Set(taskUsers.map(a => a.userId));
    const current = event.option.value.user;

    if (!current) { return; }
    if (userIDs.has(current.id)) {
      return this.showSnack('tasker.common.already-selected');
    }

    const position = taskUsersControl.value.length;
    taskUsers.push({ userId: current.id, taskRelation: relation, name: current.name });
    taskUsersControl.insert(position,
      this.fb.control({ userId: current.id, taskRelation: relation, name: current.name }));

    [this.form.get('contactInputAssignee'),
    this.form.get('contactInputObserver'),
    this.form.get('contactInputResponsible')].forEach(c => c.setValue(''));

    this.contactInputObserver.nativeElement.value = '';
    this.contactInputAssignee.nativeElement.value = '';
    this.contactInputResponsible.nativeElement.value = '';

    this.users$ = of([]);
    this.cd.detectChanges();
  }

  showSnack(message: string, options?: MatSnackBarConfig) {
    this.snack.open(this.trans.instant(message),
      this.trans.instant('tasker.common.close'),
      Object.assign({ duration: 3000 }, options) as MatSnackBarConfig);
  }

  selectedLocation($event: MatSelectChange, id?: number) {
    const locationId = id || $event.value;
    const location = this.locations.find(l => l.locationID === locationId);
    let address = null;

    if (location && !(isNullOrWhitespace(location.street) && isNullOrWhitespace(location.city))) {
      const part1 = `${[location.street, location.cityPart, location.city].find(t => !isNullOrWhitespace(t))} ${location.streetNumber}`;
      address = [part1, `${location.zipCode} ${location.city}`].filter(p => !isNullOrWhitespace(p)).map(p => p.trim()).join(', ');
    }
    this.form.get('address').setValue(address);
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.descriptionInput.resizeToFitContent(true));
  }

  addPhoto($event: Event) {
    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }
    const inpPhoto = this.inputPhotoRef;
    inpPhoto.change = ($inpEvent: Event) => {
      const f = ($inpEvent.target as HTMLInputElement).files[0];

      if (!f) {
        return;
      }

      this.fileUploading$ = true;
      this.cd.markForCheck();

      return this.fileController.UploadFile(f, $event.target as HTMLElement)
        .pipe(filter(p => !!p))
        .subscribe(p => {
          this.taskPhotos.push({
            recordUid: p.recordUID,
            fileName: p.fileName
          });
          this.cd.detectChanges();
        });
    };
    inpPhoto.open();
  }

  addAttachment($event: Event) {
    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }
    const inpAttachment = this.inputAttachmentRef;
    inpAttachment.change = ($inpEvent: Event) => {
      const f = ($inpEvent.target as HTMLInputElement).files[0];

      if (!f) {
        return;
      }

      this.fileUploading$ = true;
      this.cd.markForCheck();

      return this.fileController.UploadFile(f, $event.target as HTMLElement)
        .subscribe(p => {
          this.taskAttachments.push({
            documentUid: p.recordUID,
            name: p.fileName
          });
          this.cd.detectChanges();
        });
    };
    inpAttachment.open();
  }

  removeAttachment(attachment) {
    this.taskAttachments = this.taskAttachments.filter(ta => ta.documentUid !== attachment.documentUid);
  }

  getImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/TaskerFile/GetImage', imgGuid].join('/')
      + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  getTempImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/TenantTasker/File/gettempfile', imgGuid].join('/')
      + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  zoomAttachmentImage(photo: any) {
    const imageUrl = !photo.recordUid ?
      this.getImageUrl(photo.taskerFileUid, window.innerWidth, window.innerHeight) :
      this.getTempImageUrl(photo.recordUid, window.innerWidth, window.innerHeight);

    const callBack = (event) => {
      this.taskPhotos = this.taskPhotos
        .filter((p: any) =>
          (!!photo.taskerFileUid && p.taskerFileUid !== photo.taskerFileUid) ||
          (!!photo.recordUid && p.recordUid !== photo.recordUid));
      this.cd.detectChanges();
    };

    this.zoom(imageUrl, callBack);
  }

  private zoom(imageUrl: string, deleteCallback: (event) => void) {
    const cloak = document.createElement('div');
    cloak.classList.add('pictureView');

    const closeButton = document.createElement('div');
    closeButton.classList.add('closeButton');
    closeButton.innerText = this.translate.instant('tasker.task-activities.image-close-btn');

    const deleteButton = document.createElement('div');
    deleteButton.classList.add('deleteButton');
    deleteButton.innerText = this.translate.instant('tasker.common.delete');
    deleteButton.onclick = deleteCallback;

    const picContainer = document.createElement('div');
    picContainer.classList.add('pictureContainer');

    picContainer.style.backgroundImage = 'url(\'' + imageUrl + '\')';
    cloak.appendChild(closeButton);
    cloak.appendChild(deleteButton);
    cloak.appendChild(picContainer);
    cloak.onclick = () => this.hidePictureView(cloak);
    document.body.appendChild(cloak);
  }

  async addAssignees(item: any) {
    const resolveRequestModel = {
      selectedJobID: this.loggedUser.selectedJobID,
      userID: this.loggedUser.currentUser.userId,
      userSelectors: [item]
    };
    const userIDs = await this.jobController.Resolve(resolveRequestModel).toPromise();
    if (userIDs.length === 0) {
      this.contactInputAssignee.nativeElement.value = '';
      return;
    }

    let users = await lastValueFrom(this.jobController.PlanningUsers({ users: userIDs, roles: [], jobs: [] })
                                        .pipe(map(u => u.sort((a, b) => b.name.localeCompare(a.name)))));

    const taskUsersControl = this.form.controls.assignee as FormArray;
    const taskUsers = taskUsersControl.value;
    const existingUserIDs = new Set(taskUsers.map(a => a.userId));
    users = users.filter(u => !existingUserIDs.has(u.id));

    users.forEach(u => taskUsersControl.insert(0,
      this.fb.control({ userId: u.id, taskRelation: TaskRelation.assignee, name: u.name })));
    this.contactInputAssignee.nativeElement.value = '';
    this.cd.detectChanges();
  }

  hidePictureView(sender) {
    document.body.removeChild(sender);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if (!!s) {
        s.unsubscribe();
      }
    });
  }
}
