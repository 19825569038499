import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteDialogData,
              private cd: ChangeDetectorRef,
              private dialogRef: MatDialogRef<DeleteDialogComponent>) {
  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  reloadPage() {
    window.location.reload();
  }
}


export interface DeleteDialogData {
  deleteTitle?: string;
  deleteQuestion?: string;
}