import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import { BehaviorSubject } from 'rxjs';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

@Component({
  selector: 'task-sequence-delete',
  templateUrl: './task-sequence-delete.component.html',
  styleUrls: ['./task-sequence-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSequenceDeleteComponent implements OnInit {

  icClose = icClose;

  // Avoid delete same day deadline
  public startDateMin: Date = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 0, 0, 0);

  public endDate: Date;

  numberOfDeleted: BehaviorSubject<number> = new BehaviorSubject(0);
  deleting: BehaviorSubject<boolean> = new BehaviorSubject(false);


  public model: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> = [];

  form: FormGroup = null;
  private fb: FormBuilder = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<TaskSequenceDeleteComponent>,
              private cd: ChangeDetectorRef,
              @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
              fb: FormBuilder
              ) {
    this.fb = fb;
    this.model = this.data.items;
    this.form = this.fb.group({
      dateStart: this.fb.control(this.startDateMin, Validators.min(this.startDateMin.getTime())),
      dateEnd: this.fb.control(null)
    });
  }

  async ngOnInit() {
    this.form.get('dateEnd').setValue(this.model.map(m => new Date(m.startDate)).sort((a, b) => b.getTime() - a.getTime())[0]);

    this.form.valueChanges.subscribe(v => {
      this.calcItemsToDelete(v);
    });

    this.calcItemsToDelete(this.form.getRawValue());
  }

  startDateFilter = (d: Date | null): boolean => {
    if (!d) { return false; }
    return d >= this.startDateMin;
  }

  private calcItemsToDelete(v: any) {
    const itemsToDelete = this.model.filter(m => new Date(m.startDate) >= v.dateStart &&
                                  (!v.dateEnd ||
                                    new Date(new Date(m.startDate).getFullYear(),
                                             new Date(m.startDate).getMonth(),
                                             new Date(m.startDate).getDate()) <= v.dateEnd
                                  )
                                );
    this.numberOfDeleted.next(itemsToDelete.length);
  }

  public async delete() {
    if (!this.form.valid) {
      return;
    }
    this.deleting.next(true);
    const val = this.form.getRawValue();
    const deletedTasks = await this.taskController.DeleteSequence(val.dateStart.toJSON(),
                                                                  !!val.dateEnd ? val.dateEnd.toJSON() : null,
                                                                  this.model[0].taskSequenceID).toPromise();
    this.deleting.next(false);
    this.dialogRef.close(deletedTasks);
  }
}
