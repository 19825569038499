import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PromptDialogService } from '../../common/prompt-dialog/prompt-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskNewComponent } from '../task-new/task-new.component';
import { AppState } from 'src/app/app.states';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Globals } from 'src/app/common/globals';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { TaskSequenceDeleteComponent } from '../task-sequence-delete/task-sequence-delete.component';

@Component({
  selector: 'task-sequence',
  templateUrl: './task-sequence.component.html',
  styleUrls: ['./task-sequence.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class TaskSequenceComponent implements OnInit, AfterViewInit, OnDestroy {

  _task: Honeycomb.Tenant.Tasker.IService.Model.Task;

  uiroles: string[] = [];
  private roleDebug = [];

  subscriptions: Array<Subscription> = [];

  @Input()
  tasks: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> = of([]);

  @Input()
  currentTask: Honeycomb.Tenant.Tasker.IService.Model.Task = null;


  loading$: Observable<boolean>;
  pageEvent: any;
  defaultRecords = 10;

  items: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> = [];
  filteredArray: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> = [];

  @Output()
  redirect: EventEmitter<number> = new EventEmitter();

  @Output()
  deleted: EventEmitter<Array<number>> = new EventEmitter();

  @Output()
  refresh: EventEmitter<boolean> = new EventEmitter();

  ready: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private store: Store<AppState>,
              private trans: TranslateService,
              private cd: ChangeDetectorRef,
              private dialogOver: MatDialog,
              private globals: Globals,
              @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController)
  {
    this.store.pipe(select(x => x.user)).subscribe(u => this.uiroles = u.uiroles);
  }

  async ngOnInit() {
    const s = this.tasks.subscribe(tasks => {
      this.items = tasks;
      this.filteredArray = this.items.slice(0, this.defaultRecords);
      this.ready.next(true);
    });

    this.subscriptions.push(s);
  }

  public async reload() {
  }

  uiRole(suffix: string) {
    const reqUiRole = `tasker.task-sequence.${suffix}`;

    if (this.roleDebug.findIndex(r => r === reqUiRole) === -1) {
      this.globals.DebugOut('task-sequence', reqUiRole);
      this.roleDebug.push(reqUiRole);
    }

    return reqUiRole;
  }

  hasPermission(suffix: string) {
    return this.uiroles.indexOf(this.uiRole(suffix)) > -1;
  }

  onPaginateChange(data) {
    this.filteredArray = this.items.slice(data.pageSize * data.pageIndex, data.pageSize * data.pageIndex + data.pageSize);
  }

  selected($event) {
    this.redirect.emit($event);
  }

  ngAfterViewInit(): void {
  }

  async sequenceDelete() {
    await this.dialogOver.open(TaskSequenceDeleteComponent, { data: { items: this.items }})
                              .afterClosed()
                              .toPromise()
                              .then(res => this.deleted.emit(res));
  }

  async sequenceProlong() {
    let lastTask = null;
    if (this.items.length > 0) {
      lastTask = this.items.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())[0];
    }

    await this.dialogOver.open(TaskNewComponent,
      { data: {
        taskSequenceID: this.items[0].taskSequenceID,
        defaults: this.currentTask,
        sequenceStart: lastTask.startDate
      }})
                                    .afterClosed()
                                    .toPromise()
                                    .then(_ => this.refresh.emit(true));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
