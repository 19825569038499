import { Action, createReducer, on } from '@ngrx/store';
import { pipe } from 'rxjs';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import * as NotificationBellActions from './notification-bell.actions';
import { NotificationBellState } from './notification-bell.state';

const NotificationBellReducer = createReducer(NotificationBellState(),

// tslint:disable: max-line-length
  on(NotificationBellActions.bellConnectionStatusUpdateAction, (state, { online, error }) => ({...state, loading: true, error, online})),
  on(NotificationBellActions.bellConnectionStatusUpdateFinishedAction, (state) => ({ ...state, loading: false })),
  on(NotificationBellActions.bellDataUpdatedAction, (state, { data }) => ({ ...state, data })),
  on(NotificationBellActions.bellDataUpdatedFinishedAction, (state) => ({ ...state })),
  on(NotificationBellActions.bellItemUpdatedAction, (state, { item }) => setItemReducer(state, item)),
  on(NotificationBellActions.bellItemUpdatedFinishedAction, (state) => ({ ...state })),
  on(NotificationBellActions.bellSetReadAction, (state, { ids }) => setAsReadReducer(state, ids)),
  on(NotificationBellActions.bellSetReadFinishedAction, (state) => ({ ...state }))
);

export function reducer(state: NotificationBellState | undefined, action: Action) {
  return NotificationBellReducer(state, action);
}

export function setAsReadReducer(state: NotificationBellState, ids: number[]) {
  const s = Object.assign({}, state);
  s.data = state.data.map(m => Object.assign({}, m));
  s.data.filter(a => !a.read && ids.indexOf(a.notificationBellQueueID) > -1).map(a => a.read = new Date());
  return s;
}

export function setItemReducer(state: NotificationBellState, item: HoneycombCustom.Tenant.Tasker.IService.Model.NotificationBellModel) {
  const s = Object.assign({}, state);
  s.data = state.data.map(m => Object.assign({}, m));
  var existingNotification = s.data.find(a => a.notificationBellQueueID === item.notificationBellQueueID);

  if (!!existingNotification) {
    Object.assign(existingNotification, item);
  } else {
    s.data.splice(0, 0, item);
  }
  return s;
}