import { Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

import twotoneBorderColor from '@iconify/icons-ic/twotone-border-color';
import twotoneSwapVerticalCircle from '@iconify/icons-ic/twotone-swap-vertical-circle';
import twotoneCreateNewFolder from '@iconify/icons-ic/twotone-create-new-folder';
import twotoneDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import twotoneInfo from '@iconify/icons-ic/twotone-info';
import twotoneEdit from '@iconify/icons-ic/twotone-edit';
import roundDoneOutline from '@iconify/icons-ic/round-done-outline';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { Globals } from 'src/app/common/globals';
import { take, filter, catchError } from 'rxjs/operators';
import { zoom } from 'src/app/common/functions';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AppState } from 'src/app/app.states';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { TranslateService } from '@ngx-translate/core';
import twotoneImageSearch from '@iconify/icons-ic/twotone-image-search';
import twotoneAddAPhoto from '@iconify/icons-ic/twotone-add-a-photo';
import roundAddCircleOutline from '@iconify/icons-ic/round-add-circle-outline';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptDialogService } from '../../common/prompt-dialog/prompt-dialog.service';
import { TaskDetailComponent } from '../task-detail/task-detail.component';

@Component({
  selector: 'task-repair-activities',
  templateUrl: './task-repair-activities.component.html',
  styleUrls: ['./task-repair-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class TaskRepairActivitiesComponent implements OnInit, AfterViewInit, OnDestroy {

  twotoneBorderColor = twotoneBorderColor;
  twotoneSwapVerticalCircle = twotoneSwapVerticalCircle;
  twotoneCreateNewFolder = twotoneCreateNewFolder;
  twotoneDeleteForever = twotoneDeleteForever;
  twotoneInfo = twotoneInfo;
  twotoneEdit = twotoneEdit;
  roundDoneOutline = roundDoneOutline;
  twotoneImageSearch = twotoneImageSearch;
  twotoneAddAPhoto = twotoneAddAPhoto;
  roundAddCircleOutline = roundAddCircleOutline;

  tenantHash: string = null;

  selectedStepper = 0;
  selectedActivity: number;

  inputUpdating = false;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  statuses$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>> = of([]);

  @Input() form: FormGroup;
  @Input() task: Honeycomb.Tenant.Tasker.IService.Model.Task;
  @Input() detailComponent: TaskDetailComponent;

  origValue: any = {
    taskActivitiesUpsert: []
  };

  subscriptions: Array<Subscription> = [];

  constructor(
    private formBuilder: FormBuilder,
    @Inject('TaskActivityController') private activityController: Honeycomb.Tenant.Tasker.IService.Controller.TaskActivityController,
    private globals: Globals,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private promptDialog: PromptDialogService
    ) {
    this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);
  }

  ngOnInit() {
    this.form.addControl('selectedActivity', this.formBuilder.control(this.form.controls.taskActivitiesUpsert.value[0].activityID));


    this.origValue = JSON.parse(JSON.stringify(this.form.getRawValue()));

    const s = this.form.get('taskActivitiesUpsert').valueChanges
      .subscribe(async (ta: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivityUpsert>) => {
        this.loading$.next(true);
        ta.forEach( async (a, ix) => {
          if (this.inputUpdating) { 
            return;
          }
          
          if (this.origValue.taskActivitiesUpsert[ix].activityDone !== a.activityDone) {
            let isNewSnapshot = false;
            if (this.origValue.taskActivitiesUpsert[ix].isSnapshot) {
               var res = await lastValueFrom(this.activityController.TaskActivitySnapshotStatus({
                activityDone: a.activityDone,
                activityID: a.activityID
              }));
              this.form.get("taskStatusID").setValue(res.taskStatusID);           
            } else {
              var res2 = await lastValueFrom(this.activityController.TaskActivityStatus({
                activityDone: a.activityDone,
                taskActivityID: a.taskActivityID
              }));
              this.form.get("taskStatusID").setValue(res2.taskStatusID);
              isNewSnapshot = !!res2.operationSnapshotID;
            }
            // NOTE: If snapshot has been created during status update (single activity, set 'finished' after first input update),
            // the whole model must be updated from DB (because now snapshot values have inputIDs)
            // otherwise new value won't be saved
            if (isNewSnapshot) {

              this.inputUpdating = true;
              this.form.get('taskActivitiesUpsert').disable({onlySelf: true, emitEvent: true});
              (<FormArray>this.form.get('taskActivitiesUpsert')).controls[ix].get('isSnapshot').disable({onlySelf: true, emitEvent: true});
              this.cd.detectChanges();
              (<FormArray>this.form.get('taskActivitiesUpsert')).controls[ix].get('isSnapshot').setValue(isNewSnapshot, { emitEvent: false });
              let t = await this.detailComponent.refreshTask(this.form.get('taskID').value);
              await this.detailComponent.loadFormInternal(t, false);
              this.inputUpdating = false;
              (<FormArray>this.form.get('taskActivitiesUpsert')).controls[ix].get('isSnapshot').enable({onlySelf: true, emitEvent: false});
              this.form.get('taskActivitiesUpsert').disable({onlySelf: true, emitEvent: false});
              this.cd.detectChanges();
            }

            this.origValue = JSON.parse(JSON.stringify(this.form.value));
          }
        });
        this.loading$.next(false);
    });

    this.subscriptions.push(s);

    const s1 = this.form.controls.taskActivitiesUpsert.valueChanges
        .pipe(filter(t => !!t && t.length > 0), take(1))
        .subscribe(t => {
           this.form.controls.selectedActivity.setValue(t[0].activityID);
        });

    this.subscriptions.push(s1);
  }

  ngAfterViewInit(): void {
  }

  getActivities(form) {
    return form.controls.taskActivitiesUpsert.controls;
  }

  getActivityInputs(taskActivity) {
    return taskActivity.controls.activityInputs.controls.sort((c, d) => c.value.sortingOrder - d.value.sortingOrder);
  }

  taskActivityStepSelected( $event: StepperSelectionEvent) {
    this.selectedStepper = $event.selectedIndex;
  }

  taskActivitySelected( $event: MatSelectChange) {
    if (!$event.value) {
      this.selectedStepper = 0;
      return;
    }
    this.selectedStepper = this.form.controls.taskActivitiesUpsert.value.map(v => v.activityID).indexOf($event.value);
  }

  taskActivityIsClosed(taskActivity) {
    return taskActivity.value.activityDone;
  }

  getActivityIndex(activityID: number) {
    return this.form.controls.taskActivitiesUpsert.value.map(v => v.activityID).indexOf(activityID);
  }

  getImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/TaskerFile/GetImage', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  getActivityNameByID(activityID: number) {
    return this.form.controls.taskActivitiesUpsert.value.find(v => v.activityID === activityID);
  }

  zoomAttachmentImage(photo: any) {
    const imageUrl = this.getImageUrl(photo.taskerFileUid, window.innerWidth, window.innerHeight);
    zoom(imageUrl, this.translate.instant('tasker.common.close'));
  }

  async noteUpdated($event: string, activityInput: FormGroup) {
    const taskActivityID = activityInput.parent.parent.value.taskActivityID;
    const meaningID = activityInput.value.meaningID;
    await this.activityController.UpsertNote({ taskActivityID, meaningID, note: $event }).toPromise();
  }

  async valueUpdated($event: any, activityInput: FormGroup) {
    const taskActivityID = activityInput.parent.parent.value.taskActivityID;
    const meaningID = activityInput.value.meaningID;


    if (activityInput.value.isSnapshot) { 
      const updatedSnapshotValue = await firstValueFrom(this.activityController
      .UpsertSnapshotValue({ 
        inputID: activityInput.value.inputID, 
        value: $event.newValue
      })
      .pipe(
        catchError((e) =>
        {
          this.promptDialog.showErrorDialog(
            this.translate.instant('tasker.task-activities.update-failed'),
            this.translate.instant('tasker.task-activities.update-failed-title'));
          return of(null);
        }
        )));
      // .toPromise<Honeycomb.Tenant.Tasker.IService.Model.TaskValue>();

      if (activityInput.value.uitype === Honeycomb.Common.Enums.UiType.file) {
        activityInput.patchValue({recordUid: (updatedSnapshotValue).value}, { emitEvent: false });
        activityInput.patchValue({valueUpdated: (updatedSnapshotValue).validFromUTC}, { emitEvent: false });
      } else {
        activityInput.patchValue({value: (updatedSnapshotValue).value}, { emitEvent: false });
      }



    } else {
      const updatedValue = await this.activityController.UpsertValue({ taskActivityID, meaningID, value: $event.newValue })
      .pipe(
        catchError((e) => { this.snackbar.open(
          this.translate.instant('tasker.input.update-failed'), null, { duration: 2000 });
                            return of(null);
        }
      ))
      .toPromise();
      if (updatedValue === null) {
      return;
      }

      activityInput.patchValue({taskValueID: updatedValue.taskValueID}, { emitEvent: false });
      activityInput.patchValue({value: updatedValue.value}, { emitEvent: false });
    }
  }

  async valuePhotoInsert($event: any, activityInput: FormGroup) {
    const taskActivityID = activityInput.parent.parent.value.taskActivityID;
    const meaningID = activityInput.value.meaningID;

    if (activityInput.value.isSnapshot) {
      const inputID = activityInput.value.inputID;
      const updatedValue = await firstValueFrom(this.activityController
      .ValuePhotoInsertSnapshot({ inputID, recordUid: $event})
      .pipe(
         catchError((e) => { this.snackbar.open(
           this.translate.instant('tasker.input.update-failed'), null, { duration: 2000 });
                             return of(null);
         }
       )));
      // .toPromise<Honeycomb.Tenant.Tasker.IService.Model.InputValuePhotoSnapshot>();

      if (updatedValue === null) {
        this.cd.markForCheck();
        return;
      }

      const valPhotos = activityInput.get('taskValuePhotos').value;
      // replace tem value /w new one

      const ixToReplace = valPhotos.findIndex(v => v.recordUid === $event);
      if (ixToReplace !== -1) {
        valPhotos[ixToReplace].recordUid = null;
        valPhotos[ixToReplace].taskerFileUid  = updatedValue.taskerFileUid;
      }
      activityInput.patchValue({taskValuePhotos: valPhotos}, { emitEvent: false });

    }
    else {

      const updatedValue = await firstValueFrom(this.activityController
                                    .ValuePhotoInsert({ meaningID, recordUid: $event, taskActivityID })
                                    .pipe(
                                        catchError((e) => { this.snackbar.open(
                                          this.translate.instant('tasker.input.update-failed'), null, { duration: 2000 });
                                                            return of(null);
                                        }
                                      )));
                                    //.toPromise<Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhoto>();
      if (updatedValue === null) {
        return;
      }
      const valPhotos = activityInput.get('taskValuePhotos').value;
      // replace tem value /w new one

      const ixToReplace = valPhotos.findIndex(v => v.recordUid === $event);
      if (ixToReplace !== -1) {
        valPhotos[ixToReplace] = updatedValue;
      }
      activityInput.patchValue({taskValueID: updatedValue.taskValueID}, { emitEvent: false });
      activityInput.patchValue({taskValuePhotos: valPhotos}, { emitEvent: false });
    }
    this.cd.detectChanges();
  }

  async valuePhotoRemove($event: any, activityInput: any) {
    if (!$event.taskValuePhotoID) {
      return;
    }
    const updatedValue = await this.activityController
                                   .ValuePhotoRemove($event.taskValuePhotoID).toPromise();
    let valPhotos = activityInput.get('taskValuePhotos').value;
    // replace tem value /w new one

    valPhotos = valPhotos.filter(v => v.taskValuePhotoID !== $event.taskValuePhotoID);
    activityInput.patchValue({taskValuePhotos: valPhotos}, { emitEvent: false });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
