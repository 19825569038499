import { IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { BuildConfigService } from "../services/build-config.service";

/**
 * MUST READ:
 * https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-angular-auth-code
 */
export function MSALInstanceFactory(buildConfig: BuildConfigService ) : IPublicClientApplication {

    if (!buildConfig.initialized) {
        return new PublicClientApplication({auth: { clientId: null }});    
    }

    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    return new PublicClientApplication({
        auth: {
            // NG Retail
            //clientId: "45363671-8615-462d-a299-ba46747c668f",
            // sportisimo
            // clientId: "a77a40cf-8a41-4544-8550-c94345f27b14",
            // redirectUri: "http://localhost:8081",
            // postLogoutRedirectUri: "http://localhost:8081"
            clientId: buildConfig.azureLogin.clientID,
            redirectUri: buildConfig.azureLogin.redirectUri,
            postLogoutRedirectUri: buildConfig.azureLogin.postLogoutRedirectUri,
        },
        system: {
            loadFrameTimeout: 20000,
            iframeHashTimeout: 20000,
            loggerOptions: {
                loggerCallback: (
                    level: LogLevel,
                    message: string,
                    containsPii: boolean
                  ): void => {
                    if (containsPii) {
                      return;
                    }
                    switch (level) {
                      case LogLevel.Error:
                        console.error(message);
                        return;
                      case LogLevel.Info:
                        console.info(message);
                        return;
                      case LogLevel.Verbose:
                        console.debug(message);
                        return;
                      case LogLevel.Warning:
                        console.warn(message);
                        return;
                    }
                  },
                  piiLoggingEnabled: false,
            }

        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE
        },
        telemetry: {
            application: {
                appName: 'CRS',
                appVersion: '0.0.0.0'
            }
        }
    });

}