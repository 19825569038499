import { Component, TemplateRef, ViewChild, ViewEncapsulation, 
         ChangeDetectionStrategy, Inject, ChangeDetectorRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject, Observable, of, Subscription, lastValueFrom, map, BehaviorSubject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { addDays, isSameMonth } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';
import { TranslateService } from '@ngx-translate/core';
import { WeekViewHourColumn } from 'calendar-utils';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { TaskDetailComponent } from 'src/app/pages/tasks/task-detail/task-detail.component';
import { TaskNewComponent } from 'src/app/pages/tasks/task-new/task-new.component';
import { MeetingNewComponent } from 'src/app/pages/meeting/meeting-new/meeting-new.component';
import { StoreVisitNewComponent } from 'src/app/pages/stores/store-visit/store-visit-new.component';

import icChevronLeft from '@iconify/icons-ic/twotone-chevron-left';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import theme from 'src/@vex/utils/tailwindcss';
import icSearch from '@iconify/icons-ic/twotone-search';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icAdd from '@iconify/icons-ic/twotone-add';
import baselineEventAvailable from '@iconify/icons-ic/baseline-event-available';
import icStore from '@iconify/icons-ic/twotone-store';
import baselineForum from '@iconify/icons-ic/baseline-forum';
import { ConfigService } from 'src/@vex/services/config.service';


const colors: any = {
  blue: {
    primary: theme.colors.primary['500'],
    secondary: theme.textColor['primary-contrast']['500']
  },
  yellow: {
    primary: theme.colors.amber['500'],
    secondary: '#FDF1BA'
  },
  red: {
    primary: theme.colors.red['500'],
    secondary: 'white'
  },
  shadow: {
    primary: 'white',
    secondary: 'var(--foreground-divider)'
  }
};

@Component({
  selector: 'calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar.component.scss'],
  templateUrl: './calendar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit  {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  @Input() tasks: Honeycomb.Tenant.Tasker.IService.Model.Task[];

  @Output() taskUpdated: EventEmitter<number> = new EventEmitter();

  @Input() viewDate: Date = new Date();

  @Output() dateSelected: EventEmitter<Date> = new EventEmitter();

  layoutCtrl = new FormControl('fullwidth');

  icSearch = icSearch;
  icFilterList = icFilterList;
  icAdd = icAdd;
  icStore = icStore;
  icTask = baselineEventAvailable;
  icForum = baselineForum;
  icChevronLeft = icChevronLeft;
  icChevronRight = icChevronRight;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  initialized: Observable<boolean> = of(true);

  selectedDayViewDate: Date;
  hourColumns: WeekViewHourColumn[];

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  events: CalendarEvent[] = [];

  activeDayIsOpen = true;
  fabActionsVisible = false;
  subscriptions: Array<Subscription> = [];
  statuses: Honeycomb.Tenant.Tasker.IService.Model.TaskStatus[] = [];
  statusesAll$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>>;
  langIsoCode$: BehaviorSubject<string> = new BehaviorSubject('cs');

  filter = new Honeycomb.Tenant.Tasker.IService.TaskListFilter();

  constructor(private dialog: MatDialog,
              private trans: TranslateService,
              private cd: ChangeDetectorRef,
              private config: ConfigService,
              private store: Store<AppState>,
              @Inject('TaskStatusController') private taskStatusController: Honeycomb.Tenant.Tasker.IService.Controller.TaskStatusController,
              @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController) {

              this.filter.dateFrom = addDays(Date.now(), - 60);
              this.filter.dateTo = addDays(Date.now(), 60);
              this.filter.dateTo.setHours(23, 59, 59, 999);
              this.filter.userTaskRelations = [
                Honeycomb.Common.Enums.TaskRelation.assignee,
                Honeycomb.Common.Enums.TaskRelation.assigner,
                Honeycomb.Common.Enums.TaskRelation.creator
              ];
  }
  async ngOnInit() {

    this.statusesAll$ = this.taskStatusController.List(null, null);
    this.statuses = await lastValueFrom(this.statusesAll$);

    this.langIsoCode$.next(this.config.getLanguage());

    const userSub = this.store.pipe(select(s => s.user))
    .subscribe(u => {
      if (!!u && !!u.currentUser) {
        this.filter.jobID = u.selectedJobID;
      }
      this.loadEvents();
    });

    this.subscriptions.push(userSub);

     this.loadEvents();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if (s)  {
        s.unsubscribe();
      }
    });
  }

  private loadEvents() {
    this.events = [];
    if (!this.tasks || this.tasks.length === 0) {
      return;
    }
    this.tasks.forEach(t => {
      const startDate = ((!!t.startDate) ? new Date(t.startDate as unknown as string) : new Date());
      const endDate = ((!!t.deadline) ? new Date(t.deadline as unknown as string) : new Date());
      let opName = t.operation.name;

      // const taskState = Honeycomb.Common.Enums.TaskState[t.taskStatusBase];
      let status = '';
      const taskState = this.statuses.find(ts => ts.taskStatusID == t.taskStatusID);
      if (taskState) {
        status = ` - <span class="task-state-${taskState.code}">${taskState.name}</span>`;
      }

      opName = opName.split(' ').filter(c => c.length > 0).map(m => m[0].toLocaleUpperCase()).join('');
      let title = '<b>' + t.name + '</b>&nbsp;' + '#' + t.taskID + status;
      if (!!t.locationName && t.locationName.length > 0) {
        title += '<br>' + t.locationName
      }

      if (!!opName && opName.length > 0) {
        title = opName + ' - ' + title;
      }
      const e = {
        start: startDate,
        end: endDate,
        title,
        allDay: startDate.getHours() === 0,
        draggable: false,
        color: this.priorityToColor(t.priority),
        meta: t
      } as CalendarEvent;
      this.events.push(e);
    });
    this.cd.detectChanges();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (true || isSameMonth(date, this.viewDate)) {
      // this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0);
      this.activeDayIsOpen = true;
      this.viewDate = date;
      this.dateSelected.emit(date);
    }
  }

  priorityToColor(priority: Honeycomb.Common.Enums.Priority): { primary: string; secondary: string; } {
    switch (priority) {
      case Honeycomb.Common.Enums.Priority.critical:
        return colors.red;
      case Honeycomb.Common.Enums.Priority.high:
        return colors.yellow;
      default:
        return colors.blue;
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.dialog.open(TaskDetailComponent, {
        data: event.meta
    }).afterClosed()
    .toPromise()
    .then(
      () => {
          this.taskUpdated.emit(event.meta.taskID);
          // this.loadEvents();
        }
      );
  }

  addTask(): void {
    this.dialog.open(TaskNewComponent,
      { data: {defaults: this.getDialogDefaults() }})
      .afterClosed().toPromise().then(() => this.loadEvents());
  }

  addMeeting() {
    this.dialog.open(MeetingNewComponent, 
      { data: this.getDialogDefaults() })
      .afterClosed().toPromise().then(() => this.loadEvents());
  }

  addStoreVisit() {
    this.dialog.open(StoreVisitNewComponent, 
      { data: this.getDialogDefaults() })
      .afterClosed().toPromise().then(() => this.loadEvents());
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  hourSegmentClicked({ date }) {
    this.selectedDayViewDate = date;
    this.addSelectedDayViewClass();
  }

  beforeWeekViewRender(event: CalendarWeekViewBeforeRenderEvent) {
    this.hourColumns = event.hourColumns;
    this.addSelectedDayViewClass();
  }

  private addSelectedDayViewClass() {
    this.hourColumns.forEach(column => {
      column.hours.forEach(hourSegment => {
        hourSegment.segments.forEach(segment => {
          delete segment.cssClass;
          if (
            this.selectedDayViewDate &&
            segment.date.getTime() === this.selectedDayViewDate.getTime()
          ) {
            segment.cssClass = 'cal-segment-selected';
          }
        });
      });
    });
  }

  private getDialogDefaults() {
    return {
      agenda: TaskAgenda.MyTasks,
      useTime: true,
      startDate: this.view === CalendarView.Month ? this.viewDate : this.selectedDayViewDate,
    };
  }
}
