import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFromUtc'
})
export class DateFromUtcPipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
        return null;
    }

    // JSON format "2021-05-10T11:48:08.47" or "2021-08-16T08:08:40"
    if (typeof(value) === 'string') {
        const b = value.split(/\D+/);
        return new Date(Date.UTC(Number(b[0]), Number(b[1]) - 1, Number(b[2]), Number(b[3]), Number(b[4]), Number(b[5])));
    }

    // Should never get here, but can handle the rest
    const tmpDate = new Date((Date.parse(value)));
    tmpDate.setMinutes(tmpDate.getMinutes() - tmpDate.getTimezoneOffset());
    return tmpDate;
}

}
