<vex-page-layout mode="simple">
  <vex-page-layout-content fxLayoutGap="10px" fxLayout="column">
    <stores-main [showReport]="true"
                 [showAdditionalInfo]="true" 
                 [showOnlyUserRelated]="true"
                 [showMyStoreDetail]="true"
                 [showMap]="true"
                 [title]="'tasker.menu.my-stores'">

    </stores-main>
  </vex-page-layout-content>
</vex-page-layout>