import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogButtonType, DialogData, DialogDataInputType, DialogResult } from './prompt-dialog.model';

@Component({
    selector   : 'prompt-dialog-component',
    templateUrl: './prompt-dialog.component.html',
    styleUrls  : ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements AfterViewInit
{
    public inputType = DialogDataInputType;
    @ViewChild('promptinput') promptinput: ElementRef;

    form: FormGroup;
    /**
     * Constructor
     *
     * @param {MatDialogRef<PromptDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<PromptDialogComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    )
    {
        if (this.data.inputType !== DialogDataInputType.none) {
            this.form = this.formBuilder.group({
                input   : ['', [Validators.required]]
            });
        }
    }

    ngAfterViewInit(): void {
    }

    public buttonClicked( button ): void {
        const r = new DialogResult();
        r.button = button;
        if (this.data.inputType !== DialogDataInputType.none) {
            r.value = this.form.get('input').value;
        }
        this.dialogRef.close( r );
    }

    public onKeydown(event): void {
        if (event.key === 'Enter') {
          this.buttonClicked(DialogButtonType.ok);
        }
      }
}
