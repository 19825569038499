import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export class TaskListSortModel {
    public static get sortItemsDefault()
    {
        return [
            { column: 'taskID', direction: Honeycomb.Common.SortDirection.desc },
            { column: 'priority', direction: Honeycomb.Common.SortDirection.desc },
            { column: 'deadline', direction: Honeycomb.Common.SortDirection.asc },
            { column: 'name', direction: Honeycomb.Common.SortDirection.asc }
        ];
    }
}
