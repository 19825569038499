import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  delay
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  bellConnectionStatusUpdateAction,
  bellConnectionStatusUpdateFinishedAction,
  bellDataUpdatedFinishedAction,
  bellDataUpdatedAction,
  bellSetReadAction,
  bellSetReadFinishedAction,
  bellItemUpdatedAction,
  bellItemUpdatedFinishedAction
} from './notification-bell.actions';
import { AppState } from 'src/app/app.states';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/common/globals';
import { BellNotificationService } from 'src/@vex/services/bell-notification.service';

@Injectable()
export class NotificationBellEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private globals: Globals,
    private store: Store<AppState>,
    private bellService: BellNotificationService
  ) {}

  setBellConnectionStatusEffect = createEffect(() => this.actions$.pipe(
    ofType(bellConnectionStatusUpdateAction),
    map(() => {
        return bellConnectionStatusUpdateFinishedAction();
    })
  ));


  setBellItemUpdatedEffect = createEffect(() => this.actions$.pipe(
    ofType(bellItemUpdatedAction),
    map(() => {
        return bellItemUpdatedFinishedAction();
    })
  ));

  setBellDataUpdateEffect = createEffect(() => this.actions$.pipe(
    ofType(bellDataUpdatedAction),
    map(() => {
        return bellDataUpdatedFinishedAction();
    })
  ));

  setBellReadEffect = createEffect(() => this.actions$.pipe(
    ofType(bellSetReadAction),
    withLatestFrom(this.store),
    map(([data, store]) => {
        if (!data || !data.ids) {
          return bellSetReadFinishedAction();
        }
        data.ids.map(async (id) => {
        return await this.bellService.setNotificationAsRead(id);
      });
        return bellSetReadFinishedAction();
    })
  ));

}
