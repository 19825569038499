import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { MatButtonModule } from '@angular/material/button';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { WidgetTableModule } from 'src/@vex/components/widgets/widget-table/widget-table.module';
import { ColorFadeModule } from 'src/@vex/pipes/color/color-fade.module';
import { SharedModule } from 'src/app/shared.module';
import { StoresMainModule } from './stores-main/stores-main.module';
import { StoresRoutingModule } from './stores-routing.module';
import { StoreItemModule } from './store-item/store-item.module';

import { MyStoreDetailModule } from './my-store-detail/my-store-detail.module';
import { PopoverTableModule } from 'src/@vex/components/popover-table/popover-table.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoresRoutingModule,
    StoresMainModule,
    StoreItemModule,
    SharedModule,
    MyStoreDetailModule,

    PageLayoutModule,
    SecondaryToolbarModule,
    MatButtonModule,

    ContainerModule,
    WidgetTableModule,
    PageLayoutModule,
    ColorFadeModule,

    PopoverTableModule
  ],
  providers: [
    Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
    Honeycomb.Tenant.Reports.IService.ReportController
  ],
  entryComponents: [
  ]
})
export class StoresModule { }
