import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonRatingComponent } from './button-rating.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { IconModule } from '@visurel/iconify-angular';

@NgModule({
  declarations: [ButtonRatingComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    IconModule
  ],
  exports: [ButtonRatingComponent]
})
export class ButtonRatingModule {
}
