import { createFeatureSelector, MetaReducer, ActionReducer, Action } from '@ngrx/store';

import * as auth from './api/auth/auth.reducer';
import * as myTasks from './api/tasks/my-tasks/my-tasks.reducer';
import * as dashboard from './api/dashboard/dashboard-tasks.reducer';
import * as operations from './api/operations/operations.reducer';
import * as contacts from './api/contacts/contacts.reducer';
import * as taskerFile from './api/taskerfiles/taskerfiles.reducer';
import * as statuses from './api/statuses/statuses.reducer';
import * as user from './api/user/user.reducer';
import * as pbi from './api/pbi/pbi.reducer';
import * as push from './api/notification/push/notification-push.reducer';
import * as bell from './api/notification/bell/notification-bell.reducer';
import { AuthActionTypes } from './api/auth/auth.action';
import { AuthState } from './api/auth/auth.state';
import { MyTasksStateIFace } from './api/tasks/my-tasks/my-tasks.state';
import { DashboardTasksInfoState } from './api/dashboard/dashboard-tasks.state';
import { OperationsState } from './api/operations/operations.state';
import { ContactsState } from './api/contacts/contacts.state';
import { TaskerFilesState } from './api/taskerfiles/taskerfiles.state';
import { StatusState } from './api/statuses/statuses.state';
import { UserStateIFace } from './api/user/user.state';
import { PbiState } from './api/pbi/pbi.state';
import { NotificationPushState } from './api/notification/push/notification-push.state';
import { NotificationBellState } from './api/notification/bell/notification-bell.state';

export declare const INIT: '@ngrx/store/init';
export declare const UPDATE: '@ngrx/store/update';
// import { ActionReducer, INIT, UPDATE } from '@ngrx/store';

export interface AppState {
  auth: AuthState;
  dashboard: DashboardTasksInfoState;
  myTasks: MyTasksStateIFace;
  operations: OperationsState;
  contacts: ContactsState;
  file: TaskerFilesState;
  status: StatusState;
  user: UserStateIFace;
  pbi: PbiState;
  push: NotificationPushState;
  bell: NotificationBellState;
}

export const reducers = {
  auth: auth.reducer,
  myTasks: myTasks.reducer,
  dashboard: dashboard.reducer,
  operations: operations.reducer,
  contacts: contacts.reducer,
  file: taskerFile.reducer,
  status: statuses.reducer,
  user: user.reducer,
  pbi: pbi.reducer,
  push: push.reducer,
  bell: bell.reducer
};

export const metaReducers: MetaReducer<AppState>[] = [ clearState ];

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  // tslint:disable-next-line: only-arrow-functions
  return function(state: AppState, action: Action): AppState {
    if (action.type === AuthActionTypes.LogoutConfirmed) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const selectAuthState = createFeatureSelector<AppState>('auth');
export const selectAssignedToMeFilterState = createFeatureSelector<AppState>('tasksAssignedToMeFilter');
export const tasksByMeFilterState = createFeatureSelector<AppState>('tasksByMeFilter');
