import { Injectable } from '@angular/core';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface AppStatus {
  taskDialogs: Map<number, TaskDialogStatus>;
}

export interface TaskDialogStatus {
  activeTabIndex: number;
  activeTabName: string;
  selectedActivityIndex: number;
  scrollPosition?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AppStatusService {

  private appStatus: AppStatus;

  constructor() {
    this.appStatus = {
      taskDialogs: new Map<number, TaskDialogStatus>()
    };
  }

  public getTaskDetailConfig(taskID: number): TaskDialogStatus {
    const taskStatus = this.appStatus.taskDialogs[taskID];
    if (!!taskStatus) {
      return taskStatus;
    }

    return {
      selectedActivityIndex: 0,
      activeTabName: 'data-tab-main', // default
      scrollPosition: 0
    } as TaskDialogStatus;
  }

  public setTaskDetailConfig(taskID: number, dialogStatus: TaskDialogStatus) {
    this.appStatus.taskDialogs[taskID] = dialogStatus;
  }
}
