import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';

export interface NotificationBellState {
    loading: boolean;
    error: string;
    online: boolean;
    data: Array<HoneycombCustom.Tenant.Tasker.IService.Model.NotificationBellModel>;
}

export const NotificationBellState = () => {
    return {
        loading: false,
        error: null,
        data: [],
        online: false
    } as NotificationBellState;
  };
