<div class="message" [ngClass]="{'in-edit': inEdit}" fxFlex="1 1 100%" >
    <ng-container *ngIf="isFromMe(message)" >
        <div class="from-me" fxFlex="1 1 0%" fxFlexAlign="end" fxLayout="column">
            <div>
                <div class="name"><b> {{ 'tasker.messages.me' | translate }}</b></div>
                <div class="time">{{ message.created | date: 'd.M.yy HH:mm' }}</div>
                <div *ngIf="isLast" class="edit" (click)="edit(message)"><mat-icon style="height: 15px;" [icIcon]="twotoneEdit"></mat-icon></div>
                <!-- <div class="attachment" *ngIf="message.imageUID" (click)="zoomAttachmentImage(message.imageUID)">O</div> -->
            </div>
            <div class="content" [innerHTML]="sanitizeContent(message.content)"></div>
            <div class="message-attachments">
                <div class="photo-thumbnail" *ngIf="message.imageUID" (click)="zoomAttachmentImage(message.imageUID)">
                    <img [src]="getImageUrl(message.imageUID)">
                </div>
                <div class="document-thumbnail" *ngIf="message.documentUID" (click)="download(message)">
                    <mat-icon [icIcon]="roundContentPaste"></mat-icon>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!isFromMe(message)">
        <div class="from-partner" fxFlex="1 1 0%" fxFlexAlign="start" fxLayout="column" style="border-bottom: 1px solid #e6e6e6;">
            <div style="padding-top: 5px;">
                <div class="name"><b> {{ message.contactName }}</b></div>
                <div class="time">{{ message.created | date: 'd.M.yy HH:mm' }}</div>
                <!-- <div *ngIf="isLast" class="edit" (click)="edit(message)">EDIT</div> -->
                <!-- <div class="attachment" *ngIf="message.imageUID" (click)="zoomAttachmentImage(message.imageUID)">O</div> -->
            </div>
            <div class="content">{{ message.content }}</div>
            <div class="message-attachments">
                <div class="photo-thumbnail" *ngIf="message.imageUID" (click)="zoomAttachmentImage(message.imageUID)">
                    <img [src]="getImageUrl(message.imageUID)">
                </div>
                <div class="document-thumbnail" *ngIf="message.documentUID" (click)="download(message)">
                    <mat-icon [icIcon]="roundContentPaste"></mat-icon>
                </div>
            </div>
            <div *ngIf="!!message.read" class="read" [title]="message.read"><mat-icon [icIcon]="roundCheck"></mat-icon></div>
        </div>
    </ng-container>
    <mat-divider>
    </mat-divider>
</div>