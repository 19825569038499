<div class="relative text-contrast-white flex-none">
  <div class="bg-contrast-black opacity-25 absolute top-0 left-0 right-0 bottom-0 z-0 w-full h-full"></div>
  <div class="py-4 px-6 z-10 relative flex items-end">
    <div class="flex-auto flex flex-col sm:flex-row justify-between items-center">
      <div class="headline">{{ viewDate | calendarDate:(view + 'ViewTitle'):(langIsoCode$ | async) | uppercase }}</div>

      <div class="actions flex flex-col sm:flex-row justify-end items-center">
        <div class="chevrons">
          <button [(viewDate)]="viewDate" [view]="view" mat-icon-button mwlCalendarPreviousView>
            <mat-icon [icIcon]="icChevronLeft"></mat-icon>
          </button>
          <button [(viewDate)]="viewDate" [view]="view" mat-icon-button mwlCalendarNextView>
            <mat-icon [icIcon]="icChevronRight"></mat-icon>
          </button>
        </div>
        <div class="flex">
          <button (click)="view = CalendarView.Month" mat-button>{{ 'tasker.calendar.month' | translate  | uppercase }}</button>
          <button (click)="view = CalendarView.Week" mat-button>{{ 'tasker.calendar.week' | translate  | uppercase }}</button>
          <button (click)="view = CalendarView.Day" mat-button>{{ 'tasker.calendar.day' | translate | uppercase }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [locale]="langIsoCode$ | async"
    [weekStartsOn]="1"
    [events]="events"
    [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [locale]="langIsoCode$ | async"
    [weekStartsOn]="1"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (hourSegmentClicked)="hourSegmentClicked($event)"
    (eventTimesChanged)="eventTimesChanged($event)"
    (beforeViewRender)="beforeWeekViewRender($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [locale]="langIsoCode$ | async"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
    (hourSegmentClicked)="hourSegmentClicked($event)"
    (beforeViewRender)="beforeWeekViewRender($event)"
  >
  </mwl-calendar-day-view>
</div>