import { Honeycomb } from "src/app/services/honeycomb-api/honeycomb-api";

export const CustomSpTypes = () => [
    {
        type: 'tasker.spTaskSummaryCreateMultipleTasks',
        paramsFn: (zeroPointsItems: Array<{ // zero point items model
            value: any,
            input: any,
            followUpTask: any,
            caption: string
        }>) => {
            const activities = zeroPointsItems.filter(a => !a.followUpTask);
            let parentSnapshotInputIDs = [];
            let parentTaskValueIDs = [];
            activities.forEach(a => {
                if (a.input.isSnapshot) {
                    parentSnapshotInputIDs.push(a.input.inputID);
                } else {
                    parentTaskValueIDs.push(a.input.taskValueID);
                }
            });
            return { 
                "taskValueIDs": parentTaskValueIDs, 
                "snapshotInputIDs": parentSnapshotInputIDs
            };
        },
        visibleFn: (zeroPointsItems: Array<{ // zero point items model
            value: any,
            input: any,
            followUpTask: any,
            caption: string
        }>): boolean => {
            return zeroPointsItems.filter(a => !a.followUpTask).length > 0;
        }
    }
]