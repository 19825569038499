<div class="popover popover-table">
  <div class="mat-mdc-dialog-container mdc-dialog">
    <div class="mat-mdc-dialog-title" style="display: flex; flex-direction: row">
      <h1 [innerHTML]="title" style="flex-grow: 1"></h1>
      <button class="text-secondary" mat-mdc-dialog-close mat-icon-button type="button" (click)="close()">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>
    
    <div class="mat-mdc-dialog-content mdc-dialog__content">
      <div class="mat-mdc-table">
        <div class="mat-header-row" style="flex-direction: row; display: flex;;min-height: 30px; height: 30px;">
          <div class="mat-header-cell" style="width: 150px" *ngFor="let col of data.cols; let c=index">{{ col }}</div>
        </div>
        <div class="mat-row" *ngFor="let row of data.data; let i=index"  style="flex-direction: row; display: flex;min-height: 30px; height: 30px;" >
          <div class="mat-cell" style="width: 150px; max-width: 150px;" *ngFor="let v of row">{{ v }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
