import { Injectable }                   from '@angular/core';
import { isNullOrWhitespace } from './functions';

@Injectable()
export class LocalStorage {

    private crsUIRoles = 'crsuiroles';

    public getUIRoles(): Array<string> {
        const uiRolesString = sessionStorage.getItem(this.crsUIRoles);
        if (isNullOrWhitespace(uiRolesString)) {
            return null;
        }
        return JSON.parse(uiRolesString);
    }

    public setUIRoles(uiroles: Array<string>): void {
        sessionStorage.setItem(this.crsUIRoles, JSON.stringify(uiroles));
    }
}
