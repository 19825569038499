<div *ngIf="!(detailLoading$ | async)" fxLayout="row" fxLayoutAlign="start center" mat-dialog-title
  style="min-width: 70vw;">
  <div fxLayout="column" fxFlex="1 1 100%">
    <h1>{{ model?.name }}
      <ng-container  *ngxPermissionsOnly="uiRole('risk')">
        <span [class]="'risk ' + model.mappedAttributes?.risk" *ngIf="model.mappedAttributes?.risk">{{ model.mappedAttributes?.risk }}</span>
        <span *ngIf="model.mappedAttributes?.riskReason">{{ model.mappedAttributes?.riskReason }}</span>
      </ng-container>
    </h1>
    <h5 (click)="navigate(address(model))">{{ address(model) }} <span class="navigate">{{ 'tasker.store-detail.navigate'
        | translate }}</span></h5>
  </div>
  <div fxLayout="row">
    <button class="search-panel-text" mat-button type="button" (click)="openMapDialog()">{{ 'tasker.common.map' |
      translate }}</button>
  </div>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>
<mat-divider class="text-border"></mat-divider>
<mat-dialog-content fxLayout="column">
  <mat-tab-group (selectedTabChange)="tabChanged($event)" style="min-height: 100%; flex: 1 0 100%">
    <mat-tab [label]="'tasker.common.basicInfo' | translate" *ngxPermissionsOnly="uiRole('tab-basic-info')">
      <div class="top-header-container">
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="column" fxFlex="1 0 50%">
            <div class="general-contact">
              <div><b>{{ model?.name}}</b></div>
              <div>{{ model?.street }} {{ model?.city }}</div>
              <a fxLayout="row" href="mailto:{{ model?.email }}" class="general-contact-mail">
                <span class="row-icon" [icIcon]="twotoneEmail"></span> {{ model?.email }}
              </a>
              <a fxLayout="row" href="tel:{{ model?.phone }}" class="general-contact-phone">
                <span class="row-icon" [icIcon]="twotonePhoneForwarded"></span>{{ model?.phone }}
              </a>
            </div>
            <mat-divider class="text-border"></mat-divider>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between stretch">
              <div class="opening-list" fxLayout="column">
                <div *ngFor="let opening of openings" class="opening" fxFlex>
                  <span class="opening-name">{{ 'tasker.store-detail.opening.' + opening.locationAttributeType.name |
                    translate }}</span>
                  <span class="opening-hours">{{ opening.value }}</span>
                </div>
              </div>
              <ng-container *ngxPermissionsOnly="uiRole('tab-main-events')">
                <div class="opening-list" fxLayout="column" *ngIf="!!model && !!model.mappedAttributes && !!model.mappedAttributes.storeEvents">
                    <div class="opening" *ngFor="let row of model.mappedAttributes.storeEvents.data | slice:0:4; let i = index">
                      <ng-container *ngFor="let col of row; let ic = index">
                        <span [ngClass]="('ic === 0') ? 'opening-name' : 'opening-hours'" class="opening-name">{{ col }}</span>
                      </ng-container>
                    </div>
                  <div *ngIf="model.mappedAttributes.storeEvents.data.length > 4">
                    <button mat-button (click)="showAllEvents(model.mappedAttributes.storeEvents, $event)">{{ 'tasker.common.show-all' | translate }} ( {{ model.mappedAttributes.storeEvents.data.length }} )</button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngxPermissionsOnly="uiRole('tab-main-dates')">
                <div class="stocktaking-list" fxLayout="column" *ngIf="!!model && !!model.mappedAttributes">
                  <div fxLayout="row">
                    <span class="opening-name">INV</span><span class="opening-hours">{{ model.mappedAttributes?.lastStockTaking | date:'dd.MM.yyyy' }}</span>
                  </div>
                  <div fxLayout="row">
                    <span class="opening-name">MK</span><span class="opening-hours">{{ model.mappedAttributes?.lastManagerCheck | date:'dd.MM.yyyy' }}</span>
                  </div>
                </div>
              </ng-container>
            </div>
            
          </div>
          <div fxFlex="1 0 calc(50% - 10px)">
            <div class="contact-detail-image"
              [style.background]="'url(' + this.getImageUrl(storeImage?.recordUID, 670, 230) + ')'">
            </div>
          </div>
          <!-- <div class="contact-detail-image">
            <img *ngIf="storeImage" [src]="this.getImageUrl(storeImage?.recordUID, 670, 230)" style="margin: auto;">
            </div> 
          -->
        </div>
      </div>
      <mat-divider class="text-border"></mat-divider>
      <div class="contact-table" *ngIf="contacts">
        <div *ngFor="let contact of contacts" class="contact-table-item">
          <div class="contact-header">
            <div class="contact-title"><strong>{{ contact.name }}</strong></div>
          </div>
          <div class="contact-action">
            <a *ngIf="contact.phone" href="tel:{{contact.phone}}" class="contact-phone">
              <div class="btn-content"><span [icIcon]="twotonePhoneForwarded"></span>{{ contact.phone }}</div>
            </a>
            <a *ngIf="contact.email" href="mailto:{{contact.email}}" class="contact-mail">
              <div class="btn-content"><span [icIcon]="twotoneEmail"></span>{{ contact.email }}</div>
            </a>
          </div>
        </div>
      </div>
        <ng-container *ngxPermissionsOnly="uiRole('tab-main-contract-validity')">
          <mat-divider class="text-border"></mat-divider>
          <div *ngIf="contractValidity" class="contact-table-item">
            <div class="contact-header">
              <div class="contact-title"><strong>{{ 'tasker.store-detail.contract-validity' | translate }} </strong></div>
            </div>
            <div class="contact-action">
              {{ contractValidity.value }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngxPermissionsOnly="[uiRole('tab-main-opening-date-my-store'), uiRole('tab-main-opening-date')]">
          <mat-divider class="text-border"></mat-divider>
          <div *ngIf="openingDate" class="contact-table-item">
            <div class="contact-header">
              <div class="contact-title"><strong>{{ 'tasker.store-detail.opening-date' | translate }} </strong></div>
            </div>
            <div class="contact-action">
              {{ openingDate.value }}
            </div>
          </div>
        </ng-container>
        <ng-container  *ngxPermissionsOnly="uiRole('tab-main-custom-attributes')">
          <mat-divider class="text-border"></mat-divider>
          <div fxLayout="column" *ngIf="!!additionalAttr && additionalAttr.length > 0">
            <div style="padding: 15px" *ngFor="let attribute of additionalAttr">
              <strong>{{ attribute.localizationKey | translate }}</strong> {{ attribute.value }}
            </div>
          </div>
        </ng-container>
      <div class="delivery-table" *ngIf="deliveryDays && deliveryDays.length > 0" [ngStyle]="{'padding-bottom': this.delivaryDaysPadding}">
          <mat-table [dataSource]="deliveryDays">
            <ng-container matColumnDef="supplier">
              <mat-header-cell *matHeaderCellDef>{{ 'tasker.store-detail.supplier' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.supplier}} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="order">
              <mat-header-cell *matHeaderCellDef> {{ 'tasker.store-detail.order-day' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.order}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delivery">
              <mat-header-cell *matHeaderCellDef> {{ 'tasker.store-detail.delivery-day' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.delivery}} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="note">
              <mat-header-cell *matHeaderCellDef> {{ 'tasker.common.note' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.note}} </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="['supplier', 'order', 'delivery', 'note']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['supplier', 'order', 'delivery', 'note'];"></mat-row>
          </mat-table>
      </div>
    </mat-tab>
    <mat-tab [label]="'Plánogram'" *ngxPermissionsOnly="uiRole('tab-planograms')">
      <div *ngFor="let file of planograms; let i = index" class="task-file-wrap" fxLayoutGap="15px" (click)="downloadTaskFile(file)">
        <div>
          <mat-icon [icIcon]="twotoneInsertDriveFile"></mat-icon>
        </div>
        <div class="name">{{ file.name }}</div>
      </div>
    </mat-tab>
    <mat-tab [label]="'common.attachments' | translate" *ngxPermissionsOnly="uiRole('tab-attachments')">
      <div>{{ attechmentDirPathVisible() }}</div>
      <div *ngFor="let file of files; let i = index" class="task-file-wrap" fxLayoutGap="15px"
        (click)="processFile(file)">
        <div>
          <mat-icon *ngIf="!file.isFile && file.name !== '..'" [icIcon]="twotoneFolder"></mat-icon>
          <mat-icon *ngIf="!file.isFile && file.name === '..'" [icIcon]="twotoneDriveFolderUpload"></mat-icon>
          <mat-icon *ngIf="file.isFile" [icIcon]="twotoneInsertDriveFile"></mat-icon>
        </div>
        <div class="name">{{ file.name }}</div>
        <div class="time">{{ file.created | date: 'd.M.yyyy HH:mm' }}</div>
      </div>
    </mat-tab>
    <mat-tab #tabMessage data-tab-messages *ngxPermissionsOnly="uiRole('tab-messages')">
      <ng-template mat-tab-label>
        <mat-icon *ngIf="model" [icIcon]="twotoneSpeakerNotes"
          [ngClass]="{'unread': messagesTotal > messagesRead}"></mat-icon>
        {{ 'tasker.common.messages' | translate }}
      </ng-template>
      <message-list #messageList fxFlex="1 0 100%" fxLayout="column" style="height: 100%; overflow: auto;" [items]="messages"
            [firstLoading]="messagesLoading" [componentType]="data.permission" [refID]="data?.locationID" [refType]="messageRef.Location"></message-list>
    </mat-tab>
    <mat-tab [label]="'admin.web.statistics' | translate" data-tab-store-bpi *ngxPermissionsOnly="uiRole('statistics')">
      <div #pbiview class="fixed-panel disable-scroll"
        [attr.data-report-stats-name]="reportName" style="width: 100%; height: 100%;min-height: 100%;">
      </div>
    </mat-tab>
    <mat-tab data-tab-store-tasks [label]="'tasker.common.tasks' | translate" *ngxPermissionsOnly="uiRole('tasks')" style="position: relative;">

      <div class="overflow-hidden">
        <div class="bg-app-bar bluebar h-16 left-0" fxLayout="row" fxLayoutAlign="start center"
          style="padding-left: 1.25rem;padding-right: 1.25rem;">
          <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
            <span>{{ 'tasker.common.tasks' | translate}}</span>
          </h2>
          <div class="bg-card rounded-full border px-4 search-field" fxFlex="400px" fxFlex.lt-md="auto" fxLayout="row"
          fxLayoutAlign="start center">
          <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
          <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent search-input"
            (focus)="searchFocus($event)" (blur)="searchBlur($event)"
            placeholder="{{ 'tasker.task-filter-placeholder.all-for-store' | translate}}" type="search" />
          </div>
          <span fxFlex="1 1 0%"></span>
          <div fxFlex="none" class="ml-4 sort-trigger hide-on-focus" color="secondary" mat-icon-button
            matTooltip="{{ 'tasker.common.new-task' | translate }}" (click)="createTask()"
            *ngxPermissionsOnly="uiRole('tasker.MyTasks.new', true)">
            <mat-icon [icIcon]="baselinePlus"></mat-icon>
          </div>
          <div fxFlex="none" class="ml-4 sort-trigger hide-on-focus" color="secondary" mat-icon-button
            matTooltip="{{ 'tasker.common.new-task' | translate }}" (click)="createTask(true)"
            *ngxPermissionsOnly="uiRole('tasker.Repairs.new', true)">
            <mat-icon [icIcon]="twotoneBuildCircle"></mat-icon>
          </div>
          <div fxFlex="none" class="ml-4 sort-trigger hide-on-focus" color="secondary" mat-icon-button
            matTooltip="{{ 'tasker.common.view-type' | translate }}" (click)="toggleTaskView()">
            <mat-icon [icIcon]="outlineGridOn" *ngIf="viewType === taskViewType.Rows"></mat-icon>
            <mat-icon [icIcon]="twotoneListAlt" *ngIf="viewType === taskViewType.Table"></mat-icon>
          </div>
          <div fxFlex="none" *ngIf="viewType === taskViewType.Table" class="ml-4 sort-trigger hide-on-focus" color="secondary" mat-icon-button
            matTooltip="{{ 'tasker.common.columns' | translate }}" #originRefCols (click)="showColumnsDialog(originRefCols)">
            <mat-icon [icIcon]="roundViewColumn"></mat-icon>
          </div>
          <div fxFlex="none" class="ml-4 sort-trigger hide-on-focus" *ngIf="viewType === taskViewType.Rows" color="secondary" mat-icon-button
            matTooltip="{{ 'tasker.common.sort' | translate }}" #originRef (click)="showSortDialog(originRef)">
            <mat-icon [icIcon]="roundSortByAlpha"></mat-icon>
          </div>
          <div fxFlex="none" class="ml-4 filter-trigger" color="secondary" mat-icon-button
            matTooltip="{{ 'tasker.common.filter' | translate }}" #originRef (click)="showFilterDialog(originRef)">
            <mat-icon [icIcon]="icFilterList"></mat-icon>
          </div>
        </div>
      </div>

      <div class="ball-scale-multiple blue table" id="tasks-progress2" 
            [ngStyle]="{'display': ((loading$ | async) || !(initialized | async)) ? 'block' : 'none'}"
            style="display: block; padding-top: 260px; margin-bottom: 66px; top: -65px;">
        <div></div>
        <div></div>
        <div></div>
      </div>

        <ng-container  *ngIf="viewType === taskViewType.Rows">
          <div class="tasks-list">
            <tasks-list #tasksList
              [tasks]="storeTasks" 
              (selected)="updateTask($event)" 
              [agendaKey]="agendaKey"
              [viewSort]="viewSort">
            </tasks-list>
          </div>
        </ng-container>
        <div *ngIf="viewType === taskViewType.Table">
          <tasks-table #tasksTable
            [tasks]="storeTasks" 
            (selected)="updateTask($event)" 
            [agendaKey]="agendaKey"
            [viewSort]="viewSort"
            [stickyHorizontalScroll]="true">
          </tasks-table>
        </div>

    </mat-tab>
    <mat-tab data-tab-calendar [label]="'tasker.menu.calendar' | translate" *ngxPermissionsOnly="uiRole('calendar')">
      <calendar *ngIf="!calendarTasksLoading" [tasks]="calendarTasks" (taskUpdated)="calendarTaskUpdated($event)" [viewDate]="calendarSelectedDate" (dateSelected)="calendarDateSelected($event)"></calendar>
      <div style="min-height: 300px;padding-top: 20px" *ngIf="calendarTasksLoading">
        <div class="ball-scale-multiple blue task-row">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">{{ 'tasker.common.close' | translate }}</button>
</mat-dialog-actions>