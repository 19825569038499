import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarOnlineComponent } from './toolbar-online.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';


@NgModule({
  declarations: [ToolbarOnlineComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    IconModule
  ],
  exports: [ToolbarOnlineComponent]
})
export class ToolbarOnlineModule {
}
