<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block"
     fxLayout="row"
     fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">CRS</h1>
  </a>

  <div class="flex" style="width: 100%; justify-content: space-between;">

    <vex-toolbar-user></vex-toolbar-user>

    <div *ngIf="!(attendanceLoaded | async) || !(userLoaded | async)" style="margin-left: auto"></div>

    <div class="toolbar-buttons">
      <button [matMenuTriggerFor]="logoutMenu" style="margin-left: auto; display: inline-flex; flex-direction: row;cursor: pointer;height: 40px;align-items: center;padding-top: 2px;min-width: 30px;">
        <mat-icon [icIcon]="icPerson"></mat-icon>
        <div style="display: inline-block; padding: 3px 5px; color: var(--color-primary);overflow: hidden; text-overflow: ellipsis; max-width: 140px;" [ngStyle.lt-sm]="{'display': 'none'}">{{userName | async}}</div>
      </button>
        
      <mat-menu #logoutMenu="matMenu" xPosition="before" style="cursor: pointer">
        <div mat-menu-item style="color: var(--color-primary);" [ngStyle.gt-xs]="{'display': 'none'}">{{userName | async}}</div>
        <button mat-menu-item (click)="logout()" style="font-weight: bold;">{{ 'tasker.toolbar-user.logout' | translate }}</button>
      </mat-menu>

      <button class="button" mat-icon-button type="button" (click)="showAttendance($event)" *ngIf="(attendanceLoaded | async) && (userLoaded | async)">
        <mat-icon *ngIf="attendanceType !== attendanceTypeEnum.enter" [icIcon]="icStore"  style="cursor: pointer;" class="attendance-btn out"></mat-icon>
        <mat-icon *ngIf="attendanceType === attendanceTypeEnum.enter" [icIcon]="icStore"  style="cursor: pointer;" class="attendance-btn in"></mat-icon>
      </button>

      <vex-toolbar-notifications></vex-toolbar-notifications>

      <div [hidden]="!(showLanguageSwitch | async)">
        <button [matMenuTriggerFor]="languageMenu" mat-icon-button  class="header-button" type="button">
          <mat-icon [icIcon]="selectedLangIcon"></mat-icon>
        </button>
      </div>

      <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="useLanguage(l.langIsoCode)" *ngFor="let l of supportedLanguages">
          <mat-icon [icIcon]="l.icon"></mat-icon>
          <span>{{ l.text }}</span>
        </button>
      </mat-menu>
      <vex-toolbar-online></vex-toolbar-online>
    </div>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
