<ng-container *ngIf="!!task">
    <div fxLayout="column" style="padding-top: 20px;">
        <div>
            <h3 style="padding-left: 24px;">
                {{ 'tasker.task-summary.activities' | translate }} 
                {{ sumAll(task) }} / {{ sumTotal(task) }} 
                ({{ (sumAll(task) / sumTotal(task) * 100) | number:'1.0-0' }}%) </h3>
        </div>

        <mat-accordion [multi]="true" fxFlex>
            <mat-expansion-panel *ngFor="let taskActivity of task.taskActivities">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ taskActivity.activity.name }}&nbsp;&nbsp; 
                        {{ pointsSum(taskActivity) }} / {{ pointsTotal(taskActivity) }}
                        ({{ (pointsSum(taskActivity) / pointsTotal(taskActivity) * 100) | number:'1.0-0' }}%)
                    </mat-panel-title>
                    <mat-panel-description>
                    <!-- {{ taskactivity.status.activityClosed }} {{ getActivityAvg(taskactivity) }} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <!-- <button *ngIf="isActivityTask(taskActivity.activity, task)" color="primary" mat-raised-button>Založit úkol: {{ getTemplateName(taskActivity.activity, task) }}</button> -->
                    <table style="width: 100%;">
                        <tbody>
                            <tr *ngFor="let activityInput of sortInputs(taskActivity.activity.activityInputs)">
                                <td style="width: 70%;">
                                    <div>{{ activityInput.input.name }}</div>
                                    <div class="input-note" *ngIf="!!activityInput.input.note">{{ activityInput.input.note }}</div>
                                    <div class="input-note" *ngIf="(activityInput.input.uitype === textType || activityInput.input.uitype === textAreaType) && !!activityInput.input.value">{{ activityInput.input.value }}</div>
                                    <!-- <button *ngIf="isInputTask(activityInput, task)" mat-raised-button>Založit úkol</button> -->
                                </td>
                                <td style="text-align: right; padding-right: 6px;" *ngIf="activityInput.input.uitype === ratingType">
                                    <ng-container *ngIf="activityInput.input.value === null || activityInput.input.value === -1">
                                        {{ 'tasker.task-summary.not-evalued' | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="activityInput.input.value !== null && activityInput.input.value !== -1">
                                        {{ activityInput.input.value }}    
                                    </ng-container>
                                </td>
                                <td  style="float: right;" *ngIf="activityInput.input.uitype === inputNumber">
                                    <ng-container *ngIf="activityInput.input.value === null">
                                        <ic-icon [icIcon]="outlineCheckBoxOutlineBlank" size="20px"></ic-icon>
                                    </ng-container>
                                    <ng-container *ngIf="activityInput.input.value !== null">
                                        {{ activityInput.input.value }}    
                                    </ng-container>
                                </td>
                                <td  style="float: right;" *ngIf="activityInput.input.uitype !== ratingType && activityInput.input.uitype !== inputNumber && activityInput.input.value">
                                    <ic-icon [icIcon]="outlineCheckBox" size="20px"></ic-icon>
                                </td>
                                <td style="float: right;" *ngIf="activityInput.input.uitype !== ratingType && activityInput.input.uitype !== inputNumber && !activityInput.input.value">
                                    <ic-icon [icIcon]="outlineCheckBoxOutlineBlank" size="20px"></ic-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div *ngFor="let tar of taskActivity.taskActivityResults">
                        {{ tar.resultType.description }}
                        {{ tar.value }}
                    </div> -->
                </ng-template>

            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="(zeroPointsItems | async).length > 0" expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title style="flex: 100%">
                        <div style="display: flex; flex: 100%">
                            {{ 'tasker.task.ListOfProblems' | translate }}
                        </div>
                        <div style="display: flex;flex: 100%;flex-direction: column;align-items: flex-end;">
                            <custom-sp-button label="tasker.task-summary.create-all-tasks" 
                                type="tasker.spTaskSummaryCreateMultipleTasks" 
                                [data]="zeroPointsItems | async"
                                [refreshVisibility]="zeroPointsItemsUpdated"
                                (success)="createAllTasksSucessCallback($event)" 
                                (error)="createAllTasksErrorCallback($event)">
                            </custom-sp-button>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <div class="ball-scale-multiple blue task-row" [ngStyle]="{'display': (updatingZeroPoints | async) ? 'block' : 'none'}" style="position: relative;margin-top: 50px">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    <table style="width: 100%;" *ngIf="!(updatingZeroPoints | async)">
                        <tbody>
                            <tr *ngFor="let item of (zeroPointsItems | async)">
                                <td style="width: 70%;">
                                    {{ item.caption }}
                                </td>
                                
                                <td  style="float: right;">
                                    <button *ngIf="!item.followUpTask" mat-button mat-raised-button (click)="createSubsequentTask(item.input)">{{ 'tasker.common.new-task' | translate }}</button>
                                    <div *ngIf="item.followUpTask">{{'tasker.common.task' | translate}} {{ item.followUpTask.taskID }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-container>