import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import icClose from '@iconify/icons-ic/twotone-close';
import icRoundLink from '@iconify/icons-ic/round-link';
import twotoneSpeakerNotes from '@iconify/icons-ic/twotone-speaker-notes';
import twotonePostAdd from '@iconify/icons-ic/twotone-post-add';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, catchError, lastValueFrom, of } from 'rxjs';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { isNullOrUndefined } from 'src/app/common/functions';
import { TaskNewComponent } from '../task-new/task-new.component';
import { MatDialog } from '@angular/material/dialog';
import { TaskDetailComponent } from '../task-detail/task-detail.component';

@Component({
  selector: 'task-input-buttons',
  templateUrl: './' + environment.dirPrefix +  '/task-input-buttons.component.html',
  styleUrls: ['./task-input-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TaskInputButtonsComponent implements OnInit, AfterViewInit, OnDestroy {

  icClose = icClose;
  twotoneSpeakerNotes = twotoneSpeakerNotes;
  twotonePostAdd = twotonePostAdd;
  icRoundLink = icRoundLink;

  visible: BehaviorSubject<boolean> = new BehaviorSubject(false);
  taskID: BehaviorSubject<number> = new BehaviorSubject(null);

  uitype: Honeycomb.Common.Enums.UiType;

  @Output() closeFn = new EventEmitter<boolean>();

  @Input('activityInputControl') activityInput: FormGroup;
  @Input() task: Honeycomb.Tenant.Tasker.IService.Model.Task;
  @Input() detailComponent: TaskDetailComponent;

  @Input() public taskAttributes: Array<any> = [];

  fuTask: any = {};

  constructor(
    private trans: TranslateService,
    private dialogOver: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject('ContactJobController') private jobController: Honeycomb.Tenant.Contact.IService.Controller.JobController,
    @Inject('ConfigController') private configController: Honeycomb.Tenant.Admin.IService.NamedConfigurationsController) {

  }
  ngOnInit(): void {
    this.uitype = this.activityInput.get('uitype').value;
    let value = this.activityInput.get('inputValue').value;
    this.fuTask = this.getFollowUpTask(this.activityInput.value);

    if (!!this.fuTask) {
      this.taskID.next(this.fuTask.taskID);
    } else {
      this.taskID.next(null);
    }

    this.updateVisibility(value);
  }

  async ngAfterViewInit() {
  }

  public async updateVisibility(newValue: any) {
    if (newValue === null) {
      this.visible.next(false);
      return;
    }

    //  https://ngretail.atlassian.net/browse/DEV-4856
    // Traficon - CRS - detail úkolu - Aktivity - tlačítko pro vytvoření navazujícího úkolu by se mělo u vstupu jen pokud již má vyplněnou některou hodnotu (takto je i ve specifikaci)
    if (!isNaN(Number(newValue))) {
      this.visible.next(!this.fuTask); 
      return;
    }
    // if (!isNaN(Number(newValue))) {
    //   this.visible.next(Number(newValue) > -1 && !this.fuTask);
    //   return;
    // }
    this.visible.next(isNullOrUndefined(newValue) && !this.fuTask);
  }

  public getFollowUpTask( ai: any ) {
    if (!this.task || !this.task.children) {
      return null;
    }
    return this.task.children.find( c => 
      (!!c.parentTaskValueID && c.parentTaskValueID === ai.taskValueID) ||
      (!!c.parentSnapshotInputID && c.parentSnapshotInputID === ai.inputID));
  }

  public async createSubsequentTask($event)
  {
    $event.stopPropagation();
    $event.cancelBubble = true;
    $event.preventDefault();

    let input = this.activityInput.value;
    this.detailComponent.createSubsequentTask(input);
  }

  ngOnDestroy(): void {
  }

  async close() {
    this.closeFn.next(true);
  }

  redirect($event, taskID) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    $event.preventDefault();

    this.detailComponent.redirect(taskID);
  }

  getAttribute(attrName: string): string {
    if (!this.taskAttributes) return null;
    let attr = this.taskAttributes.find(ta => ta.taskAttributeTypeName === attrName);
    if (!attr) return null;
    return attr.value;
  }

  get mkDate(): Date {
    if (!this.taskAttributes) return null;
    let mkTa = this.taskAttributes.find(ta => ta.taskAttributeTypeName === 'LastStockTaking');
    let res = mkTa ? new Date(mkTa.value) : null;
    return res;
  }
}
