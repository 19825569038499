import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import icClose from '@iconify/icons-ic/twotone-close';
import icRoundLink from '@iconify/icons-ic/round-link';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import twotoneSpeakerNotes from '@iconify/icons-ic/twotone-speaker-notes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'task-detail-header',
  templateUrl: './' + environment.dirPrefix +  '/task-detail-header.component.html',
  styleUrls: ['./task-detail-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TaskDetailHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  icClose = icClose;
  twotoneSpeakerNotes = twotoneSpeakerNotes;
  icRoundLink = icRoundLink;

  @Output() closeFn = new EventEmitter<boolean>();

  @Input() taskForm: FormGroup;

  @Input() task: Honeycomb.Tenant.Tasker.IService.Model.Task;

  @Input() public taskAttributes: Array<any> = [];

  constructor(private trans: TranslateService) {

  }
  ngOnInit(): void {
  }

  async ngAfterViewInit() {
  }


  ngOnDestroy(): void {
  }

  async close() {
    this.closeFn.next(true);
  }

  getAttribute(attrName: string): string {
    if (!this.taskAttributes) return null;
    let attr = this.taskAttributes.find(ta => ta.taskAttributeTypeName === attrName);
    if (!attr) return null;
    return attr.value;
  }

  get mkDate(): Date {
    if (!this.taskAttributes) return null;
    let mkTa = this.taskAttributes.find(ta => ta.taskAttributeTypeName === 'LastStockTaking');
    let res = mkTa ? new Date(mkTa.value) : null;
    return res;
  }
}
