import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface StatusState {
    loading: boolean;
    error: string;
    online: boolean;
}

export const StatusState = () => {
    return {
        loading: false,
        error: null,
        results: [],
        online: null
    } as StatusState;
  };
