import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { TaskAgenda } from "../api/tasks/my-tasks/my-tasks.state";
import { BuildConfig } from "./build-config";
import { Honeycomb } from "./honeycomb-api/honeycomb-api";

@Injectable({
    providedIn: 'root'
})

export class BuildConfigService extends BuildConfig {

    public initialized = false;

    constructor(private http: HttpClient) {
        super();
    }

    public taskDetailRepairAttribute(attributeType: string, task: Honeycomb.Tenant.Tasker.IService.Model.Task): boolean {
        return  (!!task && !!task.operation && task.operation.taskType === Honeycomb.Common.Enums.TaskType.repairRequest) &&
                (!!this.taskDetail && !!this.taskDetail.repair && !!this.taskDetail.repair.attributes && this.taskDetail.repair.attributes.map(a => a.toLowerCase()).indexOf(attributeType.toLocaleLowerCase()) > -1);
    }

    public taskListRepairAttribute(attributeType: string, agenda: TaskAgenda): boolean {
        return  (!!agenda && agenda === TaskAgenda.Repairs) &&
                (!!this.taskList && !!this.taskList.repair && !!this.taskList.repair.attributes && this.taskList.repair.attributes.map(a => a.toLowerCase()).indexOf(attributeType.toLocaleLowerCase()) > -1);
    }

    load() {
        return firstValueFrom(this.http.get<BuildConfig>('configuration/configuration.json'))
            .then(data => {
                this.info = data.info || 'default';
                if (!!data.azureLogin) {
                    this.azureLogin = {
                        enabled: data.azureLogin.enabled || false,
                        redirectUri: data.azureLogin.redirectUri || null,
                        postLogoutRedirectUri: data.azureLogin.postLogoutRedirectUri || null,
                        clientID: data.azureLogin.clientID || null,
                    }
                }
                if (!!data.standardLogin) {
                    this.standardLogin = {
                        enabled: data.standardLogin.enabled || false,
                        alphaNumericLogin: data.standardLogin.alphaNumericLogin ?? false
                    }
                }
                this.tenantID = data.tenantID || -1;
                this.initialized = true;
                this.externalAuthApiUri = data.externalAuthApiUri || 'http://localhost';
                this.customButtons = data.customButtons || [];
                this.supportedLanguages = data.supportedLanguages || ['cs'];
                
                this.taskList = data.taskList;
                if (!this.taskList.default) {
                    this.taskList.default = {
                        attributes: []
                    }
                } else {
                    if (!this.taskList.default.attributes) {
                        this.taskList.default.attributes = [];
                    }
                }

                this.taskDetail = data.taskDetail;
                if (!this.taskDetail.default) {
                    this.taskDetail.default = {
                        attributes: []
                    }
                } else {
                    if (!this.taskDetail.default.attributes) {
                        this.taskDetail.default.attributes = [];
                    }
                }

                if (!!data.forgotPassword) {
                    this.forgotPassword = {
                        enabled: data.forgotPassword.enabled
                    }
                }
            })
            .catch(() => {
                console.error('Could not load configuration');
                this.info = 'default';
                this.standardLogin = {
                    enabled: true,
                    alphaNumericLogin: false
                },
                this.azureLogin = {
                    enabled: false,
                    redirectUri: null,
                    postLogoutRedirectUri: null,
                    clientID: null,
                    authApiUri: 'http://localhost'
                };
                this.tenantID = -1
                this.customButtons = [];
                this.supportedLanguages = ['cs'];
                this.initialized = true;
                this.taskDetail = {
                    repair: {
                        attributes: []
                    },
                    default: {
                        attributes: []
                    }
                };
                this.taskList = {
                    repair: {
                        attributes: []
                    },
                    default: {
                        attributes: []
                    }
                };
                this.forgotPassword = {
                    enabled: true
                };
            })
    }

}