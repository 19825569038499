import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icNoteAdd from '@iconify/icons-ic/twotone-clear-all';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icClearAll from '@iconify/icons-ic/twotone-note-add';
import icEyeRead from '@iconify/icons-fa-solid/eye';
import icEyeUnread from '@iconify/icons-fa-solid/eye-slash';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { bellSetReadAction } from 'src/app/api/notification/bell/notification-bell.actions';
import { Overlay } from '@angular/cdk/overlay';
import { PopoverService } from '../../popover/popover.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationSettingsComponent } from 'src/app/pages/notification/settings/settings.component';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  @Output() closePanel = new EventEmitter<void>();
  
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  icEyeRead = icEyeRead;
  icEyeUnread = icEyeUnread;
  icNoteAdd = icNoteAdd;
  icEdit = icEdit;
  trackById = trackById;

  notifications: Observable<Array<Notification>> = of([]);
  unreadIDs: number[] = [];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private overlay: Overlay,
    private dialog: MatDialog,
    private popoverService: PopoverService
  ) { 
    // var bell = this.store.select(s => s.bell).pipe(map(b => b.data));
    this.notifications = this.store.select(s => s.bell)
      .pipe(map(b => b.data), 
      tap((n) => {
        this.unreadIDs = n.filter(a => !a.read).map(a => a.notificationBellQueueID);
      }))
      .pipe(map(m => m.map((b) => {
        let a = {
          title: b.title,
          id: b.notificationBellQueueID,
          read: !!b.read,
          route: b.route,
          descriptionRows: !!b.rows ? b.rows.map(r => r).sort((a, b) => a.sortOrder - b.sortOrder) /*.sort((a, b) => a.sortOrder - b.sortOrder) */ : [],
          type: b.bellType,
          icon: {}
        };
        return a as Notification;
      }),
      )
      );
  }

  ngOnInit() {
  }

  public async setAllAsRead() {
    if (this.unreadIDs.length > 0) {
      this.store.dispatch(bellSetReadAction({ ids: this.unreadIDs }));
    }
  }

  public getIcon(bellType: string) {
    switch (bellType) {
      case 'task-new':
        return this.icNoteAdd
      default:
        return this.icEdit;
    }
  }

  public notificationClick(n: Notification) {

    this.popoverService.close();

    if (!n.read) {
      this.store.dispatch(bellSetReadAction({ ids: [n.id] }));
    }
    
    this.router.navigate([n.route]);
  }

  public showNotificationSettings() {
    this.popoverService.close();
    this.dialog.open(NotificationSettingsComponent, { data: {  } }).afterClosed()
    .subscribe(async result => {
      console.log('Notifications updated');
    });
  }
}
