import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { IconModule } from '@visurel/iconify-angular';
import { MatIconModule } from '@angular/material/icon';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { WidgetTableModule } from 'src/@vex/components/widgets/widget-table/widget-table.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorFadeModule } from 'src/@vex/pipes/color/color-fade.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared.module';
import { StarRatingModule } from 'src/@vex/components/star-rating/star-rating.module';
import { ButtonRatingModule } from 'src/@vex/components/button-rating/button-rating.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TaskFilterComponent } from './task-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    TaskFilterComponent
  ],
  providers: [
  ],
  imports: [
    CommonModule,

    MatButtonModule,
    FlexLayoutModule,
    BreadcrumbsModule,
    IconModule,
    MatIconModule,
    MatInputModule,
    ContainerModule,
    StarRatingModule,
    ButtonRatingModule,

    WidgetTableModule,

    PageLayoutModule,
    FlexLayoutModule,
    BreadcrumbsModule,
    MatPaginatorModule,
    MatTableModule,
    MatAutocompleteModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    IconModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ContainerModule,
    MatSelectModule,
    ColorFadeModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatChipsModule,
    TranslateModule
  ],
  entryComponents: [
    TaskFilterComponent
  ]
})
export class TaskFilterModule { }
