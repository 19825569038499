<mat-button-toggle-group [value]="value" (change)="onSelectedChange($event)">
  <mat-button-toggle [value]="-1">
    <ic-icon [icon]="outlineCancel" inline="true"></ic-icon>
  </mat-button-toggle>
  <ng-container *ngIf="isNumber">
    <mat-button-toggle [value]="val" *ngFor="let val of parsedRatingValues">
      {{ val }}
    </mat-button-toggle>
  </ng-container>
  <ng-container *ngIf="isObject">
    <mat-button-toggle [value]="val.value" *ngFor="let val of parsedRatingValues">
      {{ val.displayValue }}
    </mat-button-toggle>
  </ng-container>
</mat-button-toggle-group>