import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import type { IconifyIcon } from '@iconify/types';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUK from '@iconify/icons-emojione/flag-for-flag-united-kingdom';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import emojioneCS from '@iconify/icons-emojione/flag-for-flag-czechia';
import emojioneSK from '@iconify/icons-emojione/flag-for-flag-slovakia';
import emojioneRO from '@iconify/icons-emojione/flag-for-flag-romania';
import emojioneHU from '@iconify/icons-emojione/flag-for-flag-hungary';
import emojioneHR from '@iconify/icons-emojione/flag-for-flag-croatia';
import emojioneBG from '@iconify/icons-emojione/flag-for-flag-bulgaria';
import emojionePL from '@iconify/icons-emojione/flag-for-flag-poland';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map, tap } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icPerson from '@iconify/icons-ic/twotone-person';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import signIn from '@iconify/icons-fa-solid/sign-in-alt';
import signOut from '@iconify/icons-fa-solid/sign-out-alt';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icStore from '@iconify/icons-ic/twotone-store';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
//import icSearch from '@iconify/icons-ic/twotone-search';
import { Router } from '@angular/router';
import { AttendanceDialogService } from 'src/app/pages/common/attendance-dialog/attendance-dialog.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { getAttendance } from 'src/app/api/user/user.selectors';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { BehaviorSubject, of } from 'rxjs';
import { toolbarSettings } from './toolbar.settings';
import { BuildConfigService } from 'src/app/services/build-config.service';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  showLanguageSwitch: BehaviorSubject<boolean> = new BehaviorSubject(false);

  attendanceTypeEnum = Honeycomb.Common.Enums.AttendanceType;
  attendanceType: Honeycomb.Common.Enums.AttendanceType;

  allLanguages: Array<{langIsoCode: string, icon: IconifyIcon, text: string}> = [
    { langIsoCode: 'cs', icon: emojioneCS, text: 'Česky' },
    { langIsoCode: 'en', icon: emojioneUK, text: 'English' },
    { langIsoCode: 'sk', icon: emojioneSK, text: 'Slovensky' },
    { langIsoCode: 'ro', icon: emojioneRO, text: 'Română' },
    { langIsoCode: 'hu', icon: emojioneHU, text: 'Magyar' },
    { langIsoCode: 'hr', icon: emojioneHR, text: 'Hrvatski' },
    //{ langIsoCode: 'nl-be', icon: emojioneBG, text: 'Belgisk' },
    { langIsoCode: 'bg', icon: emojioneBG, text: 'български' },
    { langIsoCode: 'pl', icon: emojionePL, text: 'Polski' }
  ];

  supportedLanguages: Array<{langIsoCode: string, icon: IconifyIcon, text: string}> = [];

  navigationItems = this.navigationService.menuItems$;
  attendanceLoaded = of(false);
  userLoaded = of(false);
  userName: BehaviorSubject<string> = new BehaviorSubject('');

  selectedLangIcon = this.allLanguages.find(l => l.langIsoCode === this.configService.getLanguage()).icon;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icBookmarks = icBookmarks;
  emojioneUK = emojioneUK;
  emojioneCS = emojioneCS;
  emojioneDE = emojioneDE;
  emojioneSK = emojioneSK;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icPerson = icPerson;
  icArrowDropDown = icArrowDropDown;
  icStore = icStore;

  signIn = signIn;
  signOut = signOut;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private attendanceDialogService: AttendanceDialogService,
              private config: BuildConfigService,
              private store: Store<AppState>,
              private router: Router,
              private popoverService: PopoverService) {

      
      this.supportedLanguages = this.allLanguages.filter(l => config.supportedLanguages.indexOf(l.langIsoCode) > -1);

      this.store.select(s => s.user)
        .pipe(tap((u) => {
        let userLoaded = !!u && !!u.currentUser;
        this.userLoaded = of(userLoaded);
        if (userLoaded) {
          let lang = u.currentUser.languageISOCode;
          this.userName.next(u.currentUser.contacts[0].formatedName);

          if (!localStorage.getItem('lang')) {
            this.useLanguage(lang || 'cs');
          } else {
            this.useLanguage(localStorage.getItem('lang'));
          }
        }
        
      })).subscribe(_ => null);

      const userSub = this.store.select(getAttendance).pipe(tap((a) => {
        if (!!a && !!a.attendanceID) {
          this.attendanceType = a.attendanceType;
        } else {
          this.attendanceType = Honeycomb.Common.Enums.AttendanceType.notSet;
        }
        this.attendanceLoaded = of(true);
      })).subscribe(_ => null);

      this.showLanguageSwitch.next(toolbarSettings.showLangSwitch);
  }

  ngOnInit() {
    
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  useLanguage(lang: string) {
    this.configService.setLanguage(lang);
    this.selectedLangIcon = this.allLanguages.find(l => l.langIsoCode === this.configService.getLanguage()).icon;
  }

  logout() {
    this.router.navigate(['/login']);
  }

  showAttendance(event) {
    this.attendanceDialogService.showDialog();
  }
}
