import { createAction, props } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export enum StatusActionTypes {
  OnlineStatus = '[Ping] Checking online',
  OnlineStatusCompleted = '[Ping] Is online',
  OnlineStatusFailed = '[Ping] Is offline'
}

// tslint:disable: max-line-length
export const checkOnlineAction = createAction(StatusActionTypes.OnlineStatus);

export const checkOnlineCompletedAction = createAction(StatusActionTypes.OnlineStatusCompleted, props<{result: boolean}>());

export const checkOnlineFailedAction = createAction(StatusActionTypes.OnlineStatusFailed);
