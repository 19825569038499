import { Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Inject, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { Globals } from 'src/app/common/globals';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AppState } from 'src/app/app.states';
import { TranslateService } from '@ngx-translate/core';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import { enumToStrings, isNullOrWhitespace, zoom, zoomScrollable } from 'src/app/common/functions';
import { FileInputComponent } from 'ngx-material-file-input';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent, DeleteDialogData } from '../../common/delete-dialog/delete-dialog.component';

import baselineSend from '@iconify/icons-ic/baseline-send';
import twotoneFileUpload from '@iconify/icons-ic/twotone-file-upload';
import twotoneInsertDriveFile from '@iconify/icons-ic/twotone-insert-drive-file';
import roundContentPaste from '@iconify/icons-ic/round-content-paste';
import roundSubdirectoryArrowRight from '@iconify/icons-ic/round-subdirectory-arrow-right';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'task-files',
  templateUrl: './task-files.component.html',
  styleUrls: ['./task-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.Emulated
})
export class TaskFilesComponent implements OnInit, AfterViewInit, OnDestroy {

  selectedStepper = 0;
  selectedActivity: number;
  userID: number;
  loading$: Observable<boolean>;
  twotoneFileUpload = twotoneFileUpload;
  twotoneInsertDriveFile = twotoneInsertDriveFile;
  roundContentPaste = roundContentPaste;
  roundSubdirectoryArrowRight = roundSubdirectoryArrowRight;

  subscriptions: Array<Subscription> = [];
  activityPhotos: Array<any> = [];

  public taskAttachmentID: number = null;
  public documentUID: string = null;
  public documentEditUID: string = null;

  @Input() taskFiles: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> = [];
  @Input() messages: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage> = [];
  @Input() taskPhotos: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPhoto> = [];
  @Input() activities: Array<any> = [];

  get currentTaskFiles() {
    const cf = this.taskFiles.find(f => f.taskID === this.taskID);
    if (!cf) {
       return [];
    }
    return cf.taskAttachments;
  }

  get activityFiles(): any[] {
    const a = this.activities;
    if (!a) {
       return [];
    }
    this.activityPhotos = [];
    for (const ta of a) {
      for (const ai of ta.activityInputs) {
        if (ai.uitype === Honeycomb.Common.Enums.UiType.file && (
            !!ai.taskerFileUid ||
            !!ai.recordUid ||
            typeof ai.inputValue === 'string')) {
           this.activityPhotos.push({
              taskerFileUid: ai.taskerFileUid || ai.recordUid || ai.inputValue, /* temporary | new saved | loaded */
              valueUpdated: ai.valueUpdated,
              validFromUTC: ai.validFromUTC || ai.valueUpdated || ai.updateTime,
              activityName: ta.name,
              inputName: ai.name,
              isAttachment: false,
              isTemp: !!ai.recordUid
            });
        }
        if (ai.taskValuePhotos && ai.taskValuePhotos.length > 0) {
          ai.taskValuePhotos.forEach(vp => {
            if (!!vp.taskerFileUid || !!vp.recordUid) {
              this.activityPhotos.push({
                taskerFileUid: vp.taskerFileUid || vp.recordUid,
                valueUpdated: vp.valueUpdated,
                validFromUTC: vp.validFromUTC,
                activityName: ta.name,
                inputName: ai.name,
                isAttachment: true,
                isTemp: !!vp.recordUid
              });
            }
          });
        }
      }
    }
    return this.activityPhotos;
  }

  loadActivitiesFiles() {
    const a = this.activities;
    if (!a) {
       return [];
    }

    this.activityPhotos = [];

    for (const ta of a) {
      for (const ai of ta.activity.activityInputs) {
        if (ai.input.uitype === Honeycomb.Common.Enums.UiType.file && !!ai.input.value) {
           this.activityPhotos.push({
              taskerFileUid: ai.input.value,
              valueUpdated: ai.input.valueUpdated,
              created: ai.input.created,
              activityName: ta.activity.name,
              inputName: ai.input.name,
              isAttachment: false
            });
        }
        if (ai.input.taskValuePhotos && ai.input.taskValuePhotos.length > 0) {
          ai.input.taskValuePhotos.forEach(vp => {
            if (!!vp.taskerFileUid) {
              this.activityPhotos.push({
                taskerFileUid: vp.taskerFileUid,
                updateTime: vp.updateTime,
                created: vp.created,
                activityName: ta.activity.name,
                inputName: ai.input.name,
                isAttachment: true
              });
            }
          });
        }
      }
    }
    return this.activityPhotos;
  }

  get currentMessageFiles() {
    const res = [];
    this.messages.forEach(m => {
      if (m.documentUID) {
        res.push({documentUID: m.documentUID, updateTime: m.updateTime});
      }
      if (m.imageUID) {
        res.push({imageUID: m.imageUID, updateTime: m.updateTime});
      }
    });
    return res;
  }

  get subTasksFiles() {
    const cf = this.taskFiles.filter(f => f.taskID !== this.taskID &&
        (
          (f.taskAttachments && f.taskAttachments.length > 0) ||
          (f.taskPhotos && f.taskPhotos.length > 0)
        )
        );
    return cf;
  }

  addUser(relation: Honeycomb.Common.Enums.TaskRelation, event: MatAutocompleteSelectedEvent) {

    const enumHashMap = enumToStrings(Honeycomb.Common.Enums.TaskRelation);
    const taskUsersControl = this.form.controls[enumHashMap[relation]] as FormArray;
    const taskUsers = taskUsersControl.value;
    const userIDs = new Set(taskUsers.map(a => a.userId));
    const current = event.option.value.user;

    if (!current) { return; }
    if (userIDs.has(current.id)) {
      // return this.showSnack('tasker.common.already-selected');
    }
    const position = taskUsersControl.value.length;
    this.cd.detectChanges();
  }


  async download(message) {

    const fileMetadata = await this.fileController.GetMetadata(message.documentUID).toPromise();
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    link.href =  `${this.globals.GetUrlPrefix()}/api/TenantTasker/file/taskattachment/${message.documentUID}/${fileMetadata.fileName}?TenantHash=${this.tenantHash}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  @Input()
  firstLoading: Observable<boolean> = of(true);

  @Input()
  taskID: number = null;

  baselineSend = baselineSend;
  tenantHash: string = null;

  @ViewChild('inputFile') inputFileRef: FileInputComponent;
  fileUploaded$: Observable<any> = of({});
  fileUploading$ = false;

  public fileInEdit(msg): boolean {
    return msg.taskAttachmentID === this.taskAttachmentID;
  }

  public get imageInEdit(): boolean {
    return !!this.documentUID;
  }

  // @Input() form: FormGroup;

  private fb: FormBuilder = null;

  form: FormGroup = null;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    @Inject('FileControllerCustom') private fileCustomController: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
    @Inject('FileController') private fileController: Honeycomb.Tenant.Tasker.IService.Controller.FileController,
    private cd: ChangeDetectorRef,
    private globals: Globals,
    private trans: TranslateService,
    fb: FormBuilder
    ) {
        this.fb = fb;

        this.form = this.fb.group({
          name: null,
          description: null
        });

        this.store.select(s => s.auth.userID).subscribe(s => this.userID = s);
        this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);
  }

  async uploadFile($event: MouseEvent) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    $event.preventDefault();
    const descr = this.form.controls.description.value;
    const fileName = this.form.controls.name.value;
    if (isNullOrWhitespace(descr) && isNullOrWhitespace(this.documentUID)) {
      return;
    }

    this.loading$ = of(true);

    const fileToUpdate =  this.currentTaskFiles.find(f => f.name === fileName);

    if (fileToUpdate) {
      const delDialog = this.dialog.open(DeleteDialogComponent, { 
        data: {
           deleteQuestion: 'tasker.delete-dialog.replace-question',
           deleteTitle: 'tasker.delete-dialog.replace-title'
        } as DeleteDialogData});
      const delSub = delDialog.afterClosed().subscribe(async d => {
      if (d === true) {
        await this.fileController.UpdateTaskAttachment(fileToUpdate.taskAttachmentID,
          { description: descr, taskID: this.taskID,
            documentUid: this.documentUID, name: fileName } as Honeycomb.Tenant.Tasker.IService.Model.TaskAttachment)
        .toPromise()
        .then(_ => this.refreshFiles());
      } else {
        this.refreshFiles();
      }
    });
    this.subscriptions.push(delSub);
    } else {
      await this.fileController.InsertTaskAttachment(
        { description: descr, taskID: this.taskID,
          documentUid: this.documentUID, name: fileName } as Honeycomb.Tenant.Tasker.IService.Model.TaskAttachment)
      .toPromise()
      .then(_ => this.refreshFiles());
    }

    this.form.get('name').setValue(null);
    this.form.get('description').setValue(null);
    this.documentUID = null;
    this.inputFileRef.clear();
    this.cd.detectChanges();
  }

  async refreshFiles() {
    this.taskFiles = await this.fileController.GetTaskFiles(this.taskID).toPromise();
    this.loading$ = of(false);
    this.firstLoading = of(false);
    this.fileUploading$ = false;
    this.cd.detectChanges();
    this.scrollToBotton();
  }

  editFile(file: Honeycomb.Tenant.Tasker.IService.Model.TaskAttachment) {

    if (!this.taskAttachmentID) { // toggle
      this.form.get('description').setValue(file.description);
      this.documentUID = file.documentUid;
      this.taskAttachmentID = file.taskAttachmentID;
    } else {
      this.taskAttachmentID = null;
      this.documentUID = null;
      this.form.get('description').setValue(null);
    }
  }

  private scrollToBotton() {
    const msgContainer = document.getElementById('file-container');
    msgContainer.scrollTop = msgContainer.scrollHeight - msgContainer.clientHeight;
  }

  downloadFile(file) {
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    link.href =  `${this.globals.GetUrlPrefix()}/api/TenantTasker/file/taskattachment/${file.documentUid}/${file.name}?TenantHash=${this.tenantHash}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async ngOnInit() {
    // this.loadActivitiesFiles();
  }

  ngAfterViewInit(): void {

  }

  public async delete(file, $event: Event) {
    const delDialog = this.dialog.open(DeleteDialogComponent);
    const delSub = delDialog.afterClosed().subscribe(async d => {
      if (d === true) {
        await this.fileController.DeleteTaskAttachment(file.taskAttachmentID).toPromise();
        await this.refreshFiles();
      }
    });
    this.subscriptions.push(delSub);
  }

  addFile($event: Event) {
    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }

    const inpFile = this.inputFileRef;
    inpFile.change = ($inpEvent: Event) => {
      this.fileUploading$ = true;
      const f = ($inpEvent.target as HTMLInputElement).files[0];
      this.form.get('name').setValue(f.name);
      this.cd.markForCheck();

      return this.fileCustomController.UploadFile(f, $event.target as HTMLElement)
                  .subscribe(p => {
                    if (p) {
                      this.documentUID = p.recordUID;
                      this.documentEditUID = null;
                      this.cd.detectChanges();
                    }
                  });
    };
    inpFile.open();
  }

  getImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/TaskerFile/GetImage', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  zoomAttachmentImage(photoUID: any) {
    zoomScrollable(this.getImageUrl(photoUID, 1920, 1080), this.trans.instant('tasker.common.close'),
                   this.trans.instant('tasker.common.delete'));
  }

  getTempImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/TenantTasker/File/gettempfile', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  zoomAttachmentTempImage(photoUID: any) {
    zoomScrollable(this.getTempImageUrl(photoUID, 1920, 1080), this.trans.instant('tasker.common.close'),
                   this.trans.instant('tasker.common.delete'),
                   () => {
                    this.documentUID = null;
                    this.cd.detectChanges();
                   } );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
