import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';
import { BuildConfig } from 'src/app/services/build-config';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { TasksTableColumnsModel } from './tasks-table-columns/tasks-table-columns.model';

export function filterNameToEnum(filterName: string) {
    switch(filterName) {
        case 'my-locations':
            return Honeycomb.Common.Enums.TaskMainFilter.myLocations;
        case 'my-tasks':
            return Honeycomb.Common.Enums.TaskMainFilter.myTasks;
        case 'assigned-by-me':
            return Honeycomb.Common.Enums.TaskMainFilter.assgnedByMe;
        default:
            return Honeycomb.Common.Enums.TaskMainFilter.all;
        }
}

export function filterEnumToName(filterEnum: Honeycomb.Common.Enums.TaskMainFilter) {
    switch(filterEnum) {
        case Honeycomb.Common.Enums.TaskMainFilter.myLocations:
            return 'my-locations';
        case Honeycomb.Common.Enums.TaskMainFilter.myTasks:
            return 'my-tasks';
        case Honeycomb.Common.Enums.TaskMainFilter.assgnedByMe:
            return 'assigned-by-me';
        default:
            return 'all';
        }
}

export enum TaskViewType {
    Rows = 0,
    Table = 1
}

// export class TaskViewSort {
//     constructor(
//         public column: string, 
//         public direction: Honeycomb.Common.SortDirection) {
//     }
//     get directionStr() { return this.direction === Honeycomb.Common.SortDirection.desc ? 'desc' : 'asc'; }

//     public reverseSortDirection() {
//         if (this.direction === Honeycomb.Common.SortDirection.asc) {
//             this.direction = Honeycomb.Common.SortDirection.desc;
//         } else {
//             this.direction = Honeycomb.Common.SortDirection.asc;
//         }
//     }
// }

        // tslint:disable: max-line-length
export function availableCols(agenda?: TaskAgenda): TasksTableColumnsModel[] {
    let res = [
        { columnName: 'dateRead', sortOrder: 0, locKey: 'tasker.common.read', visible: true },
        { columnName: 'taskID', sortOrder: 1, locKey: 'tasker.common.id', visible: true },
        { columnName: 'taskStatus', sortOrder: 2, locKey: 'tasker.common.task-status', visible: true },
        { columnName: 'childTasksCount', sortOrder: 3, locKey: 'tasker.tasks-table.child-tasks-tount', visible: true },
        { columnName: 'parentTaskID', sortOrder: 4, locKey: 'tasker.tasks-table.parent-task', visible: true },
        { columnName: 'taskSequenceID', sortOrder: 5, locKey: 'tasker.tasks-table.sequence-task', visible: true },
        { columnName: 'name', sortOrder: 6, locKey: 'tasker.common.name', visible: true },
        { columnName: 'messagesTotal', sortOrder: 7, locKey: 'tasker.common.messages', visible: true },
        { columnName: 'description', sortOrder: 8, locKey: 'tasker.common.description', visible: true },
        { columnName: 'externalSupplier', sortOrder: 9, locKey: 'tasker.common.external-supplier', visible: true, agenda: TaskAgenda.Repairs, isAttribute: true },
        { columnName: 'repairType', sortOrder: 11, locKey: 'tasker.common.repair-type', visible: true, agenda: TaskAgenda.Repairs, isAttribute: true },
        { columnName: 'creator', sortOrder: 11, locKey: 'tasker.common.creator', visible: true },
        { columnName: 'assignee', sortOrder: 12, locKey: 'tasker.common.assigned', visible: true },
        { columnName: 'address', sortOrder: 13, locKey: 'tasker.common.address', visible: true },
        { columnName: 'locationName', sortOrder: 14, locKey: 'tasker.common.location', visible: true },
        { columnName: 'startDate', sortOrder: 15, locKey: 'tasker.common.start', visible: true },
        { columnName: 'deadline', sortOrder: 16, locKey: 'tasker.common.deadline', visible: true },
        { columnName: 'priority', sortOrder: 17, locKey: 'tasker.common.priority', visible: true },
        { columnName: 'created', sortOrder: 18, locKey: 'tasker.common.created', visible: true }
    ];
    if (!!agenda) {
        return res.filter(a => !a.agenda || a.agenda === agenda);
    }
    return res;
};

export function getVisibleColsList(taskAgenda: TaskAgenda): Array<TasksTableColumnsModel> {
    const visibleColsSerialized = localStorage.getItem('TasksVisibleColumns' + taskAgenda);
    if (!visibleColsSerialized) {
        let res = availableCols().sort((a, b) => a.sortOrder - b.sortOrder);
        if (!!taskAgenda) {
            res = res.filter(r => !r.agenda || r.agenda === taskAgenda);
        }
        return res;
    }
    let visibleColsStored = JSON.parse(visibleColsSerialized) as TasksTableColumnsModel[];
    // return only valid fields
    visibleColsStored = visibleColsStored.filter(c => availableCols().map(ac => ac.columnName).includes(c.columnName));
    if (!!taskAgenda) {
        visibleColsStored = visibleColsStored.filter(r => !r.agenda || r.agenda === taskAgenda);
    }
    return visibleColsStored.sort((a, b) => a.sortOrder - b.sortOrder);
}

export function getVisibleCols(taskAgenda?: TaskAgenda, buildConfig?: BuildConfig): string[] {

    let visibleCols = getVisibleColsList(taskAgenda).filter(c => c.visible === true);
    if(!buildConfig) {
        return visibleCols.filter(a => !a.isAttribute).map(a => a.columnName);
    }
    if (taskAgenda === TaskAgenda.Repairs) {
        if(buildConfig && buildConfig.taskList && buildConfig.taskList.repair && buildConfig.taskList.repair.attributes) {
            visibleCols = visibleCols.filter(a => !a.isAttribute || buildConfig.taskList.repair.attributes.indexOf(a.columnName) > -1);
        }
    } 
    else {
        if(buildConfig && buildConfig.taskList && buildConfig.taskList.default && buildConfig.taskList.default.attributes) {
            visibleCols = visibleCols.filter(a => !a.isAttribute || buildConfig.taskList.default.attributes.indexOf(a.columnName) > -1);
        }
    }
    return visibleCols.map(a => a.columnName);
}

export function setVisibleCols(cols: TasksTableColumnsModel[], taskAgenda: TaskAgenda): TasksTableColumnsModel[] {
    const fieldsToStore = cols.filter(c => availableCols().map(ac => ac.columnName).includes(c.columnName)); // store only valid fields
    localStorage['TasksVisibleColumns' + taskAgenda] = JSON.stringify(fieldsToStore);
    return fieldsToStore;
}