import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Globals } from 'src/app/common/globals';
import { Geolocation } from 'src/app/common/model/geolocation';

@Component({
  selector: 'locations-map-dialog',
  templateUrl: './locations-map-dialog.component.html',
  styleUrls: ['./locations-map-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsMapDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: LocationMapData,
              private cd: ChangeDetectorRef,
              private globals: Globals,
              private dialogRef: MatDialogRef<LocationsMapDialogComponent>) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  reloadPage() {
    window.location.reload();
  }
}


export interface LocationMapData {
  showOnlyUserRelated: boolean;
  highlightedLocationIDs: number[];
  centerToPosition: Geolocation.GeolocationCoordinates;
}