import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  delay
} from 'rxjs/operators';
import { of } from 'rxjs';

import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  checkOnlineCompletedAction,
  checkOnlineFailedAction,
  checkOnlineAction,
} from './statuses.actions';
import { AppState } from 'src/app/app.states';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/common/globals';

@Injectable()
export class StatusEffects {
  constructor(
    private actions$: Actions,
    @Inject('StatusController')
    private statusController: Honeycomb.Tenant.Tasker.IService.Controller.StatusController,
    private httpClient: HttpClient,
    private globals: Globals,
    private store: Store<AppState>
  ) {}

  onlineStatusEffect = createEffect(() =>
  this.actions$.pipe(
    delay(1000),
    ofType(checkOnlineAction),
    withLatestFrom(this.store),
    mergeMap((s) => {
      const reportRequest = this.httpClient.get(this.globals.GetUrlPrefix() + '/api/TenantTasker/ping', { responseType: 'text' });
      return reportRequest.pipe(
        map(res => {
          return checkOnlineCompletedAction({ result: res === 'pong' });
        }),
        catchError(error => {
          return of(checkOnlineFailedAction());
        })
      );
    })
));
}
