<div fxFlex fxLayout="column" fxLayoutGap="10px" style="padding: 20px;">
    <div>{{ 'tasker.update-dialog.message' | translate }}</div>
    <button mat-raised-button color="primary" (click)="reloadPage()">
        <div fxLayout="row" fxLayoutGap="10px">
            <div>{{ 'tasker.update-dialog.button' | translate }}</div>
            <div>{{ count | async }}</div>          
            <!-- <div class="wrapper">
                <div class="ball-scale-multiple">
                  <div style="background-color: rgba(64,255,128, 0.5);"></div>
                  <div style="background-color: rgba(255, 64,128, 0.5);"></div>
                  <div style="background-color: rgba(255, 128, 64, 0.5);"></div>
                </div>
            </div> -->
        </div>
    </button>
</div>