<ng-container *ngIf="form && form.controls" [formGroup]="form">
    <div formArrayName="taskActivitiesUpsert">
        <div *ngFor="let taskActivity of getActivities(form); let ix = index" fxLayoutGap="12px" [formGroupName]="ix" style="max-height: calc(65vh - 35px);" >

            <mat-card appearance="outlined" fxLayout="column" *ngIf="ix === selectedStepper">
                <mat-card-title style="font-size: 16px;">
                    <div fxLayout="row">
                        <label class="mt-3" fxFlex><b>{{ taskActivity.value.name }}</b></label>
                        <div class="mt-3">
                            {{ 'tasker.status.closed' | translate }}
                            <mat-icon *ngIf="!taskActivity.value.activityDone" class="activity-in-progress" [icIcon]="twotoneIndeterminateCheckBox"></mat-icon>
                            <mat-icon *ngIf="taskActivity.value.activityDone" class="activity-done" [icIcon]="twotoneCheckBox"></mat-icon>
                        </div>
                    </div>
                </mat-card-title>
                <mat-card-content>
                
                <div formArrayName="activityInputs" fxLayout="column">
                    <task-input *ngFor="let activityInput of getActivityInputs(taskActivity); trackBy: trackInputBy" 
                        [activityInputControl]="activityInput"
                        [detailComponent]="detailComponent"
                        [task]="task"
                        fxLayoutGap="12px" 
                        (noteUpdated)="noteUpdated($event, activityInput)"
                        (valueUpdated)="valueUpdated($event, activityInput)"
                        (valuePhotoInsert)="valuePhotoInsert($event, activityInput)"
                        (valuePhotoRemove)="valuePhotoRemove($event, activityInput)"
                        [inputUpdating]="inputUpdating">
                    </task-input>
                </div>
                </mat-card-content>
                <mat-card-title style="font-size: 16px;" *ngIf="getActivityInputs(taskActivity).length > 4">
                    <div fxLayout="row">
                        <label class="mt-3" fxFlex><b>{{ taskActivity.value.name }}</b></label> 
                        <div class="mt-3">
                            {{ 'tasker.status.closed' | translate }}
                            <mat-icon *ngIf="!taskActivity.value.activityDone" class="activity-in-progress" [icIcon]="twotoneIndeterminateCheckBox"></mat-icon>
                            <mat-icon *ngIf="taskActivity.value.activityDone" class="activity-done" [icIcon]="twotoneCheckBox"></mat-icon>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card>
        </div>
    </div>
</ng-container>