import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardStatusActions from './statuses.actions';
import { StatusState } from './statuses.state';

const StatusReducer = createReducer(StatusState(),

// tslint:disable: max-line-length
  on(DashboardStatusActions.checkOnlineAction, (state) => ({...state, loading: true, error: null})),
  on(DashboardStatusActions.checkOnlineCompletedAction, (state, { result }) => ({ ...state, online: result })),
  on(DashboardStatusActions.checkOnlineFailedAction, (state) => ({ ...state, online: false })),
);

export function reducer(state: StatusState | undefined, action: Action) {
  return StatusReducer(state, action);
}
