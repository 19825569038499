import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import baselineFiberManualRecord from '@iconify/icons-ic/baseline-fiber-manual-record';
import { HttpClient } from '@angular/common/http';
import { timer, Observable, Subject, of, interval, Subscription } from 'rxjs';
import { switchMap, takeUntil, catchError, map, tap, debounceTime } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { checkOnlineAction } from 'src/app/api/statuses/statuses.actions';


@Component({
  selector: 'vex-toolbar-online',
  templateUrl: './toolbar-online.component.html',
  styleUrls: ['./toolbar-online.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarOnlineComponent implements OnInit, OnDestroy {

  baselineFiberManualRecord = baselineFiberManualRecord;

  private killTrigger: Subject<void> = new Subject();
  private isOnline: Observable<boolean> = this.store.pipe(select(s => s.status.online));
  private pingSubs: Subscription;
  statusClass$: Observable<string> = this.store.pipe(select(s => s.status.online)).pipe(map(s => {
    if (s === true) {
      return 'online';
    } else if (s === false) {
      return 'offline';
    }
    return 'unknown';
  }));

  private refreshInterval$: Observable<any> = timer(0, 10000)
    .pipe(
      debounceTime(9000),
      // This kills the request if the user closes the component
      takeUntil(this.killTrigger),
      // switchMap cancels the last request, if no response have been received since last tick
      tap(() => this.store.dispatch(checkOnlineAction()))
    );

  constructor(private store: Store<AppState>) {
    this.store.dispatch(checkOnlineAction());
  }

  ngOnInit() {
    // this.pingSubs = this.refreshInterval$.subscribe();
  }

  ngOnDestroy(): void {
    this.killTrigger.next();
    if (this.pingSubs) {
      this.pingSubs.unsubscribe();
    }
  }
}
