<form [formGroup]="form" class="messages-wrap">
    <div style="display: flex; flex-flow: column; height: 100%;">
        <div style="display: flex; flex-flow: column; flex: 1 1 auto; overflow-y: auto;scroll-behavior: smooth;" id="message-container">
            <div class="ball-scale-multiple blue task-row" style="margin-top: 30px;" *ngIf="firstLoading | async">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div *ngFor="let msg of items; let i = index" class="task-message-wrap">
                <message-item [message]="msg" [isLast]="(i + 1 == items.length)" [inEdit]="messageInEdit(msg)" (editMessage)="editMessage($event)">
                </message-item>
            </div>
        </div>
            <div class="messageInput" style="display: flex; flex: 0 1 75px; padding-top: 15px;"  *ngxPermissionsOnly="uiRole('message-input')">
            <div style="width: calc(100% - 80px);">
                <mat-form-field style="width: 100%;">
                    <textarea matInput placeholder="" formControlName="newMessage" cdkTextareaAutosize
                        rows="1" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" [disabled]="(loading$ | async)"></textarea>
                </mat-form-field>
            </div>
            <div class="photo-thumbnail" (click)="zoomAttachmentTempImage(imageUID)" style="margin: 7px 0 0 10px;" *ngIf="!imageEditUID">
                <img *ngIf="imageUID" [src]="getTempImageUrl(imageUID)">
            </div>
            <div class="photo-thumbnail" (click)="zoomAttachmentImage(imageEditUID)" style="margin: 7px 0 0 10px;" *ngIf="!!imageEditUID">
                <img *ngIf="imageEditUID" [src]="getImageUrl(imageEditUID)">
            </div>
            <div class="photo-add-btn progress-container" (click)="addPhoto($event)" style="margin: 10px 0 0 10px;">
                <ic-icon [icIcon]="twotoneAddAPhoto" size="26px"></ic-icon>
                <!-- <div>{{ 'tasker.task-activities.add-photo' | translate }}</div> -->
            </div>
            <div class="attachment-add-btn progress-container" [ngClass]="{'attachment-added': !!documentUID || !!documentEditUID}" (click)="addAttachment($event)" style="margin: 10px 0 0 10px;">
                <ic-icon [icIcon]="roundContentPaste" size="26px"></ic-icon>
            </div>
            <button style="max-height: 43px;" mat-button class="button" color="primary" [disabled]="(loading$ | async)" (click)="sendMessage($event)">
                <mat-icon *ngIf="!(loading$ | async)" [icIcon]="baselineSend"></mat-icon>
                <mat-progress-spinner *ngIf="(loading$ | async)" diameter="20" mode="indeterminate"></mat-progress-spinner>
                &nbsp;{{ 'tasker.common.send' | translate }}
            </button>
        </div>
        <ngx-mat-file-input #inputPhoto style="display: none;" placeholder="" accept="image/*,text/plain" capture="environment">
        </ngx-mat-file-input>
        <ngx-mat-file-input #inputAttachment style="display: none;" placeholder="" accept="*/*" capture="environment">
        </ngx-mat-file-input>
    </div>
</form>