import { createFeatureSelector, createSelector, State } from '@ngrx/store';
import * as fromUser from './user.reducer';
import { UserStateIFace } from './user.state';
import { RoleAttributeTaskerValueModel } from './roleAttributeTaskerValue.model';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { distinct, distinctNum, isNullOrUndefined } from 'src/app/common/functions';
import { AppState } from 'src/app/app.states';

export const dashboardSelector = (state: UserStateIFace) => state.dashboard;

export const selectJobID = (state: UserStateIFace) => state.selectedJobID;

export const selectUserJobs = (state: UserStateIFace) => state.currentUser;

export const selectLocations = createSelector(selectJobID, selectUserJobs,
    ( selectedJobID: number, currentUser: Honeycomb.Tenant.Contact.IService.UserInfo) => {
        if (!!selectedJobID && !!currentUser && !!currentUser.userJobs) {
            const userJob = currentUser.userJobs.find(uj => uj.jobID === selectedJobID);
            if (isNullOrUndefined(userJob)) {
                return [];
            }
            let res = userJob.job.jobLocations.map(l => l.locationID);
            res = distinctNum(res);
            return res;
        } else {
            return [];
        }
    });

export const selectFilters = (state: UserStateIFace) => state.dashboard.taskFilters;

// export const selectAttendance = createFeatureSelector<AppState>('attendance');

export const getAttendance = createSelector((state: AppState) => state,
    (state: AppState) => !state ? null : !state.user ? null : state.user.attendance);