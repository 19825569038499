import { DefaultTaskFilter } from "../api/user/roleAttributeTaskerValue.model";
import { Honeycomb } from "./honeycomb-api/honeycomb-api";

export interface TaskSearchProperties {
    pageIndexMap: Map<string, number>; // Agenda key - page index
    pageSizeMap: Map<string, number>; // Agenda key - page size
    agenda: TaskAgenda;
    orderBy: string;
    loading: boolean;
    error: string;
    results: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>;
    listFilter: Honeycomb.Tenant.Tasker.IService.TaskListFilter;
    sort: Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort;
    taskFilters: DefaultTaskFilter[];

    detail: Honeycomb.Tenant.Tasker.IService.Model.Task;
    detailError: string;
    detailLoading: boolean;
    detailSaving: boolean;
    detailSaved: boolean;
    selectedTaskID: number;
}

export enum TaskAgenda {
  MyTasks = 1,
  TasksByMe = 2,
  Dashboard = 3,
  Overview = 4,
  Repairs = 5,
  MyStoreDetailTasks = 6,
  Calendar = 7
}

const FilterInit = () => {
    let res = {
        pageIndexMap: new Map<string, number>(),
        pageSizeMap: new Map<string, number>(),
        agenda: TaskAgenda.MyTasks,
        orderBy: '',
        loading: false,
        error: null,
        detailError: null,
        detailLoading: false,
        detailSaving: false,
        detailSaved: false,
        results: null,
        listFilter: {
          userTaskRelations: null,
          taskRelationAdditional: [],
          sortParams: [],
          dateFrom: null,
          dateTo: null,
          fulltext: null,
          includeAttributes: [],
          jobID: null,
          limit: null,
          locationID: null,
          locations: null,
          mainFilter: Honeycomb.Common.Enums.TaskMainFilter.all,
          offset: null,
          operations: null,
          priorities: [],
          priority: null,
          taskRead: null,
          taskState: null,
          taskStatusIDs: [],
          taskSequenceID: null,
          baseTaskStatuses: [],
          taskTypes: []
        } as Honeycomb.Tenant.Tasker.IService.TaskListFilter,
        taskFilters: [],
        detail: null,
        selectedTaskID: null,
        sort: {
          column: "taskID",
          direction: "desc",
          isAttribute: false
        } as Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort
    } as TaskSearchProperties;

    res.pageIndexMap.set('tasks-main-list-' + TaskAgenda.MyTasks, 0);
    res.pageSizeMap.set('tasks-main-list-' + TaskAgenda.MyTasks, 10);

    res.pageIndexMap.set('tasks-main-list-' + TaskAgenda.Repairs, 0);
    res.pageSizeMap.set('tasks-main-list-' + TaskAgenda.Repairs, 10);

    res.pageIndexMap.set('tasks-main-table-' + TaskAgenda.MyTasks, 0);
    res.pageSizeMap.set('tasks-main-table-' + TaskAgenda.MyTasks, 10);

    res.pageIndexMap.set('tasks-main-table-' + TaskAgenda.Repairs, 0);
    res.pageSizeMap.set('tasks-main-table-' + TaskAgenda.Repairs, 10);

    res.pageIndexMap.set('my-store-list-default', 0);
    res.pageSizeMap.set('my-store-list-default', 5);
    return res;
  };

export default FilterInit;
