import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFromUtcPipe } from './date-from-utc.pipe';


@NgModule({
  declarations: [DateFromUtcPipe],
  exports: [
    DateFromUtcPipe
  ],
  imports: [
    CommonModule
  ],
})
export class DateFromUtcModule {
}
