import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import * as signalR from '@microsoft/signalr';
import { bellDataUpdatedAction, bellItemUpdatedAction } from 'src/app/api/notification/bell/notification-bell.actions';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
@Injectable({
  providedIn: 'root',
})
export class BellNotificationService {

  connection: signalR.HubConnection;

  constructor(private store: Store<AppState>) 
  {
  }

  /**
   * Subscribes to WS endpoint
   * @param auth not null, if user is logged in
   * @param bellUrl Path to server signalR URL, http://localhost:8381/api/signalr/bell
   */
  subscribeToBell(bellUrl: string ) {

    if (!this.connection) {
      var jwtToken = localStorage["token"] || sessionStorage["token"] ;
      this.connection = new signalR.HubConnectionBuilder()
                                  .configureLogging(signalR.LogLevel.Error)
                                  .withUrl(bellUrl + "?access_token=" + jwtToken, 
                                  {
                                    skipNegotiation: true,
                                    transport: signalR.HttpTransportType.WebSockets,
                                    accessTokenFactory: () => jwtToken
                                  })
                                  .withAutomaticReconnect([0, 5000, 10000])
                                  .build();

      this.connection.on("bell-msg", (a: HoneycombCustom.Tenant.Tasker.IService.Model.NotificationBellModel) => {
        this.store.dispatch(bellItemUpdatedAction({ item: a }));
      });
  
      this.connection.start()
      .then(async c => {
        let data = await this.connection.invoke("subscribe");
        this.store.dispatch(bellDataUpdatedAction({ data: data }));
      })
      .catch(err => {
        console.log(err);
      });
    }
  }
  async setNotificationAsRead(notificationBellQueueID: number, read: boolean = true) {
    await this.connection.invoke("SetRead", notificationBellQueueID, read);
  }
}