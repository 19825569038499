import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogButtonType } from '../prompt-dialog/prompt-dialog.model';

@Component({
    selector   : 'button-bar',
    templateUrl: './button-bar.component.html',
    styleUrls  : ['./button-bar.component.scss']
})
export class ButtonBarComponent
{
    @Input()
    public buttons: DialogButtonType[] = [];

    @Output()
    buttonClicked: EventEmitter<DialogButtonType> = new EventEmitter();


    @Input()
    public isError: boolean = null;

    constructor(
        private trans: TranslateService
    )
    {
    }

    public get deleteButton(): DialogButtonType {
        return this.buttons.find( b => b === DialogButtonType.delete );
    }

    public get nonDeleteButtons(): DialogButtonType[] {
        return this.buttons.filter( b => b !== DialogButtonType.delete );
    }

    public getButtonColor( button: DialogButtonType): string {

        if (!!this.isError) {
            return 'warn';
        }

        switch (button) {
            case DialogButtonType.ok:
            case DialogButtonType.yes:
                return 'primary';

            case DialogButtonType.save:
                return 'primary';

            case DialogButtonType.delete:
                return 'warn';
        }
    }

    public getButtonText( button: DialogButtonType): string {
        if (button === DialogButtonType.ok) {
            return this.trans.instant('tasker.common.ok'); // TCh: this syntax is standard for whole app
        }

        // TCh: I don undestand why everyone needs to implement his own standard instead of using already implemented one
        return this.trans.instant( 'button.' + DialogButtonType[button] );
    }

    public buttonClick( button: DialogButtonType ): void {
        if (this.buttonClicked) {
            this.buttonClicked.emit( button );
        }
    }

}
