import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { timer } from 'rxjs';
import { map, tap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  private _counterSource$ = new Subject<any>();
  private _subscription = Subscription.EMPTY;

  counter = 6;
  interval = 1000;
  value = new EventEmitter<number>();
  count: Observable<number>;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private cd: ChangeDetectorRef,
              private dialogRef: MatDialogRef<UpdateDialogComponent>) {
                this.count = of(this.counter);
                this._subscription = timer(0, this.interval)
                .pipe(
                  map(val => this.counter - 1),
                  takeWhile(val => val > -1),
                  tap((val) => {
                    console.log(val);
                    this.count = of(val);
                    this.counter--;
                    this.cd.detectChanges();
                    if (this.counter === 0 ) {
                      this.reloadPage();
                    }
                  }),
                ).subscribe();
  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  reloadPage() {
    console.log('UpdateDialogComponent - reloading page');
    window.location.reload();
  }
}
