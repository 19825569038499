<ng-container *ngIf="form" [formGroup]="form">
    <div formArrayName="taskActivitiesUpsert">
        <div *ngFor="let taskActivity of getActivities(form); let ix = index" fxLayoutGap="12px" [formGroupName]="ix" style="max-height: calc(65vh - 35px);" >
            <mat-card appearance="outlined" fxLayout="column">
                <mat-card-title style="font-size: 16px;">
                    <div fxLayout="row">
                        <label class="mt-3" fxFlex><b>{{ taskActivity.value.name }}</b></label>
                        <mat-slide-toggle  class="mt-3" formControlName="activityDone" [checked]="taskActivity.value.activityDone">{{ 'tasker.status.repaired' | translate }}
                        </mat-slide-toggle>
                    </div>
                </mat-card-title>
                <mat-card-content >
                    <div class="ball-scale-multiple blue" *ngIf="(loading$ | async)">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    <div formArrayName="activityInputs" fxLayout="column" [ngStyle]="{'display': (loading$ | async) ? 'none':'block'}">
                        <task-input *ngFor="let activityInput of getActivityInputs(taskActivity)" [activityInputControl]="activityInput" fxLayoutGap="12px"
                            (noteUpdated)="noteUpdated($event, activityInput)"
                            (valueUpdated)="valueUpdated($event, activityInput)"
                            (valuePhotoInsert)="valuePhotoInsert($event, activityInput)"
                            (valuePhotoRemove)="valuePhotoRemove($event, activityInput)">
                        </task-input>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-container>