import { createAction, props } from '@ngrx/store';
import { LoginForm, JwtToken } from './auth.model';

export enum AuthActionTypes {
  Login = '[Login Page] Login',
  LoginComplete = '[Login Page] Login Complete',
  LoginSuccess = '[Auth API] Login Success',
  AwaitingTenantSelect = '[Auth API] Awaiting Tenant Select',
  TenantSelected = '[Auth API] Tenant Selected',
  ReturnUrlSet = '[Login Page] Return URL set',
  LoginFailure = '[Auth API] Login Failure',
  CheckLogin = '[Auth] Check Login',
  Logout = '[Login Page] Logout',
  LogoutConfirmed = '[Login Page] Logout Confirmed'
}

export const loginAction = createAction(AuthActionTypes.Login, props<LoginForm>());

export const loginCompleteAction = createAction(AuthActionTypes.LoginComplete);

export const loginSuccessAction = createAction(AuthActionTypes.LoginSuccess,
                                              props<{jwtTokenStrings: string[], rememberMe: boolean}>());

export const loginAwaitingTenantSelectAction = createAction(AuthActionTypes.AwaitingTenantSelect, props<{jwtTokens: JwtToken[]}>());

export const loginTenantSelectedAction = createAction(AuthActionTypes.TenantSelected, props<{jwtToken: JwtToken, rememberMe: boolean}>());

export const loginSetReturnUrlAction = createAction(AuthActionTypes.ReturnUrlSet, props<{returnUrl: string}>());

export const loginFailureAction = createAction(AuthActionTypes.LoginFailure, props<{error: string}>());

export const logoutAction = createAction(AuthActionTypes.Logout);

export const logoutConfirmedAction = createAction(AuthActionTypes.LogoutConfirmed);
