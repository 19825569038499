import { loginAction,
         loginCompleteAction,
         loginSuccessAction,
         loginAwaitingTenantSelectAction,
         loginTenantSelectedAction,
         loginSetReturnUrlAction,
         loginFailureAction,
         logoutAction, 
         logoutConfirmedAction} from './auth.action';
import { AuthState, initializeState } from './auth.state';
import { createReducer, on, Action } from '@ngrx/store';
import { JwtToken } from './auth.model';
import { JwtHelperService } from '@auth0/angular-jwt';
const jwtHelper = new JwtHelperService();

const authReducer = createReducer(initializeState(),
  on(loginAction, state => ({...state, loading: true, error: null})),
  on(loginCompleteAction, state => ( {...state, loading: false } )),
  on(loginSuccessAction, (state, { jwtTokenStrings } ) => ( { ...state, tokenStrings: [...jwtTokenStrings], loading: false } )),
  on(loginAwaitingTenantSelectAction, (state, { jwtTokens }) => (  { ...state, jwtTokens: [...jwtTokens] })),
  on(loginTenantSelectedAction, (state, {jwtToken}) => tenantSelectRedudude(state, jwtToken)),
  on(loginSetReturnUrlAction, (state, {returnUrl}) => (  { ...state, returnUrl })),
  on(loginFailureAction, (state, { error }) => (  { ...state, jwtToken: null, loading: false, error: error })),
  on(logoutConfirmedAction, (state) => (  { ...state, jwtToken: null, jwtTokens: null, tenantID: null, tenantHash: null, appRoleIDs: null })),
  on(logoutAction, (state) => ( Object.assign({}, initializeState(true))))
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}

export function tenantSelectRedudude(state: AuthState, jwtToken: JwtToken | string): AuthState {

  const s = Object.assign(initializeState(false), state);

  if (!jwtToken || jwtToken === '') {
    s.jwtToken = null;
    s.jwtTokens = null;
    s.tenantID = null;
    s.tenantHash = null;
    s.appRoleIDs = null;
    s.loading = false;
    return;
  }

  jwtToken = jwtToken as JwtToken;

  let roleIDs = [];
  if (!!jwtToken.app_role_id) {
    if (!isNaN(Number(jwtToken.app_role_id))) { // is number
      roleIDs.push(Number(jwtToken.app_role_id));
    } else if (Array.isArray(jwtToken.app_role_id)) { // is array
      roleIDs = jwtToken.app_role_id.map(r => Number(r));
    }
  }

  s.jwtToken = jwtToken;

  s.userID = Number(jwtToken.sub);
  s.tenantID = Number((jwtToken).TenantID);
  s.tenantHash = (jwtToken).TenantHash;
  s.appRoleIDs = roleIDs;
  s.loading = false;
  return s;
}

export const getJwtTokens = (state: AuthState) => state.jwtTokens;
export const getJwtTokenStrings = (state: AuthState) => state.tokenStrings;
