import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { MatIconModule } from '@angular/material/icon';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TaskSequenceComponent } from './task-sequence.component';
import { TaskItemModule } from 'src/@vex/components/task-item/task-item.module';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    TaskSequenceComponent
  ],
  imports: [
    CommonModule,
    PageLayoutModule,
    MatButtonModule,
    FlexLayoutModule,
    IconModule,
    MatIconModule,
    ContainerModule,
    PageLayoutModule,
    MatPaginatorModule,
    TranslateModule,
    TaskItemModule,
    MatPaginatorModule,
    MatDividerModule,
    NgxPermissionsModule
  ],
  exports: [
    TaskSequenceComponent
  ]
})
export class TaskSequenceModule { }
