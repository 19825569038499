import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  loadDashboardTasksInfoAction,
  loadDashboardTasksInfoCompletedAction,
  loadDashboardTasksInfoFailedAction,
  setTileUnreadNumberAction
} from './dashboard-tasks.actions';
import { AppState } from 'src/app/app.states';
import { TileDashboardSection, TileDetail } from '../user/roleAttributeTaskerValue.model';

/**
 * Handles only dashboard tiles values
 * Dashboard configuration can be found in user.dashboard
 */

@Injectable()
export class DashboardTaskEffects {
  constructor(
    private actions$: Actions,
    @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
    private router: Router,
    private store: Store<AppState>
  ) {}

  loadDashboardTasksInfoEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDashboardTasksInfoAction),
      withLatestFrom(this.store),
      map(([data, store]) => {
        const tileKeys = Array<TileDetail>();
        if (!!store.user && !!store.user.dashboard && !!store.user.dashboard.sections) {
          store.user.dashboard.sections.filter(s => s.type === 2)
          .map(t => t as TileDashboardSection)
          .filter(t => t.tileKeys && t.tileKeys.length > 0)
          .forEach(tile => {
            tile.tileKeys.forEach(tileKey => {
              tileKeys.push(tileKey);
            });
          });
        }
        return {
          tileKeys,
          store
        };
      }),
      mergeMap((m) =>
        this.taskController.Dashboard({ tileKeys: m.tileKeys.map(tk => tk.key), jobID: m.store.user.selectedJobID }).pipe(
          map(tileBadges => {
            return loadDashboardTasksInfoCompletedAction({ tileBadges });
          }),
          catchError(error => of(loadDashboardTasksInfoFailedAction(error)))
        )
      )
    )
  );


  setTileUnreadNumberEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(setTileUnreadNumberAction),
      withLatestFrom(this.store),
      map(([data, s]) => {
        // TODO: placeholder for future implementation
        // const task = s.myTasks.results.find(t => t.taskID === data.taskID);

        // if (!task) {
        //   return s;
        // }

        // const resState = Object.assign({}, s);
        // const dashState = Object.assign({}, resState.dashboard);
        // resState.dashboard = dashState;
        // const tileKeys: Array<string> = ['tasks'];
        // if (task.operation.taskType === Honeycomb.Common.Enums.TaskType.repairRequest) {
        //   tileKeys.push('repairs');
        // }
        // dashState.tileBadges = Object.assign({}, dashState.tileBadges);

        // tileKeys.forEach(tk => {
        //   const tasksVal = dashState.tileBadges[tk];
        //   if (tasksVal) {
        //     if (!isNaN(Number(tasksVal))) {
        //       const nr = Number(tasksVal);
        //       let move = -1;
        //       if (nr === 0) {
        //         move = 0; // this shold never happen
        //         console.error('Maximum read tasks reached - cannot reduce');
        //       }
        //       dashState.tileBadges[tk] = (nr + move) + '';
        //     }
        //   }
        // });
        // return resState;
      })
    ),
    { dispatch: false }
  );
}
