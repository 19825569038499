import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthGuardDevService } from './services/auth-guard-service-dev.service';
import { JwtModule } from '@auth0/angular-jwt';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './api/auth/auth.effects';
import { AuthService, STORAGE_TOKEN_KEY } from './api/auth/auth.service';
import { Globals } from './common/globals';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import localeSk from '@angular/common/locales/sk';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from './shared.module';
import { reducers, metaReducers } from './app.states';
import { MyTasksEffects } from './api/tasks/my-tasks/my-tasks.effects';
import { Honeycomb } from './services/honeycomb-api/honeycomb-api';
import { DashboardTaskEffects } from './api/dashboard/dashboard-tasks.effects';
import { ApiCaller } from './services/honeycomb-api/api-caller';
import { OperationsEffects } from './api/operations/operations.effects';
import { ContactsEffects } from './api/contacts/contacts.effects';
import { TaskerFilesEffects } from './api/taskerfiles/taskerfiles.effects';
import { HoneycombCustom } from './services/honeycomb-api/honeycomb-custom-api';
import { StatusEffects } from './api/statuses/statuses.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UserEffects } from './api/user/user.effects';
import { PbiEffects } from './api/pbi/pbi.effects';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { TaskListFilterModule } from './pages/tasks/task-list-filter/task-list-filter.module';
import { TaskListSortModule } from './pages/tasks/task-list-sort/task-list-sort.module';
import { UpdateDialogComponent } from './pages/common/update-dialog/update-dialog.component';
import { TasksTableModule } from './pages/tasks/tasks-table/tasks-table.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
// import getLocelizedPaginatorIntl from './..//app/common/localized-paginator-intl.ts';
import { getLocelizedPaginatorIntl } from 'src/app/common/localized-paginator-intl';
import { TasksTableColumnsModule } from './pages/tasks/tasks-table-columns/tasks-table-columns.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LocalStorage } from './common/localStorage';
import { StoresModule } from './pages/stores/stores.module';
import { AppStatusService } from 'src/@vex/services/app-status.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { CustomDateAdapter } from './common/custom-date-adapter';
import { NotificationPushEffects } from './api/notification/push/notification-push.effects';
import { NotificationBellEffects } from './api/notification/bell/notification-bell.effects';
import { WinAuthInterceptor } from './interceptors/win-auth.interceptor';
import { MsalModule, MsalRedirectComponent, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { BuildConfigService } from './services/build-config.service';
import { BuildConfig } from './services/build-config';
import { MSALInstanceFactory } from './common/MSALInstanceFactory';
import { LanguageInterceptor } from './interceptors/language.interceptor';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { TasksService } from './services/tasks.service';


registerLocaleData(localeCs);
registerLocaleData(localeEn);
registerLocaleData(localeSk);

export function tokenGetter() {
  return sessionStorage.getItem(STORAGE_TOKEN_KEY) || localStorage.getItem(STORAGE_TOKEN_KEY);
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function buildConfigInitializer(buildConfigService: BuildConfigService) {
  return () => {
    return buildConfigService.load();
  }
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    TaskListFilterModule,
    TaskListSortModule,
    TasksTableColumnsModule,
    TasksTableModule,
    StoresModule,

    MatFormFieldModule,
        MatInputModule,

    SharedModule.forRoot(),

    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AuthEffects,
      MyTasksEffects,
      DashboardTaskEffects,
      OperationsEffects,
      ContactsEffects,
      TaskerFilesEffects,
      StatusEffects,
      UserEffects,
      NotificationPushEffects,
      NotificationBellEffects,
      PbiEffects
    ]),

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      }
    }),

    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [
          // locals
          'localhost:8381', 'localhost:8081', 'localhost:8377', 'tasker.local', 'localhost', window.location.hostname,
          'dev-api.airme.cz',
          'dev-tasker.airme.cz',
          'proxy.net-programator.cz',
          // test
          'test-api.airme.cz',
          'test-tasker.airme.cz',
          // prod
          'tasker.airme.cz',
          'api.airme.cz',
          'crs.ngretail.cz',
          'apiairme.ngretail.cz',
          // traficon
          'crs.traficon.cz',
          'api-crs.traficon.cz',
          'crs-test.traficon.cz',
          'api-crs-test.traficon.cz',
          // sportisimo
          'uat-crs.sportisimo.cz',
          'uat-api-airme.sportisimo.cz',
          'crs.sportisimo.cz',
          'api-airme.sportisimo.cz',
          // cityrealex
          'uat-airme-api.cityrealex.com',
          'uat-airme-crs.cityrealex.com',
          'crs.cityrealex.com',
          'airme-api.cityrealex.com',
          // MFP
          'crs.mfp.cz',
          'airme-api.mfppapirnictvi.cz',
          'test-crs.mfp.cz',
          'test-airme-api.mfppapirnictvi.cz'
        ]
      }
    }),

    MsalModule,

    // Vex
    VexModule,
    NgxPermissionsModule.forRoot(),
    // CoreModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ServiceWorkerModule.register('./ngsw-worker.crs.js', { enabled: true }),
  ],
  providers: [

    AuthGuardDevService,
    {
      provide: 'TaskController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.TaskController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'InputController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.InputController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'TaskActivityController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.TaskActivityController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'ReportController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Reports.IService.ReportController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'PBIController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Reports.IService.PBIController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'OperationController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.OperationController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'ContactController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Contact.IService.Controller.ContactController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'ContactUserController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Contact.IService.UserController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'TaskerLocationController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.LocationController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'LookupLocationController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.LookupTables.IService.LocationsController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'UserController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Contact.IService.UserController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'ContactJobController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Contact.IService.Controller.JobController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'AdminRoleController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Admin.IService.RoleController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'FileController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.FileController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'FileControllerCustom',
      useFactory: (apiCaller: ApiCaller, httpClient: HttpClient, globals: Globals) =>
        (new HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom(apiCaller, httpClient, globals)),
      deps: [ApiCaller, HttpClient, Globals]
    },
    {
      provide: 'StatusController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.StatusController(apiCaller)),
      deps: [ApiCaller]
    },
    {
        provide: 'ConfigController',
        useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Admin.IService.NamedConfigurationsController(apiCaller)),
        deps: [ApiCaller]
    },
    {
      provide: 'MessageController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.MessageController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'FileController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.FileController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'CodeListController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.LookupTables.IService.CodeListController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'TaskerFileController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.DocumentStorage.IService.TaskerFileController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'AdminUserController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Admin.IService.UserController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'AttendanceController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Contact.IService.Controller.AttendanceController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'SequenceController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.SequenceController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'ApiController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.ApiController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'TaskStatusController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.TaskStatusController(apiCaller)),
      deps: [ApiCaller]
    },
    {
      provide: 'NotificationController',
      useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.NotificationController(apiCaller)),
      deps: [ApiCaller]
    },
    AuthService,
    AppStatusService,
    TasksService,
    Globals,
    LocalStorage,
    { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
    { provide: MatPaginatorIntl, useValue: getLocelizedPaginatorIntl() },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: BuildConfig,
      deps: [HttpClient],
      useExisting: BuildConfigService
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ BuildConfigService ],
      useFactory: buildConfigInitializer
    },
    { provide: MSAL_INSTANCE, deps: [BuildConfigService], useFactory: MSALInstanceFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    MsalService,
  ],
  entryComponents: [
    UpdateDialogComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  const isLocalhost = window.location.hostname.indexOf('localhost') > -1 || window.location.hostname === 'tasker.local';
  const isDevRemoteDebug = window.location.hostname.indexOf('192.168.') > -1;
  if (isLocalhost) {
    return new TranslateHttpLoader(http, `http://localhost:8381/api/i18n/`, '');
  }
  if (isDevRemoteDebug) {
    return new TranslateHttpLoader(http, `http://${ window.location.hostname }/api/TenantTasker/i18n/`, '');
  }

  return new TranslateHttpLoader(http, `/api/i18n/`, '');
}
