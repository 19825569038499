import { Observable, of } from "rxjs";
import { Injectable, Inject } from "@angular/core";
import { RequestParam } from './assets/request-param.model';
import { ApiCaller } from './api-caller';
import { RequestParams } from './assets/request-params.model';
import { HttpMethod } from './assets/http-method.enum';
import { RequestParamType } from './assets/request-param-type.enum';
import { Honeycomb } from './honeycomb-api';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Globals } from 'src/app/common/globals';
import { filter, map, tap } from "rxjs/operators";

// tslint:disable: no-namespace
// tslint:disable: max-line-length
// tslint:disable: no-trailing-whitespace
export namespace HoneycombCustom {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {
                export namespace Controller {

                    @Injectable()
                    export class FileControllerCustom {

                        controllerRoute = `api/TenantTasker/file`;

                        constructor(
                            private apiCaller: ApiCaller,
                            @Inject(HttpClient) private httpClient: HttpClient,
                            @Inject(Globals) private globals: Globals
                            ) {}
                        
                        
                        public UploadFile(file: File, sourceElement?: HTMLElement): Observable<any>{

                            if (!file) {
                                return of(null); // cancelled upload window
                            }

                            let progressElement: HTMLElement = null;
                            let progressContainer: HTMLElement = null;
                            if (!!sourceElement) {

                                progressContainer = sourceElement.closest('.progress-container');

                                if (!!progressContainer) {
                                    const uploadingAttribute = progressContainer.getAttribute('data-uploading');
                                    if (!!uploadingAttribute && uploadingAttribute === 'true') {
                                        return of(null);
                                    }
                                    progressContainer.setAttribute('data-uploading', 'true');
                                
                                    progressElement = document.createElement('div');
                                    progressContainer.appendChild(progressElement);
                                    progressElement.classList.add('progress-element');

                                    const uploadingText = (progressContainer.querySelector('.photo-uploading-text') as HTMLElement);
                                    const originalText = (progressContainer.querySelector('.photo-add-text') as HTMLElement);
                                    if (!!uploadingText) { uploadingText.style.display = 'block'; }
                                    if (!!originalText) { originalText.style.display = 'none'; }
                                }
                                
                            }

                            const endpointRoute = 'uploadfile';
                            const formData = new FormData(); 
                            formData.append('file', file, file.name);
                            const bypass = '?ngsw-bypass=true';
                            return this.httpClient.post<any>([this.globals.GetUrlPrefix(), this.controllerRoute, endpointRoute].join('/') + bypass, formData, 
                            { 
                                reportProgress: true,
                                observe: 'events'
                            }).pipe(
                                tap((e => {
                                    if (e.type === HttpEventType.UploadProgress && !!progressElement) {
                                        progressElement.style.width = sourceElement.clientWidth * (e.loaded / e.total) + 'px';
                                    }
                                })),
                                filter(e => e.type === HttpEventType.Response),
                                map((p: any) => p.body),
                                tap(_ => { 
                                    if (!!progressContainer) {
                                        progressContainer.removeAttribute('data-uploading');
                                        progressContainer.removeChild(progressElement);

                                        const uploadingText = (progressContainer.querySelector('.photo-uploading-text') as HTMLElement);
                                        const originalText = (progressContainer.querySelector('.photo-add-text') as HTMLElement);

                                        if (!!uploadingText) { uploadingText.style.display = 'none'; }
                                        if (!!originalText) { originalText.style.display = 'block'; }
                                    }
                                })
                            );
                        }
                    }
                }

                export namespace Model {
                    export class BellNotificationRow {
                        sortOrder: number;
                        type: string;
                        content: string;
                        entityID?: number;
                    }

                    export class NotificationBellModel {
                        notificationBellQueueID: number;
                        contactUserID: number;
                        read?: Date;
                        bellType: string;
                        title: string;
                        route: string;
                        rows: Array<BellNotificationRow>
                    }
                }
            }
        }
    }
}
