import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { getAttendance } from 'src/app/api/user/user.selectors';
import { AppState } from 'src/app/app.states';
import { Globals } from 'src/app/common/globals';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Geolocation } from 'src/app/common/model/geolocation';
import { calculateDistance } from 'src/app/common/functions';
import { attendanceSaveAction } from 'src/app/api/user/user.actions';
import baselineAccessAlarms from '@iconify/icons-ic/baseline-access-alarms';
import twotoneEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MyStoreDetailComponent } from '../../stores/my-store-detail/my-store-detail.component';
import { TranslateService } from '@ngx-translate/core';

declare var SMap: any;
declare var JAK: any;

@Component({
    selector   : 'locations-map-component',
    templateUrl: './locations-map.component.html',
    styleUrls  : ['./locations-map.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LocationsMapComponent implements AfterViewInit, OnDestroy
{
    @Input()
    showOnlyUserRelated = false;

    @Input()
    highlightedLocationIDs: number[] = null;

    @Input()
    centerToPosition: Geolocation.GeolocationCoordinates = null;

    @ViewChild('map') mapElem: ElementRef<HTMLElement>;

    baselineAccessAlarms = baselineAccessAlarms;
    twotoneEditLocation = twotoneEditLocation;

    attendanceTypeEnum = Honeycomb.Common.Enums.AttendanceType;
    attendanceType: Honeycomb.Common.Enums.AttendanceType;
    locationID: number = null;

    geolocationAvailable = false;

    subscriptions: Array<Subscription> = [];

    address: string;

    closestLocation: any; // Honeycomb.Tenant.LookupTables.IService.Model.LocationShort;
    distanceToClosestLocation: Observable<number> = of(null);
    selectedLocation: any; // Honeycomb.Tenant.LookupTables.IService.Model.LocationShort;
    distanceToSelectedLocation: Observable<number>  = of(null);

    smap: any;
    myLayer: any;

    disableButtons: Observable<boolean> = of(true);

    // tslint:disable-next-line: new-parens
    time: Observable<Date> = of(new Date);

    constructor(
        private globals: Globals,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private trans: TranslateService,
        private store: Store<AppState>,
        @Inject('LookupLocationController') private lookupLocationController:  Honeycomb.Tenant.LookupTables.IService.LocationsController,
        // tslint:disable-next-line:max-line-length
        @Inject('TaskerLocationController') private taskerLocationController:  Honeycomb.Tenant.Tasker.IService.Controller.LocationController
    )
    {
    }

    async ngAfterViewInit(): Promise<void> {
        this.geolocationAvailable = this.globals.geoLocationAvailable;
        await this.refreshLocation();
    }

    async refreshLocation() {
        const lastPosition = this.globals.lastPosition;
        if (!lastPosition) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                this.globals.lastPosition = position;
                await this.setupMap(position);
            });
        } else {
            await this.setupMap(lastPosition);
        }
    }

    private async setupMap(lastPosition: Geolocation.GeolocationPosition) {
        if (this.mapElem) {
            return;
        }

        // MAP CENTER
        let mapCenter: any;
        if (this.centerToPosition) { // Specific position
            mapCenter = SMap.Coords.fromWGS84(this.centerToPosition.longitude,
                                              this.centerToPosition.latitude);
        } else { // Specific current position
            mapCenter = SMap.Coords.fromWGS84(lastPosition.coords.longitude, lastPosition.coords.latitude);
        }

        const m = new SMap(JAK.gel('map'), mapCenter, 14);
        m.addDefaultControls();
        m.addDefaultLayer(SMap.DEF_BASE).enable();

        const layer = new SMap.Layer.Marker();

        // MY POSITION
        const myPointOptions = { };
        const myPosition = SMap.Coords.fromWGS84(lastPosition.coords.longitude, lastPosition.coords.latitude);
        const myMarker = new SMap.Marker(myPosition, 'myPosition', myPointOptions);

        layer.addMarker(myMarker);


        let locationToSearch = null;

        if (this.showOnlyUserRelated) {
            locationToSearch = await this.taskerLocationController
            .UserRelatedLocations()
            .toPromise();
        }

        var locationsRequest: Honeycomb.Tenant.LookupTables.IService.Model.LocationRequest = {
            specificLocations: locationToSearch,
            specificLocationsFilter: null
        };

        const foundLocation = await this.lookupLocationController
                                .ListSimpleLong(null, null, 300, true, null, null,
                                    lastPosition.coords.latitude,
                                    lastPosition.coords.longitude,
                                    locationsRequest)
                                .toPromise();

        const highlightedLocations: any[] = [];
        const normalLocations: any[] = [];

        for (const location of foundLocation) {
            if (!!this.highlightedLocationIDs && this.highlightedLocationIDs.indexOf(location.locationID) !== -1) {
                highlightedLocations.push(location);
            } else {
                normalLocations.push(location);
            }
        }

        for (const loc of normalLocations) {
            this.addMapPosition( layer, loc.longitude, loc.latitude, '/assets/drop-dt.png', loc );
        }

        for (const loc of highlightedLocations) {
            this.addMapPosition( layer, loc.longitude, loc.latitude, '/assets/drop-dt-highlight.png', loc );
        }

        m.addLayer(layer);
        layer.enable();
    }

    addMapPosition( layer: any,
                    longitude: number,
                    latitude: number,
                    image: string,
                    loc: any,
                   ): void
    {
        const znacka = JAK.mel('div');
        const obrazek = JAK.mel('img', {src: image || 'https://api.mapy.cz/img/api/marker/drop-red.png'});
        znacka.appendChild(obrazek);

        //cl.codeValues[this.trans.getDefaultLang()] || cl.codeValues.default;

        const popisek = JAK.mel('div');
        popisek.className = 'popup-map-store';
        // Initialize an empty array to build the HTML content
        let content = [];
        if (loc.name) {
            content.push(`<strong>${loc.name}</strong>`);
        }
        if (loc.street) {
            content.push(`${loc.street}`);
        }
        if (loc.phone) {
            content.push(`${loc.phone}`);
        }
        if (loc.email) {
            content.push(`${loc.email}`);
        }

        content.push(`
            <a href="#" class="view-details-link" data-location-id="${loc.locationID}">
                ${this.trans.instant('tasker.go-store-detail')}
            </a>
        `);

        // Join the array into a single string separated by <br> tags and set it as innerHTML
        popisek.innerHTML = content.join('<br>');

        const beak = JAK.mel('div');
        beak.className = 'beak';

        popisek.appendChild(beak);
        znacka.appendChild(popisek);

        const marker = new SMap.Marker(SMap.Coords.fromWGS84(longitude, latitude), loc.locationID, { url: znacka });
        layer.addMarker(marker);

        // Add hover event listeners
        znacka.addEventListener('mouseover', () => {
            popisek.style.display = 'block';
            beak.style.display = 'block';  // Show the beak along with the bubble
        });

        znacka.addEventListener('mouseout', () => {
            popisek.style.display = 'none';
            beak.style.display = 'none';  // Hide the beak when the bubble is hidden
        });

        //print
        znacka.addEventListener('click', (event: Event) => {
            const target = event.target as HTMLElement;
            if (target && target.classList.contains('view-details-link')) {
                event.preventDefault(); // Prevent the default link behavior
                const id = target.getAttribute('data-location-id');
                if (id) {
                    this.openDetail(Number(id));
                }
            }
        });
    }

    openDetail(locationID: number) {
        this.dialog.open(MyStoreDetailComponent, {
            data: { locationID, permission: 'my-store' }
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
