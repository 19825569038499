import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  catchError,
  map,
  tap,
  mapTo,
  withLatestFrom,
  switchMap
} from 'rxjs/operators';
import { EMPTY, of, forkJoin } from 'rxjs';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  loadContactsCompletedAction,
  loadContactsAction,
  loadContactsFailedAction,
  loadContactsByUserIDAction,
  loadContactsByUserIDCompletedAction,
  loadContactsByUserIDFailedAction,
  cleanContactsByUserAction
} from './contacts.actions';
import { AppState } from 'src/app/app.states';

@Injectable()
export class ContactsEffects {
  constructor(
    private actions$: Actions,
    @Inject('ContactController')
    private contactController: Honeycomb.Tenant.Contact.IService.Controller.ContactController,
    @Inject('ReportController')
    private reportController: Honeycomb.Tenant.Reports.IService.ReportController,
    private router: Router,
    private store: Store<AppState>
  ) {}

  loadMyContactsEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContactsAction),
      withLatestFrom(this.store),
      mergeMap(([data, s]) => {
        if (!s.contacts.context) {
          s.contacts.context = new Honeycomb.Tenant.Reports.IService.Model.ReportContext();
        }
        s.contacts.context.reportName = 'ListOfCustomers';
        s.contacts.context.pagination = { offset: 0, rowCount: 10 };
        s.contacts.context.fulltextFilter = data.filter;

        const reportRequest = this.reportController.GetByName(
          'ListOfCustomers'
        );
        const dataRequest = this.reportController.Run(s.contacts.context);

        return forkJoin([reportRequest, dataRequest]).pipe(
          map(res => {
            let cols = res[0].reportColumns;
            let data = (res[1] as any).data as Array<any>;
            let results: Array<any> = [];
            data.forEach(d => {
              let result: any = {};
              cols.forEach((c, ix) => {
                result[c.queryableColumn.name] = d[ix];
              });
              results.push(result);
            });

            return loadContactsCompletedAction({
              result: results as Honeycomb.Tenant.Contact.IService.Contact[],
              report: res[1] as any
            });
          }),
          catchError(error => of(loadContactsFailedAction(error)))
        );
      })
    )
  );

  loadMyContactsByUserIDsEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContactsByUserIDAction),
      withLatestFrom(this.store),
      mergeMap(([data, s]) => {
        const reqModel = new Honeycomb.Tenant.Contact.IService.ContactByUserIDsRequest();
        reqModel.userIDs = data.userIDs;
        const reportRequest = this.contactController.PublicByUserIdList(reqModel);

        return reportRequest.pipe(
          map(res => {
            return loadContactsByUserIDCompletedAction({
              result: res
            });
          }),
          catchError(error => of(loadContactsByUserIDFailedAction(error)))
        );
      })
    )
  );

  cleanContactsByUserIDsEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(cleanContactsByUserAction),
      withLatestFrom(this.store),
      map(_ => loadContactsByUserIDCompletedAction({ result: [] }))
    )
  );
}
