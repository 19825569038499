<form *ngIf="form" (ngSubmit)="save()" [formGroup]="form" style="min-width: 70vw;">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    
    <h2 class="headline m-0" fxFlex="auto">
        {{ 'tasker.common.repair-task' | translate }}
    </h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content fxLayout="column" style="min-height: 65vh;">
    <div fxLayout="column" style="padding: 0 15px;">

      <!-- operation -->
      <mat-form-field class="mt-3" fxFlex *ngIf="!(singleRepairOperation$ | async)">
        <mat-label>{{ 'tasker.common.task-template' | translate }}</mat-label>
        <mat-select matNativeControl placeholder="{{ 'tasker.common.select' | translate }}"
          formControlName="operationID" (selectionChange)="operationSelected($event)">
          <mat-option *ngFor="let item of operations$ | async" [value]="item.operationID">
              <mat-icon [icIcon]="twotoneAssignment" class="mr-3" *ngIf="item.taskType != repair"></mat-icon>
              <mat-icon [icIcon]="twotoneAssistant" class="mr-3" *ngIf="item.taskType == repair"></mat-icon>
              {{ item.name }}
          </mat-option>
        </mat-select>
        <mat-icon [icIcon]="twotoneSpeakerNotes" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

        <!-- task name -->
        <mat-form-field class="mt-3" fxFlex  *ngxPermissionsOnly="uiRole('name')">
            <mat-label>{{ 'tasker.task-repair.name' | translate }}</mat-label>
            <input type="text" matInput formControlName="name" required>
            <mat-icon [icIcon]="twotoneMore" flip="horizontal" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>

        <!-- description -->
        <mat-form-field class="mt-3" fxFlex *ngxPermissionsOnly="uiRole('description')">
          <mat-label>{{ 'tasker.common.description' | translate }}</mat-label>
          <textarea type="text" matInput formControlName="description" rows="1" required ></textarea>
          <mat-icon [icIcon]="twotoneDescription" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>

        <div class="photo-row" fxLayoutGap="10px" [ngClass]="{'required': !taskPhotos || !taskPhotos.length}">
          <div *ngFor="let photo of taskPhotos" class="photo-thumbnail" (click)="zoomAttachmentImage(photo)">
            <img *ngIf="photo.recordUid" [src]="getTempImageUrl(photo.recordUid)">
          </div>
          <div class="photo-add-btn progress-container" (click)="addPhoto($event)" *ngxPermissionsOnly="uiRole('photo')">
            <ic-icon [icIcon]="roundAddCircleOutline" size="40px"></ic-icon>
            <div class="photo-add-text">{{ 'tasker.task-activities.add-photo' | translate }}</div>
            <div class="photo-uploading-text">{{ 'tasker.common.uploading' | translate }}</div>
          </div>
        </div>

        <!-- location & address -->
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px" *ngxPermissionsOnly="uiRole('location-auto')">
        <mat-form-field fxFlex class="mt-3">
          <mat-label>{{ 'tasker.task-repair.location' | translate }}</mat-label>
          <mat-select [placeholder]="'tasker.task-repair.not-entered' | translate" fxFlex formControlName="locationID"
            (selectionChange)="selectedLocation($event)">
            <mat-option [value]="null">-- {{ 'tasker.task-repair.not-entered' | translate }} --</mat-option>
            <mat-option *ngFor="let loc of locations$ | async " [value]="loc.locationID">{{ loc.name }}</mat-option>
          </mat-select>
          <mat-icon [icIcon]="twotoneStore" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
        <mat-form-field fxFlex class="mt-3">
            <mat-label>{{ 'tasker.task-repair.address' | translate }}</mat-label>
            <input matInput [placeholder]="'tasker.task-repair.address' | translate" formControlName="address" [readonly]="!!form.get('locationID').value">
            <mat-icon [icIcon]="twotoneLocationOn" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px" *ngxPermissionsOnly="uiRole('location-manual')">
        <mat-form-field fxFlex class="mt-3">
          <mat-label>{{ 'tasker.task-repair.location' | translate }}</mat-label>
          <mat-select [placeholder]="'tasker.task-repair.not-entered' | translate" fxFlex formControlName="locationID"
            (selectionChange)="selectedLocation($event)">
            <mat-option [value]="null">-- {{ 'tasker.task-repair.not-entered' | translate }} --</mat-option>
            <mat-option *ngFor="let loc of locations$ | async " [value]="loc.locationID">{{ loc.name }}</mat-option>
          </mat-select>
          <mat-icon [icIcon]="twotoneStore" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
        <mat-form-field fxFlex class="mt-3">
            <mat-label>{{ 'tasker.task-repair.address' | translate }}</mat-label>
            <input matInput [placeholder]="'tasker.task-repair.address' | translate" formControlName="address" [readonly]="!!form.get('locationID').value">
            <mat-icon [icIcon]="twotoneLocationOn" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px" *ngxPermissionsOnly="uiRole('start-date')">
        <mat-form-field class="mt-3" fxFlex="49%">
          <mat-label>{{ 'tasker.common.start' | translate }}</mat-label>
          <input matInput [matDatepicker]="dateStart" placeholder="title"formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="dateStart"></mat-datepicker-toggle>
          <mat-datepicker #dateStart></mat-datepicker>
          <mat-icon [icIcon]="twotoneRocket" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>

      <!-- priority -->
      <mat-form-field class="mt-3" *ngxPermissionsOnly="uiRole('priority')">
        <mat-label>{{ 'tasker.common.priority' | translate }}</mat-label>
        <mat-select matNativeControl placeholder="{{ 'tasker.common.select' | translate }}"
            formControlName="priority">
            <mat-option [value]="0">{{ 'tasker.priority.not-set' | translate }}</mat-option>
            <mat-option [value]="1">{{ 'tasker.priority.low' | translate }}</mat-option>
            <mat-option [value]="2">{{ 'tasker.priority.normal' | translate }}</mat-option>
            <mat-option [value]="3">{{ 'tasker.priority.high' | translate }}</mat-option>
            <mat-option [value]="4">{{ 'tasker.priority.critical' | translate }}</mat-option>
        </mat-select>
        <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
    </mat-form-field> 
    <!-- External supplier -->
    <!-- <mat-form-field class="mt-3">
      <mat-label>{{ 'tasker.common.external-supplier' | translate }}</mat-label>
      <mat-select matNativeControl placeholder="{{ 'tasker.common.external-supplier' | translate }}" formControlName="externalSupplier">
          <mat-option *ngFor="let item of externalSuppliers | async" [value]="item.codeValueId">{{ item.displayValue }}</mat-option>
      </mat-select>
      <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>  -->
    </div>
    <ngx-mat-file-input #inputPhoto style="display: none;" placeholder="" accept="image/*,text/plain" capture="environment">
    </ngx-mat-file-input>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div *ngIf="detailError$ | async">{{ detailError$ | async }}</div>
    <button mat-button [mat-dialog-close]="false" type="button"  *ngxPermissionsOnly="uiRole('save')">{{ 'tasker.common.cancel' | translate }}</button>
    <button color="primary" mat-button type="submit" disabled="{{ creatingTask$ | async }}">{{ 'tasker.task-repair.create' | translate }}</button>
  </mat-dialog-actions>
</form>
