import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icPerson from '@iconify/icons-ic/twotone-person';
import twotoneMore from '@iconify/icons-ic/twotone-more';
import twotoneRocket from '@iconify/icons-ic/twotone-rocket';
import twotoneTimerOff from '@iconify/icons-ic/twotone-timer-off';
import baselineSort from '@iconify/icons-ic/baseline-sort';
import twotoneSpeakerNotes from '@iconify/icons-ic/twotone-speaker-notes';
import twotoneDescription from '@iconify/icons-ic/twotone-description';
import twotoneLocationOn from '@iconify/icons-ic/twotone-location-on';
import twotoneStore from '@iconify/icons-ic/twotone-store';
import twotoneAssignment from '@iconify/icons-ic/twotone-assignment';
import twotoneAssistant from '@iconify/icons-ic/twotone-assistant';
import twotoneGroup from '@iconify/icons-ic/twotone-group';
import sharpPostAdd from '@iconify/icons-ic/sharp-post-add';
import roundAddCircleOutline from '@iconify/icons-ic/round-add-circle-outline';


import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { dialogMyTaskClosedAction } from 'src/app/api/tasks/my-tasks/my-tasks.actions';
import { loadOperationsAction, loadOperationsFailedAction } from 'src/app/api/operations/operations.actions';
import { debounceTime, tap, distinctUntilChanged, map, take, filter, mapTo } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { isNullOrWhitespace, zoomScrollable } from 'src/app/common/functions';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';
import { Globals } from 'src/app/common/globals';
import { FileInputComponent } from 'ngx-material-file-input';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import { NgxPermissionsService } from 'ngx-permissions';


@Component({
  selector: 'task-repair',
  templateUrl: './task-repair.component.html',
  styleUrls: ['./task-repair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskRepairComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('taskUserInput') taskUserInput: ElementRef<HTMLInputElement>;
  @ViewChild('inputPhoto') inputPhotoRef: FileInputComponent;

  form: FormGroup;
  initialized$: Observable<boolean> = of(false);
  singleRepairOperation$: Observable<boolean> = of(true);
  detailError$: Observable<string>;
  creatingTask$: Observable<boolean> = of(false);

  locations$: Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort>> = of([]);
  users$: Observable<Array<any>> = of([]);
  operations$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> = of([]);

  // externalSuppliers: Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeValue>> = of([]);
  repairStatuses: Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeValue>> = of([]);

  dialogClosedSubscription: Subscription;
  locations: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort> = [];
  subscriptions: Array<Subscription> = [];

  icClose = icClose;
  icPerson = icPerson;
  twotoneMore = twotoneMore;
  twotoneRocket = twotoneRocket;
  twotoneTimerOff = twotoneTimerOff;
  baselineSort = baselineSort;
  twotoneSpeakerNotes = twotoneSpeakerNotes;
  twotoneDescription = twotoneDescription;
  twotoneLocationOn = twotoneLocationOn;
  twotoneStore = twotoneStore;
  twotoneAssignment = twotoneAssignment;
  twotoneAssistant = twotoneAssistant;
  twotoneGroup = twotoneGroup;
  roundAddCircleOutline = roundAddCircleOutline;
  sharpPostAdd = sharpPostAdd;

  repair = Honeycomb.Common.Enums.TaskType.repairRequest;
  configKey = 'tasker.tasks.asignee-selectors';
  config: any;
  loggedUser: any;
  batchSelectors: Array<string> = [];
  agenda: TaskAgenda;

  tenantHash: string = null;

  taskPhotos = [];
  fileUploaded$: Observable<any> = of({});
  fileUploading$ = false;
  imageUID: string;

  uiroles: string[] = [];
  roleDebug = [];

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              @Inject('LookupLocationController') private lookupLocationController: Honeycomb.Tenant.LookupTables.IService.LocationsController,
              @Inject('ContactJobController') private jobController: Honeycomb.Tenant.Contact.IService.Controller.JobController,
              @Inject('AdminRoleController') private roleController: Honeycomb.Tenant.Admin.IService.RoleController,
              @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
              @Inject('ConfigController') private configController: Honeycomb.Tenant.Admin.IService.NamedConfigurationsController,
              @Inject('OperationController') private operationController: Honeycomb.Tenant.Tasker.IService.Controller.OperationController,
              @Inject('FileControllerCustom') private fileController: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
              @Inject('CodeListController') private codeListController: Honeycomb.Tenant.LookupTables.IService.CodeListController,
              private dialogRef: MatDialogRef<TaskRepairComponent>,
              private store: Store<AppState>,
              private snack: MatSnackBar,
              private trans: TranslateService,
              private cd: ChangeDetectorRef,
              private globals: Globals,
              private permissionService: NgxPermissionsService,
              private fb: FormBuilder) {

      store.pipe(select(x => x.user)).subscribe(u => this.uiroles = u.uiroles);

      store.select(s => s.user).pipe(
      filter(u => !!u.selectedJobLocations /* && u.selectedJobLocations.length > 0 */),
      map(u => u.selectedJobLocations),
      distinctUntilChanged(),
      tap(jl => {
        var locationsRequest: Honeycomb.Tenant.LookupTables.IService.Model.LocationRequest = {
          specificLocations: jl,
          specificLocationsFilter: null
        };

        this.locations$ = this.lookupLocationController
        .ListSimpleLong('OnlyMyLocationsWorkaround', null, null, true, null, null, null, null, locationsRequest)
        .pipe(
          tap(l => {
            this.locations = l;
            if (l.length === 1) {
              this.selectedLocation(null, l[0].locationID);
            } else {
              if (this.defaults && this.defaults.locationID) { this.selectedLocation(null, this.defaults.locationID); }
            }
            this.cd.detectChanges();
        }));
      })
    ).subscribe(_ => null);

      this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);

      this.operations$ = this.operationController.ActiveList(Honeycomb.Common.Enums.TaskType.repairRequest);

      this.form = this.fb.group({
      taskID: -1,
      name: this.fb.control('', Validators.required),
      description: this.fb.control('', Validators.required),
      operationID: this.fb.control(null, Validators.required),
      priority: this.fb.control(2),
      locationID: this.fb.control(null, Validators.required),
      address: this.fb.control(''),
      taskUsers: this.fb.array([], Validators.minLength(1)),
      startDate: this.fb.control(new Date(), Validators.required),
      deadline: this.fb.control(null),
      externalSupplier: this.fb.control(null),
      repairStatus: this.fb.control(null),

      userSelector: this.fb.control(null),
      repairItemType: this.fb.control(0, Validators.required),
      contactInput:  this.fb.control(''),
    });

      this.form.get('contactInput').valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(value => !isNullOrWhitespace(value)),
      map(async (value: any) => {
        if (!!value && value.trim && value.trim().length > 2) {
          const found = await this.jobController.JobUsers(1, false, value).toPromise();
          this.users$ = of(found);
          if (!found.length) { this.showSnack('tasker.common.no-matching-record'); }
        } else {
          this.users$ = of([]);
          this.showSnack('tasker.common.min-3-chars');
        }
        this.cd.detectChanges();
      })).subscribe(_ => null);

      this.dialogClosedSubscription = dialogRef.beforeClosed().subscribe(_ => {
      this.store.dispatch(dialogMyTaskClosedAction());
    });
      this.subscriptions.push(this.dialogClosedSubscription);
  }

  async ngOnInit() {
    this.agenda = this.defaults.agenda;
    const cfg = await this.configController.GetByNames([this.configKey]).toPromise();
    if (cfg && cfg[this.configKey]) {
        this.config = cfg[this.configKey];
        this.store.pipe(select(x => x.user), take(1)).subscribe(cu => {
            this.loggedUser = cu;
            const roleId = cu.currentUser.userJobs.find(uj => uj.jobID === cu.selectedJobID).job.roleID;
            const roleName = cu.currentUser.contactRoles.find(r => r.roleID === roleId).name;
            if (this.config[roleName]) { this.batchSelectors.concat(this.config[roleName]); }
        });
    }
    await this.operations$.pipe(filter(ops => Array.isArray(ops)), tap(
        ops => {
          let operationID: number;
          let name: string;
          this.singleRepairOperation$ = of(ops.length === 1);
          if (ops.length === 1) {
            const repOperation = ops[0];
            operationID = repOperation.operationID;
            name = repOperation.name;
            this.form.get('operationID').setValue(operationID);
            // this.form.get('name').setValue(name);
          }
        })).toPromise();
    // this.externalSuppliers = this.codeListController.DetailByName('ExternalSupplier')
    //                             .pipe(
    //                               take(1),
    //                               map(cl => cl.codes),
    //                               map(c => c.sort((a, b) => a.orderIndex - b.orderIndex)),
    //                               map(c => c.map(cl => {
    //                                    return cl.codeValues[this.trans.getDefaultLang()] || cl.codeValues.default;
    //                                   }
    //                                 )
    //                               )
    //                             );

    this.repairStatuses = this.codeListController.DetailByName('RepairStatus')
                                .pipe(
                                  take(1),
                                  map(cl => cl.codes),
                                  map(c => c.sort((a, b) => a.orderIndex - b.orderIndex)),
                                  tap(c => {
                                    this.form.get('repairStatus').setValue(c[0].codeId);
                                  }),
                                  map(c => c.map(cl => {
                                       return cl.codeValues[this.trans.getDefaultLang()] || cl.codeValues.default;
                                      }
                                    )
                                  )
                                );

    // Execute loading default value
    await this.repairStatuses.toPromise();

    this.initialized$ = of(true);
  }

  async ngAfterViewInit() {
  }

  uiRole(suffix: string) {
    const reqUiRole = `tasker.task-repair.${suffix}`;

    if (this.roleDebug.findIndex(r => r === reqUiRole) === -1) {
      this.globals.DebugOut('task-repair', reqUiRole);
      this.roleDebug.push(reqUiRole);
    }

    return reqUiRole;
  }

  hasPermission(suffix: string) {
    return this.uiroles.indexOf(this.uiRole(suffix)) > -1;
  }


  async operationSelected($event: MatSelectChange) {
      const operationID = $event.value;
      await this.operations$.pipe(tap(ops => {
        const selectedOp = ops.find(o => o.operationID === operationID);
        this.form.get('name').setValue(selectedOp.name);
      })).toPromise();
  }

  async save() {

    const task = this.form.value;

    if (!this.form.valid || !this.taskPhotos || this.taskPhotos.length === 0) { return; }

    const updateTask = {
      taskID: -1, // otherwise won't deserialize
      operationID: task.operationID,
      parentTaskID: this.defaults ? this.defaults.taskID : null,
      locationID: task.locationID,
      name: task.name,
      taskPhotos: this.taskPhotos,
      description: task.description,
      address: task.address,
      startDate: task.startDate,
      deadline: task.deadline,
      priority: task.priority,
      taskUsers: task.taskUsers,
      taskAttributesUpsert: [
        {
          taskAttributeTypeName: 'ExternalSupplier',
          value: task.externalSupplier
        },
        {
          taskAttributeTypeName: 'RepairStatus',
          value: task.repairStatus
        }
      ]
    } as unknown as Honeycomb.Tenant.Tasker.IService.Model.TaskUpsert;

    this.creatingTask$ = of(true);

    await this.taskController.Insert(updateTask)
              .toPromise()
              .catch(response => this.showSnack(response.statusText));

    this.creatingTask$ = of(false);

    this.dialogRef.close(true);
  }

  removeUser(userId: number) {
    this.form.controls.taskUsers = this.fb.array(this.form.get('taskUsers').value.filter(f => f.userId !== userId));
  }

  addUser(event: MatAutocompleteSelectedEvent) {
    let taskUsersControl = this.form.controls.taskUsers;
    const taskUsers = taskUsersControl.value;
    const userIDs = new Set(taskUsers.map(a => a.userId));
    const current = event.option.value.user;

    if (!current) { return; }
    if (userIDs.has(current.id)) { return this.showSnack('tasker.common.already-selected'); }

    taskUsers.push({ userId: current.id, taskRelation: Honeycomb.Common.Enums.TaskRelation.assignee, name: current.name });
    taskUsersControl = this.fb.array(taskUsers);
    this.taskUserInput.nativeElement.value = '';
    this.form.get('contactInput').setValue(null);
    this.cd.detectChanges();
  }

  showSnack(message: string, options?: MatSnackBarConfig) {
    this.snack.open(this.trans.instant(message),
        this.trans.instant('tasker.common.close'),
        Object.assign({ duration: 3000 }, options) as MatSnackBarConfig);
  }

  selectedLocation($event: MatSelectChange, id?: number) {
    const locationId = id || $event.value;
    const location = this.locations.find(l => l.locationID === locationId);
    let address = null;

    if (location && !(isNullOrWhitespace(location.street) && isNullOrWhitespace(location.city))) {
        const part1 = `${[location.street, location.cityPart, location.city].find(t => !isNullOrWhitespace(t))} ${location.streetNumber}`;
        address = [part1, `${location.zipCode} ${location.city}`].filter(p => !isNullOrWhitespace(p)).map(p => p.trim()).join(', ');
    }
    this.form.get('address').setValue(address);
    if (!$event) {
      this.form.get('locationID').setValue(id);
    }
  }


  addPhoto($event: Event) {
    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }

    const inpPhoto = this.inputPhotoRef;
    inpPhoto.change = ($inpEvent: Event) => {
      const f = ($inpEvent.target as HTMLInputElement).files[0];
      if (!f) {
        return;
      }

      this.fileUploading$ = true;
      this.cd.markForCheck();

      return this.fileController.UploadFile(f, $event.target as HTMLElement)
      .pipe(filter(p => !!p))
      .subscribe(p => {
        this.taskPhotos.push({
          recordUid: p.recordUID,
          fileName: p.fileName
        });
        this.cd.detectChanges();
      });
    };
    inpPhoto.open();
  }

  getTempImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/TenantTasker/File/gettempfile', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  zoomAttachmentImage(photo: any) {
    zoomScrollable(this.getTempImageUrl(photo.recordUid, 1920, 1080), this.trans.instant('tasker.common.close'),
                   this.trans.instant('tasker.common.delete'),
                   () => {
                    this.taskPhotos = this.taskPhotos.filter(tp => tp.recordUid !== photo.recordUid);
                    this.imageUID = null;
                    this.cd.detectChanges();
                   } );
  }

  hidePictureView(sender) {
    document.body.removeChild(sender);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if (!!s) {
        s.unsubscribe()
      }
    });
  }


}
