import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface NotificationPushState {
    loading: boolean;
    error: string;
    online: boolean;
    endpoint: string;
    pubKey: string;
    data: Array<PushNotification>;
    registration: ServiceWorkerRegistration
}

export interface PushNotification {
    data: any;
    eventName: string;
    notificationPushQueueID: number,
    read: Date,
}

export const NotificationPushState = () => {
    return {
        loading: false,
        error: null,
        data: [],
        online: false,
        pubKey: null,
        registration: null
    } as NotificationPushState;
  };
