<form *ngIf="form" (ngSubmit)="save()" [formGroup]="form" style="min-width: 70vw;">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

    <h2 class="headline m-0" fxFlex="auto">
      {{ (!defaults || !defaults.taskID ? 'tasker.common.new-task' : 'tasker.common.new-task-followup') | translate }}
    </h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content fxLayout="column" style="min-height: 65vh;">
    <div fxLayout="column" style="padding: 0 15px;">

      <!-- single task or batch -->
      <div fxLayout="row" fxFlex class="form-line" *ngIf="uiroles.includes('tasker_task_create_batch') || uiroles.includes('tasker_task_create_sequence')">
        <mat-radio-group formControlName="newItemType" fxLayoutGap="20px">
          <mat-radio-button [value]="0">{{ 'tasker.task-new.single-task' | translate }}</mat-radio-button>
          <mat-radio-button [value]="1" *ngIf="uiroles.includes('tasker_task_create_batch')">{{ 'tasker.task-new.task-batch' | translate }}</mat-radio-button>
          <mat-radio-button [value]="2" *ngIf="uiroles.includes('tasker_task_create_sequence')">{{ 'tasker.task-new.task-sequence' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- sequence -->
      <ng-container *ngIf="form.get('newItemType') && form.get('newItemType').value === 2">

        <mat-card appearance="outlined" formGroupName="sequence" fxLayout="row" fxLayout.lt-lg="column"  fxLayoutGap="10px">
          <!-- <mat-card-title>
            {{ 'tasker.task-new.sequence-setting' | translate }}
          </mat-card-title> -->
          <div fxLayout="row" fxLayoutGap="10px" fxFlex="1 0 0%">
            <mat-radio-group formControlName="periodType" fxLayout="column" fxFlex="0 0 130px">
              <mat-radio-button class="rbt-small" [value]="PeriodType.day">{{ 'tasker.common.day' | translate }}</mat-radio-button>
              <mat-radio-button class="rbt-small" [value]="PeriodType.week">{{ 'tasker.common.week' | translate }}
              </mat-radio-button>
              <mat-radio-button class="rbt-small" [value]="PeriodType.month">{{ 'tasker.common.month' | translate }}
              </mat-radio-button>
              <mat-radio-button class="rbt-small" [value]="PeriodType.year">{{ 'tasker.common.year' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div fxLayout="column" fxFlex *ngIf="getPeriodType() === PeriodType.day">
              <mat-radio-group formControlName="periodRepeat" fxLayout="column" >
                <mat-radio-button class="rbt-small" [value]="1">{{ 'tasker.task-new.every-day' | translate }}</mat-radio-button>
                <mat-radio-button class="rbt-small" [value]="-1">{{ 'tasker.task-new.every-working-day' | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div fxLayout="column" fxFlex="1 1 0%" *ngIf="getPeriodType() === PeriodType.week" fxFlayoutGap="5px">
              <span fxFlex="0 1 35px">
                {{ 'tasker.task-new.repeat-every' | translate }} <input formControlName="periodRepeat" type="number" min="1" max="56" style="width: 30px; border: 1px solid;" /> {{ 'tasker.task-new.week' | translate }}
              </span>
              <div  fxFlex="1 1 0%" fxLayout="row"  fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                <div fxLayout="column">
                  <mat-checkbox formControlName="weekDayMonday">{{ 'tasker.common.monday' | translate }}</mat-checkbox>
                  <mat-checkbox formControlName="weekDayTuesday">{{ 'tasker.common.tuesday' | translate }}</mat-checkbox>
                </div>
                <div fxLayout="column">
                  <mat-checkbox formControlName="weekDayWednesday">{{ 'tasker.common.wednesday' | translate }}</mat-checkbox>
                  <mat-checkbox formControlName="weekDayThursday">{{ 'tasker.common.thursday' | translate }}</mat-checkbox>
                </div>
                <div fxLayout="column">
                  <mat-checkbox formControlName="weekDayFriday">{{ 'tasker.common.friday' | translate }}</mat-checkbox>
                  <mat-checkbox formControlName="weekDaySaturday">{{ 'tasker.common.saturday' | translate }}</mat-checkbox>
                </div>
                <div fxLayout="column">
                  <mat-checkbox formControlName="weekDaySunday">{{ 'tasker.common.sunday' | translate }}</mat-checkbox>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex *ngIf="getPeriodType() === PeriodType.month">
              <span>
                {{ 'tasker.task-new.repeat' | translate }} <input formControlName="monthRepeatDay" type="number" min="1" max="31" style="width: 30px; border: 1px solid;" /> {{ 'tasker.task-new.day-in-every' | translate }} <input formControlName="periodRepeat" type="number" min="1" max="56" style="width: 30px; border: 1px solid;" /> {{ 'tasker.task-new.month' | translate }}.
              </span>
            </div>
            <div fxLayout="column" fxFlex *ngIf="getPeriodType() === PeriodType.year" fxLayoutGap="10px">
              <span>
                {{ 'tasker.task-new.repeat-every' | translate }} <input formControlName="periodRepeat" type="number" min="1" max="56" style="width: 30px; border: 1px solid;" /> {{ 'tasker.task-new.year' | translate }}.
              </span>
              <span>
                {{ 'tasker.task-new.day' | translate }} <input formControlName="yearRepeatDayNr" type="number" min="1" max="56" style="width: 30px; border: 1px solid;" /> {{ 'tasker.task-new.month' | translate }} 
                <select formControlName="yearRepeatMonthNr" style="border: 1px solid;">
                  <option [value]="1">{{ 'tasker.common.january' | translate }}</option>
                  <option [value]="2">{{ 'tasker.common.february' | translate }}</option>
                  <option [value]="3">{{ 'tasker.common.march' | translate }}</option>
                  <option [value]="4">{{ 'tasker.common.april' | translate }}</option>
                  <option [value]="5">{{ 'tasker.common.may' | translate }}</option>
                  <option [value]="6">{{ 'tasker.common.june' | translate }}</option>
                  <option [value]="7">{{ 'tasker.common.july' | translate }}</option>
                  <option [value]="8">{{ 'tasker.common.august' | translate }}</option>
                  <option [value]="9">{{ 'tasker.common.september' | translate }}</option>
                  <option [value]="10">{{ 'tasker.common.october' | translate }}</option>
                  <option [value]="11">{{ 'tasker.common.november' | translate }}</option>
                  <option [value]="12">{{ 'tasker.common.december' | translate }}</option>
                </select>
                <!-- <input type="number" min="1" max="56" style="width: 30px; border: 1px solid;" /> -->
              </span>
            </div>
          </div>
          
          <div fxLayout="column" fxFlex="1 1 0%" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px" style="margin: 0 10px; padding: 10px">
            <mat-divider fxFlex fxHide.gt-md="true"></mat-divider>
            <div fxLayout="row" fxFlex="1 0 100%">
              <div fxLayout="column" fxFlex="1 1 0%" style="height: 75px;">
                <mat-form-field fxFlex="1 1 100%" style="width: 185px;">
                  <mat-label>{{ 'tasker.task-new.start-sequence' | translate }}</mat-label>
                  <input matInput [matDatepicker]="sequenceStartPicker" placeholder="title" formControlName="sequenceStart">
                  <mat-datepicker-toggle matSuffix [for]="sequenceStartPicker"></mat-datepicker-toggle>
                  <mat-datepicker #sequenceStartPicker></mat-datepicker>
                  <mat-icon [icIcon]="twotoneRocket" matPrefix></mat-icon>
                </mat-form-field>
              </div>
            
                
              <div fxFlex="1 0 0%">
                <mat-radio-group formControlName="useNumberOfRepeats" fxLayout="column" fxLayoutGap="5px">
                  <mat-radio-button [value]="false">
                    <span fxLayout="row">
                      <mat-form-field fxFlex="1 1 100%" style="width: 185px;">
                        <mat-label>{{ 'tasker.task-new.end-sequence' | translate }}</mat-label>
                        <input matInput [min]="getsequenceEndMin" [matDatepicker]="sequenceEndPicker" placeholder="title" 
                               formControlName="sequenceEnd" [disabled]="getUseNumberOfRepeats()">
                        <mat-datepicker-toggle matSuffix [for]="sequenceEndPicker"></mat-datepicker-toggle>
                        <mat-datepicker #sequenceEndPicker></mat-datepicker>
                        <mat-icon [icIcon]="eventRepeat" matPrefix></mat-icon>
                      </mat-form-field>
                    </span>
                  </mat-radio-button>
                  <mat-radio-button [value]="true">
                    <span>
                      {{ 'tasker.task-new.end-after' | translate }}
                      <input  type="number" min="1" [max]="maxOccurencesGenerated" style="width: 40px; border: 1px solid;" formControlName="numberOfRepeats" [disabled]="!getUseNumberOfRepeats()" [readonly]="!getUseNumberOfRepeats()">
                      {{ 'tasker.task-new.occurences' | translate }}.
                    </span>
                    </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <!-- <div style="overflow: auto;">
            <div *ngFor="let p of prescriptions">
              {{ p.taskStart | date: 'yyMMdd E' }}
            </div>
          </div> -->
        </mat-card>
      </ng-container>

      <!-- operation -->
      <mat-form-field class="mt-3" fxFlex>
        <mat-label>{{ 'tasker.common.task-template' | translate }}</mat-label>
        <mat-select matNativeControl placeholder="{{ 'tasker.common.select' | translate }}"
          formControlName="operationID" (selectionChange)="operationSelected($event)">
          <mat-option *ngFor="let item of operations$ | async" [value]="item.operationID">
            <mat-icon [icIcon]="twotoneAssignment" class="mr-3" *ngIf="item.taskType != freeTask"></mat-icon>
            <mat-icon [icIcon]="twotoneAssistant" class="mr-3" *ngIf="item.taskType == freeTask"></mat-icon>
            {{ item.name }}
          </mat-option>
        </mat-select>
        <mat-icon [icIcon]="twotoneSpeakerNotes" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

      <!-- task name -->
      <mat-form-field class="mt-3" fxFlex *ngxPermissionsOnly="uiRole('name')">
        <mat-label>{{ 'tasker.common.name' | translate }}</mat-label>
        <textarea type="text" matInput formControlName="name" maxlength="64" cdkTextareaAutosize  rows="1" matAutosizeMinRows="1"
          matAutosizeMaxRows="2"></textarea>
        <mat-icon [icIcon]="twotoneMore" flip="horizontal" class="mr-3" matPrefix></mat-icon>
        <mat-hint align="end">{{form.get('name').value?.length || 0 }} / 64</mat-hint>
      </mat-form-field>

      <!-- description -->
      <mat-form-field class="mt-3" fxFlex *ngxPermissionsOnly="uiRole('description')">
        <mat-label>{{ 'tasker.common.description' | translate }}</mat-label>
        <textarea type="text" style="box-sizing:content-box;" matInput cdkTextareaAutosize
          #descriptionInput="cdkTextareaAutosize" formControlName="description" rows="1" maxlength="1024" matAutosizeMinRows="1"
          matAutosizeMaxRows="5">
          </textarea>
        <mat-icon [icIcon]="twotoneDescription" class="mr-3" matPrefix></mat-icon>
        <mat-hint align="end">{{(form.get('description').value?.length) || 0}} / 1024</mat-hint>
      </mat-form-field>

      <div class="files-container">
        <div class="photo-row" fxLayoutGap="10px" *ngxPermissionsOnly="uiRole('photo')">
          <div *ngFor="let photo of taskPhotos" class="photo-thumbnail" (click)="zoomAttachmentImage(photo)">
            <img *ngIf="photo.recordUid" [src]="getTempImageUrl(photo.recordUid)">
            <img *ngIf="photo.taskerFileUid" [src]="getImageUrl(photo.taskerFileUid)">
          </div>
          <div class="photo-add-btn progress-container" (click)="addPhoto($event)">
            <ic-icon [icIcon]="roundAddCircleOutline" size="40px"></ic-icon>
            <div class="photo-add-text">{{ 'tasker.task-activities.add-photo' | translate }}</div>
            <div class="photo-uploading-text">{{ 'tasker.common.uploading' | translate }}</div>
          </div>
        </div>
        <div class="attachment-row" fxLayoutGap="10px" *ngxPermissionsOnly="uiRole('attachment')">
          <div *ngFor="let attachment of taskAttachments" class="attachment-thumbnail">
            <ic-icon [icIcon]="roundContentPaste" size="40px"></ic-icon>
            <span class="file-name">{{ attachment.fileName }}</span>
            <span class="remove-button" (click)="removeAttachment(attachment)">
              <ic-icon [icIcon]="roundCancelPresentation" size="10px"></ic-icon>
            </span>
          </div>
          <div class="attachment-add-btn progress-container" (click)="addAttachment($event)">
            <ic-icon [icIcon]="roundAddCircleOutline" size="40px"></ic-icon>
            <div class="photo-add-text">{{ 'tasker.task.add-attachment' | translate }}</div>
            <div class="photo-uploading-text">{{ 'tasker.common.uploading' | translate }}</div>
          </div>
        </div>
      </div>

      <!-- location & address -->
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px"
        *ngxPermissionsOnly="uiRole('location-manual')">
        <mat-form-field fxFlex class="mt-3">
          <mat-label>{{ 'tasker.task-new.location' | translate }}</mat-label>
          <mat-select [placeholder]="'tasker.task-new.not-entered' | translate" fxFlex formControlName="locationID"
            (selectionChange)="selectedLocation($event)">
            <mat-option [value]="null">-- {{ 'tasker.task-new.not-entered' | translate }} --</mat-option>
            <mat-option *ngFor="let loc of locations$ | async " [value]="loc.locationID">{{ loc.name }}</mat-option>
          </mat-select>
          <mat-icon [icIcon]="twotoneStore" class="mr-3" matPrefix></mat-icon>
          <!-- <mat-hint>{{ 'tasker.task-new.task-users.location-note' | translate }}</mat-hint> -->
        </mat-form-field>
        <mat-form-field fxFlex class="mt-3">
          <mat-label>{{ 'tasker.task-new.address' | translate }}</mat-label>
          <input matInput [placeholder]="'tasker.task-new.address' | translate" formControlName="address"
            [readonly]="!!form.get('locationID').value">
          <mat-icon [icIcon]="twotoneLocationOn" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>
      <!-- <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px" *ngxPermissionsOnly="uiRole('location-auto')">
        <mat-form-field fxFlex class="mt-3">
          <mat-label>{{ 'tasker.task-new.location' | translate }}</mat-label>
          <mat-select [placeholder]="'tasker.task-new.not-entered' | translate" fxFlex formControlName="locationID"
            (selectionChange)="selectedLocation($event)">
            <mat-option [value]="null">-- {{ 'tasker.task-new.not-entered' | translate }} --</mat-option>
            <mat-option *ngFor="let loc of locations$ | async " [value]="loc.locationID">{{ loc.name }}</mat-option>
          </mat-select>
          <mat-icon [icIcon]="twotoneStore" class="mr-3" matPrefix></mat-icon>
          <mat-hint>{{ 'tasker.task-new.task-users.location-note' | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field fxFlex class="mt-3">
          <mat-label>{{ 'tasker.task-new.address' | translate }}</mat-label>
          <input matInput [placeholder]="'tasker.task-new.address' | translate" formControlName="address"
            [readonly]="!!form.get('locationID').value">
          <mat-icon [icIcon]="twotoneLocationOn" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div> -->

      <!-- person -->
      <ng-container *ngIf="form.get('newItemType') && (form.get('newItemType').value === 0 || form.get('newItemType').value === 2)">
        <mat-form-field *ngxPermissionsOnly="uiRole('assignee')" class="mt-3" form
          [class.mat-form-field-invalid]="form.controls.assignee.hasError('required') && form.touched" fxFlex>
          <mat-label>{{ 'tasker.common.assignee' | translate }}</mat-label>
          <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
          <mat-chip-grid #taskAssingeesChipList1>
            <mat-chip-row *ngFor="let taskUser of form.get('assignee').value" [removable]="hasPermission('assignee.edit')"
              (removed)="removeUser(taskUser.userId, taskRelation.assignee)">
              {{ taskUser.name }}
              <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
            </mat-chip-row>
          </mat-chip-grid>
          <input #contactInputAssignee formControlName="contactInputAssignee" [matAutocomplete]="taskUserAssingeeAuto"
              [matChipInputFor]="taskAssingeesChipList1" [readonly]="!hasPermission('assignee.edit')">
          <mat-autocomplete #taskUserAssingeeAuto="matAutocomplete"
            (optionSelected)="addUser(taskRelation.assignee, $event)">
            <mat-option *ngFor="let userjob of (users$ | async)" [value]="userjob">
              {{ userjob.user.name }} ({{ userjob.job.name }})
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{ 'tasker.my-task-detail.no-users' | translate }}</mat-error>
        </mat-form-field>
      </ng-container>

      <!-- batch selector -->
      <ng-container *ngIf="form.get('newItemType') && form.get('newItemType').value === 1">
        <ng-container *ngxPermissionsOnly="uiRole('assignee')">
          <div>
            <button mat-button color="primary" type="button" *ngFor="let item of batchSelectors"
              (click)="addAssignees(item)">{{ 'tasker.selector.' + item | translate }}</button>
            <button mat-button color="secondary" type="button" (click)="clearUsers()">{{ 'tasker.common.clear' |
              translate }}</button>
          </div>
          <mat-form-field class="mt-3" fxFlex
            [class.mat-form-field-invalid]="form.controls.assignee.hasError('required') && form.touched">
            <mat-label>{{ 'tasker.common.assignees' | translate }}</mat-label>
            <mat-chip-grid #taskAssingeesChipList2 required>
              <mat-chip-row *ngFor="let taskUser of form.get('assignee').value" [removable]="hasPermission('assignee.edit')"
                (removed)="removeUser(taskUser.userId, taskRelation.assignee)">
                {{ taskUser.name }}
                <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
              </mat-chip-row>
            </mat-chip-grid>
            <input #contactInputAssignee formControlName="contactInputAssignee" [matAutocomplete]="taskUserAuto"
                [matChipInputFor]="taskAssingeesChipList2">
            <mat-autocomplete #taskUserAuto="matAutocomplete" (optionSelected)="addUser(taskRelation.assignee, $event)">
              <mat-option *ngFor="let userjob of (users$ | async)" [value]="userjob">
                {{ userjob.user.name }} ({{ userjob.job.name }})
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{ 'tasker.my-task-detail.no-users' | translate }}</mat-error>
            <mat-icon [icIcon]="twotoneGroup" class="mr-3" matPrefix></mat-icon>
          </mat-form-field>
        </ng-container>
      </ng-container>

      <!-- observer -->
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px" *ngxPermissionsOnly="uiRole('observer')">
        <mat-form-field class="mt-3" fxFlex>
          <mat-label>{{ 'tasker.common.observer' | translate }}</mat-label>
          <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
          <mat-chip-grid #taskObserversChipList>
            <mat-chip-row *ngFor="let taskUser of form.get('observer').value" [removable]="hasPermission('observer.edit')"
              (removed)="removeUser(taskUser.userId, taskRelation.observer)">
              {{ taskUser.name }}
              <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
            </mat-chip-row>
          </mat-chip-grid>
          <input #contactInputObserver formControlName="contactInputObserver" [matAutocomplete]="taskUserObserverAuto"
              [matChipInputFor]="taskObserversChipList" [readonly]="!hasPermission('observer.edit')">
          <mat-autocomplete #taskUserObserverAuto="matAutocomplete"
            (optionSelected)="addUser(taskRelation.observer, $event)">
            <mat-option *ngFor="let userjob of (users$ | async)" [value]="userjob">
              {{ userjob.user.name }} ({{ userjob.job.name }})
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{ 'tasker.my-task-detail.no-users' | translate }}</mat-error>
        </mat-form-field>
      </div>


      <!-- responsible -->
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px" *ngxPermissionsOnly="uiRole('responsible')">
        <mat-form-field class="mt-3" fxFlex>
          <mat-label>{{ 'tasker.common.responsible' | translate }}</mat-label>
          <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
          <mat-chip-grid #taskResponsibleChipList>
            <mat-chip-row *ngFor="let taskUser of form.get('responsible').value"
              [removable]="hasPermission('responsible.edit')"
              (removed)="removeUser(taskUser.userId, taskRelation.responsible)">
              {{ taskUser.name }}
              <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
            </mat-chip-row>
          </mat-chip-grid>
          <input #contactInputResponsible formControlName="contactInputResponsible"
              [matAutocomplete]="taskUserResponsibleAuto" [matChipInputFor]="taskResponsibleChipList"
              [readonly]="!hasPermission('responsible.edit')">
          <mat-autocomplete #taskUserResponsibleAuto="matAutocomplete"
            (optionSelected)="addUser(taskRelation.responsible, $event)">
            <mat-option *ngFor="let userjob of (users$ | async)" [value]="userjob">
              {{ userjob.user.name }} ({{ userjob.job.name }})
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{ 'tasker.my-task-detail.no-users' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <!-- priority -->
      <mat-form-field class="mt-3" *ngxPermissionsOnly="uiRole('priority')">
        <mat-label>{{ 'tasker.common.priority' | translate }}</mat-label>
        <mat-select matNativeControl placeholder="{{ 'tasker.common.select' | translate }}" formControlName="priority">
          <mat-option [value]="0">{{ 'tasker.priority.not-set' | translate }}</mat-option>
          <mat-option [value]="1">{{ 'tasker.priority.low' | translate }}</mat-option>
          <mat-option [value]="2">{{ 'tasker.priority.normal' | translate }}</mat-option>
          <mat-option [value]="3">{{ 'tasker.priority.high' | translate }}</mat-option>
          <mat-option [value]="4">{{ 'tasker.priority.critical' | translate }}</mat-option>
        </mat-select>
        <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

      <!-- startDate & deadline -->

      <ng-container *ngIf="form.get('newItemType') && form.get('newItemType').value !== 2">
      <div *ngIf="useTime" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px">
        <mat-form-field class="mt-3" fxFlex *ngxPermissionsOnly="uiRole('startDate')">
          <mat-label>{{ 'tasker.common.start' | translate }}</mat-label>
          <input matInput [ngxMatDatetimePicker]="datetimeStart" placeholder="title" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="datetimeStart"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #datetimeStart [showSeconds]="false"></ngx-mat-datetime-picker>
          <mat-icon [icIcon]="twotoneRocket" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
        <mat-form-field class="mt-3" fxFlex *ngxPermissionsOnly="uiRole('deadline')">
          <mat-label>{{ 'tasker.common.deadline' | translate }}</mat-label>
          <input matInput [ngxMatDatetimePicker]="datetimeEnd" placeholder="title" formControlName="deadline">
          <mat-datepicker-toggle matSuffix [for]="datetimeEnd"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #datetimeEnd [showSeconds]="false"></ngx-mat-datetime-picker>
          <mat-icon [icIcon]="twotoneTimerOff" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>

      <div *ngIf="!useTime" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px">
        <mat-form-field class="mt-3" fxFlex *ngxPermissionsOnly="uiRole('startDate')">
          <mat-label>{{ 'tasker.common.start' | translate }}</mat-label>
          <input matInput [matDatepicker]="dateStart" placeholder="title" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="dateStart"></mat-datepicker-toggle>
          <mat-datepicker #dateStart></mat-datepicker>
          <mat-icon [icIcon]="twotoneRocket" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
        <mat-form-field class="mt-3" fxFlex *ngxPermissionsOnly="uiRole('deadline')">
          <mat-label>{{ 'tasker.common.deadline' | translate }}</mat-label>
          <input matInput [matDatepicker]="dateEnd" placeholder="title" formControlName="deadline">
          <mat-datepicker-toggle matSuffix [for]="dateEnd"></mat-datepicker-toggle>
          <mat-datepicker #dateEnd></mat-datepicker>
          <mat-icon [icIcon]="twotoneTimerOff" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>
    </ng-container>
    </div>

    <ngx-mat-file-input #inputPhoto style="display: none;" placeholder="" accept="image/*,text/plain" capture="environment">
    </ngx-mat-file-input>
    <ngx-mat-file-input #inputAttachment style="display: none;" placeholder="" accept="*/*" capture="environment">
    </ngx-mat-file-input>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div *ngIf="detailError$ | async">{{ detailError$ | async }}</div>
    <button mat-button [mat-dialog-close]="false" type="button">{{ 'tasker.common.cancel' | translate }}</button>
    <button color="primary" mat-button type="submit" *ngxPermissionsOnly="uiRole('save')"
      disabled="{{ (creatingTask$ | async) || !(initialized$ | async) }}">{{ 'tasker.my-task-detail.create-task' | translate }}</button>
  </mat-dialog-actions>
</form>