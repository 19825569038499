import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/@vex/services/config.service";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor{
    
    constructor(private config: ConfigService){}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedReq = req.clone({ 
            headers: req.headers.set('X-AirMe-LanguageIsoCode', this.config.getLanguage() || 'cs'),
        });
        return next.handle(modifiedReq);
    }
}