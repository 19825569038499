<div fxLayout="row" fxFlex="1 1 100%" fxLayoutAlign="center baseline">
  <mat-form-field [ngStyle.lt-sm]="{'max-width': '150px'}">
    <mat-label>{{ 'tasker.job.position' | translate }}</mat-label>
    <mat-select [(value)]="selectedJobID" (selectionChange)="selectedJobChanged($event)">
      <mat-option *ngFor="let userJob of userJobs$ | async" [value]="userJob.jobID">
        <ng-container *ngIf="userJob.job">
          {{ userJob.job.name }}
        </ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>