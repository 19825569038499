
<div style="flex: 1 0 100%; flex-direction: row; display: flex;" fxLayoutGap="15px" fxLayoutAlign="start center" (click)="updateTask(task.taskID)">
  <task-status [task]="task"></task-status>
  <div fxLayout="column">
    <div style="flex: 1 1 0%; white-space: nowrap; display: flex" title="{{ task.taskID }}">
      <b>{{ task.name }}</b>&nbsp;&nbsp;
      <span class="task-nr">#{{ task.taskID }}</span>
      <span *ngIf="task.messagesTotal > 0" class="message-icon" [title]="task.messagesTotal + '/' + (task.messagesTotal - task.messagesRead)">
        <mat-icon [icIcon]="outlineMessage" class="read" *ngIf="task.messagesTotal === task.messagesRead"></mat-icon>
        <mat-icon [icIcon]="twotoneMessage" class="unread" *ngIf="task.messagesTotal > task.messagesRead">{{ task.messagesTotal - task.messagesRead }}</mat-icon>
      </span>
      <span class="message-icon child-tasks-count" *ngIf="task.childTasksCount > 0" matBadge="{{ task.childTasksCount }}">
        <mat-icon [icIcon]="certificateIcon" color="primary"></mat-icon>
      </span>
      <span *ngIf="task.parentTaskID" class="message-icon">
        <mat-icon [icIcon]="roundSubdirectoryArrowRight" color="primary"></mat-icon>
      </span>
      <!-- {{ task.messagesRead | json }} / {{ task.messagesTotal | json }} -->
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="task-item-infoline" *ngIf="!!task && !!task.operation && task.operation.taskType === taskType.repairRequest">
      <div style="flex: 1 1 0%; display: flex">{{ task.description }}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="task-item-infoline">
      <div style="flex: 1 1 0%; display: flex">{{ task.taskInfo?.locationName }}</div>
      <div title="{{ 'tasker.priority.' + getPriorityName(task.priority) | translate }}">
        <span *ngIf="task.priority === 3" class="task-item-priority high">High</span>
        <span *ngIf="task.priority === 4" class="task-item-priority critical">Critical</span>
      </div>
    </div>
  </div>
  <div style="flex: 1 0 100px; display: flex;" fxLayoutAlign="end center" fxLayoutGap="15px">
    <!-- <span class="task-item-date">{{ task.deadline | date:'dd.MM.yyyy' }}</span> -->
    <span class="task-item-date" [title]="task.deadline">{{ distanceToNow(task.deadline) }}</span>
    <mat-icon [icIcon]="outlineKeyboardArrowRight" class="row-arrow"></mat-icon>
  </div>
</div>