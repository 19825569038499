import { createAction, props } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export enum ContactsActionTypes {
  LoadContacts = '[Contacts] Load My Contacts',
  LoadContactsCompleted = '[Contacts] Loaded My Contacts Completed',
  LoadContactsFailed = '[Contacts] Loaded My Contacts Failed',

  LoadContactsByUserID = '[Contacts] Load Contacts by UserID',
  LoadContactsByUserIDCompleted = '[Contacts] Load Contacts by UserID Comleted',
  LoadContactsByUserIDFailed = '[Contacts] Load Contacts by UserID Failed',
  CleanContactsByUserID = '[Contacts] Clean Contacts by UserID Failed',
}

// tslint:disable: max-line-length
export const loadContactsAction = createAction(ContactsActionTypes.LoadContacts, props<{ filter: string }>());

export const loadContactsCompletedAction = createAction(ContactsActionTypes.LoadContactsCompleted, props<{result: Array<Honeycomb.Tenant.Contact.IService.Contact>, report: Honeycomb.Tenant.Reports.IService.Model.Report}>());

export const loadContactsFailedAction = createAction(ContactsActionTypes.LoadContactsFailed, props<{error: string}>());


export const loadContactsByUserIDAction = createAction(ContactsActionTypes.LoadContactsByUserID, props<{ userIDs: Array<number> }>());

export const cleanContactsByUserAction = createAction(ContactsActionTypes.CleanContactsByUserID);

export const loadContactsByUserIDCompletedAction = createAction(ContactsActionTypes.LoadContactsByUserIDCompleted, props<{result: Array<Honeycomb.Tenant.Contact.IService.Contact>}>());

export const loadContactsByUserIDFailedAction = createAction(ContactsActionTypes.LoadContactsByUserIDFailed, props<{error: string}>());
