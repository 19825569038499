<div class="dialog-content-wrapper task-sequence-delete" fxLayout="column" fxFlex [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <div fxLayout="column" fxFlex>
            <h2 class="headline m-0" fxFlex="auto">{{ 'tasker.task-sequence-delete.title' | translate }}</h2>
        </div>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-content fxLayout="column" fxFlex="1 1 0%">

        <mat-form-field appearance="fill" style="padding: 20px;">
            <mat-label>{{ 'tasker.task-sequence-delete.range' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker" [dateFilter]="startDateFilter">
              <input matStartDate formControlName="dateStart" [min]="startDateMin" placeholder="{{ 'tasker.common.start' | translate }}">
              <input matEndDate formControlName="dateEnd" placeholder="{{ 'tasker.common.end' | translate }}">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker [startAt]="startDateMin" #picker></mat-date-range-picker>
        </mat-form-field>

        <div style="padding: 20px">{{ 'tasker.task-sequence-delete.items-to-delete' | translate }}: <b>{{ numberOfDeleted | async }}</b></div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <!-- <div fxLayoutAlign="end center" fxFlex>
        </div> -->
        <div fxLayoutAlign="end center" fxFlex style="padding: 15px" fxLayoutGap="10px">
            <button mat-button mat-dialog-close type="button">{{ 'tasker.common.close' | translate }}</button>
            <button mat-button type="button" (click)="delete()" [disabled]="deleting | async" color="warn">{{ 'tasker.common.delete' | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>