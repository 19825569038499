import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverTableComponent } from './popover-table.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';

@NgModule({
  declarations: [PopoverTableComponent],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    IconModule
  ],
  entryComponents: [PopoverTableComponent]
})
export class PopoverTableModule {
}
