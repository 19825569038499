import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TasksTableComponent } from './tasks-table.component';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { MatIconModule } from '@angular/material/icon';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    TasksTableComponent
  ],
  imports: [
    CommonModule,

    PageLayoutModule,
    SharedModule,

    MatButtonModule,
    FlexLayoutModule,
    IconModule,
    MatIconModule,
    ContainerModule,
    PageLayoutModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    TranslateModule
  ],
  exports: [
    TasksTableComponent
  ]
})
export class TasksTableModule { }
