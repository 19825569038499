import { Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Store } from '@ngrx/store';
import { lastValueFrom, Observable, of } from 'rxjs';
import { Globals } from 'src/app/common/globals';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AppState } from 'src/app/app.states';
import { TranslateService } from '@ngx-translate/core';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import { isNullOrWhitespace, zoom, zoomScrollable } from 'src/app/common/functions';
import baselineSend from '@iconify/icons-ic/baseline-send';
import { FileInputComponent } from 'ngx-material-file-input';
import twotoneAddAPhoto from '@iconify/icons-ic/twotone-add-a-photo';
import roundContentPaste from '@iconify/icons-ic/round-content-paste';
import { filter } from 'rxjs/operators';
import { MessageRef } from './message-list';

@Component({
  selector: 'message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class MessageListComponent implements OnInit, AfterViewInit {
  selectedStepper = 0;
  selectedActivity: number;
  userID: number;
  loading$: Observable<boolean>;
  twotoneAddAPhoto = twotoneAddAPhoto;
  roundContentPaste = roundContentPaste;
  taskMessageID: number = null;
  imageEditUID: string = null;
  documentEditUID: string = null;

  @Input()
  items: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage> = [];

  @Input()
  firstLoading: Observable<boolean> = of(true);

  @Input()
  refID: number;

  @Input()
  refType: MessageRef;

  @Input()
  locationID?: number;

  // For permissions
  @Input()
  componentType: number;

  imageUID: string;
  baselineSend = baselineSend;
  tenantHash: string = null;

  documentUID: string;
  documentName: string;

  @ViewChild('inputPhoto') inputPhotoRef: FileInputComponent;
  @ViewChild('inputAttachment') inputAttachmentRef: FileInputComponent;

  fileUploaded$: Observable<any> = of({});
  fileUploading$ = false;

  private roleDebug = [];

  public messageInEdit(msg): boolean {
    return msg.taskMessageID === this.taskMessageID;
  }

  public get imageInEdit(): boolean {
    return !!this.imageEditUID;
  }

  private fb: FormBuilder = null;
  public form: FormGroup = null;

  constructor(
    private store: Store<AppState>,
    @Inject('MessageController') private messageController: Honeycomb.Tenant.Tasker.IService.Controller.MessageController,
    @Inject('FileControllerCustom') private fileController: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
    private cd: ChangeDetectorRef,
    private globals: Globals,
    private trans: TranslateService,
    fb: FormBuilder
    ) {
      this.fb = fb;
      this.form = this.fb.group({
        newMessage: null
      });
        this.store.select(s => s.auth.userID).subscribe(s => this.userID = s);
        this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);
  }

  async sendMessage($event: MouseEvent) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    $event.preventDefault();
    const msg = this.form.controls.newMessage.value;
    if (isNullOrWhitespace(msg) && isNullOrWhitespace(this.imageUID)) {
      return;
    }

    this.loading$ = of(true);

    // EDIT
    if (!!this.taskMessageID) {
      this.messageController.Update(this.taskMessageID,
        { content: msg,
          taskID: this.refType === MessageRef.Task ? this.refID : null,
          locationID: this.refType === MessageRef.Location ? this.refID : null,
          imageUID: this.imageUID,
          documentUID: this.documentUID
         } as Honeycomb.Tenant.Tasker.IService.Model.TaskMessage)
      .toPromise()
      .then(_ => this.refreshMessages())
      .then(_ => {
        this.imageEditUID = null;
        this.documentEditUID = null;
        this.taskMessageID = null;
      });
    } else { // INSERT
      this.messageController.Insert(
        { content: msg, 
          taskID: this.refType === MessageRef.Task ? this.refID : null,
          locationID: this.refType === MessageRef.Location ? this.refID : null,
          imageUID: this.imageUID,
          documentUID: this.documentUID
        } as Honeycomb.Tenant.Tasker.IService.Model.TaskMessage)
      .toPromise()
      .then(_ => this.refreshMessages());
    }
      this.form.controls.newMessage.setValue(null);
      this.imageUID = null;
      this.cd.detectChanges();
  }

  async refreshMessages() {

    if (this.refType === MessageRef.Task) {
      this.items = await lastValueFrom(this.messageController.List(this.refID));
    } else if (this.refType === MessageRef.Location) {
      this.items = await lastValueFrom(this.messageController.LocationMessages(this.refID));
    }
    this.loading$ = of(false);
    this.firstLoading = of(false);
    this.cd.detectChanges();
    this.scrollToBotton();
  }

  editMessage(message: Honeycomb.Tenant.Tasker.IService.Model.TaskMessage) {

    if (!this.taskMessageID) { // toggle
      this.form.get('newMessage').setValue(message.content);
      this.taskMessageID = message.taskMessageID;
      this.imageEditUID = message.imageUID;
      this.documentEditUID = message.documentUID;
    } else {
      this.taskMessageID = null;
      this.imageEditUID = null;
      this.documentEditUID = null;
      this.form.get('newMessage').setValue(null);
    }
  }

  private scrollToBotton() {
    const msgContainer = document.getElementById('message-container');
    msgContainer.scrollTop = msgContainer.scrollHeight - msgContainer.clientHeight;
  }

  testClick($event) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    
  }

  addPhoto($event: Event) {
    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }
    const inpPhoto = this.inputPhotoRef;
    inpPhoto.change = ($inpEvent: Event) => {
      const f = ($inpEvent.target as HTMLInputElement).files[0];
      if (!f) {
        this.imageEditUID = null;
        return;
      }
      this.fileUploading$ = true;
      this.cd.markForCheck();

      return this.fileController.UploadFile(f, $event.target as HTMLElement)
                  .pipe(filter(p => !!p))
                  .subscribe(p => {
                    this.imageUID = p.recordUID;
                    this.imageEditUID = null;
                    this.cd.detectChanges();
                  });
    };
    inpPhoto.open();
  }

  addAttachment($event: Event) {
    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }
    const inpAttachment = this.inputAttachmentRef;
    inpAttachment.change = ($inpEvent: Event) => {
      const f = ($inpEvent.target as HTMLInputElement).files[0];
      if (!f) {
        this.documentEditUID = null;
        return;
      }
      this.fileUploading$ = true;
      this.cd.markForCheck();

      return this.fileController.UploadFile(f, $event.target as HTMLElement)
                  .subscribe(p => {
                    this.documentUID = p.recordUID;
                    this.documentName = p.fileName;
                    this.documentEditUID = null;
                    this.cd.detectChanges();
                  });
    };
    inpAttachment.open();
  }

  uiRole(suffix: string) {
    let reqUiRole: string = null;

    if (this.refType === MessageRef.Task) {
      reqUiRole = `tasker.task-detail.${this.componentType}.messages.${suffix}`;
    }  else {
      reqUiRole = `tasker.${this.componentType}.messages.${suffix}`;
    }

    if (this.roleDebug.findIndex(r => r === reqUiRole) === -1) {
      this.globals.DebugOut('message-list', reqUiRole, false);
      this.roleDebug.push(reqUiRole);
    }

    return reqUiRole;
  }

  getImageUrl(imgGuid: string, maxWidth: number = 50, maxHeight: number = 50): string {
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/TaskerFile/GetImage', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  zoomAttachmentImage(photoUID: any) {
    zoomScrollable(this.getImageUrl(photoUID, 1920, 1080), this.trans.instant('tasker.common.close'),
                   this.trans.instant('tasker.common.delete'));
  }

  getTempImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/TenantTasker/File/gettempfile', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  zoomAttachmentTempImage(photoUID: any) {
    zoomScrollable(this.getTempImageUrl(photoUID, 1920, 1080), this.trans.instant('tasker.common.close'),
                   this.trans.instant('tasker.common.delete'),
                   () => {
                    this.imageUID = null;
                    this.cd.detectChanges();
                   } );
  }
}
