import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';


import outlineKeyboardArrowRight from '@iconify/icons-ic/outline-keyboard-arrow-right';
import twotoneCheckBox from '@iconify/icons-ic/twotone-check-box';
import twotoneIndeterminateCheckBox from '@iconify/icons-ic/twotone-indeterminate-check-box';
import twotoneCheckBoxOutlineBlank from '@iconify/icons-ic/twotone-check-box-outline-blank';
import roundDoneOutline from '@iconify/icons-ic/round-done-outline';
import roundAutorenew from '@iconify/icons-ic/round-autorenew';
import twotoneNewReleases from '@iconify/icons-ic/twotone-new-releases';
import roundPriorityHigh from '@iconify/icons-ic/round-priority-high';
import twotoneWhatshot from '@iconify/icons-ic/twotone-whatshot';
import twotoneError from '@iconify/icons-ic/twotone-error';
import twotoneBuildCircle from '@iconify/icons-ic/twotone-build';
import outlineMessage from '@iconify/icons-ic/outline-message';
import twotoneMessage from '@iconify/icons-ic/twotone-message';
import certificateIcon from '@iconify/icons-fa-solid/certificate';
import roundSubdirectoryArrowRight from '@iconify/icons-ic/round-subdirectory-arrow-right';

import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import csLocale from 'date-fns/locale/cs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskItemComponent implements OnInit, OnDestroy {

  roundDoneOutline = roundDoneOutline;
  roundAutorenew = roundAutorenew;
  twotoneNewReleases = twotoneNewReleases;
  roundPriorityHigh = roundPriorityHigh;
  twotoneWhatshot = twotoneWhatshot;

  outlineKeyboardArrowRight = outlineKeyboardArrowRight;
  twotoneCheckBox = twotoneCheckBox;
  twotoneIndeterminateCheckBox = twotoneIndeterminateCheckBox;
  twotoneCheckBoxOutlineBlank = twotoneCheckBoxOutlineBlank;
  twotoneError = twotoneError;
  twotoneBuildCircle = twotoneBuildCircle;
  outlineMessage = outlineMessage;
  twotoneMessage = twotoneMessage;
  certificateIcon = certificateIcon;
  roundSubdirectoryArrowRight = roundSubdirectoryArrowRight;

  taskType = Honeycomb.Common.Enums.TaskType;

  @Input()
  task: Honeycomb.Tenant.Tasker.IService.Model.Task; // Report task

  @Output()
  selected: EventEmitter<number> = new EventEmitter();

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  getStateName(stateID: number) {
    const stateName = Honeycomb.Common.Enums.TaskState[stateID];
    return stateName;
  }

  getPriorityName(priorityID: number) {
    const priorityName = Honeycomb.Common.Enums.Priority[priorityID];
    return priorityName;
  }

  updateTask(taskID: number) {
    this.selected.emit(taskID);
  }

  public distanceToNow(date: any) {

    if (date === null) { // deadline not set
      return '';
    }
    let d = new Date(date);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    let dToday = new Date();
    dToday = new Date(dToday.getFullYear(), dToday.getMonth(), dToday.getDate());

    if (d.valueOf() === dToday.valueOf()) {
      return this.translate.instant('tasker.common.today');
    }

    if ((d.valueOf() - dToday.valueOf()) === 86400000) {
      return this.translate.instant('tasker.common.tomorrow');
    }
    if ((dToday.valueOf() - d.valueOf()) === 86400000) {
      return this.translate.instant('tasker.common.yesterday');
    }

    return formatDistanceStrict(d, dToday, { addSuffix: true, unit: 'day', roundingMethod: 'floor', locale: csLocale  });
    // return distanceInWordsToNow(date, { includeSeconds: false,  }, csLocale);
  }
}
