import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { loginSuccessAction,
         loginFailureAction,
         loginAction,
         loginTenantSelectedAction,
         loginAwaitingTenantSelectAction,
         logoutAction,
         logoutConfirmedAction} from './auth.action';
import { AuthService } from './auth.service';
import { mergeMap, catchError, map, tap, mapTo, withLatestFrom, switchMap } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { Router } from '@angular/router';
import { JwtToken } from './auth.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { loadCurrentUserAction, loadUIRolesAction, positionRecorderAction } from '../user/user.actions';
import { Globals } from 'src/app/common/globals';
import { isNullOrWhitespace } from 'src/app/common/functions';
import { TranslateService } from '@ngx-translate/core';
import { MsalService } from '@azure/msal-angular';

const jwtHelper = new JwtHelperService();

// Please read: https://medium.com/@tanya/understanding-ngrx-effects-and-the-action-stream-1a74996a0c1c

@Injectable()
export class AuthEffects {

    constructor(private actions$: Actions,
                private authService: AuthService,
                private router: Router,
                private trans: TranslateService,
                private store: Store<AppState>,
                private globals: Globals) {
    }

    authUserEffect = createEffect(() => this.actions$.pipe(
            ofType(loginAction),
            mergeMap(
                (data) => this.authService.multilogin(data.login, data.password)
                    .pipe(
                        map(res => {
                            if (!res || res.length === 0) {
                                return loginFailureAction({ error: 'tasker.login.failed' });
                            }
                            return loginSuccessAction({jwtTokenStrings: res, rememberMe: data.rememberMe});
                        }),
                        catchError(error => of(loginFailureAction({ error: error})))
                    )
            )
        ));

    loginSuccessEffect = createEffect(() => this.actions$.pipe(
                ofType(loginSuccessAction),
                map((data) => {
                    const decoded = data.jwtTokenStrings.map(jwt => jwtHelper.decodeToken(jwt)) as Array<JwtToken>;
                    if (data.jwtTokenStrings.length === 1) {
                        return loginTenantSelectedAction({ jwtToken: decoded[0], rememberMe: data.rememberMe});
                    } else {
                        return loginAwaitingTenantSelectAction({ jwtTokens: decoded });
                    }
                })
            ));

    loginTenantSelectedEffect = createEffect(() => this.actions$.pipe(
                ofType(loginTenantSelectedAction),
                withLatestFrom(this.store),
                tap(([data, s]) => {
                    if (!s.auth) {
                        return;
                    }
                    const authJwt =
                        s.auth.tokenStrings.find(j => (jwtHelper.decodeToken(j) as JwtToken).TenantID === data.jwtToken.TenantID);
                    if (!authJwt) {
                        return;
                    }
                    const decodedToken = jwtHelper.decodeToken(authJwt) as JwtToken;
                    this.globals.SetApiUrl(decodedToken);
                    this.authService.setToken(authJwt, null, data.rememberMe);
                    this.store.dispatch(loadCurrentUserAction());

                    // Reload language with http client containing tenant ID
                    // console.log("Reloading language", this.trans.defaultLang);
                    // this.trans.reloadLang(this.trans.defaultLang);

                    if (!isNullOrWhitespace(s.auth.returnUrl)) {
                        const returnUrl = s.auth.returnUrl;
                        this.router.navigateByUrl(returnUrl);
                        return;
                    }
                    // to load tenant localizations
                    this.router.navigate(['/dashboard/main']);
                })
        ), {dispatch: false});

    logoutConfirmedEffect = createEffect(() => this.actions$.pipe(
            ofType(logoutConfirmedAction),
            withLatestFrom(this.store),
            tap(() => {
                console.log('logout confirmed');
            })
        ), {dispatch: false});

    logoutSuccessEffect = createEffect(() => this.actions$.pipe(
            ofType(logoutAction),
            tap(async _ => await this.authService.logout()),
            mapTo(logoutConfirmedAction())
        ));
}