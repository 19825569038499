export abstract class BuildConfig {
    info: string;
    standardLogin?: {
        alphaNumericLogin?: boolean;
        enabled: boolean,
    };
    azureLogin?: {
        enabled: boolean,
        redirectUri?: string,
        postLogoutRedirectUri?: string,
        clientID?: string,
        authApiUri?: string
    };
    tenantID?: number = null;
    externalAuthApiUri?: string = "http://localhost";
    customButtons?: Array<string> = [];
    taskDetail: {
        repair: {
            attributes?: Array<string>;
        },
        default: {
            attributes?: Array<string>;
        },
    };
    taskList: {
        repair: {
            attributes?: Array<string>;
        },
        default: {
            attributes?: Array<string>;
        },
    };
    supportedLanguages: Array<string> = [
        'cs', 'en', 'sk', 'ro', 'hu', 'hr', 'nl-be'
    ];
    forgotPassword?: {
        enabled: boolean
    };
}