import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
@Injectable({
  providedIn: 'root',
})
export class WebNotificationService {
  constructor(private http: HttpClient,
              private swPush: SwPush,
              @Inject('NotificationController')
            private notificationController: Honeycomb.Tenant.Tasker.IService.Controller.NotificationController,) 
  {
  }

  subscribeToNotification(vapidPublicey: string) {

    this.swPush.requestSubscription({
        serverPublicKey: vapidPublicey
    })
    .then(sub => this.sendToServer(sub))
    .catch(err => { 
        console.error('Could not subscribe to notifications', err);
    });
  }
  sendToServer(subscription: PushSubscription) {
    var jsonObj = subscription.toJSON();
    const rawKey = subscription.getKey ? subscription.getKey('p256dh') : '';
    const key = rawKey ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))) : '';
    const rawAuthSecret = subscription.getKey ? subscription.getKey('auth') : '';
    const authSecret = rawAuthSecret ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret))) : '';
    const expirationTime = (subscription as any).expirationTime || -1;

    this.notificationController.PushSubscription({ endpoint: subscription.endpoint, expirationTime: expirationTime, keys: { auth: authSecret, p256dh: key  } })
                               .subscribe();
    // this.http.post(this.baseUrl, { notification : params }).subscribe();
  }
}