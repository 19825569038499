import { createAction, props } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export enum PbiActionTypes {
  LoadPbiToken = '[Pbi] Load My Pbi',
  LoadPbiTokenCompleted = '[Pbi] Loaded My Pbi Completed',
  LoadPbiTokenFailed = '[Pbi] Loaded My Pbi Failed'
}

// tslint:disable: max-line-length
export const loadPbiTokenAction = createAction(PbiActionTypes.LoadPbiToken);

export const loadPbiTokenCompletedAction = createAction(PbiActionTypes.LoadPbiTokenCompleted, props<{ aad: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD }>());

export const loadPbiTokenFailedAction = createAction(PbiActionTypes.LoadPbiTokenFailed, props<{error: string}>());
