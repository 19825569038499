import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { TranslateService } from '@ngx-translate/core';
import { TasksService } from 'src/app/services/tasks.service';
import { isNullOrUndefined } from 'src/app/common/functions';

@Component({
  selector: 'tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
  animations: [
  ],
})
export class TasksListComponent implements OnInit, OnDestroy, AfterViewInit {


  @Input()
  tasks: BehaviorSubject<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>>;

  //items: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> = [];

  itemsTotal: Number = 1000;
    
  filteredArray: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> = [];

  dataSource = new MatTableDataSource<Honeycomb.Tenant.Tasker.IService.Model.Task>();

  pageEvent: any;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Output()
  selected: EventEmitter<number> = new EventEmitter();

  subscriptions: Array<Subscription> = [];

  filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter;

  taskType = Honeycomb.Common.Enums.TaskType;
  taskRelation = Honeycomb.Common.Enums.TaskRelation;
  taskState = Honeycomb.Common.Enums.TaskState;

  @Input()
  viewSort: Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort;

  @Input()
  agendaKey: string;

  @Input()
  ignoreViewSort: boolean = false;

  showPaginator: BehaviorSubject<boolean> = new BehaviorSubject(false);

  loading$: Observable<boolean>;
  loading: boolean;

  pageIndex$: Observable<Map<string, number>>;
  pageIndex: number;

  pageSize$: Observable<Map<string, number>>;
  pageSize: number = 50;
  //pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);


  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private trans: TranslateService,
    private tasksService: TasksService,
    @Inject('CodeListController') private codeListController: Honeycomb.Tenant.LookupTables.IService.CodeListController,
  ) {
    this.loading$ = this.tasksService.loading;
    this.loading$.subscribe(s => {
      const progressElement = document.getElementById('tasks-progress');
      const taskRowElem = document.getElementById('task-row-container');
      if (s && progressElement && taskRowElem) {
        progressElement.style.height = taskRowElem.scrollHeight + 'px';
      }
    });

    var fpu = this.tasksService.forcePagerUpdate.pipe(take(1))
    .subscribe(async s => await this.forcePagerUpdate());
  }

  async ngOnInit() {
    this.dataSource.data = this.tasks.getValue();
    this.tasksService.total.subscribe(t => { 
      this.itemsTotal = t;
      this.cd.detectChanges
    });
    this.pageSize = this.tasksService.pageSize(this.agendaKey);
    this.showPaginator.next(true);
  }


  private setPaginator() {
    if (!!this.paginator) {
      let filterCopy = this.tasksService.getFilterCopy();
      if (isNullOrUndefined(this.pageIndex) || filterCopy.pageIndex !== this.pageIndex) {
        this.pageIndex = filterCopy.pageIndex ?? this.tasksService.pageIndex(this.agendaKey) ?? 0;
        this.pageSize = filterCopy.pageSize ??  this.tasksService.pageSize(this.agendaKey) ?? 10;
      }

      if (this.paginator.pageIndex === this.pageIndex) {
        console.log(`setPaginator - NOT CHANGED ${this.agendaKey}: pageIndex: ${this.pageIndex}, pageIndex: ${this.pageSize}`);
        return;
      }

      if (this.pageIndex > 0) {
        this.paginator.disabled = true;
        var startedOnZero = this.paginator.pageIndex === 0;
        this.paginator.pageIndex = this.pageIndex;
        if (startedOnZero) {
          this.paginator.nextPage(); // - otherwise 1st is selected and page index is ignored  
          this.paginator.previousPage(); // the only way how to light up correct button in paginator
        
        } else {
          this.paginator.previousPage(); // the only way how to light up correct button in paginator
          this.paginator.nextPage(); // - otherwise 1st is selected and page index is ignored
        }
        this.paginator.disabled = false;
      } else if (this.pageIndex === 0 && this.paginator.pageIndex !== this.pageIndex) {
        this.paginator.disabled = true;
        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;
        this.paginator.nextPage(); // - otherwise 1st is selected and page index is ignored  
        this.paginator.previousPage(); // the only way how to light up correct button in paginator
        this.paginator.disabled = false;
      }

      console.log(`setPaginator ${this.agendaKey}: pageIndex: ${this.pageIndex}, pageSize: ${this.pageSize}`);
      this.cd.detectChanges();
    }
  }

  public async forcePagerUpdate() {
    this.setPaginator();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // this.paginator._intl.getRangeLabel = this.paginatorRangeLabel;
      this.setPaginator();
    }, 0);
  }

  async pageChangedHandler(event: PageEvent) {
      if (this.paginator.disabled) { 
        return; 
      }

      this.tasksService.setPager(this.agendaKey, event.pageIndex, event.pageSize);
      await this.tasksService.load(this.agendaKey);
      this.itemsTotal = this.tasksService.totalStatic;
      this.pageSize = this.tasksService.pageSize(this.agendaKey);
      this.showPaginator.next(true);
  }

  public paginatorRangeLabel(page, size, length): string {
    // if (page === 0) {
    //   return `${page} - ${page * size}`;
    // }
    if ((page + 1)   * size > length) {
      return `${page * size} - ${length}`;
    }
    return `${page * size} - ${(page + 1)   * size}`;
  }

  public updateTask(taskID) {
    this.selected.emit(taskID);
  }
}
