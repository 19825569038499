import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardTaskActions from './dashboard-tasks.actions';
import { DashboardTasksInfoStateState, DashboardTasksInfoState } from './dashboard-tasks.state';

const dashboardTaskReducer = createReducer(DashboardTasksInfoStateState(),

// tslint:disable: max-line-length
  on(DashboardTaskActions.loadDashboardTasksInfoAction, (state) => ({...state, loading: true, error: null})),
  on(DashboardTaskActions.loadDashboardTasksInfoCompletedAction, (state, { tileBadges }) =>
      ({ ...state, tileBadges, loading: false, error: null })),
  on(DashboardTaskActions.loadDashboardTasksInfoFailedAction, (state, { error }) => ({ ...DashboardTasksInfoStateState(), error })),
  on(DashboardTaskActions.setTileUnreadNumberAction, (state, { taskID }) => ({...state, taskID}))

);

export function reducer(state: DashboardTasksInfoState | undefined, action: Action) {
  return dashboardTaskReducer(state, action);
}
