import { createAction, props } from '@ngrx/store';
import { TileDetail } from '../user/roleAttributeTaskerValue.model';

export enum DashboardTasksActionTypes {
  LoadDashboardTasksInfo = '[DashboardTasks] Load Tasks Info',
  LoadDashboardTasksInfoCompleted = '[DashboardTasks] Load Tasks Info Completed',
  LoadDashboardTasksInfoFailed = '[DashboardTasks] Load Tasks Info Failed',

  SetTaskUnreadNumber = '[DashboardTasks] Set task unread number',
}

// tslint:disable: max-line-length
export const loadDashboardTasksInfoAction = createAction(DashboardTasksActionTypes.LoadDashboardTasksInfo, props<{ tileKeys: Array<TileDetail> }>());

export const loadDashboardTasksInfoCompletedAction = createAction(DashboardTasksActionTypes.LoadDashboardTasksInfoCompleted, props<{ tileBadges: { [key: string]: string }}>());

export const loadDashboardTasksInfoFailedAction = createAction(DashboardTasksActionTypes.LoadDashboardTasksInfoFailed, props<{error: string}>());

export const setTileUnreadNumberAction = createAction(DashboardTasksActionTypes.SetTaskUnreadNumber, props<{ taskID: number }>());
