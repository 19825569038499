<form *ngIf="form" (ngSubmit)="save()" [formGroup]="form" style="min-width: 70vw;">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

    <h2 class="headline m-0" fxFlex="auto">
      {{ 'tasker.store-visit.new-visit' | translate }}
    </h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content fxLayout="column" style="min-height: 65vh;">
    <div fxLayout="column" style="padding: 0 15px;">

      <!-- task name -->
      <mat-form-field class="mt-3" fxFlex>
        <mat-label>{{ 'tasker.common.name' | translate }}</mat-label>
        <input type="text" matInput formControlName="name">
        <mat-icon [icIcon]="twotoneMore" flip="horizontal" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

      <!-- description -->
      <mat-form-field class="mt-3" fxFlex>
        <mat-label>{{ 'tasker.common.description' | translate }}</mat-label>
        <textarea type="text" style="box-sizing:content-box;" matInput cdkTextareaAutosize
           #descriptionInput="cdkTextareaAutosize" formControlName="description" rows="1"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5">
          </textarea>
        <mat-icon [icIcon]="twotoneDescription" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

      <!-- startDate & deadline -->
      <div *ngIf="useTime" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px">
        <mat-form-field class="mt-3" fxFlex>
          <mat-label>{{ 'tasker.common.start' | translate }}</mat-label>
          <input matInput [ngxMatDatetimePicker]="datetimeStart" placeholder="title" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="datetimeStart"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #datetimeStart [showSeconds]="false"></ngx-mat-datetime-picker>
          <mat-icon [icIcon]="twotoneRocket" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>
      <div *ngIf="!useTime" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px">
        <mat-form-field class="mt-3" fxFlex>
          <mat-label>{{ 'tasker.common.start' | translate }}</mat-label>
          <input matInput [matDatepicker]="dateStart" placeholder="title" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="dateStart"></mat-datepicker-toggle>
          <mat-datepicker #dateStart></mat-datepicker>
          <mat-icon [icIcon]="twotoneRocket" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div *ngIf="detailError$ | async">{{ detailError$ | async }}</div>
    <button mat-button [mat-dialog-close]="false" type="button">{{ 'tasker.common.cancel' | translate }}</button>
    <button color="primary" mat-button type="submit">{{ 'tasker.store-visit.create-visit' | translate }}</button>
  </mat-dialog-actions>
</form>