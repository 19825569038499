import { createAction, props } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export enum OperationsActionTypes {
  LoadOperations = '[Operations] Load My Operations',
  LoadOperationsCompleted = '[Operations] Loaded My Operations Completed',
  LoadOperationsFailed = '[Operations] Loaded My Operations Failed'
}

// tslint:disable: max-line-length
export const loadOperationsAction = createAction(OperationsActionTypes.LoadOperations);

export const loadOperationsCompletedAction = createAction(OperationsActionTypes.LoadOperationsCompleted, props<{result: Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>}>());

export const loadOperationsFailedAction = createAction(OperationsActionTypes.LoadOperationsFailed, props<{error: string}>());
