import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardContactActions from './contacts.actions';
import { ContactsState } from './contacts.state';

const ContactsReducer = createReducer(ContactsState(),

// tslint:disable: max-line-length
  on(DashboardContactActions.loadContactsAction, (state) => ({...state, loading: true, error: null})),
  on(DashboardContactActions.loadContactsCompletedAction, (state, { result }) =>
      ({ ...state, results: result, loading: false, error: null })),
  on(DashboardContactActions.loadContactsFailedAction, (state, { error }) => ({ ...state, results: [], error, loading: false })),


  on(DashboardContactActions.loadContactsByUserIDAction, (state) => ({...state, loading: true, error: null})),
  on(DashboardContactActions.loadContactsByUserIDCompletedAction, (state, { result }) =>
      ({ ...state, contactsByUserID: result, loading: false, error: null })),
  on(DashboardContactActions.loadContactsByUserIDFailedAction, (state, { error }) => ({ ...state, contactsByUserID: [], error, loading: false })),
);

export function reducer(state: ContactsState | undefined, action: Action) {
  return ContactsReducer(state, action);
}
