<div id="task-row-container" style="">
  <div *ngFor="let item of (tasks | async)" class="task-row read-box" 
    [ngStyle]="{'display': (loading$ | async) ? 'none' : 'block'}"
    [class.unread]="!item.dateRead" [class.read]="!!item.dateRead">
    <task-item [task]="item" (selected)="updateTask($event)"></task-item>
    <mat-divider></mat-divider>
  </div>
</div>

<!-- <mat-paginator stylePaginator showFirstLastButtons #paginator *ngIf="showPaginator | async"
      [length]="itemsTotal"
      [pageSize]="(pageSize | async)"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      (page)="pageChangedHandler($event)">
</mat-paginator> -->

<mat-paginator stylePaginator showFirstLastButtons #paginator [ngStyle]="{'display': (showPaginator | async) ? 'block' : 'none'}"
[length]="itemsTotal"
[pageSize]="pageSize"
[disabled]="(loading$ | async)"
(page)="pageChangedHandler($event)"
[pageSizeOptions]="[5, 10, 20, 50, 100]">
</mat-paginator>