import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AttendanceModel, RoleAttributeTaskerValueModel } from './roleAttributeTaskerValue.model';

export interface UserStateIFace {
    currentUser: Honeycomb.Tenant.Contact.IService.UserInfo;
    uiroles: string[];
    loading: boolean;
    error: string;
    selectedJobID: number;
    selectedJobLocations: Array<number>;
    dashboard: RoleAttributeTaskerValueModel;
    attendance: AttendanceModel;
    locationWatchId: number;
}

const UserState = () => {
    return {
        currentUser: null,
        uiroles: [],
        loading: false,
        error: null,
        selectedJobID: null,
        selectedJobLocations: [],
        dashboard: null,
        locationWatchId: null
    } as UserStateIFace;
  };

export default UserState;
