import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef, Self, Optional, AfterContentInit, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { isNullOrUndefined } from 'src/app/common/functions';
import outlineCancel from '@iconify/icons-ic/outline-cancel';
import { ButtonRatingModel } from './button-rating.model';

@Component({
  selector: 'button-rating',
  templateUrl: './button-rating.component.html',
  styleUrls: ['./button-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ButtonRatingComponent)    ,
    multi: true
  },
]
})

export class ButtonRatingComponent implements AfterContentInit, ControlValueAccessor 
// Validator
{

  private static readonly INP_RATING_VALUES: string = 'ratingValues';
  private static readonly INP_VALUE: string = 'value';

  private _ratingValues: Array<number | ButtonRatingModel>;
  private onRatingValuesChange: Subject<Array<number | ButtonRatingModel>>;

  private _value: number = null;
  private onValueChange: Subject<number>;
  required = false;

  public isNumber = true;
  public isObject = false;

  outlineCancel = outlineCancel;

  private control: FormControl;

  onChanged: any = () => {};
  onTouched: any = () => {};
  // onValidationChange: any = () => {};

  language: string;

   @Input(ButtonRatingComponent.INP_RATING_VALUES) set ratingValues(values: string) {
    this._ratingValues = JSON.parse(values);
    if (this._ratingValues) {
      if (typeof(this._ratingValues[0]) === 'object') {
        this.isObject = true;
        this.isNumber = false;      
      } else {
        this.isObject = false;
        this.isNumber = true;
      }
      this.onRatingValuesChange.next(this._ratingValues);
    }
  }

  get parsedRatingValues(): Array<number | ButtonRatingModel> {
    this.language = localStorage.getItem('lang') || 'cs';
    this._ratingValues.forEach(i => {
      var displayValue = Object(i)["displayValue"];
      if (typeof(displayValue) === 'object') {
        var displayValueByLanguage = displayValue.find(d => d.lang === this.language)?.displayValue || '?';
        Object(i)["displayValue"] = displayValueByLanguage;
      }
    });
    return this._ratingValues;
  }

  get value(): number {
    return this._value;
  }

  writeValue(value: number): void {
    this._value = value;
    this.onValueChange.next(value);
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onSelectedChange($event: MatButtonToggleChange) {
    this.onChanged($event.value);
    this._value = $event.value;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private injector: Injector,
  ) {
    if (!this.onRatingValuesChange) {
      this.onRatingValuesChange = new Subject();
      this.onRatingValuesChange.subscribe(() => {
      });
    }

    if (!this.onValueChange) {
      this.onValueChange = new Subject();
      this.onValueChange.subscribe(() => {
      });
    }
  }

  ngAfterContentInit(): void {
  }
}
