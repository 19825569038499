import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule, TranslatePipe, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Globals } from './common/globals';
import { ApiCaller } from './services/honeycomb-api/api-caller';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { TaskDetailComponent } from './pages/tasks/task-detail/task-detail.component';
import { TaskSimpleComponent } from './pages/tasks/task-simple/task-simple.component';
import { TaskInputComponent } from './pages/tasks/task-input/task-input.component';
import { IconModule } from '@visurel/iconify-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { TaskActivitiesComponent } from './pages/tasks/task-activities/task-activities.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { TaskItemModule } from 'src/@vex/components/task-item/task-item.module';
import { StarRatingModule } from 'src/@vex/components/star-rating/star-rating.module';
import { ButtonRatingModule } from 'src/@vex/components/button-rating/button-rating.module';
import { TaskTableModule } from 'src/@vex/components/task-table/task-table.module';
import { GeoInputModule } from 'src/@vex/components/geo-input/geo-input.module';
import { TaskNewComponent } from './pages/tasks/task-new/task-new.component';
import { TaskRepairComponent } from './pages/tasks/task-repair/task-repair.component';
import { TaskSummaryComponent } from './pages/tasks/task-summary/task-summary.component';
import { UpdateDialogComponent } from './pages/common/update-dialog/update-dialog.component';
import { StoreItemModule } from './pages/stores/store-item/store-item.module';
import { TaskRepairActivitiesComponent } from './pages/tasks/task-repair-activities/task-repair-activities.component';
import { StoreVisitNewComponent } from './pages/stores/store-visit/store-visit-new.component';
import { MeetingNewComponent } from './pages/meeting/meeting-new/meeting-new.component';
import { StylePaginatorDirective } from 'src/@vex/components/style-paginator/style-paginator.directive';
import { TaskFilesComponent } from './pages/tasks/task-files/task-files.component';
import { DeleteDialogComponent } from './pages/common/delete-dialog/delete-dialog.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PromptDialogComponent } from './pages/common/prompt-dialog/prompt-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ButtonBarComponent } from './pages/common/button-bar/button-bar.component';
import { AttendanceDialogComponent } from './pages/common/attendance-dialog/attendance-dialog.component';
import { LocationsMapComponent } from './pages/common/locations-map/locations-map.component';
import { LocationsMapDialogComponent } from './pages/common/locations-map/locations-map-dialog.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { CalendarModule } from 'src/@vex/components/calendar/calendar.module';
import { TaskFilterModule } from 'src/@vex/components/task-filter/task-filter.module';
import { TaskSortModule } from 'src/@vex/components/task-sort/task-sort.module';
import { TaskSequenceModule } from './pages/tasks/task-sequence/task-sequence.module';
import { DateFromUtcModule } from 'src/@vex/pipes/date-from-utc/date-from-utc.module';
import { TaskStatusModule } from 'src/@vex/components/task-status/task-status.module';
import { MessagesModule } from 'src/@vex/components/messages/messages.module';
import { TaskDetailHeaderComponent } from './pages/tasks/task-detail-header/task-detail-header.component';
import { CustomSpButtonModule } from 'src/@vex/components/custom-sp-button/custom-sp-button.module';
import { CustomSpButtonComponent } from 'src/@vex/components/custom-sp-button/custom-sp-button.component';
import { TaskInputButtonsComponent } from './pages/tasks/task-input-buttons/task-input-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    TranslateModule,
    MatMenuModule,
    IconModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatChipsModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatExpansionModule,
    MatToolbarModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    FlexLayoutModule,
    BreadcrumbsModule,
    MatTabsModule,
    MaterialFileInputModule,
    NgxPermissionsModule,
    DateFromUtcModule,

    TaskItemModule,
    TaskStatusModule,
    TaskTableModule,
    StarRatingModule,
    CustomSpButtonModule,
    ButtonRatingModule,
    GeoInputModule,
    StoreItemModule,
    CalendarModule,
    TaskFilterModule,
    TaskSortModule,
    TaskSequenceModule,
    MessagesModule,
    
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  declarations: [
    TaskDetailComponent,
    TaskDetailHeaderComponent,
    TaskSimpleComponent,
    TaskInputComponent,
    TaskInputButtonsComponent,
    TaskNewComponent,
    TaskRepairComponent,
    TaskActivitiesComponent,
    TaskRepairActivitiesComponent,
    TaskSummaryComponent,
    UpdateDialogComponent,
    DeleteDialogComponent,
    ButtonBarComponent,
    StoreVisitNewComponent,
    MeetingNewComponent,
    TaskFilesComponent,
    
    PromptDialogComponent,
    AttendanceDialogComponent,
    StylePaginatorDirective,
    LocationsMapComponent,
    LocationsMapDialogComponent,
  ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatChipsModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatExpansionModule,

    IconModule,
    FlexLayoutModule,
    BreadcrumbsModule,
    MaterialFileInputModule,

    TaskItemModule,
    TaskStatusModule,
    TaskTableModule,
    StarRatingModule,
    CustomSpButtonModule,
    ButtonRatingModule,
    GeoInputModule,
    CalendarModule,
    TaskFilterModule,
    StoreItemModule,
    StylePaginatorDirective,
    LocationsMapComponent,
    LocationsMapDialogComponent,
    NgxPermissionsModule,
    DateFromUtcModule
  ],
  providers: [
    {
      provide: 'ApiCaller',
      useFactory: (httpClient: HttpClient, globals: Globals) => (new ApiCaller(httpClient, globals)),
      deps: [HttpClient, Globals]
    },
    {
      provide: ApiCaller,
      useFactory: (httpClient: HttpClient, globals: Globals) => (new ApiCaller(httpClient, globals)),
      deps: [HttpClient, Globals]
    }
  ],
  entryComponents: [
    TaskDetailComponent,
    TaskDetailHeaderComponent,
    TaskSimpleComponent,
    TaskNewComponent,
    TaskRepairComponent,
    StoreVisitNewComponent,
    MeetingNewComponent,
    DeleteDialogComponent,
    PromptDialogComponent,
    AttendanceDialogComponent,
    LocationsMapComponent,
    LocationsMapDialogComponent
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
      ]
    }
  }
}
