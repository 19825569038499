import { Action, createReducer, on } from '@ngrx/store';
import * as NotificationPushActions from './notification-push.actions';
import { NotificationPushState } from './notification-push.state';

const NotificationPushReducer = createReducer(NotificationPushState(),

// tslint:disable: max-line-length

  on(NotificationPushActions.pushGetPubKeyAction, (state) => ({...state, loading: true, error: null, online: false, pubKey: null})),
  on(NotificationPushActions.pushGetPubKeyFinishedAction, (state, { pubKey }) => ({ ...state, loading: false, online: true, pubKey })),
  on(NotificationPushActions.pushGetPubKeyFailedAction, (state) => ({ ...state, loading: false, online: false })),

  on(NotificationPushActions.pushSubscribeAction, (state) => ({...state, loading: true, error: null, online: false})),
  on(NotificationPushActions.pushSubscribeFinishedAction, (state, { registration }) => ({ ...state, loading: false, online: true, registration })),
  on(NotificationPushActions.pushSubscribeFailedAction, (state) => ({ ...state, loading: false, online: false })),

  on(NotificationPushActions.pushDataUpdatedAction, (state, { data }) => ({ ...state, data })),
  on(NotificationPushActions.pushDataUpdatedFinishedAction, (state) => ({ ...state }))
);

export function reducer(state: NotificationPushState | undefined, action: Action) {
  return NotificationPushReducer(state, action);
}
