<h2 mat-dialog-title>{{ 'tasker.common.map' | translate }}</h2>
<mat-dialog-content class="large-dialog">
  <locations-map-component [showOnlyUserRelated]="data.showOnlyUserRelated" 
                           [highlightedLocationIDs]="data.highlightedLocationIDs"
                           [centerToPosition]="data.centerToPosition"
                           fxFlex>
  </locations-map-component>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'tasker.common.close' | translate }}</button>
</mat-dialog-actions>