import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface ContactsState {
    pageIndex: number;
    pageSize: number;
    orderBy: string;
    loading: boolean;
    error: string;
    detailError: string;
    report: Honeycomb.Tenant.Reports.IService.Model.Report;
    context: Honeycomb.Tenant.Reports.IService.Model.ReportContext;
    detail: Honeycomb.Tenant.Contact.IService.Contact;
    results: Array<Honeycomb.Tenant.Contact.IService.Contact>;
    contactsByUserID: Array<Honeycomb.Tenant.Contact.IService.Contact>;
}

export const ContactsState = () => {
    return {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        loading: false,
        error: null,
        detailError: null,
        results: null,
        report: null,
        context: null,
        detail: null,
        contactsByUserID: null
    } as ContactsState;
  };
