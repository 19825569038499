  <div *ngIf="!!task && !!task.operation && task.operation.taskType !== taskType.repairRequest" fxLayoutAlign="center center" fxLayout="column" style="padding-left: 15px;" class="task-item-status">
    <span title="{{ 'tasker.task-status.' + getStateName(task) | translate }}">
      <mat-icon *ngIf="getStateNameBase(task) === 'new'" [icIcon]="twotoneCheckBoxOutlineBlank" style="height: 18px; opacity: 0.3;"></mat-icon>
      <mat-icon *ngIf="getStateNameBase(task) === 'inProgress'" [icIcon]="twotoneIndeterminateCheckBox" style="height: 18px; opacity: 0.3;"></mat-icon>
      <mat-icon *ngIf="getStateNameBase(task) === 'finished'" [icIcon]="twotoneCheckBox" style="height: 18px; color: #4CAF50;"></mat-icon>
      <mat-icon *ngIf="getStateNameBase(task) === 'archived'" [icIcon]="twotoneArchive" style="height: 18px; opacity: 0.3;"></mat-icon>
    </span>
    <span class="status-title" [title]="getStateName(task)">
      {{ 'tasker.task-status.' + getStateName(task) | translate }}
    </span>    
  </div>
  <div *ngIf="!!task && !!task.operation && task.operation.taskType === taskType.repairRequest" fxLayoutAlign="center center" fxLayout="column" style="padding-left: 15px;" class="task-item-status">
    <span title="{{ 'tasker.task-status.' + getStateName(task) | translate }}">
      <mat-icon *ngIf="getStateNameBase(task) === 'new'" [icIcon]="twotoneBuildCircle" style="height: 18px; opacity: 0.3;"></mat-icon>
      <mat-icon *ngIf="getStateNameBase(task) === 'inProgress'" [icIcon]="twotoneBuildCircle" style="height: 18px; opacity: 0.3;"></mat-icon>
      <mat-icon *ngIf="getStateNameBase(task) === 'finished'" [icIcon]="twotoneBuildCircle" style="height: 18px; color: #4CAF50;"></mat-icon>
      <mat-icon *ngIf="getStateNameBase(task) === 'archived'" [icIcon]="twotoneArchive" style="height: 18px; opacity: 0.3;"></mat-icon>
    </span>
    <span class="status-title" [title]="getStateName(task)">
      {{ 'tasker.task-status.' + getStateName(task) | translate }}
    </span>    
  </div>