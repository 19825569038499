import { Action, createReducer, on } from '@ngrx/store';
import { isNullOrUndefined } from 'src/app/common/functions';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { RoleAttributeTaskerValueModel } from '../../user/roleAttributeTaskerValue.model';
import * as DashboardTaskActions from './my-tasks.actions';
import MyTasksState, { MyTasksStateIFace } from './my-tasks.state';

const myTasksReducer = createReducer(MyTasksState(),

// tslint:disable: max-line-length
 /*
  on(DashboardTaskActions.loadMyTasksAction, (state) => ({...state, loading: true, error: null, results: []})),
  on(DashboardTaskActions.loadAssignedByMeTasksAction, (state) => ({...state, loading: true, error: null, results: []})),
  on(DashboardTaskActions.loadTasksAction, (state, { filter }) => ({...state, loading: true, error: null, results: [], filter})),
  on(DashboardTaskActions.loadMyTasksCompletedAction, (state, { result }) => ({ ...state, results: result, loading: false, error: null })),
  on(DashboardTaskActions.loadMyTasksFailedAction, (state, { error }) => ({ ...MyTasksState(), error })),
  on(DashboardTaskActions.loadMyTasksSetFilterAction, (state, { filter }) => setMyTasksFilter(state, filter)),
  on(DashboardTaskActions.loadMyTasksSetFilterFinishedAction, (state, { filter }) => ({ ...state, filter})),

  on(DashboardTaskActions.loadMyTasksSetPageIndexAction, (state, { pageIndex, pageSize, agendaKey }) => setPager(state, agendaKey, pageIndex, pageSize)),
*/
  on(DashboardTaskActions.dialogMyTaskOpenAction, (state, { taskID, error, task }) => ({ ...state, detailError: error, selectedTaskID: taskID, detail: task, detailSaved: false})),
  on(DashboardTaskActions.dialogMyTaskCancelAction, (state) => ({ ...state, detailError: null, detailSaved: false})),
  on(DashboardTaskActions.dialogMyTaskSaveAction, (state) => ({ ...state, detailError: null, detailSaved: false})),
  on(DashboardTaskActions.dialogMyTaskClosedAction, (state) => ({ ...state, detailError: null, selectedTaskID: null, detail: null, detailSaved: false})),
  on(DashboardTaskActions.dialogSaveSuccessAction, (state, { taskID }) => ({ ...state, detailError: null, selectedTaskID: taskID, detail: null, detailSaved: true })),
  on(DashboardTaskActions.dialogSaveFailedAction, (state, { detailError }) => ({ ...state, detailError, detailSaved: false})),

  on(DashboardTaskActions.setMyTaskReadAction, (state, { taskID }) => setMyTaskRead(state, taskID)),

  on(DashboardTaskActions.loadMyTaskDetailAction, (state) => ({...state, loading: true, detail: null, detailError: null})),
  on(DashboardTaskActions.loadMyTaskDetailComletedAction, (state, { result }) => ({ ...state, detail: result, loading: false, detailError: null })),
  on(DashboardTaskActions.loadMyTaskDetailFailedAction, (state, { error }) => ({ ...state, detailError: error })),

  on(DashboardTaskActions.taskFilterSetDefaultAction, (state, { filterName,  dashboard}) => setDefaultFilter(state, filterName, dashboard)),
  on(DashboardTaskActions.taskFilterSetFinishedAction, (state) => state),
);

export function reducer(state: MyTasksStateIFace | undefined, action: Action) {
  return myTasksReducer(state, action);
}

// function setPager(state: MyTasksStateIFace, agendaKey: string, pageIndex: number, pageSize?: number) {
//   state.pageIndex.set(agendaKey, pageIndex);

//   if (!isNullOrUndefined(pageSize)) {
//     state.pageSize.set(agendaKey, pageSize);
//   } 
//   const resState = Object.assign({}, state);
//   const resFilter = Object.assign({}, state.filter);

//   resState.filter = resFilter;

//   if (!isNullOrUndefined(pageSize)) {
//     resFilter.offset = pageSize * pageIndex;
//     resFilter.limit = pageSize;
//   }
//   return resState;
// }

function setMyTasksFilter(state: MyTasksStateIFace, filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter) {

  const resState = Object.assign({}, state);
  const resFilter = Object.assign({}, state.filter);

  resState.filter = resFilter;
  resFilter.mainFilter = filter.mainFilter;
  resFilter.jobID = filter.jobID;
  if (!!filter.includeAttributes) {
    resFilter.includeAttributes = filter.includeAttributes.map(a => a);
  } else {
    resFilter.includeAttributes = [];
  }

  if (filter.taskTypes !== undefined) {
    if (filter.taskTypes !== resFilter.taskTypes) {
      resFilter.operations = null; // Reset operations on task types change
    }
    resFilter.taskTypes = filter.taskTypes;
  }

  if (filter.taskRelationAdditional !== undefined) {
    resFilter.taskRelationAdditional = filter.taskRelationAdditional;
  }

  if (filter.taskStatusIDs !== undefined && filter.taskStatusIDs.length > 0) {
    resFilter.taskStatusIDs = filter.taskStatusIDs;
  }

  if (filter.dateFrom !== undefined) {
    resFilter.dateFrom = filter.dateFrom;
  }
  if (filter.dateTo !== undefined) {
    resFilter.dateTo = filter.dateTo;
    this.resFilter.dateTo.setHours(23, 59, 59, 999);
  }

  resFilter.fulltext = filter.fulltext;

  return resState;
}

function setDefaultFilter(state: MyTasksStateIFace, filterName: string, dashboard: RoleAttributeTaskerValueModel) {

  const resState = Object.assign({}, state);

  if (!isNullOrUndefined(filterName)) {
    if (dashboard) {
      const d = Object.assign({}, dashboard);
      resState.taskFilters = d.taskFilters.map(r =>  ({ default: r.default, name: r.name}));
      const filterToSetAsDefault = resState.taskFilters.find(tf => tf.name === filterName);
      if (filterToSetAsDefault) {
        resState.taskFilters.forEach(tf => {
          tf.default = tf.name === filterName;
        });
      }
    }
  }
  return resState;
}

function setMyTaskRead(state: MyTasksStateIFace, taskID: number) {

  const resState = Object.assign({}, state);

  if (!isNullOrUndefined(taskID) && !!resState.results) {
    resState.results = resState.results.map(t => {
      const task = Object.assign({}, t);
      if (task.taskID === taskID && !task.dateRead) {
          task.dateRead = new Date();
      }
      return task;
    });
  }
  return resState;
}
