import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardOperationActions from './pbi.actions';
import { PbiState } from './pbi.state';

const PbiReducer = createReducer(PbiState(),

// tslint:disable: max-line-length
  on(DashboardOperationActions.loadPbiTokenAction, (state) => ({...state, loading: true, error: null, aad: null})),
  on(DashboardOperationActions.loadPbiTokenCompletedAction, (state, { aad }) =>
      ({ ...state, aad, loading: false, error: null })),
  on(DashboardOperationActions.loadPbiTokenFailedAction, (state, { error }) => ({ ...state, error, aad: null, loading: false })),
);

export function reducer(state: PbiState | undefined, action: Action) {
  return PbiReducer(state, action);
}
