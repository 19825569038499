import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

import outlineKeyboardArrowRight from '@iconify/icons-ic/outline-keyboard-arrow-right';
import twotoneCheckBox from '@iconify/icons-ic/twotone-check-box';
import twotoneStore from '@iconify/icons-ic/twotone-store';
import { Subscription } from 'rxjs';
import { isNullOrWhitespace, locationToAddress } from 'src/app/common/functions';
import twotonePhoneForwarded from '@iconify/icons-ic/twotone-phone-forwarded';
import twotonePersonPin from '@iconify/icons-ic/twotone-person-pin';
import { Globals } from 'src/app/common/globals';


@Component({
  selector: 'store-item',
  templateUrl: './store-item.component.html',
  styleUrls: ['./store-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreItemComponent implements OnInit, OnDestroy {
  outlineKeyboardArrowRight = outlineKeyboardArrowRight;
  twotoneCheckBox = twotoneCheckBox;
  twotoneStore = twotoneStore;
  twotonePhoneForwarded = twotonePhoneForwarded;
  twotonePersonPin = twotonePersonPin;

  subscriptions: Array<Subscription> = [];

  uiroles: string[] = [];
  private roleDebug = [];

  @Input()
  store: Honeycomb.Tenant.LookupTables.IService.Model.LocationShort; // Report stores

  @Input()
  storeAdditionalInfo: Honeycomb.Tenant.Tasker.IService.Model.AdditionalLocationInfoItem; // Report stores

  @Output()
  selected: EventEmitter<number> = new EventEmitter();

  constructor(
    private globals: Globals
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  uiRole(suffix: string) {
    const reqUiRole = `tasker.store-item.${suffix}`;

    if (this.roleDebug.findIndex(r => r === reqUiRole) === -1) {
      this.globals.DebugOut('store-item', reqUiRole);
      this.roleDebug.push(reqUiRole);
    }

    return reqUiRole;
  }

  public getRiskValue(location: Honeycomb.Tenant.LookupTables.IService.Model.LocationShort): string {
    if (!location || !location.attributes) { return null; }

    const attr = location.attributes.find(a => a.locationAttributeType.name === 'Risk');
    if (!attr)  { return null; }
    return attr.value;
  }

  openDetail(locationID: number) {
    this.selected.emit(locationID);
  }

  phoneNrValid(phoneNr: string): boolean
  {
    if (isNullOrWhitespace(phoneNr)) {
        return false;
    }
    const phoneRegEx = /^[+]?[()\/0-9. -]{9,15}/;
    return !!phoneNr.match(phoneRegEx);
  }

  getTxtAddress(location: Honeycomb.Tenant.LookupTables.IService.Model.LocationShort) {
    return locationToAddress(location);
  }

  phoneNrClicker($event: MouseEvent) {
      $event.stopPropagation();
      return true;
  }
}
