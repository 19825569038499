<div class="footer" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <!--
    <a class="action"
       color="primary"
       fxFlex="none"
       href="tel:+420 602 575 795"
       id="get-vex"
       mat-raised-button>
      <ic-icon [icon]="icFooter" class="ltr:mr-2 rtl:ml-2" inline="true" size="18px"></ic-icon>
      <span>Kontaktovat support</span>
    </a>
    <div class="ltr:ml-4 rtl:mr-4" fxHide fxShow.gt-sm>
      Dostupný v pracovní dny od 8:00 do 16:00
    </div>
    -->
    <div class="footer-back">
      <a href="javascript:history.back()"><mat-icon [icIcon]="baselineKeyboardArrowLeft" inline="true"></mat-icon>{{ 'tasker.back' | translate }}</a>
    </div>
    <div class="footer-copy" fxFlex="auto">
      Created by <img src="assets/airme-logo.png" alt="airme" style="max-width: 75px; display: inline-block">&nbsp; v.{{ version$ | async }}
      <a href="##release-link##" target="_blank"><mat-icon class="release-notes-icon">info</mat-icon></a>
    </div>
    <div class="footer-pagination">
      <!-- pagination -->
      <a [routerLink]="[ '/' ]">
        <div class="small-tenant-logo"></div>
      </a>
    </div>
  </div>
</div>
