import { KeyValuePair } from './model/keyValuePair.model';
import { format } from 'date-fns';
import { Honeycomb } from '../services/honeycomb-api/honeycomb-api';
import { createSelector } from '@ngrx/store';
export function isJsObject(o) {
    return o !== null && (typeof o === 'function' || typeof o === 'object');
}

/**
 * Converts an object to a parametrised string.
 * @param object
 * @returns {string}
 */
export function objectToParams(object): string {
    if (!object) {
        return '';
    }

    return Object.keys(object).map((key) => isJsObject(object[key]) ?
        subObjectToParams(encodeURIComponent(key), object[key]) :
        `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
    ).join('&');
}

export function isFuckingIE(): boolean {
    return (navigator.appName === 'Microsoft Internet Explorer' ||
        !!(navigator.userAgent.match(/Trident/) ||
            navigator.userAgent.match(/rv:11/)));
}

export function isFuckingEdge(): boolean {
    return (/Edge/.test(navigator.userAgent));
}

export function isFirefox(): boolean {
    return /firefox/i.test(navigator.userAgent);
}

/**
 * Converts a sub-object to a parametrised string.
 * @param object
 * @returns {string}
 */
function subObjectToParams(key, object): string {
    if (!object) {
        return '';
    }

    return Object.keys(object).map((childKey) => isJsObject(object[childKey]) ?
        subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]) :
        `${key}.${encodeURIComponent(childKey)}=${encodeURIComponent(object[childKey])}`
    ).join('&');
}

export function toNetTime(parameter: string): string {
    return '\/Date(' + new Date(parameter).getTime() + ')\/';
}

export function toNetParameterDate(parameter: Date): string {
    return parameter.getFullYear() + '-' + (parameter.getMonth() + 1) + '-' + parameter.getDate();
}

export function toNetParameterDateTime(parameter: Date): string {
    return `${parameter.getFullYear()}-${padLeft( parameter.getMonth() + 1, 2, '0')}-${padLeft(parameter.getDate(), 2, '0')}T${padLeft(parameter.getHours(), 2, '0')}:${padLeft(parameter.getMinutes(), 2, '0')}:${padLeft(parameter.getSeconds(), 2, '0')}`;
}

export function toLocalTime(d: Date): string {
    return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear() + ' ' +
        padLeft(d.getHours(), 2) + ':' + padLeft(d.getMinutes(), 2) + ':' + padLeft(d.getSeconds(), 2) + ' (CET)';
}

/** Because FF cannot parse stardard ISO format '1990-01-01 10:00:00.00 +02:00' */
export function getDateFromString(dStr: string): Date {
    return new Date(dStr.replace(' ', 'T').replace(' ', ''));
}

export function isNullOrUndefined(value) {
    return value === null || value === undefined;
}

export function getStringFromDate(d: Date, langISO: String = 'cs'): string {
    if (d === null || d === undefined) {
        return '';
    }

    if (typeof (d) === 'string') {
        d = new Date(d);
    }

    switch (langISO) {
        case 'cs':
        default:
            return format(d, 'dd.MM.yyyy');
    }
}

Object.defineProperty(Object.prototype, 'getProp', {
    value: function (prop) {
        let key, self = this;
        for (key in self) {
            if (key.toLowerCase() === prop.toLowerCase()) {
                return self[key];
            }
        }
    },
    // this keeps jquery happy
    enumerable: false
});


export function padLeft(input: number, length: number, str: string = null) {
    return Array(length - String(input).length + 1).join(str || '0') + input;
}

export function createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // tslint:disable-next-line:no-bitwise
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function compareBitwise(model: number, value: number): boolean {
    // tslint:disable-next-line:no-bitwise
    return (model & value) > 0;
}

export function moveInArray(input_array: Array<any>, old_index, new_index) {
    if (new_index >= input_array.length) {
        let k = new_index - input_array.length;
        while ((k--) + 1) {
            input_array.push(undefined);
        }
    }
    input_array.splice(new_index, 0, input_array.splice(old_index, 1)[0]);
    return input_array; // for testing purposes
}

export function trimChar(str: string, charToRemove: string) {
    while (str.charAt(0) === charToRemove) {
        str = str.substring(1);
    }
    while (str.charAt(str.length - 1) === charToRemove) {
        str = str.substring(0, str.length - 1);
    }
    return str;
}

export function copyToClipboard(text: string, success: () => void, error: () => void) {
    let textArea = document.createElement('textarea');

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.select();

    try {
        let successful = document.execCommand('copy');
        if (successful) {
            success();
        } else {
            error();
        }
    } catch (err) {
        error();
    }

    document.body.removeChild(textArea);
}

/**
 * https://github.com/danguer/blog-examples/blob/master/js/base64-binary.js
 */
export const Base64Binary = {
    _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

    /* will return a  Uint8Array type */
    decodeArrayBuffer: function (input) {
        let bytes = (input.length / 4) * 3;
        let ab = new ArrayBuffer(bytes);
        this.decode(input, ab);
        return ab;
    },

    removePaddingChars: function (input) {
        let lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
        if (lkey === 64) {
            return input.substring(0, input.length - 1);
        }
        return input;
    },

    decode: function (input, arrayBuffer) {
        // get last chars to see if are valid
        input = this.removePaddingChars(input);
        input = this.removePaddingChars(input);
        let bytes = parseInt(((input.length / 4) * 3) + '', 10);

        let uarray;
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;
        let j = 0;

        if (arrayBuffer) {
            uarray = new Uint8Array(arrayBuffer);
        } else {
            uarray = new Uint8Array(bytes);
        }

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

        for (i = 0; i < bytes; i += 3) {
            // get the 3 octects in 4 ascii chars
            enc1 = this._keyStr.indexOf(input.charAt(j++));
            enc2 = this._keyStr.indexOf(input.charAt(j++));
            enc3 = this._keyStr.indexOf(input.charAt(j++));
            enc4 = this._keyStr.indexOf(input.charAt(j++));

            // tslint:disable:no-bitwise
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            uarray[i] = chr1;
            if (enc3 !== 64) { uarray[i + 1] = chr2; }
            if (enc4 !== 64) { uarray[i + 2] = chr3; }
        }
        return uarray;
    }
};

/**
 * Number.prototype.format(n, x, s, c)
 * @param numeric input: input number
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export function formatMoney(input: number, symbol = '', n = 2, x = 3, s = ' ', c = ','): string {

    if (isNullOrUndefined(input)) { return ''; }

    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = input.toFixed(Math.max(0, ~~n));

    if (!symbol) { symbol = ''; }

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ',')) + (symbol.length > 0 ? (' ' + symbol) : '');
}


export function enumToHashMap(enumType): Map<string, any> {
    const types = Object.keys(enumType);
    const result = new Map<string, any>();
    const typeNames = types.slice(types.length / 2);
    typeNames.forEach(n => {
        result.set(n, enumType[n]);
    });

    return result;
}

export function enumToValues(enumType): Array<any> {
    const types = Object.values(enumType);
    const result = types.slice(types.length / 2);
    return result;
}

export function enumToStrings(enumType): Array<string> {
    const types = Object.keys(enumType);
    const result = types.slice(types.length / 2);
    return result;
}


export function isNullOrWhitespace(input: string): boolean {
    if (isNullOrUndefined(input)) { return true; }
    if (typeof input !== 'string') { return false; }
    input = input.replace(' ', '');
    return input.length === 0;
}

export function stripHtmlFromTags(htmlString = ''): string {
    if (isNullOrWhitespace(htmlString)) {
        return htmlString;
    }
    return htmlString.replace(/<(?:.|\n)*?>/gm, '');
}

export function insertAtCursor(myField, myValue) {
    // IE support
    if ((<any>document).selection) {
        myField.focus();
        let sel = (<any>document).selection.createRange();
        sel.text = myValue;
        // Microsoft Edge
    } else if (window.navigator.userAgent.indexOf('Edge') > -1) {
        let startPos = myField.selectionStart;
        let endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos) + myValue
            + myField.value.substring(endPos, myField.value.length);
        let pos = startPos + myValue.length;
        myField.focus();
        myField.setSelectionRange(pos, pos);
        // MOZILLA and others
    } else if (myField.selectionStart || myField.selectionStart === '0') {
        let startPos = myField.selectionStart;
        let endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
    } else {
        myField.value += myValue;
    }
}

export function toKeyValuePairs(data: any): Array<KeyValuePair> {
    let keyValuePairs = [];
    if (data) {
        keyValuePairs = Object.keys(data).map(key => <KeyValuePair>{
            [key]: data[key]
        });
    }

    return keyValuePairs;
}


var diacriticsMap = {
    // tslint:disable: object-literal-key-quotes
    '\u00C0': 'A',  // À => A
    '\u00C1': 'A',   // Á => A
    '\u00C2': 'A',   // Â => A
    '\u00C3': 'A',   // Ã => A
    '\u00C4': 'A',   // Ä => A
    '\u00C5': 'A',   // Å => A
    '\u00C6': 'AE', // Æ => AE
    '\u00C7': 'C',   // Ç => C
    '\u00C8': 'E',   // È => E
    '\u00C9': 'E',   // É => E
    '\u00CA': 'E',   // Ê => E
    '\u00CB': 'E',   // Ë => E
    '\u00CC': 'I',   // Ì => I
    '\u00CD': 'I',   // Í => I
    '\u00CE': 'I',   // Î => I
    '\u00CF': 'I',   // Ï => I
    '\u0132': 'IJ', // Ĳ => IJ
    '\u00D0': 'D',   // Ð => D
    '\u00D1': 'N',   // Ñ => N
    '\u00D2': 'O',   // Ò => O
    '\u00D3': 'O',   // Ó => O
    '\u00D4': 'O',   // Ô => O
    '\u00D5': 'O',   // Õ => O
    '\u00D6': 'O',   // Ö => O
    '\u00D8': 'O',   // Ø => O
    '\u0152': 'OE', // Œ => OE
    '\u00DE': 'TH', // Þ => TH
    '\u00D9': 'U',   // Ù => U
    '\u00DA': 'U',   // Ú => U
    '\u00DB': 'U',   // Û => U
    '\u00DC': 'U',   // Ü => U
    '\u00DD': 'Y',   // Ý => Y
    '\u0178': 'Y',   // Ÿ => Y
    '\u00E0': 'a',   // à => a
    '\u00E1': 'a',   // á => a
    '\u00E2': 'a',   // â => a
    '\u00E3': 'a',   // ã => a
    '\u00E4': 'a',   // ä => a
    '\u00E5': 'a',   // å => a
    '\u00E6': 'ae', // æ => ae
    '\u00E7': 'c',   // ç => c
    '\u00E8': 'e',   // è => e
    '\u00E9': 'e',   // é => e
    '\u00EA': 'e',   // ê => e
    '\u00EB': 'e',   // ë => e
    '\u00EC': 'i',   // ì => i
    '\u00ED': 'i',   // í => i
    '\u00EE': 'i',   // î => i
    '\u00EF': 'i',   // ï => i
    '\u0133': 'ij', // ĳ => ij
    '\u00F0': 'd',   // ð => d
    '\u00F1': 'n',   // ñ => n
    '\u00F2': 'o',   // ò => o
    '\u00F3': 'o',   // ó => o
    '\u00F4': 'o',   // ô => o
    '\u00F5': 'o',   // õ => o
    '\u00F6': 'o',   // ö => o
    '\u00F8': 'o',   // ø => o
    '\u0153': 'oe', // œ => oe
    '\u00DF': 'ss', // ß => ss
    '\u00FE': 'th', // þ => th
    '\u00F9': 'u',   // ù => u
    '\u00FA': 'u',   // ú => u
    '\u00FB': 'u',   // û => u
    '\u00FC': 'u',   // ü => u
    '\u00FD': 'y',   // ý => y
    '\u00FF': 'y',   // ÿ => y
    '\uFB00': 'ff', // ﬀ => ff
    '\uFB01': 'fi',   // ﬁ => fi
    '\uFB02': 'fl', // ﬂ => fl
    '\uFB03': 'ffi',  // ﬃ => ffi
    '\uFB04': 'ffl',  // ﬄ => ffl
    '\uFB05': 'ft', // ﬅ => ft
    '\uFB06': 'st',  // ﬆ => st
    'ř': 'r',
    'Ř': 'r',
    'č': 'c',
    'Č': 'C',
    'ž': 'z',
    'Ž': 'Z'
};
// tslint:enable: object-literal-key-quotes
export function replaceDiacritics(str) {
    let returnStr = '';
    if (str) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < str.length; i++) {
            if (diacriticsMap[str[i]]) {
                returnStr += diacriticsMap[str[i]];
            } else {
                returnStr += str[i];
            }
        }
    }
    return returnStr;
}


// tslint:disable-next-line: no-unused-expression
(String.prototype as any).replaceDiacritics = function (str) { return replaceDiacritics(str) };

/*
 * Method for JSON.stringify() that removes functions and circular references
 */
export function circularRefReplacer(key: string, value) {
    const cache = new Set();

    if (typeof value === 'function') {
        return;
    }

    if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
            // Circular reference found, discard key
            return;
        }
        // Store value in our set
        cache.add(value);
    }
    return value;
}


export function deepCopy(obj) {
    if (obj === null || obj === undefined) { return obj; }
    return Object.keys(obj).reduce((v, d) => Object.assign(v, {
        // JSO added condition to obj[d] exists, it happened that it was null and then it creashed
        [d]: (obj[d] && obj[d].constructor === Object) ? deepCopy(obj[d]) : obj[d]
    }), {});
}

export function isPromise(p) {
    return p && Object.prototype.toString.call(p) === '[object Promise]';
}

export function distinct(inputArray: Array<string>): Array<string> {
    const unique = [];
    const res = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < inputArray.length; i++) {
        if (!unique[inputArray[i]]) {
            res.push(inputArray[i]);
            unique[inputArray[i]] = 1;
        }
    }
    return res;
}

export function distinctNum(inputArray: Array<number>): Array<number> {
    const unique = [];
    const res = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < inputArray.length; i++) {
        if (!unique[inputArray[i]]) {
            res.push(inputArray[i]);
            unique[inputArray[i]] = 1;
        }
    }
    return res;
}

export function locationToAddress(location: Honeycomb.Tenant.LookupTables.IService.Model.LocationShort) {
    let sb = '';
    if (!isNullOrWhitespace(location.street)) {
        sb += location.street;
    }
    if (!isNullOrWhitespace(location.streetNumber)) {
        if (sb.length > 0) {
            sb += ' ';
        }
        sb += location.streetNumber;
    }
    if (!isNullOrWhitespace(location.cityPart)) {
        if (sb.length > 0) {
            sb += ', ';
        }
        sb += location.cityPart;
    }
    if (!isNullOrWhitespace(location.zipCode)) {
        if (sb.length > 0) {
            sb += ', ';
        }
        sb += location.zipCode;
    }
    if (!isNullOrWhitespace(location.city)) {
        if (isNullOrWhitespace(location.zipCode)) {
            sb += ',';
        }
        sb += (' ' + location.city);
    }
    return sb;
}

export function zoom(imageUrl: string, closeButton: string, deleteButton?: string, deleteCallback?: (event) => void) {
    const cloak = document.createElement('div');
    cloak.classList.add('pictureView');

    const closeBtn = document.createElement('div');
    closeBtn.classList.add('closeButton');
    closeBtn.innerText = closeButton; // this.translate.instant('tasker.task-activities.image-close-btn');
    closeBtn.onclick = () => document.body.removeChild(cloak);

    if (!!deleteCallback) {
        const deleteBtn = document.createElement('div');
        deleteBtn.classList.add('deleteButton');
        deleteBtn.innerText = deleteButton; // this.translate.instant('tasker.common.delete');
        deleteBtn.onclick = (evt) => {
            deleteCallback(evt);
            document.body.removeChild(cloak);
        };
        cloak.appendChild(deleteBtn);
    }

    const picContainer = document.createElement('div');
    picContainer.classList.add('pictureContainer');
    picContainer.style.backgroundImage = 'url(\'' + imageUrl + '\')';
    cloak.appendChild(closeBtn);
    cloak.appendChild(picContainer);
    // cloak.onclick = () => document.body.removeChild(cloak);
    document.body.appendChild(cloak);
}

export function addZoomButton(imageContainer: HTMLDivElement, img: HTMLImageElement) {

    const maxBtn = document.createElement('div');
    const minBtn = document.createElement('div');

    maxBtn.classList.add('max-btn');
    imageContainer.appendChild(maxBtn);

    minBtn.classList.add('min-btn');
    imageContainer.appendChild(minBtn);

    maxBtn.onclick = ($event) => {
        img.style.maxWidth = 'none';
        img.style.maxHeight = 'none';
        imageContainer.style.overflow = 'scroll';
        maxBtn.style.display = 'none';
        minBtn.style.display = 'block';
        $event.stopPropagation();
        $event.cancelBubble = true;
        $event.preventDefault();
    };

    minBtn.onclick = ($event) => {
        img.style.maxWidth = '100%';
        img.style.maxHeight = '100%';
        imageContainer.style.overflow = 'unset';
        maxBtn.style.display = 'block';
        minBtn.style.display = 'none';
        $event.stopPropagation();
        $event.cancelBubble = true;
        $event.preventDefault();
    };
}

export function zoomScrollable(imageUrl: string, closeButton: string, deleteButton?: string, deleteCallback?: (event) => void) {
    const cloak = document.createElement('div');
    cloak.classList.add('pictureView');

    const closeBtn = document.createElement('div');
    closeBtn.classList.add('closeButton');
    closeBtn.innerText = closeButton; // this.translate.instant('tasker.task-activities.image-close-btn');
    closeBtn.onclick = () => document.body.removeChild(cloak);

    if (!!deleteCallback) {
        const deleteBtn = document.createElement('div');
        deleteBtn.classList.add('deleteButton');
        deleteBtn.innerText = deleteButton; // this.translate.instant('tasker.common.delete');
        deleteBtn.onclick = (evt) => {
            deleteCallback(evt);
            document.body.removeChild(cloak);
        };
        cloak.appendChild(deleteBtn);
    }

    const imageContainer = document.createElement('div');
    imageContainer.classList.add('imageContainer');

    const img = document.createElement('img');
    imageContainer.appendChild(img);

    addZoomButton(imageContainer, img);

    img.src = imageUrl;
    cloak.appendChild(closeBtn);
    cloak.appendChild(imageContainer);
    // cloak.onclick = () => document.body.removeChild(cloak);
    document.body.appendChild(cloak);
}

export function closeZoom() {
    const cloakElement = document.querySelector('.pictureView');
    if (!!cloakElement) {
        cloakElement.remove();
    }
}

export function calculateDistance(position1: { lat: number, lon: number }, position2: { lat: number, lon: number }) {
    const R = 6371e3; // metres
    const φ1 = position1.lat * Math.PI / 180; // φ, λ in radians
    const φ2 = position2.lat * Math.PI / 180;
    const Δφ = (position2.lat - position1.lat) * Math.PI / 180;
    const Δλ = (position2.lon - position1.lon) * Math.PI / 180;
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // in metres
    return d;
}

