import { createAction, props } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { RoleAttributeTaskerValueModel } from '../../user/roleAttributeTaskerValue.model';

export enum TasksActionTypes {
  LoadMyTasks = '[Tasks] Load My Tasks',
  LoadAssignedByMe = '[Tasks] Load Assigned by me Tasks',
  LoadTasks = '[Tasks] Set filter, Load Tasks',
  LoadMyTasksCompleted = '[Tasks] Loaded My Tasks Completed',
  LoadMyTasksFailed = '[Tasks] Loaded My Tasks Failed',
  LoadMyTasksSetFilter = '[Tasks] Filter set',
  LoadMyTasksSetFilterFinished = '[Tasks] Filter set finished',

  LoadMyTasksSetPageTableIndex = '[Tasks] Page index table set',
  LoadMyTasksSetPageTableIndexFinished = '[Tasks] Page index table set finished',

  LoadMyTasksSetPageListIndex = '[Tasks] Page index list set',
  LoadMyTasksSetPageListIndexFinished = '[Tasks] Page index list set finished',

  LoadMyTasksSetPageIndex = '[Tasks] Page index for agenda',
  LoadMyTasksSetPageIndexFinished = '[Tasks] Page index for agenda set finished',

  OpenMyTaskDetailDialog = '[Tasks] Open Detail Dialog',
  CancelMyTaskDetailDialog = '[Tasks] Cancel Detail Dialog',
  SaveMyTaskDetailDialog = '[Tasks] Save Detail Dialog',
  SaveMyTaskDetailDialogFailed = '[Tasks] Save Detail Dialog Failed',
  SaveMyTaskDetailDialogSuccess = '[Tasks] Save Detail Dialog Success',
  MyTaskDialogClosed = '[Tasks] My tasks dialog closed',

  LoadMyTaskDetail = '[Task] Load detail',
  LoadMyTaskDetailComleted = '[Task] Load detail completed',
  LoadMyTaskDetailFailed = '[Task] Load detail failed',
  SetMyTaskRead = '[Task] Set task read',

  TaskFilterSetDefault = '[Task] Default Filter Selected',
  TaskFilterSetFinished = '[Task] Default Filter Finished',
}

// tslint:disable: max-line-length
//export const loadMyTasksAction = createAction(TasksActionTypes.LoadMyTasks);

//export const loadAssignedByMeTasksAction = createAction(TasksActionTypes.LoadAssignedByMe);

//export const loadTasksAction = createAction(TasksActionTypes.LoadTasks, props<{ filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter}>());

//export const loadMyTasksCompletedAction = createAction(TasksActionTypes.LoadMyTasksCompleted, props<{result: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>}>());

//export const loadMyTasksFailedAction = createAction(TasksActionTypes.LoadMyTasksFailed, props<{error: string}>());

//export const loadMyTasksSetFilterAction = createAction(TasksActionTypes.LoadMyTasksSetFilter, props<{filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter}>());

//export const loadMyTasksSetFilterFinishedAction = createAction(TasksActionTypes.LoadMyTasksSetFilterFinished, props<{filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter}>());

//export const loadMyTasksSetPageIndexAction = createAction(TasksActionTypes.LoadMyTasksSetPageIndex, props<{agendaKey: string, pageIndex: number, pageSize?: number}>());

//export const loadMyTasksSetPageIndexFinishedAction = createAction(TasksActionTypes.LoadMyTasksSetPageIndexFinished, props<{pageIndex: number, pageSize: number, agendaKey: string}>());


export const dialogMyTaskOpenAction = createAction(TasksActionTypes.OpenMyTaskDetailDialog, props<{ taskID?: number, error?: string, task?: Honeycomb.Tenant.Tasker.IService.Model.Task }>());

export const dialogMyTaskCancelAction = createAction(TasksActionTypes.CancelMyTaskDetailDialog);

export const dialogMyTaskSaveAction = createAction(TasksActionTypes.SaveMyTaskDetailDialog, props<{task: Honeycomb.Tenant.Tasker.IService.Model.TaskUpsert}>());

export const dialogSaveSuccessAction = createAction(TasksActionTypes.SaveMyTaskDetailDialogSuccess, props<{taskID: number}>());

export const dialogMyTaskClosedAction = createAction(TasksActionTypes.MyTaskDialogClosed);

export const dialogSaveFailedAction = createAction(TasksActionTypes.SaveMyTaskDetailDialogFailed, props<{detailError: string}>());

// Detail load
export const loadMyTaskDetailAction = createAction(TasksActionTypes.LoadMyTaskDetail, props<{ taskID: number }>());

export const loadMyTaskDetailComletedAction = createAction(TasksActionTypes.LoadMyTaskDetailComleted, props<{result: Honeycomb.Tenant.Tasker.IService.Model.Task}>());

export const loadMyTaskDetailFailedAction = createAction(TasksActionTypes.LoadMyTaskDetailFailed, props<{error: string}>());

export const setMyTaskReadAction = createAction(TasksActionTypes.SetMyTaskRead, props<{taskID: number}>());


export const taskFilterSetDefaultAction = createAction(TasksActionTypes.TaskFilterSetDefault, props<{ filterName: string, dashboard: RoleAttributeTaskerValueModel}>());

export const taskFilterSetFinishedAction = createAction(TasksActionTypes.TaskFilterSetFinished);



