import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardOperationActions from './operations.actions';
import { OperationsState } from './operations.state';

const OperationsReducer = createReducer(OperationsState(),

// tslint:disable: max-line-length
  on(DashboardOperationActions.loadOperationsAction, (state) => ({...state, loading: true, error: null})),
  on(DashboardOperationActions.loadOperationsCompletedAction, (state, { result }) =>
      ({ ...state, results: result, loading: false, error: null })),
  on(DashboardOperationActions.loadOperationsFailedAction, (state, { error }) => ({ ...OperationsState(), error })),
);

export function reducer(state: OperationsState | undefined, action: Action) {
  return OperationsReducer(state, action);
}
