import { Component, OnInit, Inject, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { of, pipe, Observable, Subscription } from 'rxjs';
import icSearch from '@iconify/icons-ic/twotone-search';
import { StyleService, Style } from 'src/@vex/services/style.service';
import { FormControl } from '@angular/forms';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { debounceTime, distinctUntilChanged, tap, map, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MyStoreDetailComponent } from '../my-store-detail/my-store-detail.component';
import { LocationsMapDialogComponent } from '../../common/locations-map/locations-map-dialog.component';
import { BIDashboardSection, DashboardSection } from 'src/app/api/user/roleAttributeTaskerValue.model';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'src/app/common/functions';
import { AppState } from 'src/app/app.states';
import { select, Store } from '@ngrx/store';
import { selectLocations } from 'src/app/api/user/user.selectors';
import * as pbi from 'powerbi-client';
import { loadPbiTokenAction } from 'src/app/api/pbi/pbi.actions';
import { ConfigService } from 'src/@vex/services/config.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'stores-main',
  templateUrl: './stores-main.component.html',
  styleUrls: ['./stores-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoresMainComponent implements OnInit, OnDestroy {
  dashboardSections$: Observable<Array<DashboardSection>>;
  dashboardSections: Array<DashboardSection>;
  pbiSections: Array<DashboardSection> = [];
  pbiToken$: Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.AAD>;
  pbiToken: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD;
  jobLocationsIDs$: Observable<Array<number>>;
  jobLocationsIDs: Array<number>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  jobID: number;

  subscriptions: Array<Subscription> = [];
  private embed: pbi.Embed;
  private powerbi: pbi.service.Service;
  reportName = '';


  icSearch = icSearch;

  tableData$ = of([]);
  data = [];
  searchCtrl = new FormControl();
  activeOnly = new FormControl(true);

  offset = 0;
  limit = 10000;
  itemsTotal: number = 2000;
  pageSize: number = 20;
  pageIndex: number = 0;
  loading = true;
  searchString = '';
  activeOnlyChecked = true;
  loading$: Observable<boolean>;
  locAddInfo = {};
  storesAdditionalInfo: Honeycomb.Tenant.Tasker.IService.Model.AdditionalLocationInfo;
  allLocations: any;

  scrollElement = document.getElementsByClassName('sidenav-content')[0];

  @Input()
  showReport = false;
  @Input()
  title: string;
  @Input()
  showAdditionalInfo = false;
  @Input()
  showOnlyUserRelated = false;
  @Input()
  showMyStoreDetail = false;
  @Input()
  showMap = false;


  constructor(
    private store: Store<AppState>,
    private styleService: StyleService,
    private dialog: MatDialog,
    private config: ConfigService,
    private cd: ChangeDetectorRef,
    @Inject('TaskerLocationController') private taskerLocationController:  Honeycomb.Tenant.Tasker.IService.Controller.LocationController,
    @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
    @Inject('PBIController') private pbiController: Honeycomb.Tenant.Reports.IService.PBIController,
    private router: Router
  ) {
    this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    this.styleService.setStyle(Style.stores);
    this.scrollElement.addEventListener('scroll', this.scrolled.bind(this));
    this.jobLocationsIDs$ = this.store.pipe(map(state => selectLocations(state.user)));
    const jobSubs = this.jobLocationsIDs$.subscribe(j => {
      this.jobLocationsIDs = j;
    });

    this.subscriptions.push(jobSubs);

    const userSub = this.store.pipe(select(s => s.user)).subscribe(u => {
      if (!!u && !!u.currentUser) {
        this.jobID = u.selectedJobID;
      }
    });
    this.subscriptions.push(userSub);


    this.dashboardSections$ = store.pipe(
      filter(x => !x.user.loading && !!x.user.dashboard),
      select(x => x.user.dashboard),
      map(d => {
        if (!d) {
          return [];
        }
        return d.sections;
      }),
      tap(sections => {
        this.pbiSections = sections.filter(s => s.type === 1);
        if (this.pbiSections.length > 0) { // If any PBI exists
          this.store.dispatch(loadPbiTokenAction()); // Ask for data for tiles
        }
      })
    );
    this.dashboardSections$.subscribe(s => this.dashboardSections = s);

    this.pbiToken$ = store.pipe(
      filter(x => !!x.pbi && !!x.pbi.aad),
      select(x => x.pbi),
      map(p => p.aad)
    );
    const pbiTokenSubscription = this.pbiToken$.subscribe(token => {
      if (this.pbiToken == null
      // || token.access_token !== this.pbiToken.access_token
      ) { // Hack, called twice for unknown reason
        this.pbiToken = token;
        this.setPbiElements(token);
      }
    });
    this.subscriptions.push(pbiTokenSubscription);
  }
  ngOnDestroy(): void {
    this.scrollElement.removeEventListener('scroll', this.scrolled);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async ngOnInit() {
    this.searchCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      tap(s => this.searchString = s)
    ).subscribe(value => this.onFilterChange(value, this.activeOnlyChecked));

    this.activeOnly.valueChanges.pipe(
      distinctUntilChanged(),
      tap(s => this.activeOnlyChecked = s)
    ).subscribe(value => this.onFilterChange(this.searchString, value));

    this.onFilterChange(this.searchString, this.activeOnlyChecked);

    /*this.paginator.disabled = true;
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
    this.paginator.nextPage(); // - otherwise 1st is selected and page index is ignored  
    this.paginator.previousPage(); // the only way how to light up correct button in paginator
    this.paginator.disabled = false;*/
  }

  openDetail(locationID: number) {
    if (this.showMyStoreDetail) {
      this.dialog.open(MyStoreDetailComponent, {
        data: { locationID, permission: 'my-store' }
      });

    } else {
      this.dialog.open(MyStoreDetailComponent, {
        data: { locationID, permission: 'store' }
      });
    }
  }

  async openMapDialog() {
    const locIds = this.data.map( l => l.locationID );
    const dialogRef = this.dialog.open(LocationsMapDialogComponent, {data: {showOnlyUserRelated: this.showOnlyUserRelated,
                                                                            filteredLocationIDs: locIds}});
    await dialogRef.afterClosed().toPromise();
  }

  async onFilterChange(value: string, activeOnly: boolean) {
    value = value.trim();
    value = value.toLowerCase();
    this.data = [];
    this.loading = true;
    this.cd.detectChanges();
    this.offset = 0;

    this.allLocations = await this.taskerLocationController
      .Filter(this.offset, this.limit, activeOnly, null, null, this.jobID, this.showOnlyUserRelated, value ).toPromise()
      .catch(l => [] as Array<Honeycomb.Tenant.Contact.IService.UserElastic>);
    this.itemsTotal = this.allLocations.length;
    let locations = this.allLocations.slice(this.pageIndex * this.pageSize, (this.pageIndex + 1) * this.pageSize);

    if (this.showAdditionalInfo) {
      this.storesAdditionalInfo = await this.taskController.GetAdditionalLocationInfo().toPromise();
      this.populateAdditionalInfo(locations);
    }

    this.data = locations;
    this.cd.detectChanges();
    this.loading = false;
    this.cd.detectChanges();
  }

  setPbiElements(token: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD) {
    if (!!this.dashboardSections) {
      this.pbiSections.forEach(section => {
        const s = section as BIDashboardSection;
        this.reportName = s.bIReportName;
        const report = this.pbiController.GetReport(this.reportName).subscribe(r => {
          const element = document.querySelector('[data-report-name="' + this.reportName + '"]') as HTMLElement;
          if (element) {
            element.removeAttribute('powerbi-access-token');
            const embed = this.powerbi.embed(element, this.getPBIConfig(r, token));
            embed.reload();
            embed.on('loaded', e => {
              const bookmarks = {};
              (embed as any).bookmarksManager.getBookmarks().then(
                (s: any[]) => {
                  s.forEach(b => {
                    bookmarks[b.name] = b.displayName;
                  });
                },
                (f: any) => console.error(f)
              );
              embed.on('buttonClicked', (event) => {
                const bm = bookmarks[(event.detail as any).bookmark];
                this.router.navigate(['/reporting/detail', bm]);
              });
            });
          }
        });
      });
    }
  }

  async handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let locations = this.allLocations.slice(this.pageIndex * this.pageSize, (this.pageIndex + 1) * this.pageSize);

    if (this.showAdditionalInfo) {
      this.populateAdditionalInfo(locations);
    }

    this.data = locations;
    this.cd.detectChanges();
    this.loading = false;
    this.cd.detectChanges();
  }

  private getPBIConfig(
    report: Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport,
    token: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD) {
    return {
      type: 'report',
      id: report.reportID,
      viewMode: pbi.models.ViewMode.View,
      tokenType: pbi.models.TokenType.Aad,
      permissions: pbi.models.Permissions.All,
      pageView: 'oneColumn',
      filters: this.getFilter(),
      accessToken: token.access_token,
      embedUrl: report.report.embedUrl + '&language=' + this.config.getLanguage(), // cs for now
      settings: {
        navContentPaneEnabled: false,
        filterPaneEnabled: false,
        layoutType: pbi.models.LayoutType.Custom,
        hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent
      },
      pageName: 'DefaultPage'
    } as pbi.IEmbedConfiguration;
  }

  populateAdditionalInfo(locations: any) {
    this.locAddInfo = {};
    locations.forEach(l => {
        var addInfo = this.storesAdditionalInfo.items.find(i => i.locationID === l.locationID);
        if (addInfo) {
            this.locAddInfo[l.locationID] = addInfo;
        }
    });
  }

  getFilter(): any[] {

    if (isNullOrUndefined(this.jobLocationsIDs) || this.jobLocationsIDs.length === 0) {
      return null;
    }

    const locationFilter = {
      filterType: 1,
      target: {
        column: 'LookupLocationID',
        table: 'Filiálky'
      },
      operator: 'In',
      values: this.jobLocationsIDs,
    };
    return [locationFilter];
  }

  searchFocus($event: FocusEvent) {
    const appBar = ($event.target as HTMLElement).closest('.bg-app-bar');
    appBar.classList.add('input-focused');
  }

  searchBlur($event: FocusEvent) {
    const appBar = ($event.target as HTMLElement).closest('.bg-app-bar');
    appBar.classList.remove('input-focused');
  }

  scrolled(event) {
    if (this.loading) {
      return;
    }

    // lazy loading disabled
    return;

    const container = event.target;

    if (container.scrollTop + container.offsetHeight >= container.scrollHeight) {
      this.loading = true;
      this.taskerLocationController.Filter(this.pageSize /* as offset */, 10,
        this.activeOnlyChecked, null, null, null, this.showOnlyUserRelated, this.searchString)
      .pipe(tap(l => {
        this.pageSize += 10;
        this.data.push(...l);
        // this.tableData$ = of(this.data);
        this.loading = false;
        // this.cd.detectChanges();
      })).subscribe(_ => null);
    this.cd.detectChanges();
    }
  }
}
