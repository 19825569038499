import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { DefaultTaskFilter } from '../../user/roleAttributeTaskerValue.model';

export interface MyTasksResult {
    results: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>;
}

export interface MyTasksStateIFace {
    pageIndex: Map<string, number>; // Agenda key - page index
    pageSize: Map<string, number>; // Agenda key - page size
    agenda: TaskAgenda;
    orderBy: string;
    loading: boolean;
    error: string;
    results: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>;
    filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter;
    taskFilters: DefaultTaskFilter[];

    detail: Honeycomb.Tenant.Tasker.IService.Model.Task;
    detailError: string;
    detailLoading: boolean;
    detailSaving: boolean;
    detailSaved: boolean;
    selectedTaskID: number;
}

export enum TaskAgenda {
  MyTasks = 1,
  TasksByMe = 2,
  Dashboard = 3,
  Overview = 4,
  Repairs = 5,
  MyStoreDetailTasks = 6,
  Calendar = 7
}

const MyTasksState = () => {
    let res = {
        pageIndex: new Map<string, number>(),
        pageSize: new Map<string, number>(),
        agenda: TaskAgenda.MyTasks,
        orderBy: '',
        loading: false,
        error: null,
        detailError: null,
        detailLoading: false,
        detailSaving: false,
        detailSaved: false,
        results: null,
        filter: {
          userTaskRelations: null,
          taskRelationAdditional: [],
          sortParams: [],
          dateFrom: null,
          dateTo: null,
          fulltext: null,
          includeAttributes: [],
          jobID: null,
          limit: null,
          locationID: null,
          locations: null,
          mainFilter: Honeycomb.Common.Enums.TaskMainFilter.all,
          offset: null,
          operations: null,
          priorities: [],
          priority: null,
          taskRead: null,
          taskState: null,
          taskStatusIDs: [],
          taskSequenceID: null,
          baseTaskStatuses: [],
          taskTypes: []
        } as Honeycomb.Tenant.Tasker.IService.TaskListFilter,
        taskFilters: [],
        detail: null,
        selectedTaskID: null
    } as MyTasksStateIFace;

    res.pageIndex.set('tasks-main-list-' + TaskAgenda.MyTasks, 0);
    res.pageSize.set('tasks-main-list-' + TaskAgenda.MyTasks, 10);

    res.pageIndex.set('tasks-main-list-' + TaskAgenda.Repairs, 0);
    res.pageSize.set('tasks-main-list-' + TaskAgenda.Repairs, 10);

    res.pageIndex.set('tasks-main-table-' + TaskAgenda.MyTasks, 0);
    res.pageSize.set('tasks-main-table-' + TaskAgenda.MyTasks, 10);

    res.pageIndex.set('tasks-main-table-' + TaskAgenda.Repairs, 0);
    res.pageSize.set('tasks-main-table-' + TaskAgenda.Repairs, 10);

    res.pageIndex.set('my-store-list-default', 0);
    res.pageSize.set('my-store-list-default', 5);
    return res;
  };

export default MyTasksState;
// export default MyTasksFilterDefault;
