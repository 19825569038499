import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageListComponent } from './message-list/message-list.component';
import { MessageItemComponent } from './message-item/message-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@visurel/iconify-angular';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    MessageListComponent,
    MessageItemComponent
  ],
  imports: [
    CommonModule,

    MatMenuModule,
    IconModule,
    MatIconModule,
    RouterModule,
    MatRippleModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialFileInputModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    NgxPermissionsModule,

    TranslateModule
  ],
  exports: [
    MessageListComponent
  ]
})
export class MessagesModule { }
