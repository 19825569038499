import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getAttendance } from 'src/app/api/user/user.selectors';
import { AppState } from 'src/app/app.states';
import { Globals } from 'src/app/common/globals';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { DialogButtonType, DialogData, DialogDataInputType, DialogResult } from './attendance-dialog.model';
import { Geolocation } from 'src/app/common/model/geolocation';
import { calculateDistance } from 'src/app/common/functions';
import { attendanceSaveAction } from 'src/app/api/user/user.actions';
import baselineAccessAlarms from '@iconify/icons-ic/baseline-access-alarms';
import twotoneEditLocation from '@iconify/icons-ic/twotone-edit-location';

declare var SMap: any;
declare var JAK: any;

@Component({
    selector   : 'attendance-dialog-component',
    templateUrl: './attendance-dialog.component.html',
    styleUrls  : ['./attendance-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AttendanceDialogComponent implements AfterViewInit, OnDestroy
{
    baselineAccessAlarms = baselineAccessAlarms;
    twotoneEditLocation = twotoneEditLocation;

    form: FormGroup;

    attendanceTypeEnum = Honeycomb.Common.Enums.AttendanceType;
    attendanceType: Honeycomb.Common.Enums.AttendanceType;
    locationID: number = null;

    geolocationAvailable = false;
    positionErrorCode: number = null;

    subscriptions: Array<Subscription> = [];

    address: string;

    closestLocation: any; // Honeycomb.Tenant.LookupTables.IService.Model.LocationShort;
    distanceToClosestLocation: Observable<number> = of(null);
    selectedLocation: any; // Honeycomb.Tenant.LookupTables.IService.Model.LocationShort;
    distanceToSelectedLocation: Observable<number>  = of(null);

    smap: any;
    myLayer: any;

    disableButtons: Observable<boolean> = of(true);

    // tslint:disable-next-line: new-parens
    time: Observable<Date> = of(new Date);

    constructor(
        public dialogRef: MatDialogRef<AttendanceDialogComponent>,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private cd: ChangeDetectorRef,
        private store: Store<AppState>,
        @Inject('LookupLocationController') private taskerLocationController:  Honeycomb.Tenant.LookupTables.IService.LocationsController,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    )
    {
        this.form = formBuilder.group({
             distanceToSelectedLocation: [0, Validators.max(500)],
             distanceToClosestLocation: [0, Validators.max(500)]
        });

        const attendanceSub = this.store.select(getAttendance)
        .pipe(tap((a) => {
            if (!!a && !!a.attendanceID) {
                this.attendanceType = a.attendanceType;
                this.locationID = a.locationID;
              } else {
                this.attendanceType = Honeycomb.Common.Enums.AttendanceType.notSet;
                this.locationID = null;
              }
            })).subscribe(_ => null);
          this.subscriptions.push(attendanceSub);
    }

    async ngAfterViewInit(): Promise<void> {
        this.geolocationAvailable = this.globals.geoLocationAvailable;
        await this.refreshLocation();
    }

    async refreshLocation() {
        this.positionErrorCode = this.globals.positionErrorCode;
        const lastPosition = this.globals.lastPosition;
        await this.setupMap(lastPosition);
        navigator.geolocation.getCurrentPosition(async (position) => {
            this.globals.lastPosition = position;
            this.positionErrorCode = null;
            this.globals.positionErrorCode = null;
            await this.setupMap(position);
        }, async err => {
            this.positionErrorCode = err.code;
            this.globals.positionErrorCode = err.code;
            await this.setupMap();
        }, {
            enableHighAccuracy: true
        });

    }

    private async setupMap(lastPosition?: Geolocation.GeolocationPosition) {

        var foundLocation = [];
        var m: any;

        if (!lastPosition) {
            m = new SMap(JAK.gel('map'), null, 7);
            m.addDefaultControls();
            m.addDefaultLayer(SMap.DEF_BASE).enable();
        }
        else {
            const mapCenter = SMap.Coords.fromWGS84(lastPosition.coords.longitude, lastPosition.coords.latitude);
            m = new SMap(JAK.gel('map'), mapCenter, 14);
            m.addDefaultControls();
            m.addDefaultLayer(SMap.DEF_BASE).enable();
        }

        const layer = new SMap.Layer.Marker();
        m.addLayer(layer);
        layer.enable();

        let lastPositionLat = null;
        let lastPositionLong = null;
        if (!!lastPosition) {
            lastPositionLat = lastPosition.coords.latitude;
            lastPositionLong = lastPosition.coords.longitude;

            const myPointOptions = { };
            const myPosition = SMap.Coords.fromWGS84(lastPositionLong, lastPositionLat);
            const myMarker = new SMap.Marker(myPosition, 'myPosition', myPointOptions);
            layer.addMarker(myMarker);
        }

        foundLocation = await this.taskerLocationController
            .ListSimple(null, null, 300, true, null, null, lastPositionLat, lastPositionLong, null)
            .pipe(tap(locs => {
                if (locs && locs.length > 0) {
                    if (!!lastPosition) {
                        locs.forEach(l => {
                            (l as any).distance = calculateDistance(
                                { lat: l.latitude, lon: l.longitude},
                                { lat: lastPositionLat, lon:lastPositionLong});
                        });
                        locs.sort((a: any, b: any) => a.distance - b.distance)
                        const distance = (locs[0] as any).distance;
                        this.closestLocation = locs[0];
                        this.distanceToClosestLocation = of(distance);
                        this.form.get('distanceToClosestLocation').setValue(distance);
                    }

                    if (!!this.locationID) {
                        this.selectedLocation = locs.find(l => l.locationID === this.locationID);
                        if (!!lastPosition) {
                            const selectedDistance = calculateDistance(
                                { lat: this.selectedLocation.latitude, lon: this.selectedLocation.longitude},
                                { lat: lastPosition.coords.latitude, lon:lastPosition.coords.longitude});
                            this.distanceToSelectedLocation = of(selectedDistance);
                                this.form.get('distanceToSelectedLocation').setValue(selectedDistance);
                        }
                    }
                    if (!!this.closestLocation && (this.closestLocation as any).distance < 500) {
                        this.disableButtons = of(false);
                    }
                }
            }))
            .toPromise();

		if (foundLocation && foundLocation.length > 0) {
            for (const loc of foundLocation) {
                if (!loc.longitude || !loc.latitude) {
                    continue;
                }
                const znacka = JAK.mel('div');
                const obrazek = JAK.mel('img', {src:'/assets/drop-dt.png'});
                znacka.appendChild(obrazek);

                const popisek = JAK.mel('div', {}, {position:'absolute', left:'0px', top:'2px', textAlign:'center', width:'22px', color:'white', fontWeight:'bold'});
                popisek.innerHTML = '';
                znacka.appendChild(popisek);
                const marker = new SMap.Marker(SMap.Coords.fromWGS84(loc.longitude, loc.latitude), loc.locationID, {url:znacka});
                layer.addMarker(marker);
            }
        }
    }

    public buttonClicked( button ): void {
        const r = new DialogResult();
        r.button = button;
        if (this.data.inputType !== DialogDataInputType.none) {
            r.value = this.form.get('input').value;
        }
        this.dialogRef.close( r );
    }

    public onKeydown(event): void {
        if (event.key === 'Enter') {
          this.buttonClicked(DialogButtonType.ok);
        }
    }

    public async save() {
        this.disableButtons = of(true);
    }

    public async setEnter() {
        this.disableButtons = of(true);

        this.store.dispatch(attendanceSaveAction({
            locationID: this.closestLocation.locationID,
            location: this.globals.lastPosition
        }));

        this.dialogRef.close();
    }

    public async setExit() {
        this.disableButtons = of(true);

        this.store.dispatch(attendanceSaveAction({
            locationID: this.closestLocation.locationID,
            location: this.globals.lastPosition
        }));

        this.dialogRef.close();
    }

    public getPositionErrorName(errorCode: number): string {
        if (errorCode === Geolocation.PERMISSION_DENIED) {
            return 'permission-denied';
        } else if (errorCode === Geolocation.POSITION_UNAVAILABLE) {
            return 'position-unavailable';
        } else if (errorCode === Geolocation.TIMEOUT) {
            return 'timeout';
        }
        return 'error';
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
