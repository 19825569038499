<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center">
    <img class="w-6 select-none flex-none" src="assets/logo.png" style="cursor: pointer;" onclick="window.location.href=(location.origin)">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto" style="cursor: pointer;" onclick="window.location.href=(location.origin)">CRS</h2>
    <button (click)="toggleCollapse()" class="w-8 h-8 -mr-2 leading-none flex-none" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button>
  </div>

  <div class="sidenav-items flex-auto" vexScrollbar *ngIf="items && items.length > 0">
    <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                      [item]="item"
                      [level]="0"></vex-sidenav-item>
  </div>
</div>
