import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { PopoverTableContent, PopoverTableRef } from './popover-table-ref';
import icClose from '@iconify/icons-ic/twotone-close';

@Component({
  templateUrl: './popover-table.component.html',
  styleUrls: ['./popover-table.component.scss']
})
export class PopoverTableComponent implements OnInit {
  title: string;
  data: any;
  context;
  icClose = icClose;

  constructor(
    private popoverTableRef: PopoverTableRef,
    private cd: ChangeDetectorRef

  ) {
    this.title = popoverTableRef.title;
    this.data = popoverTableRef.data;

  }

  ngOnInit() {
    this.context = {
      close: this.popoverTableRef.close.bind(this.popoverTableRef)
    };
    this.cd.detectChanges();
  }

  close() {
    this.context.close();
  }
}
