import { Injectable } from '@angular/core';
import { Geolocation } from 'src/app/common/model/geolocation';
import { AuthService } from '../api/auth/auth.service';

@Injectable()
export class Globals {

    public apiUrl: string;
    public geoLocationAvailable = false;
    public lastPosition: Geolocation.GeolocationPosition;
    public positionErrorCode: number;

    public isDevRemoteDebug = window.location.hostname.includes('192.168.');
    public inDebug = false;

    constructor(
        private authService: AuthService
    ) {
        if (authService.loggedIn()) {
            this.apiUrl = authService.getDecodedToken().apiUrl;
        }
        this.inDebug = window.location.hash.substring(1) === 'debug';
    }

    public SetApiUrl(decodedToken) {
        if (!!decodedToken.apiUrl) {
            this.apiUrl = decodedToken.apiUrl;

            if (this.isDevRemoteDebug) {
                this.apiUrl = `http://${window.location.hostname}`;
                return;
            }

            // the later logic expects the url not to end with slash, so check & remove
            const lastChar = this.apiUrl.substring(-1);
            if (lastChar === '/') {
                this.apiUrl = this.apiUrl.slice(0, -1);
            }
            this.DebugOut('apiUrl set to: ' +  this.apiUrl);
        }
    }

    /**
     * Provided by JWT token
     */
    public GetUrlPrefix(): string {
        let urlPrefix = this.apiUrl;        

        if (this.isDevRemoteDebug) {
            urlPrefix = `http://${window.location.hostname}`;
        }

        this.DebugOut('Returning UrlPrefix: ', urlPrefix);

        return urlPrefix;
    }

    lastDebugMsg: string = null;
    public DebugOut(message?: any, ...optionalParameters: any[]) {
        if (message === this.lastDebugMsg) { // avoid duplicit messages
            return;
        }
        if (this.inDebug || this.isDevRemoteDebug) {
            console.log(message, optionalParameters);
        }
        this.lastDebugMsg = message;
    }

    public WarnOut(message?: any, ...optionalParameters: any[]) {
        if (this.inDebug || this.isDevRemoteDebug) {
            console.warn(message, optionalParameters);
        }
    }

    public clearServiceWorkerCache() {
        if ('serviceWorker' in navigator) {
            caches.keys().then(function(cacheNames) {
            cacheNames.forEach(function(cacheName) {
                caches.delete(cacheName);
                console.log('Clearing SW cache: 💥', cacheName);
            });
            });
        }
    }
}