import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface TaskerFilesState {
    loading: boolean;
    error: string;
    recordUid: string;
    fileName: string;
    activityValue: any;
}

export const TaskerFilesState = () => {
    return {
        loading: false,
        error: null,
        recordUid: null,
        fileName: null,
        activityValue: null
    } as TaskerFilesState;
  };
