import { RequestParams } from './assets/request-params.model';
import { HttpHeaders } from './assets/http-headers.interface';
import { RequestParamType } from './assets/request-param-type.enum';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { HttpMethod } from './assets/http-method.enum';
import { Globals } from 'src/app/common/globals';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class ApiCaller {

    constructor(
        @Inject(HttpClient) private httpClient: HttpClient,
        @Inject(Globals) private globals: Globals) {
    }

    public request(controllerRoute: string,
                   requestParams: RequestParams,
                   headers?: HttpHeaders): Observable<any> {
        if (!this.httpClient) {
            console.error('Apicaller not initialized');
        }

        const params = this.resolveEndpointParams(requestParams);
        const endpointRoute = this.resolveEndpointRoute(requestParams.endpointRoute, params);

        if (requestParams.method === HttpMethod.GET) {
            return this.httpClient.get<any>([this.globals.GetUrlPrefix(), controllerRoute, endpointRoute].join('/'));
        }

        if (requestParams.method === HttpMethod.DELETE) {
            return this.httpClient.delete<any>([this.globals.GetUrlPrefix(), controllerRoute, endpointRoute].join('/'));
        }

        if (requestParams.method === HttpMethod.POST) {
            return this.httpClient.post<any>([this.globals.GetUrlPrefix(), controllerRoute, endpointRoute].join('/'), params.BODY);
        }

        if (requestParams.method === HttpMethod.PUT) {
            return this.httpClient.put<any>([this.globals.GetUrlPrefix(), controllerRoute, endpointRoute].join('/'), params.BODY);
        }
    }

    private resolveEndpointParams(requestParams: RequestParams): any {
        const params: any = {};
        requestParams.params.forEach(param => {
            if (!params.hasOwnProperty(param.type)) {
                params[param.type] = {};
            }
            if (param.type === RequestParamType.BODY || param.type === RequestParamType.FORM) {
                params[param.type] = param.value;
            } else {
                params[param.type][param.name] = param.value;
            }
        });
        if (params.hasOwnProperty(RequestParamType.BODY) && params.hasOwnProperty(RequestParamType.FORM)) {
            console.warn('Request contains both body & form params. Using body params only.');
            delete(params[RequestParamType.FORM]);
        }
        return params;
    }

    private resolveEndpointRoute(endpointRoute: string, params: any): string | null {
        if (params.hasOwnProperty(RequestParamType.ROUTE)) {
            for (const name in params[RequestParamType.ROUTE]) {
                const regex = new RegExp(`\{\*?${name}\??\}`, 'gi');
                endpointRoute = endpointRoute.replace(regex, params[RequestParamType.ROUTE][name]);
            }
            if (endpointRoute.match(/[{}]+/)) {
                console.error('Route cannot be resolved');
                return null;
            }
        }
        if (params.hasOwnProperty(RequestParamType.QUERY)) {
            const queryParams = this.paramsToString(params[RequestParamType.QUERY]);
            endpointRoute += queryParams.length ? `?${queryParams}` : '';
        }
        return endpointRoute;
    }

    public uploadFile(controllerRoute: string, requestParams: RequestParams): Observable<any>{
        const bypass = '?ngsw-bypass=true';
        return this.httpClient.post<any>([this.globals.GetUrlPrefix(), controllerRoute].join('/') + bypass, requestParams);
    }

    private paramsToString(pairs: any): string {
        const result: string[] = [];
        for (const key in pairs) {
            if (Array.isArray(pairs[key])) {
                pairs[key].forEach((v: any) => result.push(`${key}=${v}`));
            } else {
                if (pairs[key] !== null && pairs[key] !== undefined) {
                    result.push(`${key}=${pairs[key]}`);
                }
            }
        }
        return result.join('&');
    }
}
