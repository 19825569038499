// tslint:disable-next-line: no-namespace
export namespace Geolocation {
    export interface GeolocationCoordinates {
        readonly accuracy: number;
        readonly altitude: number | null;
        readonly altitudeAccuracy: number | null;
        readonly heading: number | null;
        readonly latitude: number;
        readonly longitude: number;
        readonly speed: number | null;
    }

    export interface GeolocationPosition {
        readonly coords: GeolocationCoordinates;
        readonly timestamp: number;
    }

    export interface GeolocationPositionError {
        readonly code: number;
        readonly message: string;
    }

    export const PERMISSION_DENIED = 1;
    export const POSITION_UNAVAILABLE  = 2;
    export const TIMEOUT = 3;
}

