import { createAction, props } from '@ngrx/store';

export enum TaskerFilesActionTypes {
  UploadTaskerFile = '[TaskerFiles] UpLoad Tasker File',
  UploadTaskerFileCompleted = '[TaskerFiles] Uploaded Tasker File Completed',
  UploadTaskerFileFailed = '[TaskerFiles] UpLoaded Tasker Files File Failed',
}

// tslint:disable: max-line-length
export const uploadTaskerFileAction = createAction(TaskerFilesActionTypes.UploadTaskerFile, props<{ file: File, name: string, activityValue: any }>());
export const uploadTaskerFileCompletedAction = createAction(TaskerFilesActionTypes.UploadTaskerFileCompleted, props<{recordUid: string, fileName: string, activityValue: any}>());
export const uploadTaskerFileFailedAction = createAction(TaskerFilesActionTypes.UploadTaskerFileFailed, props<{error: string}>());
