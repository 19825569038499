import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface PbiState {
    loading: boolean;
    error: string;
    aad: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD;
}

export const PbiState = () => {
    return {
        loading: false,
        error: null,
        aad: null,
    } as PbiState;
  };
