<form (ngSubmit)="saveAndClose()" [formGroup]="form" style="min-width: 70vw;position: relative;" [ngStyle]="{'display': visible ? 'initial' : 'none'}">

  <task-detail-header [taskForm]="form" [task]="task" (closeFn)="close()" [taskAttributes]="taskAttributes"></task-detail-header>

  <mat-divider class="text-border"></mat-divider>

  <div *ngIf="(detailLoading$ | async)">
    <mat-progress-spinner class="example-margin" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div style="position: relative; padding-left: 50%;">
    <div class="ball-scale-multiple blue task-row" *ngIf="switchActivity" style="position: absolute;top: 80px">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  
  <mat-dialog-content fxLayout="column" style="min-height: 65vh;" *ngIf="!(detailLoading$ | async)">
    <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" style="overflow: hidden; flex: 1 1 100%;" (selectedTabChange)="tabChanged($event)">
      <ng-container *ngIf="activitiesTabVisible">
      <mat-tab data-tab-activities #tabActivities *ngxPermissionsOnly="uiRole('tab-activities')">
        <ng-template mat-tab-label>
          <mat-icon [icIcon]="twotoneAssignmentTurnedIn"></mat-icon>
          {{ 'tasker.my-task-detail.activities' | translate }}
        </ng-template>
        <task-activities [form]="form" [task]="task" #activities *ngIf="!!task && !!task.operation && task.operation.taskType !== taskType.repairRequest" 
          [detailComponent]="parentReference">
        </task-activities>
        <task-repair-activities [form]="form" [task]="task" #activities *ngIf="!!task && !!task.operation && task.operation.taskType === taskType.repairRequest" [detailComponent]="parentReference">
        </task-repair-activities>
      </mat-tab>
    </ng-container>

      <mat-tab data-tab-main #tabMain *ngxPermissionsOnly="uiRole('tab-main')">
        <ng-template mat-tab-label>
          <mat-icon [icIcon]="baselinePhonelinkSetup"></mat-icon> {{ 'tasker.my-task-detail.main' | translate }}
        </ng-template>
        
        <div style="padding: 15px 15px 0 15px;">

          <div fxLayout="row" *ngIf="(isLastInSequence | async)" class="error-message">
            {{ 'tasker.my-task-detail.is-last-sequence-task' | translate }}
          </div>

          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px">

            <mat-form-field class="mt-3 can-grow" fxFlex>
              <mat-label>{{ 'tasker.common.name' | translate }}</mat-label>
              <!-- <input type="text" matInput formControlName="name" [readonly]="!hasPermission('name.edit')"> -->
              <textarea type="text" matInput matAutosizeMaxRows="3" maxlength="64"  matTextareaAutosize matAutosizeMinRows="1" formControlName="name"></textarea>
              <mat-icon [icIcon]="twotoneMore" flip="horizontal" class="mr-3" matPrefix></mat-icon>
              <mat-hint align="start">{{ 'tasker.common.operation' | translate }}: {{ operationName }}</mat-hint>
              <mat-hint align="end">{{form.get('name').value.length}} / 64</mat-hint>
            </mat-form-field>

            <mat-form-field class="mt-3" fxFlex>
              <mat-label>{{ 'tasker.common.priority' | translate }}</mat-label>
              <!-- {{ form.get('priority').value | json }} -->
              <!-- <mat-select matNativeControl placeholder="{{ 'tasker.common.select' | translate }}"
                formControlName="priority" > -->
                <mat-select matNativeControl placeholder="{{ 'tasker.common.select' | translate }}"
                formControlName="priority" [disabled]="!hasPermission('priority.edit')">
                <mat-option [value]="val" *ngFor="let val of priorityValues">
                  {{ 'tasker.priority.' + taskPriority[val] | translate }}</mat-option>
              </mat-select>
              <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
            <span style="display: none">hasPermission: {{ hasPermission('priority.edit') }}</span>

            <!-- Free task - select status -->
            <ng-container>
              <mat-form-field class="mt-3" fxFlex *ngIf="!!task && !!task.operation && !hasPermission('reject')" >
                <mat-label>{{ 'tasker.common.status' | translate }}</mat-label>
                <mat-select matNativeControl placeholder="{{ 'tasker.common.status' | translate }}"
                    formControlName="taskStatusID" [disabled]="!hasPermission('status.edit') || (task.taskStatusBase === taskStatus.archived && !statusEditForced)">
                  <mat-option [value]="val.taskStatusID" [disabled]="!val.enabled" *ngFor="let val of taskStatuses$ | async">{{ val.name | translate }}</mat-option>
                </mat-select>
                <button mat-icon-button matSuffix type="button" *ngIf="hasPermission('status.edit') && task.taskStatusBase === taskStatus.archived" (click)="statusEditForced = true">
                  <mat-icon>edit</mat-icon>
                </button>
                <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
                <mat-hint align="end">{{ task.taskStatusUpdate | date : 'd.M.yyyy' }}</mat-hint>
              </mat-form-field>
              <span style="display: none">hasPermission: {{ hasPermission('status.edit') }}</span>
            </ng-container>
            <div class="form-line" fxFlex fxLayoutAlign="center" *ngxPermissionsOnly="uiRole('reject')" [attr.data-can-reject]="canReject | async" >
                <ng-container *ngIf="!!task && !!task.operation">
                  <button *ngIf="task.taskStatusBase !== taskStatus.finished && task.taskStatusBase !== taskStatus.archived && (canReject | async)" mat-button color="warn" (click)="rejectTask($event)">{{ 'tasker.my-task-detail.reject' | translate }}</button>
                  <div class="form-line" style="color: var(--btn-red)" *ngIf="task.taskStatusBase === taskStatus.finished || task.taskStatusBase === taskStatus.archived || !(canReject | async)">{{ selectedStatusName | async }} ({{ task.taskStatusUpdate | date : 'd.M.yyyy' }})</div>
                </ng-container>
            </div>
          </div>

          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px">
            <mat-form-field class="mt-3" fxFlex>
              <mat-label>{{ 'tasker.common.description' | translate }}</mat-label>
              <!-- <input type="text" matInput formControlName="description" [readonly]="!hasPermission('description.edit')"> -->
              <textarea type="text" matInput matTextareaAutosize matAutosizeMinRows="1" maxlength="1024" formControlName="description" [readonly]="!hasPermission('description.edit')"></textarea>
              <mat-icon [icIcon]="twotoneDescription" class="mr-3" matPrefix></mat-icon>
              <mat-hint align="start">{{ 'tasker.common.operation' | translate }}: {{ operationName }}</mat-hint>
              <mat-hint align="end" *ngIf="!!form.get('description').value" >{{form.get('description').value.length}} / 1024</mat-hint>
            </mat-form-field>
          </div>

          <ng-container *ngIf="!!task">
            <div class="files-container">
              <div class="photo-row" fxLayoutGap="10px">
                <div *ngFor="let photo of task.taskPhotos; trackBy: trackByTaskerFileUid" class="photo-thumbnail" (click)="zoomAttachmentImage(photo)">
                  <img *ngIf="photo.taskerFileUid" [src]="getImageUrl(photo.taskerFileUid)">
                </div>
                <div *ngFor="let photo of taskPhotos; trackBy: trackByRecordUid" class="photo-thumbnail" (click)="zoomAttachmentImageTemp(photo)">
                  <img *ngIf="photo.recordUid" [src]="getTempImageUrl(photo.recordUid)">
                </div>
                <div class="photo-add-btn progress-container" (click)="addPhoto($event)" 
                  *ngxPermissionsOnly="uiRole('add-photo')" >
                  <ic-icon [icIcon]="roundAddCircleOutline" size="40px"></ic-icon>
                  <div class="photo-add-text">{{ 'tasker.task-activities.add-photo' | translate }}</div>
                  <div class="photo-uploading-text">{{ 'tasker.common.uploading' | translate }}</div>
                </div>
              </div>

              <div class="attachment-row" fxLayoutGap="10px">
                <div *ngFor="let attachment of task.taskAttachments" class="attachment-thumbnail">
                  <ic-icon [icIcon]="roundContentPaste" size="40px"></ic-icon>
                  <span class="file-name">{{ attachment.name }}</span>
                  <span class="remove-button" (click)="removeAttachment(attachment)">
                    <ic-icon [icIcon]="roundCancelPresentation" size="10px"></ic-icon>
                  </span>
                </div>
                <div *ngFor="let attachment of taskAttachments" class="attachment-thumbnail">
                  <ic-icon [icIcon]="roundContentPaste" size="40px"></ic-icon>
                  <span class="file-name">{{ attachment.name }}</span>
                  <span class="remove-button" *ngxPermissionsOnly="uiRole('add-attachment')" (click)="removeAttachmentTemp(attachment)">
                    <ic-icon [icIcon]="roundCancelPresentation" size="10px"></ic-icon>
                  </span>
                </div>
                <div class="attachment-add-btn progress-container" (click)="addAttachment($event)" 
                  *ngxPermissionsOnly="uiRole('add-attachment')">
                  <ic-icon [icIcon]="roundAddCircleOutline" size="40px"></ic-icon>
                  <div class="photo-add-text">{{ 'tasker.task.add-attachment' | translate }}</div>
                  <div class="photo-uploading-text">{{ 'tasker.common.uploading' | translate }}</div>
                </div>
              </div>
            </div>
          </ng-container>

          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">

          </div>
          <ng-container *ngIf="contactsLoading$ | async">
            <mat-progress-bar mode="indeterminate">
            </mat-progress-bar>
          </ng-container>
          <!-- location & address -->
          <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px">
            <mat-form-field fxFlex class="mt-3" *ngIf="locations">
              <mat-label>{{ 'tasker.task-repair.location' | translate }}</mat-label>
              <mat-select [placeholder]="'tasker.task-repair.not-entered' | translate" fxFlex
                formControlName="locationID" (selectionChange)="selectedLocation($event)"
                [disabled]="!hasPermission('location.edit')">
                <mat-option [value]="null">-- {{ 'tasker.task-repair.not-entered' | translate }} --</mat-option>
                <mat-option *ngFor="let loc of locations$ | async " [value]="loc.locationID">{{ loc.name }}</mat-option>
              </mat-select>
              <mat-icon [icIcon]="twotoneStore" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
            <mat-form-field fxFlex class="mt-3">
              <mat-label>{{ 'tasker.task-repair.address' | translate }}</mat-label>
              <input matInput [placeholder]="'tasker.task-repair.address' | translate" formControlName="address"
                [readonly]="true">
              <mat-icon [icIcon]="twotoneLocationOn" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
          </div>
          <div *ngFor="let assignee of form.get('creator').value" fxLayout="row" fxLayoutGap="12px">
            <mat-form-field class="mt-3" fxFlex>
              <mat-label>{{ 'tasker.common.creator' | translate }}</mat-label>
              <input type="text" matInput readonly [value]="contactDisplayFn(assignee)">
              <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
            <!-- <button type="button" class="mt-3" fxFlex="10%" mat-icon-button (click)="removeContact(assignee, $event)"
              *ngIf="isCreator || isAssigner || isAssigned">
              <mat-icon [icIcon]="twotoneDeleteForever"></mat-icon>
            </button> -->
          </div>

          <!-- assignee -->
          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
            <mat-form-field class="mt-3 can-grow" form
            [class.mat-form-field-invalid]="form.controls.assignee.hasError('required') && form.touched" fxFlex>
              <mat-label>{{ 'tasker.common.assignee' | translate }}</mat-label>
              <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
              <mat-chip-grid #taskAssingeesChipList>
                <mat-chip-row *ngFor="let taskUser of form.get('assignee').value"
                  [removable]="hasPermission('assignee.edit')"
                  (removed)="removeUser(taskUser.userId, taskRelation.assignee)">
                  {{ taskUser.formatedName }}
                  <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
                </mat-chip-row>
              </mat-chip-grid>
              <input #contactInputAssignee formControlName="contactInputAssignee"
                  [matAutocomplete]="taskUserAssingeeAuto" [matChipInputFor]="taskAssingeesChipList"
                  [readonly]="!hasPermission('assignee.edit')">
              <mat-autocomplete #taskUserAssingeeAuto="matAutocomplete"
                (optionSelected)="addUser(taskRelation.assignee, $event)">
                <mat-option *ngFor="let userjob of (users$ | async)" [value]="userjob">
                  {{ userjob.user.name }} ({{ userjob.job.name }})
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ 'tasker.my-task-detail.no-users' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <!-- observer -->
          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
            <mat-form-field class="mt-3 can-grow" fxFlex>
              <mat-label>{{ 'tasker.common.observer' | translate }}</mat-label>
              <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
              <mat-chip-grid #taskObserversList>
                <mat-chip-row *ngFor="let taskUser of form.get('observer').value"
                  [removable]="hasPermission('observer.edit')"
                  (removed)="removeUser(taskUser.userId, taskRelation.observer)">
                  {{ taskUser.formatedName }}
                  <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
                </mat-chip-row>
              </mat-chip-grid>
              <input #contactInputObserver formControlName="contactInputObserver"
                [matAutocomplete]="taskUserObserverAuto" [matChipInputFor]="taskObserversList"
                [readonly]="!hasPermission('observer.edit')">
              <mat-autocomplete #taskUserObserverAuto="matAutocomplete"
                (optionSelected)="addUser(taskRelation.observer, $event)">
                <mat-option *ngFor="let userjob of (users$ | async)" [value]="userjob">
                  {{ userjob.user.name }} ({{ userjob.job.name }})
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ 'tasker.my-task-detail.no-users' | translate }}</mat-error>
            </mat-form-field>
          </div>


          <!-- responsible -->
          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
            <mat-form-field class="mt-3 can-grow" fxFlex>
              <mat-label>{{ 'tasker.common.responsible' | translate }}</mat-label>
              <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
              <mat-chip-grid #taskResponsibleList>
                <mat-chip-row *ngFor="let taskUser of form.get('responsible').value"
                  [removable]="hasPermission('responsible.edit')"
                  (removed)="removeUser(taskUser.userId, taskRelation.responsible)">
                  {{ taskUser.formatedName }}
                  <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
                </mat-chip-row>
              </mat-chip-grid>
              <input #contactInputResponsible formControlName="contactInputResponsible"
                  [matAutocomplete]="taskUserResponsibleAuto" [matChipInputFor]="taskResponsibleList"
                  [readonly]="!hasPermission('responsible.edit')">
              <mat-autocomplete #taskUserResponsibleAuto="matAutocomplete"
                (optionSelected)="addUser(taskRelation.responsible, $event)">
                <mat-option *ngFor="let userjob of (users$ | async)" [value]="userjob">
                  {{ userjob.user.name }} ({{ userjob.job.name }})
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ 'tasker.my-task-detail.no-users' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
            <mat-form-field class="mt-3" fxFlex>
              <mat-label>{{ 'tasker.common.created' | translate }}</mat-label>
              <input matInput [placeholder]="'tasker.common.created' | translate" formControlName="created" readonly [matDatepicker]="created">
              <!-- <mat-datepicker-toggle matSuffix [for]="created"></mat-datepicker-toggle> -->
              <mat-datepicker #created></mat-datepicker>
              <mat-icon [icIcon]="sharpPostAdd" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>

            <mat-form-field class="mt-3" fxFlex>
              <mat-label>{{ 'tasker.common.start' | translate }}</mat-label>
              <input matInput [matDatepicker]="dateStart" [placeholder]="'tasker.common.start' | translate" formControlName="startDate"
                [readonly]="!hasPermission('startDate.edit')">
              <mat-datepicker-toggle matSuffix [for]="dateStart" *ngIf="hasPermission('startDate.edit')">
              </mat-datepicker-toggle>
              <mat-datepicker #dateStart></mat-datepicker>
              <mat-icon [icIcon]="twotoneRocket" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>

            <mat-form-field class="mt-3" fxFlex>
              <mat-label>{{ 'tasker.common.deadline' | translate }}</mat-label>
              <input matInput [matDatepicker]="deadline" [placeholder]="'tasker.common.deadline' | translate" formControlName="deadline"
                [readonly]="!hasPermission('deadline.edit')">
              <mat-datepicker-toggle matSuffix [for]="deadline" *ngIf="hasPermission('deadline.edit')">
              </mat-datepicker-toggle>
              <mat-datepicker #deadline></mat-datepicker>
              <mat-icon [icIcon]="twotoneTimerOff" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
          </div>

          <div formArrayName="taskAttributesUpsert">
            <ng-container *ngFor="let taskAttribute of getTaskAttributes(form);let i=index">
              <div fxLayout="row" fxLayoutGap="12px" [formGroupName]="i">
                <mat-form-field class="mt-3" fxFlex *ngIf="buildConfig.taskDetailRepairAttribute(taskAttribute.value.taskAttributeTypeName, task) && taskAttribute.value.taskAttributeTypeName === 'ExternalSupplier' && !hasPermission('ExternalSupplier.edit')">
                  <mat-label>{{ 'tasker.common.external-supplier' | translate }}</mat-label>
                  <input matInput [placeholder]="'tasker.common.external-supplier' | translate" [value]="getDisplayValue('external', taskAttribute.value.value)" readonly>
                  <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
                </mat-form-field>
                <mat-form-field class="mt-3" fxFlex *ngIf="buildConfig.taskDetailRepairAttribute(taskAttribute.value.taskAttributeTypeName, task) && taskAttribute.value.taskAttributeTypeName === 'ExternalSupplier' && hasPermission('ExternalSupplier.edit')">
                  <mat-label>{{ 'tasker.common.external-supplier' | translate }}</mat-label>
                  <mat-select matNativeControl placeholder="{{ 'tasker.common.external-supplier' | translate }}"
                    formControlName="value" [disabled]="!hasPermission(taskAttribute.value.taskAttributeTypeName + '.edit')">
                    <mat-option *ngFor="let item of externalSuppliers | async" [value]="item?.codeId">{{ item?.displayValue }}</mat-option>
                  </mat-select>
                  <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
                </mat-form-field>
                <mat-form-field class="mt-3" fxFlex *ngIf="buildConfig.taskDetailRepairAttribute(taskAttribute.value.taskAttributeTypeName, task) && taskAttribute.value.taskAttributeTypeName === 'RepairType' && !hasPermission('RepairType.edit')">
                  <mat-label>{{ 'tasker.common.repair-type' | translate }}</mat-label>
                  <input matInput [placeholder]="'tasker.common.repair-type' | translate" [value]="getDisplayValue('repair', taskAttribute.value.value)" readonly>
                  <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
                </mat-form-field>
                <mat-form-field class="mt-3" fxFlex *ngIf="buildConfig.taskDetailRepairAttribute(taskAttribute.value.taskAttributeTypeName, task) && taskAttribute.value.taskAttributeTypeName === 'RepairType' && hasPermission('RepairType.edit')">
                  <mat-label>{{ 'tasker.common.repair-type' | translate }}</mat-label>
                  <mat-select matNativeControl placeholder="{{ 'tasker.common.repair-type' | translate }}"
                    formControlName="value" [disabled]="false && !hasPermission(taskAttribute.value.taskAttributeTypeName + '.edit')">
                    <mat-option *ngFor="let item of repairTypes | async" [value]="item?.codeId">{{ item?.displayValue }}</mat-option>
                  </mat-select>
                  <mat-icon [icIcon]="baselineSort" class="mr-3" matPrefix></mat-icon>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-tab>

      <mat-tab data-tab-summary #tabSummary *ngxPermissionsOnly="uiRole('tab-summary')"> 
        <ng-template mat-tab-label>
          <mat-icon [icIcon]="twotoneAssignment"></mat-icon> {{ 'tasker.my-task-detail.summary' | translate }}
        </ng-template>
        
        <div class="ball-scale-multiple blue task-row" [ngStyle]="{'display': !(summaryLoaded | async) ? 'block' : 'none'}" style="position: relative;margin-top: 50px">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <task-summary #summary [task]="task" [ngStyle]="{'display': (summaryLoaded | async) ? 'block' : 'none'}" [form]="form" [detailComponent]="parentReference" (forceReload)="forceReload()">
        </task-summary>
      </mat-tab>

      
      <ng-container *ngIf="sequenceTabVisible">
      
      <mat-tab data-tab-sequence #tabSequence [style.display]="sequenceTabVisible ? '' : 'none'" *ngxPermissionsOnly="uiRole('tab-sequence')"  >
        <ng-template mat-tab-label>
          <mat-icon [icIcon]="roundRepeat"></mat-icon> {{ 'tasker.my-task-detail.sequence' | translate }}
        </ng-template>
        <div fxFlex="0 1 0%" fxLayoutAlign="end center" fxLayout="row">
        </div>
        <ng-container *ngIf="!!task && !!task.taskSequenceID">
        <task-sequence *ngIf="(initialized$ | async)" #sequence [tasks]="taskSequence$" [currentTask]="task"
          (redirect)="redirect($event)" 
          (deleted)="sequenceDeleted($event)"
          (refresh)="sequenceRefresh($event)">
        </task-sequence>
        </ng-container>
      </mat-tab>
    </ng-container>

      <mat-tab data-tab-followup #tabFollowup *ngxPermissionsOnly="uiRole('tab-followup')">
        <ng-template mat-tab-label>
          <mat-icon [icIcon]="certificateIcon" flip="horizontal"></mat-icon>
          &nbsp;{{ 'tasker.common.tasks-followup-short' | translate }}
        </ng-template>
        <div fxFlex="1 0 100%" fxLayout="column">
          <div fxFlex="0 1 auto" fxLayout="row" fxFlexAlign="end">
            <button color="primary" mat-button type="button" (click)="addFollowUp()" *ngxPermissionsOnly="uiRole('btn-add-followup')">{{ 'tasker.common.new-task-followup'
              | translate }}</button>
          </div>
          <ng-container *ngIf="task && task.children">
            <div *ngFor="let item of task.children" class="task-row">
              <task-item [task]="item" (selected)="redirect(item.taskID)"></task-item>
              <mat-divider></mat-divider>
            </div>
          </ng-container>
        </div>
      </mat-tab>

      <mat-tab #tabMessage data-tab-messages *ngxPermissionsOnly="uiRole('tab-messages')">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="task" [icIcon]="twotoneSpeakerNotes"
            [ngClass]="{'unread': task.messagesTotal > task.messagesRead}"></mat-icon>
          {{ 'tasker.common.messages' | translate }}
        </ng-template>
        <ng-container>
          <message-list fxFlex="1 0 100%" fxLayout="column" style="height: 100%; overflow: auto;" [items]="messages"
            [firstLoading]="messagesLoading" [componentType]="task?.operation.code" [refID]="task?.taskID" [refType]="messageRef.Task"></message-list>
            
        </ng-container>
      </mat-tab>

      <mat-tab #tabAttachments data-tab-files *ngxPermissionsOnly="uiRole('tab-files')">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="task" [icIcon]="roundAttachFile"></mat-icon> {{ 'tasker.common.files' | translate }}
        </ng-template>
        <ng-container *ngIf="!!task">
          <task-files fxFlex="1 0 100%" fxLayout="column" style="height: 100%; overflow: auto;"
            [taskPhotos]="task.taskPhotos" 
            [taskFiles]="taskFiles"
            [messages]="messagesSimple"
            [activities]="form.get('taskActivitiesUpsert').value"
            [firstLoading]="taskFilesLoading" [taskID]="task?.taskID"></task-files>
        </ng-container>
      </mat-tab>

    </mat-tab-group>

  </mat-dialog-content>
  <div
    *ngIf="continueButtonVisible && !!task && !!task.operation && task.operation.taskType !== taskType.repairRequest"
    fxLayoutAlign="center center" fxLayoutGap="10px" fxLayout="row">
    <button *ngIf="selectedActivityIndex !== 0" mat-button mat-flat-button color="secondary" type="button"
      (click)="selectPrevActivity()">
      {{'tasker.back' | translate}}
    </button>
    <button mat-button mat-raised-button *ngIf="activities && !isLastActivity" [color]="activityButtonColor" [disabled]="switchActivity" type="button" (click)="selectNextActivity()">
      {{ getTaskActivityPosition }} {{'tasker.continue' | translate}}
    </button>
    <button mat-button mat-raised-button *ngIf="activities && isLastActivity" [color]="activityButtonColor" [disabled]="switchActivity" type="button" (click)="finishTaskActivities()">
      {{ getTaskActivityPosition }} {{'tasker.finish' | translate}}
    </button>
  </div>
  <mat-dialog-actions fxLayout="row"  *ngIf="!(detailLoading$ | async)">
    <button *ngIf="task && task.parentTaskID && hasParentTaskAccess" color="primary" mat-button (click)="redirect(task.parentTaskID)"
      type="button">
      &lt; {{ 'tasker.common.parent-task' | translate }}
    </button>
    <div fxLayoutAlign="end center" fxFlex>
      <div *ngIf="detailError$ | async">{{ detailError$ | async }}</div>
      <button mat-button (click)="close()" type="button">{{ 'tasker.common.close' | translate }}</button>
      <ng-container *ngxPermissionsOnly="uiRole('save')">
        <button *ngIf="(isMainTabSelected | async) && task && task.taskStatusBase !== taskStatus.archived"
        color="primary" mat-button type="submit">{{ 'tasker.my-task-detail.update-task' | translate }}</button>
        <span *ngIf="(isMainTabSelected | async) && task && task.taskStatusBase === taskStatus.archived">{{ selectedStatusName | async }}</span>
      </ng-container>
    </div>
  </mat-dialog-actions>
  <ngx-mat-file-input #inputPhoto style="display: none;" placeholder="" accept="image/*,text/plain" capture="environment">
  </ngx-mat-file-input>
  <ngx-mat-file-input #inputAttachment style="display: none;" placeholder="" accept="*/*" capture="environment">
  </ngx-mat-file-input>
</form>