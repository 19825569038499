import baselineSubject from '@iconify/icons-ic/baseline-subject';
import baselineEventAvailable from '@iconify/icons-ic/baseline-event-available';
import baselineMessage from '@iconify/icons-ic/baseline-message';
import baselineBarChart from '@iconify/icons-ic/baseline-bar-chart';
import baselineWarning from '@iconify/icons-ic/baseline-warning';
import baselineLanguage from '@iconify/icons-ic/baseline-language';
import icStore from '@iconify/icons-ic/twotone-store';
import twotoneDateRange from '@iconify/icons-ic/twotone-date-range';
import baselineAccessAlarms from '@iconify/icons-ic/baseline-access-alarms';
import phonelink from '@iconify/icons-ic/round-phonelink';



export class DashboardTile {
    tileKey: string;
    tileClass: string;
    tileIcon: object;
    tileName: string;
    route?: string;
    remoteUrl?: string;
    badgeValue?: number;
}

export const dashboardTileArray = (): Array<DashboardTile> => [
    {
        tileKey: 'overview',
        tileClass: 'overview',
        tileIcon: baselineSubject,
        tileName: 'tasker.menu.overview',
        route: '/overview/main',
        badgeValue: 1
    },
    {
        tileKey: 'tasks',
        tileClass: 'mytasks',
        tileIcon: baselineEventAvailable,
        tileName: 'tasker.menu.tasks',
        route: '/my-tasks/main',
        badgeValue: 0
    },
    {
        tileKey: 'mytasks',
        tileClass: 'mytasks',
        tileIcon: baselineEventAvailable,
        tileName: 'tasker.menu.my-tasks',
        route: '/my-tasks/main',
        badgeValue: 6
    },
    {
        tileKey: 'assignedbyme',
        tileClass: 'assignedbyme',
        tileIcon: baselineEventAvailable,
        tileName: 'tasker.menu.tasks-by-me',
        route: '/tasks-by-me/main',
        badgeValue: 3
    },
    {
        tileKey: 'stores',
        tileClass: 'stores',
        tileIcon: icStore,
        tileName: 'tasker.menu.stores',
        route: '/stores/main',
        badgeValue: null
    },
    {
        tileKey: 'communication',
        tileClass: 'communication',
        tileIcon: baselineMessage,
        tileName: 'tasker.menu.communication',
        route: '/communication/main',
        badgeValue: null
    },
    {
        tileKey: 'reports',
        tileClass: 'reports',
        tileIcon: baselineBarChart,
        tileName: 'tasker.menu.reports',
        route: '/reporting/main',
        badgeValue: null
    },
    {
        tileKey: 'repairs',
        tileClass: 'repairs',
        tileIcon: baselineWarning,
        tileName: 'tasker.menu.repair-request',
        route: '/my-tasks/repairs',
        badgeValue: null
    },
    {
        tileKey: 'intranet',
        tileClass: 'intranet',
        tileIcon: baselineLanguage,
        tileName: 'tasker.menu.intranet',
        remoteUrl: 'https://intranet.traficon.cz/joomla/',
        badgeValue: null
    },
    {
        tileKey: 'calendar',
        tileClass: 'calendar',
        tileIcon: twotoneDateRange,
        tileName: 'tasker.menu.calendar',
        route: '/calendar/main',
        badgeValue: null
    },
    {
        tileKey: 'my-stores',
        tileClass: 'my-stores',
        tileIcon: icStore,
        tileName: 'tasker.menu.my-stores',
        route: '/stores/my-stores',
        badgeValue: null
    },
    {
        tileKey: 'attendance',
        tileClass: 'attendance',
        tileIcon: baselineAccessAlarms,
        tileName: 'tasker.menu.attendance',
        route: '/attendance/main',
        badgeValue: null
    },
    {
        tileKey: 'link',
        tileClass: 'unknown',
        tileIcon: phonelink,
        tileName: '',
        route: '',
        badgeValue: null
    },
    {
        tileKey: '',
        tileClass: 'unknown',
        tileIcon: phonelink,
        tileName: '',
        route: '',
        badgeValue: null
    }
];

