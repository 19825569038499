import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ElementRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import twotoneMore from '@iconify/icons-ic/twotone-more';
import twotoneRocket from '@iconify/icons-ic/twotone-rocket';
import twotoneDescription from '@iconify/icons-ic/twotone-description';
import baselineAccessTime from '@iconify/icons-ic/baseline-access-time';

import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { loadOperationsAction } from 'src/app/api/operations/operations.actions';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';
import TaskType = Honeycomb.Common.Enums.TaskType;
import { Globals } from 'src/app/common/globals';

@Component({
  selector: 'store-visit-new',
  templateUrl: './store-visit-new.component.html',
  styleUrls: ['./store-visit-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreVisitNewComponent implements OnInit, OnDestroy {

  form: FormGroup;
  initialized$: Observable<boolean> = of(false);
  detailError$: Observable<string>;

  fileUploaded$: Observable<any> = of({});
  fileUploading$ = false;

  locations$: Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort>> = of([]);
  users$: Observable<Array<any>> = of([]);
  operations$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> = of([]);

  taskPhotos = [];


  dialogClosedSubscription: Subscription;
  locations: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort> = [];
  subscriptions: Array<Subscription> = [];

  icClose = icClose;
  twotoneMore = twotoneMore;
  twotoneRocket = twotoneRocket;
  baselineAccessTime = baselineAccessTime;
  twotoneDescription = twotoneDescription;

  freeTask = TaskType.freeTask;
  tenantHash: string = null;

  configKey = 'tasker.tasks.asignee-selectors';
  config: any;
  loggedUser: any;
  batchSelectors: Array<string> = [];
  agenda: TaskAgenda;
  startDate: Date;
  useTime = false;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<StoreVisitNewComponent>,
    private translate: TranslateService,
    private store: Store<AppState>,
    private snack: MatSnackBar,
    private trans: TranslateService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private globals: Globals,
    private _ngZone: NgZone) {

    this.form = this.fb.group({
      taskID: -1,
      name: this.fb.control('', Validators.required),
      description: this.fb.control(''),
      operationID: this.fb.control(null, Validators.required),
      priority: this.fb.control(2),
      locationID: this.fb.control(this.defaults ? this.defaults.locationID : null),
      address: this.fb.control(''),
      taskUsers: this.fb.array([], [ Validators.minLength(1) ]),
      startDate: this.fb.control(this.defaults ? this.defaults.startDate : new Date(), Validators.required),
      deadline: this.fb.control(null),

      userSelector: this.fb.control(null),
      newItemType: this.fb.control(0, Validators.required),
      contactInput:  this.fb.control(''),
    });

  }

  async ngOnInit() {
    this.agenda = this.defaults.agenda;
    this.useTime = this.defaults && this.defaults.useTime;
    this.store.dispatch(loadOperationsAction());
  }

  async save() {

    if (!this.form.valid) { return; }

  }

  showSnack(message: string, options?: MatSnackBarConfig) {
    this.snack.open(this.trans.instant(message),
        this.trans.instant('tasker.common.close'),
        Object.assign({ duration: 3000 }, options) as MatSnackBarConfig);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if (!!s) {
        s.unsubscribe()
      }
    });
  }
}
