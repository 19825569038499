<form [formGroup]="form" class="files-wrap">
    <div style="display: flex; flex-flow: column; height: 100%;">
        <div style="display: flex; flex-flow: column; flex: 1 1 auto;" id="file-container">
            <div *ngIf="currentTaskFiles.length === 0 && activityFiles.length === 0 && taskPhotos.length === 0 && currentMessageFiles.length === 0">
                {{ 'tasker.task-files.no-file' | translate }}
            </div>
            <h3 class="files-header" *ngIf="taskPhotos.length > 0">{{ 'tasker.task-files.task-photos' | translate }}</h3>
            <div class="photo-row" *ngIf="taskPhotos.length > 0">
                <div *ngFor="let photo of taskPhotos; let i = index" class="task-file-wrap photo-thumbnail" (click)="zoomAttachmentImage(photo.taskerFileUid)">
                    <img *ngIf="photo.taskerFileUid" [src]="getImageUrl(photo.taskerFileUid, 115, 80)">
                    <div class="time">{{ photo.validFromUTC | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
                </div>
            </div>
            <h3 class="files-header" *ngIf="activityFiles.length > 0">{{ 'tasker.task-files.activities-photos' | translate }}</h3>
            <div class="activity-photo-container" *ngIf="activityFiles.length > 0">
                <div *ngFor="let photo of activityFiles; let i = index" class="activity-photo" (click)="zoomAttachmentImage(photo.taskerFileUid)">
                    <div class="activity-file-wrap photo-thumbnail">
                        <div class="description" [title]="photo.activityName">{{ photo.activityName }}</div>
                        <div class="description" [title]="photo.inputName">{{ photo.inputName }}</div>
                        <div class="photo-wrapper">
                            <img *ngIf="photo.taskerFileUid" [src]="getImageUrl(photo.taskerFileUid, 115, 80)">
                            <!-- <img *ngIf="photo.isTemp" [src]="getTempImageUrl(photo.taskerFileUid, 115, 80)"> -->
                        </div>
                        <div class="time">{{ photo.validFromUTC | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
                    </div>
                </div>
            </div>
            <h3 class="files-header" *ngIf="currentMessageFiles.length > 0">{{ 'tasker.task-files.messages' | translate }}</h3>
            <div class="photo-row" *ngIf="currentMessageFiles.length > 0">
                <ng-container *ngFor="let mf of currentMessageFiles; let i = index" >
                    <div *ngIf="mf.imageUID" class="task-file-wrap photo-thumbnail" (click)="zoomAttachmentImage(mf.imageUID)">
                        <img [src]="getImageUrl(mf.imageUID, 115, 80)">
                        <div class="time">{{ mf.updateTime | date: 'd.M.yyyy HH:mm' }}</div>
                    </div>
                    <div *ngIf="mf.documentUID" class="task-file-wrap document-thumbnail" (click)="download(mf)">
                        <mat-icon [icIcon]="roundContentPaste"></mat-icon>
                        <div class="time">{{ mf.updateTime | date: 'd.M.yyyy HH:mm' }}</div>
                    </div>
                </ng-container>
            </div>
            <div class="ball-scale-multiple blue task-row" style="margin-top: 30px;" *ngIf="firstLoading | async">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h3 class="subtask-name" *ngIf="currentTaskFiles.length > 0">{{ 'tasker.task-files.task-attachments' | translate }}</h3>
            <div *ngFor="let file of currentTaskFiles; let i = index" class="task-file-wrap" fxLayoutGap="15px">
                <div><mat-icon [icIcon]="twotoneInsertDriveFile"></mat-icon></div>
                <div class="name" (click)="downloadFile(file)">{{ file.name }}</div>
                <div class="description">{{ file.description }}</div>
                <div class="time">{{ file.validFromUTC | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
                <!-- <div class="action remove" (click)="delete(file, $event)">{{ 'tasker.common.delete' | translate }}</div> -->
            </div>
            <div *ngFor="let subTask of subTasksFiles; let i = index">
                <h3 class="subtask-name">
                    <mat-icon [icIcon]="roundSubdirectoryArrowRight" color="primary" data-rotate="180deg"></mat-icon>#{{ subTask.taskID }} {{ subTask.name }}
                </h3>
                <!-- <div>{{ subTask.description }}</div> -->
                <div *ngFor="let file of subTask.taskAttachments; let i = index" class="task-file-wrap" fxLayoutGap="15px">
                    <div><mat-icon [icIcon]="twotoneInsertDriveFile"></mat-icon></div>
                    <div class="name" (click)="downloadFile(file)">{{ file.name }}</div>
                    <div class="description">{{ file.description }}</div>
                    <div class="time">{{ file.validFromUTC | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
                </div>
                <div class="photo-row" *ngIf="subTask.taskPhotos.length > 0">
                    <div *ngFor="let photo of subTask.taskPhotos; let i = index" class="task-file-wrap photo-thumbnail" (click)="zoomAttachmentImage(photo.taskerFileUid)">
                        <img *ngIf="photo.taskerFileUid" [src]="getImageUrl(photo.taskerFileUid, 115, 80)">
                        <div class="time">{{ photo.validFromUTC | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
                    </div>
                </div>
                
            </div>
        </div>
        <ngx-mat-file-input #inputFile style="display: none;" placeholder="" accept="*/*" capture="environment">
        </ngx-mat-file-input> 

    </div>
</form>