<div class="dropdown">
  <div class="dropdown-header" fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="space-between center"
    [formGroup]="sortForm">
    <div fxFlex="1 0 0%" fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
      </div>
      <div class="dropdown-heading">{{ 'tasker.common.sort' | translate }}</div>
    </div>
  </div>

  <div class="dropdown-content">
    <form fxFlex="1 0 100%" [formGroup]="sortForm">
      <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px">
        <div fxFlex fxLayoutGap="10px" *ngFor="let sortRow of sortForm.get('sortRows').value; let i = index" style="width: 100%;">
          <mat-form-field fxFlex="85%">
            <mat-label>{{ 'tasker.task-list-sort.level' | translate }} - {{ i }}</mat-label>
            <mat-select placeholder="{{ 'tasker.task-list-sort.level' | translate }} - {{ i }}"
              [(value)]="sortRow.column" (selectionChange)="sortPriorityChanged($event, i)">
              <mat-option *ngFor="let col of columns" [value]="col">
                {{ 'tasker.task-list-sort.column.' + col | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-icon *ngIf="sortRow.direction === direction.desc" (click)="toggleSortDirection(sortRow)" [icIcon]="outlineArrowDropDownCircle" fxFlex="1 0 auto" class="sortIcon desc" style="height: 60px;"></mat-icon>
          <mat-icon *ngIf="sortRow.direction === direction.asc" (click)="toggleSortDirection(sortRow)" [icIcon]="outlineArrowDropDownCircle" fxFlex="1 0 auto" class="sortIcon asc" style="height: 60px;" flip="vertical"></mat-icon>
        </div>
      </div>
    </form>
  </div>


  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="cancel()">{{ 'tasker.common.cancel' | translate }}</button>
    <button mat-button (click)="apply()">{{ 'tasker.common.apply' | translate }}</button>
  </div>

</div>