import { Action, createReducer, on } from '@ngrx/store';
import { isNullOrUndefined } from 'src/app/common/functions';
import { RoleAttributeTaskerValueModel } from './roleAttributeTaskerValue.model';
import * as CurrentUserActionTypes from './user.actions';
import UserState, { UserStateIFace } from './user.state';

const ContactsReducer = createReducer(UserState(),

// tslint:disable: max-line-length
  on(CurrentUserActionTypes.loadCurrentUserAction, (state) => ({...state, loading: true, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.loadCurrentUserCompletedAction, (state, { result }) => ({ ...state, currentUser: result, loading: false, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.loadCurrentUserFailedAction, (state, { error }) => ({ ...state, currentUser: null, error, loading: false, dashboard: null, selectedJobID: null } as UserStateIFace)),

  on(CurrentUserActionTypes.loadUIRolesAction, (state) => ({...state, loading: true, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.loadUIRolesCompletedAction, (state, { result }) => ({ ...state, uiroles: result, loading: false, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.loadUIRolesFailedAction, (state, { error }) => ({ ...state, uiroles: null, error} as UserStateIFace)),

  on(CurrentUserActionTypes.positionRecorderAction, (state) => ({...state, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.positionRecorderCompletedAction, (state, { locationWatchId }) => ({ ...state, locationWatchId, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.positionRecorderFailedAction, (state, { error }) => ({ ...state, error} as UserStateIFace)),

  on(CurrentUserActionTypes.attendanceLoadAction, (state) => ({...state, error: null, attendance: { loading: true} } as UserStateIFace)),
  on(CurrentUserActionTypes.attendanceLoadCompletedAction, (state, { result }) => ({ ...state, attendance: result,  error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.attendanceLoadFailedAction, (state, { error }) => ({ ...state, error, attendance: { loading: false}} as UserStateIFace)),

  on(CurrentUserActionTypes.attendanceSaveAction, (state) => ({...state, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.attendanceSaveCompletedAction, (state, { result }) => ({ ...state, attendance: result, error: null } as UserStateIFace)),
  on(CurrentUserActionTypes.attendanceSaveFailedAction, (state, { error }) => ({ ...state, error, attendance: { loading: false}} as UserStateIFace)),

  on(CurrentUserActionTypes.jobSelectAction, (state, { jobID }) => ({ ...state, selectedJobID: jobID, dashboard: null,
        selectedJobLocations: state.currentUser.userJobs.find(uj => uj.jobID === jobID).job.jobLocations.map(jl => jl.locationID) } as UserStateIFace)),
  on(CurrentUserActionTypes.jobSelectedAction, (state, { dashboard }) => ({ ...state, dashboard } as UserStateIFace)),
  // on(CurrentUserActionTypes.taskFilterSetDefaultAction, (state, { filterName,  dashboard}) => setDefaultFilter(state, filterName, dashboard)),
  // on(CurrentUserActionTypes.taskFilterSetFinishedAction, (state, { dashboard }) => ({ ...state, dashboard  } as UserState)),

);

export function reducer(state: UserStateIFace | undefined, action: Action) {
  return ContactsReducer(state, action);
}


