import { JwtToken } from './auth.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { STORAGE_TOKEN_KEY } from './auth.service';

export interface AuthState {
  tokenStrings: Array<string>;
  jwtTokens: Array<JwtToken>;
  jwtToken: JwtToken;
  userID: number;
  loading: boolean;
  error: string;
  tenantID: number;
  tenantHash: string;
  returnUrl: string;
  appRoleIDs: number[];
}

const jwtHelper = new JwtHelperService();

export const initializeState = (clean: boolean = false) => {

  const sessionToken = localStorage.getItem(STORAGE_TOKEN_KEY) || sessionStorage.getItem(STORAGE_TOKEN_KEY);
  if (!clean && sessionToken) {
    const decodedToken = jwtHelper.decodeToken(sessionToken) as JwtToken;

    let roleIDs = [];
    // null or undefined
    if (!!decodedToken.app_role_id) {
      if (!isNaN(Number(decodedToken.app_role_id))) { // is number
        roleIDs.push(Number(decodedToken.app_role_id));
      } else if (Array.isArray(decodedToken.app_role_id)) { // is array
        roleIDs = decodedToken.app_role_id.map(r => Number(r));
      }
    }

    return {
      error: null,
      loading: false,
      tokenStrings: [],
      jwtToken: decodedToken,
      jwtTokens: [decodedToken],
      userID: Number(decodedToken.sub),
      tenantID: Number(decodedToken.TenantID),
      tenantHash: decodedToken.TenantHash,
      returnUrl: null,
      appRoleIDs: roleIDs
    }
  }

  if (clean) {
    if(localStorage.getItem(STORAGE_TOKEN_KEY)) {
      localStorage.removeItem(STORAGE_TOKEN_KEY);
    }
    if (sessionStorage.getItem(STORAGE_TOKEN_KEY)) {
      sessionStorage.removeItem(STORAGE_TOKEN_KEY);
    }
  }

  return {
    tokenStrings: [],
    error: null,
    loading: false,
    jwtToken: null,
    jwtTokens: null,
    userID: null,
    tenantID: null,
    tenantHash: null,
    returnUrl: null,
    appRoleIDs: null
  } as AuthState;
};
