import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import twotoneMore from '@iconify/icons-ic/twotone-more';
import twotoneRocket from '@iconify/icons-ic/twotone-rocket';
import twotoneTimerOff from '@iconify/icons-ic/twotone-timer-off';
import baselineSort from '@iconify/icons-ic/baseline-sort';
import twotoneSpeakerNotes from '@iconify/icons-ic/twotone-speaker-notes';
import twotoneDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import twotoneAddBox from '@iconify/icons-ic/twotone-add-box';
import twotonePersonAdd from '@iconify/icons-ic/twotone-person-add';
import baselinePhonelinkSetup from '@iconify/icons-ic/baseline-phonelink-setup';
import twotoneAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import baselineAssignment from '@iconify/icons-ic/baseline-assignment';
import twotoneAssignment from '@iconify/icons-ic/twotone-assignment';
import twotoneAssignmentReturn from '@iconify/icons-ic/twotone-assignment-return';
import sharpPostAdd from '@iconify/icons-ic/sharp-post-add';
import roundAttachFile from '@iconify/icons-ic/round-attach-file';
import certificateIcon from '@iconify/icons-fa-solid/certificate';
import twotoneStore from '@iconify/icons-ic/twotone-store';
import twotoneLocationOn from '@iconify/icons-ic/twotone-location-on';
import roundAddCircleOutline from '@iconify/icons-ic/round-add-circle-outline';
import roundContentPaste from '@iconify/icons-ic/round-content-paste';
import roundCancelPresentation from '@iconify/icons-ic/round-cancel-presentation';
import roundRepeat from '@iconify/icons-ic/round-repeat';
import twotoneDescription from '@iconify/icons-ic/twotone-description';

import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { loadOperationsAction } from 'src/app/api/operations/operations.actions';
import { debounceTime, tap, distinctUntilChanged, map, first, take, filter, catchError, takeUntil, share } from 'rxjs/operators';
import { TaskActivitiesComponent } from '../task-activities/task-activities.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TaskNewComponent } from '../task-new/task-new.component';
import { Globals } from 'src/app/common/globals';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatSelectChange } from '@angular/material/select';
import { FileInputComponent } from 'ngx-material-file-input';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PromptDialogService } from '../../common/prompt-dialog/prompt-dialog.service';
import { DialogButtonType, StructuredDialogContent } from '../../common/prompt-dialog/prompt-dialog.model';
import { TaskSummaryComponent } from '../task-summary/task-summary.component';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AppState } from 'src/app/app.states';
import { enumToStrings, enumToValues, isNullOrUndefined, isNullOrWhitespace, zoomScrollable } from 'src/app/common/functions';
import { AppStatusService } from 'src/@vex/services/app-status.service';
import { UserStateIFace } from 'src/app/api/user/user.state';
import { TaskActivityErrorComponent } from '../task-activity-error/task-activity-error.component';
import { RouterHistoryService } from 'src/@vex/services/route-history.service';
import { MessageRef } from 'src/@vex/components/messages/message-list/message-list';
import { Router } from '@angular/router';
import { BuildConfigService } from 'src/app/services/build-config.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TaskDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  private fb: FormBuilder = null;
  public messageRef = MessageRef;
  public mkDate: Date = null;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<TaskDetailComponent>,
              private dialogTaskErrorRef: MatDialogRef<TaskActivityErrorComponent>,
              private dialogOver: MatDialog,
              private globals: Globals,
              // tslint:disable: max-line-length
              @Inject('LookupLocationController') private lookupLocationController: Honeycomb.Tenant.LookupTables.IService.LocationsController,
              @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
              @Inject('OperationController') private operationController: Honeycomb.Tenant.Tasker.IService.Controller.OperationController,
              @Inject('ContactJobController') private jobController: Honeycomb.Tenant.Contact.IService.Controller.JobController,
              @Inject('MessageController') private messageController: Honeycomb.Tenant.Tasker.IService.Controller.MessageController,
              @Inject('CodeListController') private codeListController: Honeycomb.Tenant.LookupTables.IService.CodeListController,
              @Inject('ContactController') private contactController: Honeycomb.Tenant.Contact.IService.Controller.ContactController,
              @Inject('FileController') private fileController: Honeycomb.Tenant.Tasker.IService.Controller.FileController,
              @Inject('FileControllerCustom') private fileControllerCustom: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
              @Inject('ReportController') private reportController: Honeycomb.Tenant.Reports.IService.ReportController,
              @Inject('TaskActivityController') private activityController: Honeycomb.Tenant.Tasker.IService.Controller.TaskActivityController,
              @Inject('TaskStatusController') private taskStatusController: Honeycomb.Tenant.Tasker.IService.Controller.TaskStatusController,
              @Inject('ConfigController') private configController: Honeycomb.Tenant.Admin.IService.NamedConfigurationsController,
              // tslint:enable: max-line-length
              private store: Store<AppState>,
              private snack: MatSnackBar,
              private cd: ChangeDetectorRef,
              private trans: TranslateService,
              private appStatus: AppStatusService,
              private routeHistory: RouterHistoryService,
              public buildConfig: BuildConfigService,
              private router: Router,
              fb: FormBuilder,
              private permissionService: NgxPermissionsService,
              private promptDialog: PromptDialogService) {

    this.fb = fb;

    this.form = this.fb.group({
      name: this.fb.control({ value: '', disabled: !this.hasPermission('name.edit') }),
      description: this.fb.control({ value: '', disabled: !this.hasPermission('description.edit') }),
      operationID: [ -1 ],
      priority: this.fb.control({ value: null } ),
      // priority: this.fb.control(null),
      creator: this.fb.array([]),
      contactInputAssignee: this.fb.control({ value: null, disabled: (() => !this.hasPermission('assignee.edit'))() } ),
      assignee: this.fb.array([]),
      contactInputObserver: this.fb.control({ value: null, disabled: (() => !this.hasPermission('observer.edit'))() } ),
      observer: this.fb.array([]),
      contactInputResponsible: this.fb.control({ value: null, disabled: (() => !this.hasPermission('responsible.edit'))() } ),
      responsible: this.fb.array([]),
      created: this.fb.control({ value: null, disabled: true } ),
      // this will destroy datePicker
      // startDate: this.fb.control({ value: null, disabled: (() => !this.hasPermission('startDate.edit'))() } ),
      startDate: this.fb.control({ value: null, disabled: (() => false )() } ),
      deadline: this.fb.control({ value: null, disabled: (() => false )() } ),
      locationName: null,
      locationID: this.fb.control({ value: null, disabled: (() => !this.hasPermission('location.edit'))() }),
      address: null,
      taskStatus: null,
      taskStatusID: this.fb.control({ value: null, disabled: (() => !this.hasPermission('status.edit') || (this.task.taskStatusBase === this.taskStatus.archived && !this.statusEditForced))() }),
      taskAttributesUpsert: this.fb.array([]),
      taskID: null,
      taskActivitiesUpsert: this.fb.array([this.taskActivities])
    });

    this.operationsLoading$ = of(true);
    this.operations$ = this.operationController.List()
                           .pipe(
                             tap(_ => this.operationsLoading$ = of(false))
                            );

    this.currentUser$ = store.pipe(select(x => x.user.currentUser)).pipe(filter(u => !!u));

    // Should have been loaded already
    store.select('user').pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged()).subscribe(
      s => { 
        this.userState = s;
        this.uiroles = s.uiroles;
      }
    );

    
    this.selectedJobID = this.userState.selectedJobID;

    this.detailError$ = store.pipe(select(x => x.myTasks.detailError));

    this.userSubscription = this.currentUser$.subscribe(cu => {
      this.currentUserId = cu.userId;
    });
    this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);

    if (!!this.dialogRef.beforeClosed) { // null on direct detail url
      
      var s = this.dialogRef.beforeClosed().pipe(
        take(1),
        map(() => {
        this.saveDialogStatus();
        this.dialogRef.close(this.returnValue);
        if (window.location.pathname && window.location.pathname.includes('/my-tasks/main/detail/')) {
          let prevUrl = this.routeHistory.previousUrl$.getValue();
          if (prevUrl && !prevUrl.includes('login') && prevUrl !== "/" ) { // do not redirect to login - causes logout, do not redirect to blank page
            window.history.back();
          } else {
            this.router.navigate(['/my-tasks/main']);
          }
        }
      })).subscribe();
      this.subscriptions.push(s);
    }
  }

  get taskActivities(): FormGroup {
    return this.fb.group({
      taskActivityID: null,
      activityID: null,
      statusID: null,
      taskType: null,
      name: null,
      duration: null,
      activityDone: false,
      activityInputs: this.fb.array([
        this.initInputs
      ])
    });
  }

  get initInputs() {
    return this.fb.group({
      name: null,
      description: null,
      infoText: null,
      inputID: null,
      uitype: null,
      codeListID: null,
      required: null,
      activityInputID: null,
      sortingOrder: null,
      meaningID: null,
      regex: null,
      inputParams: null,
      recordUid: this.fb.control(''),
      inputValue: this.fb.control(''),
      inputNote: this.fb.control('')
    });
  }

  get creatorContacts$(): Array<Honeycomb.Tenant.Contact.IService.ContactPublic> {
    return this.getContactsByType(Honeycomb.Common.Enums.TaskRelation.creator);
  }

  get assigneeContacts$(): Array<Honeycomb.Tenant.Contact.IService.ContactPublic> {
    return this.getContactsByType(Honeycomb.Common.Enums.TaskRelation.assignee);
  }

  get assignerContacts$(): Array<Honeycomb.Tenant.Contact.IService.ContactPublic> {
    return this.getContactsByType(Honeycomb.Common.Enums.TaskRelation.assigner);
  }

  get observerContacts$(): Array<Honeycomb.Tenant.Contact.IService.ContactPublic> {
    return this.getContactsByType(Honeycomb.Common.Enums.TaskRelation.observer);
  }

  get responsibleContacts$(): Array<Honeycomb.Tenant.Contact.IService.ContactPublic> {
    return this.getContactsByType(Honeycomb.Common.Enums.TaskRelation.responsible);
  }

  public get selectedActivityIndex(): number {
    if (!this.activities) {
      return 0;
    }
    return this.activities.selectedActivityIndex;
  }

  public get getTaskActivityPosition(): string {
    if (!this.activities) {
      return '';
    }
    const cu = this.activities.currentOf;
    return `${cu[0]} / ${cu[1]}`;
  }

  public get isLastActivity(): boolean {
    if (!this.activities) {
      return false;
    }
    return this.activities.isLastActivity;
  }

  public get activityButtonColor(): string {
    if (!this.activities) {
      return 'primary';
    }
    return !this.activities.isLastActivity ? 'primary' : 'accent';
  }

  public get parentReference() {
    return this;
  }

  public static ExternalSupplier = 'ExternalSupplier';
  public static RepairType = 'RepairType';
  public static RepairStatus = 'RepairStatus';

  private selectedJobID: number;
  public activitiesTabVisible = true;
  public sequenceTabVisible = true;
  private selectedTabName: string = null;
  private roleDebug = [];
  public hasParentTaskAccess = false;
  public statusEditForced = false;
  private userState: UserStateIFace;
  public isLastInSequence: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isMainTabSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public summaryLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public taskStatuses$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>> = of([]);
  public taskStatusesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public taskStatusesAll: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus> = [];
  public canReject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedStatusName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public changeWatchControls = {
    controls: [] as Array<AbstractControl>,
    photos: [],
    attachments: [],
    taskPhotos: [],
    taskAttachments: []
  };
  public changeWatchValues = [];

  form: FormGroup = null;

  @ViewChild(TaskActivitiesComponent, { static: false }) activitiesComponent: TaskActivitiesComponent;
  @ViewChild('inputPhoto') inputPhotoRef: FileInputComponent;
  @ViewChild('inputAttachment') inputAttachmentRef: FileInputComponent;
  @ViewChild('contactInputObserver') contactInputObserver: ElementRef<HTMLInputElement>;
  @ViewChild('contactInputAssignee') contactInputAssignee: ElementRef<HTMLInputElement>;
  @ViewChild('contactInputResponsible') contactInputResponsible: ElementRef<HTMLInputElement>;
  @ViewChild('activities') activities: TaskActivitiesComponent;
  @ViewChild(MatTabGroup, { static: false }) tabs: MatTabGroup;
  @ViewChild('summary') summaryComponent: TaskSummaryComponent;
  @ViewChild('tabActivities', { static: false }) tabActivities: MatTab;
  @ViewChild('tabMain', { static: false }) tabMain: MatTab;
  @ViewChild('tabSummary', { static: false }) tabSummary: MatTab;
  @ViewChild('tabFollowup', { static: false }) tabFollowup: MatTab;
  @ViewChild('tabMessage', { static: false }) tabMessage: MatTab;
  @ViewChild('tabAttachments', { static: false }) tabAttachments: MatTab;
  @ViewChild('tabSequence', { static: false }) tabSequence: MatTab;

  allTabs: Array<MatTab> = [];

  selectedTabIndex = 0;

  switchActivity = false;
  inputPhotoEnabled = false;

  task: Honeycomb.Tenant.Tasker.IService.Model.Task;
  messages: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage> = [];
  messagesLoading = of(true);

  taskFiles: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> = [];
  messagesSimple: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage> = [];
  taskFilesLoading = of(true);

  externalSuppliers: BehaviorSubject<Array<Honeycomb.Tenant.LookupTables.IService.CodeValue>> = new BehaviorSubject([]);
  repairTypes: BehaviorSubject<Array<Honeycomb.Tenant.LookupTables.IService.CodeValue>> = new BehaviorSubject([]);
  repairStatuses: BehaviorSubject<Array<Honeycomb.Tenant.LookupTables.IService.CodeValue>> = new BehaviorSubject([]);
  public taskAttributes = [];

  initialized$: Observable<boolean> = of(false);
  detailLoading$: Observable<boolean> = of(false);
  detailSaving$: Observable<boolean> = of(false);
  detailSaved$: Observable<boolean> = of(false);
  detailError$: Observable<string>;

  users$: Observable<Array<any>> = of([]);

  contacts: Array<Honeycomb.Tenant.Contact.IService.ContactPublic> = [];
  contacts$: BehaviorSubject<Array<Honeycomb.Tenant.Contact.IService.ContactPublic>> = new BehaviorSubject([]);

  locations$: Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort>> = of([]);
  locations: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort> = [];

  taskSequence$: BehaviorSubject<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> = new BehaviorSubject([]);

  operations$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> = of([]);
  currentUser$: Observable<Honeycomb.Tenant.Contact.IService.UserInfo> = of(null);
  operationsLoading$: Observable<boolean> = of(false);
  contactsLoading$: Observable<boolean> = of(false);
  

  taskSubscription: Subscription;
  saveSubscription: Subscription;
  userSubscription: Subscription;
  subscriptions: Array<Subscription> = [];

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;

  icPerson = icPerson;
  icMyLocation = icMyLocation;
  icLocationCity = icLocationCity;
  icEditLocation = icEditLocation;
  icPhone = icPhone;
  twotoneMore = twotoneMore;
  twotoneRocket = twotoneRocket;
  twotoneTimerOff = twotoneTimerOff;
  baselineSort = baselineSort;
  twotoneSpeakerNotes = twotoneSpeakerNotes;
  twotoneDeleteForever = twotoneDeleteForever;
  twotoneAddBox = twotoneAddBox;
  twotonePersonAdd = twotonePersonAdd;
  baselinePhonelinkSetup = baselinePhonelinkSetup;
  twotoneAssignmentTurnedIn = twotoneAssignmentTurnedIn;
  baselineAssignment = baselineAssignment;
  twotoneAssignment = twotoneAssignment;
  twotoneAssignmentReturn = twotoneAssignmentReturn;
  sharpPostAdd = sharpPostAdd;
  roundAttachFile = roundAttachFile;
  certificateIcon = certificateIcon;
  twotoneStore = twotoneStore;
  twotoneLocationOn = twotoneLocationOn;
  roundAddCircleOutline = roundAddCircleOutline;
  roundContentPaste = roundContentPaste;
  roundCancelPresentation = roundCancelPresentation;
  roundRepeat = roundRepeat;
  twotoneDescription = twotoneDescription;

  operationName: string;
  tenantHash: string = null;

  taskPhotos = [];
  taskAttachments = [];

  fileUploaded$: Observable<any> = of({});
  fileUploading$ = false;
  imageUID: string;

  taskPriority = Honeycomb.Common.Enums.Priority;
  taskType = Honeycomb.Common.Enums.TaskType;
  taskStatus = Honeycomb.Common.Enums.TaskState;
  taskRelation = Honeycomb.Common.Enums.TaskRelation;

  // taskStatusValues: any[] = [];

  priorityValues: any[] = [];

  uiroles: string[] = [];

  currentUserId: number;
  isAssigned = false;
  isAssigner = false;
  isCreator = false;

  visible = true;

  forceSummaryReload = false;

  continueButtonVisible = false;

  returnValue = {
      saved: false,
      redirect: null,
      forceReload: false, // when follow up task added
  };

  public isTaskType(taskType: Honeycomb.Common.Enums.TaskType)
  {
    return !!this.task && !!this.task.operation && this.task.operation.taskType === taskType;
  }

  public isNotTaskType(taskType: Honeycomb.Common.Enums.TaskType)
  {
    return !!this.task && !!this.task.operation && this.task.operation.taskType !== taskType;
  }

  private getContactsByType(relation: Honeycomb.Common.Enums.TaskRelation) {
    return this.contacts.filter((c: Honeycomb.Tenant.Contact.IService.ContactPublic) =>
        this.task.taskUsers.filter(u => u.taskRelation === relation)
          .map(u => u.userID).indexOf(c.userId) > -1);
  }

  async close() {
    await this.mainTabChangedCheck();
    if (!!this.dialogRef.close) { // null on detail only
      this.dialogRef.close();
    }
  }

  async ngOnInit() {
    
  }

  async LoadTask(): Promise<boolean> {
    const hasAccess = await lastValueFrom(this.taskController.HasAccess(this.selectedJobID, this.defaults.taskID));

    if (!hasAccess && !!this.dialogRef.close) { // close null on detail only
      this.dialogRef.close();
      this.showSnack('tasker.common.access-denied');
      return false;
    }

    await this.refreshTask(this.defaults.taskID);

    await this.initForm(this.task);
    this.cd.detectChanges();

    return true;
  }

  public async refreshTask(taskID: number): Promise<Honeycomb.Tenant.Tasker.IService.Model.Task> {
    this.task = await lastValueFrom(this.taskController.Detail(taskID)
    .pipe(tap(async t => {
      this.operationName = t.operation.name;

      const userRelations = t.taskUsers.filter(tu => tu.userID === this.currentUserId);
      this.isAssigned = userRelations.some(tu => tu.taskRelation === Honeycomb.Common.Enums.TaskRelation.assignee);
      this.isAssigner = userRelations.some(tu => tu.taskRelation === Honeycomb.Common.Enums.TaskRelation.assigner);
      this.isCreator = userRelations.some(tu => tu.taskRelation === Honeycomb.Common.Enums.TaskRelation.creator);

      this.contacts = await lastValueFrom(this.contactController.PublicByUserIdList({ userIDs: t.taskUsers.map(tu => tu.userID) }));
      this.messagesLoading = of(t.messagesTotal > 0);
      t.taskAttributes.forEach(ta => {
        let taExisting = this.taskAttributes.find(tae => tae.taskAttributeTypeName === ta.taskAttributeType.name);
        if (!taExisting) { // do not change already created attributes, value can be in certain type, not string
          this.taskAttributes.push({
            taskAttributeTypeName: ta.taskAttributeType.name,
            value: ta.value
          });
        }
      });
    })));
    return this.task;
  }

  async ngAfterViewInit() {
    this.dialogRef.disableClose = true;

    if (!!this.dialogRef.backdropClick) { // null on direct detail url
      this.dialogRef.backdropClick().subscribe(async _ => {
        await this.mainTabChangedCheck();
        this.dialogRef.close();
      });
    }
    
    this.priorityValues = enumToValues(Honeycomb.Common.Enums.Priority);

    if (this.defaults) {
      if (this.defaults.task) {
        await this.initForm(this.defaults.task);
      } else {

        const loadTaskRes = await this.LoadTask();

        if (!loadTaskRes) {
          return;
        }

        await this.loadAttributes();

        this.store.select(s => s.user).pipe(
            filter(u => !!u.selectedJobLocations),
            map(u => u.selectedJobLocations),
            distinctUntilChanged(),
            tap(jl => {
              let searchLocations = (this.task.locationID === null ? [] : [this.task.locationID]).concat(jl);
              searchLocations = searchLocations.filter((n, i) => searchLocations.indexOf(n) === i);
              var locationsRequest: Honeycomb.Tenant.LookupTables.IService.Model.LocationRequest = {
                specificLocations: jl,
                specificLocationsFilter: null
              };
              
              this.locations$ = this.lookupLocationController
                                  .ListSimpleLong('OnlyMyLocationsWorkaround', null, null, true, null, null, null, null, locationsRequest)
                                  .pipe(tap(l => {
                                    this.locations = l;
                                    this.cd.detectChanges();
                                  }));
            })
          ).subscribe(_ => null);
      }
    } else {
      this.defaults = new Honeycomb.Tenant.Tasker.IService.Model.Task();
    }
    
    this.store.dispatch(loadOperationsAction());

     // tslint:disable-next-line: max-line-length
     this.allTabs = [this.tabActivities, this.tabMain, this.tabSummary, this.tabSequence, this.tabFollowup, this.tabMessage, this.tabAttachments ];

     this.loadViewStatus(this.defaults.taskID);

    this.initialized$ = of(true);

    await this.refreshSequence();

    // hack for Chrome on iOS to avoid hiding buttons under browser bar for modal
    var isChromeOniOS = navigator.userAgent.indexOf('CriOS') >= 0;  // iOS&Chrome?
    if (isChromeOniOS) {
      var body = document.body;
      body.classList.add('chrome-ios');
    }
  }

  private async loadAttributes() {
    if (this.buildConfig.taskDetailRepairAttribute("ExternalSupplier", this.task)) {
      this.externalSuppliers.next(await lastValueFrom(this.codeListController.DetailByName(TaskDetailComponent.ExternalSupplier)
      .pipe(
        take(1),
        map(cl => cl.codes),
        map(c => c.sort((a, b) => a.orderIndex - b.orderIndex)),
        map(c => c.map(cl => {
            return cl.codeValues[this.trans.getDefaultLang()] || cl.codeValues.default;
            }
          )
        )
      )));
    }

    if (this.buildConfig.taskDetailRepairAttribute("RepairType", this.task)) {
      this.repairTypes.next(await lastValueFrom(this.codeListController.DetailByName(TaskDetailComponent.RepairType)
      .pipe(
        take(1),
        map(cl => cl.codes),
        map(c => c.sort((a, b) => a.orderIndex - b.orderIndex)),
        map(c => c.map(cl => {
            return cl.codeValues[this.trans.getDefaultLang()] || cl.codeValues.default;
            }
          )
        )
      )));
    }

    if (this.buildConfig.taskDetailRepairAttribute("RepairStatus", this.task)) {
      this.repairStatuses.next(await lastValueFrom(this.codeListController.DetailByName(TaskDetailComponent.RepairStatus)
      .pipe(
        take(1),
        map(cl => cl.codes),
        map(c => c.sort((a, b) => a.orderIndex - b.orderIndex)),
        map(c => c.map(cl => {
            return cl.codeValues[this.trans.getDefaultLang()] || cl.codeValues.default;
            }
          )
        )
      )));
    }
  }

  getDisplayValue(arrayName: string, codeId: number): string {
    var supplier;
    
    if (arrayName == "external")
    {
      supplier = this.externalSuppliers.value.find(s => s.codeId === codeId);
    }

    if (arrayName == "repair")
    {
      supplier = this.repairTypes.value.find(s => s.codeId === codeId);
    }
    
    return supplier ? supplier.displayValue : '';
  }

  public async loadFormInternal(t: Honeycomb.Tenant.Tasker.IService.Model.Task, disableMainTabReload?: boolean) {
    const tas = this.taskActivities;
    const taArray = !tas ? this.fb.array([]) : this.fb.array([tas]);

    this.form = this.fb.group({
      taskID: null,
      name: this.fb.control({ value: '', disabled: !this.hasPermission('name.edit') }),
      description: this.fb.control({ value: '', disabled: !this.hasPermission('description.edit') }),
      operationID: [ -1 ],
      priority: this.fb.control({ value: null /*, disabled: ( () => !this.hasPermission('priority.edit'))()*/  }  ),
      // priority: this.fb.control(null),
      creator: this.fb.array([]),
      contactInputAssignee: this.fb.control({ value: null, disabled: !this.hasPermission('assignee.edit') } ),
      assignee: this.fb.array([]),
      contactInputObserver: this.fb.control({ value: null, disabled: !this.hasPermission('observer.edit') } ),
      observer: this.fb.array([]),
      contactInputResponsible: this.fb.control({ value: null, disabled: !this.hasPermission('responsible.edit') } ),
      responsible: this.fb.array([]),
      created: this.fb.control({ value: null, disabled: true } ),
      startDate: this.fb.control({ value: null, disabled: (() => !this.hasPermission('startDate.edit'))() } ),
      deadline: this.fb.control({ value: null, disabled: (() => !this.hasPermission('deadline.edit'))() } ),
      locationName: null,
      locationID: this.fb.control({ value: null, disabled: (() => !this.hasPermission('location.edit'))() }),
      address: null,
      taskStatus: null,
      taskStatusID: this.fb.control({ value: null, disabled: !this.hasPermission('status.edit') || (this.task.taskStatusBase === this.taskStatus.archived && !this.statusEditForced) }),
      taskAttributesUpsert: this.fb.array([]),
      taskActivitiesUpsert: taArray
    });

    if (this.hasPermission('priority.edit')) {
      this.form.get('priority').enable()
    } else {
      this.form.get('priority').disable();
    } 

    /* ExtSupplier */
    if (this.buildConfig.taskDetailRepairAttribute('ExternalSupplier', this.task)) {
      const taExtSupplier = t.taskAttributes.find(taEx =>  taEx.taskAttributeType.name === TaskDetailComponent.ExternalSupplier);
      let taExtSupplierValue = null;
      if (!!taExtSupplier) {
        taExtSupplierValue = taExtSupplier.value;
      }
      this.taskAttributes = [];
      this.taskAttributes.push({
        taskAttributeTypeName: TaskDetailComponent.ExternalSupplier,
        value: Number(taExtSupplierValue)
      });
    }

    /* RepairStatus */
    if (this.buildConfig.taskDetailRepairAttribute('RepairStatus', this.task)) {
      const repairStatus = t.taskAttributes.find(taEx =>  taEx.taskAttributeType.name === TaskDetailComponent.RepairStatus);
      let repairStatusValue = null;
      if (!!repairStatus) {
        repairStatusValue = repairStatus.value;
      }
      this.taskAttributes.push({
        taskAttributeTypeName: TaskDetailComponent.RepairStatus,
        value: Number(repairStatusValue)
      });
    }

    /* RepairType */
    if (this.buildConfig.taskDetailRepairAttribute('RepairType', this.task)) {
      const repairType = t.taskAttributes.find(taEx =>  taEx.taskAttributeType.name === TaskDetailComponent.RepairType);
      let repairTypeValue = null;
      if (!!repairType) {
        repairTypeValue = repairType.value;
      }
      this.taskAttributes.push({
        taskAttributeTypeName: TaskDetailComponent.RepairType,
        value: Number(repairTypeValue)
      });
    }

    if (!this.saveSubscription) {
      this.saveSubscription = this.detailSaved$.pipe(first()).subscribe(r => {
          this.returnValue.saved = !!r;
      });
    }

    const formAttributes = this.form.get('taskAttributesUpsert') as FormArray;

    if (!disableMainTabReload) {
      [this.form.get('contactInputAssignee'),
      this.form.get('contactInputObserver'),
      this.form.get('contactInputResponsible')]
      .forEach(c =>
       c.valueChanges.pipe(
         debounceTime(300),
         distinctUntilChanged(),
         filter(value => value !== null && typeof(value) === 'string'),
         map(async (value: any) => {
           if (value.trim().length < 3) {
               this.users$ = of([]);
               this.showSnack('tasker.common.min-3-chars');
           } else {
               const found = await lastValueFrom(this.jobController.UsersForOperation(
                 {
                   userID: this.currentUserId,
                   jobID: this.selectedJobID,
                   operationID: this.form.get('operationID').value,
                   treshold: 1,
                   search: value,
                   includeDeleted: false
                  }));
               this.users$ = of(found);
               if (!found.length) { this.showSnack('tasker.common.no-matching-record'); }
           }
           this.cd.detectChanges();
         })).subscribe(_ => null)
       );

      this.form.get('name').setValue(t.name);
      this.form.get('description').setValue(t.description);
      this.form.get('priority').setValue(t.priority);
      this.form.get('startDate').setValue(t.startDate);
      this.form.get('deadline').setValue(t.deadline);
      this.form.get('locationID').setValue(t.locationID);
      this.form.get('taskStatusID').setValue(t.taskStatusID);

      [this.form.get('contactInputAssignee'),
      this.form.get('contactInputObserver'),
      this.form.get('contactInputResponsible')]
      .forEach(c =>
       c.valueChanges.pipe(
         debounceTime(300),
         distinctUntilChanged(),
         filter(value => value !== null && typeof(value) === 'string'),
         map(async (value: any) => {
           if (value.trim().length < 3) {
               this.users$ = of([]);
               this.showSnack('tasker.common.min-3-chars');
           } else {
               const found = await lastValueFrom(this.jobController.UsersForOperation(
                 {
                   userID: this.currentUserId,
                   jobID: this.selectedJobID,
                   operationID: this.form.get('operationID').value,
                   treshold: 1,
                   search: value,
                   includeDeleted: false
                  }));
               this.users$ = of(found);
               if (!found.length) { this.showSnack('tasker.common.no-matching-record'); }
           }
           this.cd.detectChanges();
         })).subscribe(_ => null)
       );

      this.form.get('name').setValue(t.name);
      this.form.get('description').setValue(t.description);
      this.form.get('priority').setValue(t.priority);
      this.form.get('startDate').setValue(t.startDate);
      this.form.get('deadline').setValue(t.deadline);
      this.form.get('locationID').setValue(t.locationID);

      this.form.get('taskStatusID')
          .valueChanges
          .pipe(
            distinctUntilChanged(),
            tap(v => {
              const tas = this.taskStatusesAll.find(ta => ta.taskStatusID === v);
              if (tas) {
                this.task.taskStatusBase = tas.taskStatusBase;
                this.task.taskStatusCode = tas.code;
                this.selectedStatusName.next(tas.name);
              }
            }))
          .subscribe(_ => null);

      this.taskAttributes.forEach(tAttr => {
        formAttributes.push(this.fb.group(tAttr));
      });
    }

    this.form.get('taskID').setValue(t.taskID);
    this.form.get('operationID').setValue(t.operationID);
    this.form.get('created').setValue(t.created);
    this.form.get('locationName').setValue(t.locationName);
    this.form.get('address').setValue(t.address);

    this.changeWatchControls.controls = [
      this.form.controls.name,
      this.form.controls.description,
      this.form.controls.priority,
      this.form.controls.startDate,
      this.form.controls.deadline,
      this.form.controls.locationID,
      this.form.controls.assignee,
      this.form.controls.creator,
      this.form.controls.observer,
      this.form.controls.responsible,
      formAttributes
    ];

    if (this.task.operation.taskType === Honeycomb.Common.Enums.TaskType.freeTask) {
      this.changeWatchControls.controls.push(this.form.controls.taskStatus);
    }

    this.storeChangeDetectValues();

    if (t.taskUsers && t.taskUsers.length > 0) {
      const userIDs = t.taskUsers.map(tu => tu.userID);
      const reqModel = new Honeycomb.Tenant.Contact.IService.ContactByUserIDsRequest();
        reqModel.userIDs = userIDs;
      this.contacts = await lastValueFrom(this.contactController.PublicByUserIdList(reqModel));
      this.contacts$.next(this.contacts);
    } else {
      this.contacts$.next([]);
    }

    this.form.controls.assignee = this.fb.array(this.assigneeContacts$);
    this.form.controls.creator = this.fb.array(this.creatorContacts$);
    this.form.controls.observer = this.fb.array(this.observerContacts$);
    this.form.controls.responsible = this.fb.array(this.responsibleContacts$);

    const formActivities = this.form.get('taskActivitiesUpsert') as FormArray;
    this.setTaskActivitiesFormArray(formActivities, t);

    this.activitiesTabVisible = !!this.task && !!this.task.operation
                                        && this.task.operation.taskType !== Honeycomb.Common.Enums.TaskType.freeTask
                                        // tslint:disable-next-line: max-line-length
                                        && this.task.taskStatusBase !== Honeycomb.Common.Enums.TaskState.archived;

    this.sequenceTabVisible = !!this.task && !!this.task.taskSequenceID;

    if (!!this.task.parentTaskID) {
      this.hasParentTaskAccess = await lastValueFrom(this.taskController.HasAccess(this.selectedJobID, this.task.parentTaskID));
    }

    const s = formActivities.valueChanges.pipe(tap(v => {
      this.forceSummaryReload = true;
      this.summaryLoaded.next(false);
    })).subscribe(_ => null);
    this.subscriptions.push(s);

    this.taskStatuses$ = this.taskStatusController.List(null, this.selectedJobID)
      .pipe(
        tap(a => { 
          this.taskStatusesAll = a;
          const taskStatus = this.taskStatusesAll.find(a => a.taskStatusID === this.task.taskStatusID);
          if (!!taskStatus) {
            this.selectedStatusName.next(taskStatus.name);
          }
        }),
        map(a => {
          
          return a
          // or already selected (for some old tasks) and not archived base status
            .filter(c => 
                (c.taskStatusBase !== Honeycomb.Common.Enums.TaskState.archived || c.taskStatusID === t.taskStatusID || c.code === 'rejected' )
              )
            // task statuses that are available for operationID 
            .filter(c => c.operationStatuses.find(os => os.operationID === t.operationID));
        }),
        tap(a => {
          this.canReject.next(!!a.find(ts => ts.code === 'rejected' ));
          this.taskStatusesLoaded.next(true);
      }));

      await this.taskStatuses$.toPromise();
  }

  private setTaskActivitiesFormArray(formActivities: FormArray, t: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    formActivities.clear();
    t.taskActivities.forEach(ta => {

      const activityGroup = this.fb.group({
        taskActivityID: ta.taskActivityID,
        isSnapshot: !isNullOrUndefined(t.operationSnapshotID),
        activityID: ta.activityID,
        statusID: ta.statusID,
        taskType: this.fb.control(ta.activity.taskType),
        name: this.fb.control(ta.activity.name),
        duration: this.fb.control(ta.duration),
        activityDone: this.fb.control(ta.status.name === 'closed'),
        activityInputs: this.fb.array(ta.activity.activityInputs.map((ai) => (this.fb.group({
          name: ai.input.name,
          isSnapshot: !isNullOrUndefined(t.operationSnapshotID),
          description: ai.input.description,
          infoText: ai.input.infoText,
          inputID: ai.inputID,
          activityInputID: ai.activityInputID,
          sortingOrder: ai.sortingOrder,
          uitype: ai.input.uitype,
          codeListID: ai.input.codeListID,
          required: ai.input.required,
          inputValue: this.fb.control(ai.input.value,
            {
              updateOn: (ai.input.uitype === Honeycomb.Common.Enums.UiType.textarea ||
                ai.input.uitype === Honeycomb.Common.Enums.UiType.inputText) ? 'blur' : 'change',
              validators: this.customInputValidation(ai)
            }),
          inputNote: this.fb.control(ai.input.note, 
            { updateOn: 'blur',
              validators: this.noteValidation(ai) 
            }),
          meaningID: ai.input.meaningID,
          inputParams: ai.input.inputParams,
          regex: ai.input.regex,
          recordUid: null,
          updateTime: ai.input.updateTime,
          valueUpdated: ai.input.valueUpdated,
          hasInfoText: ai.input.hasInfoText,
          taskValueID: ta.taskValues.findIndex(tv => tv.meaningID === ai.input.meaningID) > -1 ?
                            ta.taskValues.find(tv => tv.meaningID === ai.input.meaningID).taskValueID : null,
          taskValuePhotos: this.fb.control(ai.input.taskValuePhotos, { validators: this.photoValidation(ai) })
        }))))
      });

      formActivities.push(activityGroup);

      activityGroup.get('activityDone').valueChanges.subscribe(d => {
        this.returnValue.forceReload = true;
      });
    });
  }

  private storeChangeDetectValues() {
    this.changeWatchControls.controls.forEach((c, ix) => {
      this.changeWatchValues[ix] = c.value;
    });
    this.changeWatchControls.taskAttachments = JSON.parse(JSON.stringify(this.task.taskAttachments));
    this.changeWatchControls.taskPhotos = JSON.parse(JSON.stringify(this.task.taskPhotos));
    this.changeWatchControls.attachments = JSON.parse(JSON.stringify(this.taskAttachments));
    this.changeWatchControls.photos = JSON.parse(JSON.stringify(this.taskPhotos));
  }

  /*
   * Set main-tab values back after task reload
   */
  private undoChangeDetectValues() {
    this.changeWatchValues.forEach((v, ix) => {
      this.changeWatchControls[ix].setValue(v, { emitEvent: false });
    });
  }

  async initForm(t: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    await this.loadFormInternal(t, false);

    this.cd.detectChanges();

    
  }

  loadViewStatus(taskID: number) {
    const taskViewStatus = this.appStatus.getTaskDetailConfig(taskID);
    this.activateTab(taskViewStatus.activeTabName);
  }

  async saveAndClose() {
    
    const checkAssignee = this.form.get('assignee')

    if (checkAssignee.value === null || (Array.isArray(checkAssignee.value) && checkAssignee.value.length === 0))
    {
      this.form.controls.assignee.setErrors({ required: true });
      this.form.markAsTouched();

      return;
    }

    await this.updateMain();
    // Disable close handles accidental closing
    // visible hladles closing druring additional task creation
    if (!this.dialogRef.disableClose || this.visible) {
      if (!!this.dialogRef.close) { // null on detail only
        this.dialogRef.close();
      }
    }
  }

  async redirect(id: number) {
    this.saveDialogStatus();
    this.defaults.taskID = id;
    // await this.ngOnInit();

    await this.refreshTask(this.defaults.taskID);
    await this.initForm(this.task);
    this.cd.detectChanges();


    const taskViewStatus = this.appStatus.getTaskDetailConfig(id);
    if (!!this.activities) { // Activities tab not hidden
      this.selectedTabIndex = taskViewStatus.activeTabIndex;
      this.activities.selectedActivityIndex = taskViewStatus.selectedActivityIndex;
    } else {
      this.activateTab('data-tab-main');
    }
  }

  activateTab(tabName: string) {
    for (const t of this.allTabs) {
      if (!t) { continue; }
      const tabs = this.tabs;
      const elem = t.content.viewContainerRef.element.nativeElement as HTMLElement;
      const tabFound = !!elem.getAttributeNames().find(a => a.indexOf(tabName) > -1);
      if (tabFound) {
        const selectedIndex = (tabs._allTabs as any)._results.indexOf(t);
        this.tabs.selectedIndex = selectedIndex;
        this.tabChanged({ tab: t, index: selectedIndex });

        const taskViewStatus = this.appStatus.getTaskDetailConfig(this.task.taskID);
        if (!!this.activities) { // Activities tab not hidden
          this.selectedTabIndex = taskViewStatus.activeTabIndex;
          this.activities.selectedActivityIndex = taskViewStatus.selectedActivityIndex;
        }

        return;
      }
    }
  }

  async refreshSequence() {
    if (!!this.task.taskSequenceID) {
      const res = await lastValueFrom(this.taskController.List({ taskSequenceID: this.task.taskSequenceID, jobID: this.selectedJobID} as any)
      .pipe(
        map(ts => ts.sort((a, b) => Date.parse(a.startDate as any) - Date.parse(b.startDate as any))),
        // share(),
        tap(ts => {
          this.isLastInSequence.next(ts.length > 0 && ts[ts.length - 1].taskID === this.task.taskID);
        })));
      this.taskSequence$.next(res);
    }
  }

  noteValidation(ai: Honeycomb.Tenant.Tasker.IService.Model.ActivityInput) : ValidatorFn  {
    if (ai.input.uitype === Honeycomb.Common.Enums.UiType.rating
      && !!ai.input.inputParams) {
      try {
        let inputParams = JSON.parse(ai.input.inputParams);
        if (!Array.isArray(inputParams) || inputParams.length === 0 || typeof(inputParams[0]) !== 'object') {
          return null;
        }
        
        let v3 = (control: AbstractControl): {[key: string]: any} | null => {
          if (!control.parent) {
            return null;
          }
          var param = inputParams.find(ip => ip.value === control.parent.get('inputValue').value);
          if (param && param.noteRequired && !!control.parent && isNullOrWhitespace(control.parent.get('inputNote').value)) {
            return { noteRequired: { value: control.parent.get('inputNote').value } }
          }
          return null;
        };
        return v3;
      } catch (e) {
        console.log(`Invalid input param: ${ ai.input.name }`);
        return null;
      }     
    }
    return null;
  }

  photoValidation(ai: Honeycomb.Tenant.Tasker.IService.Model.ActivityInput) : ValidatorFn  {
    if (ai.input.uitype === Honeycomb.Common.Enums.UiType.rating
      && !!ai.input.inputParams) {
      try {
        let inputParams = JSON.parse(ai.input.inputParams);
        if (!Array.isArray(inputParams) || inputParams.length === 0 || typeof(inputParams[0]) !== 'object') {
          return null;
        }
        
        let v3 = (control: AbstractControl): {[key: string]: any} | null => {
          if (!control.parent) {
            return null;
          }
          var param = inputParams.find(ip => ip.value === control.parent.get('inputValue').value);
          if (param && param.photoRequired && !!control.parent && (!control.parent.get('taskValuePhotos').value || control.parent.get('taskValuePhotos').value.length === 0)) {
            return { photoRequired: { value: control.parent.get('taskValuePhotos').value } }
          }
          return null;
        };
        return v3;
      } catch (e) {
        console.log(`Invalid input param: ${ ai.input.name }`);
        return null;
      }     
    }
    return null;
  }

  customInputValidation(ai: Honeycomb.Tenant.Tasker.IService.Model.ActivityInput) : ValidatorFn {

    let validators: ValidatorFn[] = [];

    if (ai.input.uitype === Honeycomb.Common.Enums.UiType.rating
        && !!ai.input.required) {
      let v1 = (control: AbstractControl): {[key: string]: any} | null => {
        if (isNullOrUndefined(control.value)) {
          return { required: { value: control.value } }
        }
        if (isNaN(Number(control.value)) || Number(control.value) < 0) {
          return { required: { value: control.value } };
        }
        return null;
      };
      validators.push(v1);
    }

    if (ai.input.uitype === Honeycomb.Common.Enums.UiType.rating
      && !ai.input.required) {
      let v2 = (control: AbstractControl): {[key: string]: any} | null => {
        if (isNullOrUndefined(control.value)) {
          return { required: { value: control.value } }
        }
        return null;
      };
      validators.push(v2);
    }

    if (ai.input.required) {
      let v4 = Validators.required;
      validators.push(v4);
    }

    if (validators.length > 0) {
      return Validators.compose(validators);
    }

    return null;
  }

  async rejectTask($event: any) {

    $event.stopPropagation();
    $event.cancelBubble = true;
    $event.preventDefault();

    const r = await this.promptDialog.showYesNoDialog(
                          `${this.trans.instant('tasker.my-task-detail.reject-prompt')}`,
                          this.trans.instant( 'tasker.my-task-detail.reject-title'));
    if (r !== DialogButtonType.yes) {
      return;
    }

    await this.LoadTask(); // Just to avoid saving changes
    this.form.get('taskStatusID').setValue(this.taskStatusesAll.find(ts => ts.code === 'rejected').taskStatusID);
    await this.saveAndClose();
  }

  saveDialogStatus() {
    if (!this.task) {
      return;
    }
    this.appStatus.setTaskDetailConfig(this.task.taskID, {
      selectedActivityIndex: !!this.activities ? this.activities.selectedActivityIndex : 0,
      activeTabIndex: this.selectedTabIndex,
      activeTabName: this.selectedTabName,
      scrollPosition: 0 // todo
    });
  }

  async tabChanged($event: MatTabChangeEvent) {

    if (!$event.tab) { // No tab visible
      return;
    }

    // The only way how to identify tab - index can change, because some tabs can be hidden
    const tabElement = $event.tab.content.viewContainerRef.element.nativeElement as HTMLElement;

    // previous value is main tab (and current is not main tab - ussualy on the first load)
    if (this.selectedTabName === 'data-tab-main' &&
        tabElement.getAttributeNames().find(a => a.indexOf('data-tab') > -1) !== 'data-tab-main') {

      // check files changed
      await this.mainTabChangedCheck();
    }

    this.selectedTabName = tabElement.getAttributeNames().find(a => a.indexOf('data-tab') > -1);

    if (tabElement.hasAttribute('data-tab-messages')) {
      this.messages = await lastValueFrom(this.messageController.List(this.defaults.taskID)
                                .pipe(
                                  tap(async _ => await this.messageController.SetRead(this.defaults.taskID).toPromise())
                                ));
      this.messagesLoading = of(false);
      this.scrollMessagesDown();
    }
    if (tabElement.hasAttribute('data-tab-files')) {

      this.taskFiles = await this.fileController.GetTaskFiles(this.defaults.taskID)
                                .toPromise();

      this.messagesSimple = await this.messageController.ListSimple(this.defaults.taskID)
                                .toPromise();
      this.taskFilesLoading = of(false);
    }

    if (tabElement.hasAttribute('data-tab-activities')) {
      this.continueButtonVisible = true;
    } else {
      this.continueButtonVisible = false;
    }

    this.isMainTabSelected.next(tabElement.hasAttribute('data-tab-main'));

    if (tabElement.hasAttribute('data-tab-sequence')) {
      // placeholder
    }

    if (tabElement.hasAttribute('data-tab-summary')) {
      this.summaryLoaded.next(false);
      this.cd.detectChanges();
      if (this.returnValue.saved || this.forceSummaryReload) {
        this.task = await lastValueFrom(this.taskController.Detail(this.defaults.taskID));
        const formActivities = this.form.get('taskActivitiesUpsert') as FormArray;
        this.setTaskActivitiesFormArray(formActivities, this.task);
      }
      await this.summaryComponent.reload();
      this.summaryLoaded.next(true);
      this.forceSummaryReload = false;
      this.cd.detectChanges();
    }

    this.cd.detectChanges();
  }

  private async mainTabChangedCheck() {

    if (!this.task || this.task.taskStatusBase === Honeycomb.Common.Enums.TaskState.archived) {
      return;
    }

    if (!(await this.permissionService.hasPermission(this.uiRole('save')))) {
      if (!!this.dialogRef.close) { // null on detail only
        this.dialogRef.close();
      }
      return;
    }


    let mainChanged = JSON.stringify(this.task.taskAttachments) !== JSON.stringify(this.changeWatchControls.taskAttachments) ||
      JSON.stringify(this.task.taskPhotos) !== JSON.stringify(this.changeWatchControls.taskPhotos) ||
      JSON.stringify(this.taskAttachments) !== JSON.stringify(this.changeWatchControls.attachments) ||
      JSON.stringify(this.taskPhotos) !== JSON.stringify(this.changeWatchControls.photos);

    // check inputs changed
    if (!mainChanged) {
      this.changeWatchValues.forEach(async (cv, ix) => {
        if (!!this.changeWatchControls.controls[ix] &&
            JSON.stringify(cv) !== JSON.stringify(this.changeWatchControls.controls[ix].value)) {
          mainChanged = true;
        }
      });
    }

    if (mainChanged) {
      const r = await this.promptDialog.showYesNoDialog(
        `${this.trans.instant('tasker.my-task-detail.main-tab-changed-prompt')}`,
        this.trans.instant('tasker.my-task-detail.main-tab-changed-title'));
      if (r === DialogButtonType.yes) {
        await this.updateMain();
      }
      this.storeChangeDetectValues();
    }
  }

  private async getContactsAutocomplete(f: string) {
    const context = new Honeycomb.Tenant.Reports.IService.Model.ReportContext();
    context.reportName = 'ListOfCustomers';
    context.pagination = { offset: 0, rowCount: 10 };
    context.fulltextFilter = f;

    const reportRequest = await lastValueFrom(this.reportController.GetByName('ListOfCustomers'));
    const dataRequest = await lastValueFrom(this.reportController.Run(context));

    const cols = reportRequest.reportColumns;
    const data = (dataRequest as any).data as Array<any>;
    const results: Array<any> = [];
    data.forEach(d => {
      const result: any = {};
      cols.forEach((c, ix) => {
        result[c.queryableColumn.name] = d[ix];
      });
      results.push(result);
    });
    return results;
  }

  private scrollMessagesDown() {
    setTimeout(() => {
      const msgContainer = document.getElementById('message-container');
      if (msgContainer) {
        msgContainer.scrollTop = msgContainer.scrollHeight - msgContainer.clientHeight;
      }
    }, 100);
  }

  async addFollowUp() {
    this.visible = false;
    const taskCreated = await this.dialogOver.open(TaskNewComponent, { data: { parentTask: this.task }})
        .afterClosed().toPromise();
    this.visible = true;
    if (taskCreated) {
      await this.updateTask();
      this.reloadTask();
      this.returnValue.forceReload = true;
    }
    else {
      this.cd.detectChanges();
    }
  }


  async updateTask() {
    this.detailSaving$ = of(true);
    const formActivities = this.form.get('taskActivitiesUpsert') as FormArray;
    await this.updateMain();
    this.detailSaving$ = of(false);
    this.returnValue.saved = true;
  }


  private async updateMain() {
    const task = this.form.getRawValue();
    task.id = this.defaults.taskID;
    task.taskPhotos = this.task.taskPhotos.concat(this.taskPhotos);
    task.taskAttachments = this.task.taskAttachments.concat(this.taskAttachments);
    await lastValueFrom(this.taskController.updateTask(this.task.taskID, task));
    this.returnValue.saved = true;
  }

  autocompleteDisplayFn(contact) {
    if (!contact) { return ''; }
    return `${contact.FirstName} ${contact.LastName}` ;
  }

  contactDisplayFn(contact: Honeycomb.Tenant.Contact.IService.ContactPublic) {
    if (!contact) { return ''; }
    return `${contact.firstName} ${contact.lastName}` ;
  }

  removeUser(userId, relation: Honeycomb.Common.Enums.TaskRelation) {
    const enumHashMap = enumToStrings(Honeycomb.Common.Enums.TaskRelation);
    const formArray = this.form.get(enumHashMap[relation]) as FormArray;
    const objToRemove = formArray.value.find(u => u.userId === userId);
    const ix = formArray.value.indexOf(objToRemove);
    formArray.removeAt(ix)
    this.form.value[enumHashMap[relation]] = formArray.value;
  }

  addUser(relation: Honeycomb.Common.Enums.TaskRelation, event: MatAutocompleteSelectedEvent) {

    const enumHashMap = enumToStrings(Honeycomb.Common.Enums.TaskRelation);
    const taskUsersControl = this.form.controls[enumHashMap[relation]] as FormArray;
    const taskUsers = taskUsersControl.value;
    const userIDs = new Set(taskUsers.map(a => a.userId));
    const current = event.option.value.user;

    if (!current) { return; }
    if (userIDs.has(current.id)) {
      return this.showSnack('tasker.common.already-selected');
    }

    const position = taskUsersControl.value.length;
    taskUsersControl.insert(position,
      this.fb.control({ userId: current.id, taskRelation: relation, name: current.name, formatedName: current.name }));

    [this.form.get('contactInputAssignee'),
     this.form.get('contactInputObserver'),
     this.form.get('contactInputResponsible')].forEach(c => c.setValue(''));

    this.contactInputObserver.nativeElement.value = '';
    this.contactInputAssignee.nativeElement.value = '';
    this.contactInputResponsible.nativeElement.value = '';

    this.users$ = of([]);
    this.cd.detectChanges();
  }

  selectedLocation($event: MatSelectChange, id?: number) {
    const locationId = id || $event.value;
    const location = this.locations.find(l => l.locationID === locationId);
    let address = null;

    if (location && !(isNullOrWhitespace(location.street) && isNullOrWhitespace(location.city))) {
        const part1 = `${[location.street, location.cityPart, location.city].find(t => !isNullOrWhitespace(t))} ${location.streetNumber}`;
        address = [part1, `${location.zipCode} ${location.city}`].filter(p => !isNullOrWhitespace(p)).map(p => p.trim()).join(', ');
    }
    this.form.get('address').setValue(address);
    if (!!location) {
      this.form.get('locationName').setValue(location.name);
    } else {
      this.form.get('locationName').setValue('');
    }
  }

  async loadContactsByUserIDAction(userIDs: Array<number>) {
    const reqModel = new Honeycomb.Tenant.Contact.IService.ContactByUserIDsRequest();
    reqModel.userIDs = userIDs;
    const contactsForIDs = await lastValueFrom(this.contactController.PublicByUserIdList(reqModel));
    this.contacts.push(...contactsForIDs);
    const newTaskUsers = contactsForIDs.map(c => {
      const tu = {
        taskUserID: null,
        taskID: this.task.taskID,
        userID: c.userId,
        taskRelation: Honeycomb.Common.Enums.TaskRelation.assignee,
        created: new Date(),
        name: c.formatedName,
        task: null,
        updatedBy: null,
        updatedByContactUserID: null,
        validFromUTC: null,
        validToUTC: null
      } as Honeycomb.Tenant.Tasker.IService.Model.TaskUser;
      return tu;
    });
    this.form.value.assignee.push(contactsForIDs[0]);
    this.task.taskUsers.push(...newTaskUsers);
    this.cd.detectChanges();
  }

  getTaskAttributes(form: any) {
    return form.controls.taskAttributesUpsert.controls;
  }

  uiRole(suffix: string) {
    if (!this.task) {
      return null;
    }
    const reqUiRole = `tasker.task-detail.${this.task.operation.code}.${suffix}`;
    return reqUiRole;
  }

  hasPermission(suffix: string) {
    let uiRole = this.uiRole(suffix);
    let hasUiRole = this.uiroles.indexOf(uiRole) > -1;
    if (true || this.roleDebug.findIndex(r => r === uiRole) === -1) {
      this.globals.DebugOut('task-detail', uiRole, hasUiRole);
      this.roleDebug.push(uiRole);
    }

    return hasUiRole;
  }

  public getImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/TaskerFile/GetImage', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  async reloadTask(disableViewStatusHandling?: boolean) {
    this.returnValue.saved = true;
    if (!disableViewStatusHandling) {
      this.saveDialogStatus();
    }
    await this.ngOnInit();
    await this.ngAfterViewInit();
    if (!disableViewStatusHandling) {
      this.loadViewStatus(this.task.taskID);
    }
  }

  public selectPrevActivity() {
    this.activities.selectPrevActivity();
    if (!!document.querySelector('.mat-tab-body-content')) {
      document.querySelector('.mat-tab-body-content').scrollTop = 0; // scroll to top
    }
  }

  public async selectNextActivity() {
    const selectedActivityInputs = this.activities.selectedActivityObject.controls.activityInputs;

    if (!selectedActivityInputs.valid) {
      const requiredInputs =
        this.activities.selectedActivityObject.controls.activityInputs.controls
            .filter(c => c.invalid /* && !!c.value.required */)
            .map((c: FormControl) => { 
              let inputNoteVal = c.get('inputNote');
              if (inputNoteVal.errors && inputNoteVal.errors.noteRequired) {
                return `${c.value.name} - ${this.trans.instant('tasker.common.note')}`;
              }
              let inputPhotoVal = c.get('taskValuePhotos');
              if (inputPhotoVal.errors && inputPhotoVal.errors.photoRequired) {
                return `${c.value.name} - ${this.trans.instant('tasker.task-activities.add-photo')}`;
              }
              // let inputVal = c.get('inputValue');
              return c.value.name;
            }) as Array<string>;

      const structuredData: StructuredDialogContent[] = [];

      structuredData.push({
          title: 'tasker.missingRequiredValue',
          items: requiredInputs
        });

      const untouchedInputs =
            this.activities.selectedActivityObject.controls.activityInputs.controls
                .filter(c => c.invalid && !c.value.required)
                .map(c => c.value.name) as Array<string>;

      if (untouchedInputs.length > 0) {
        structuredData.push({
          title: 'tasker.missingInputValue',
          items: untouchedInputs
        });
      }

      const r = await this.promptDialog.showYesNoDialogStructured(
          'tasker.missingInputValue',
          'tasker.confirmContinue',
          structuredData
          );
      if (r !== DialogButtonType.yes) {
        return;
      }
    }

    // this.activities.selectedActivityObject.patchValue({activityDone: true});

    this.switchActivity = true;
    this.cd.detectChanges();
    this.activities.setActivityClosed();
    if (this.activities.isLastActivity) {
      this.selectedTabName = 'data-tab-summary';
      this.selectedTabIndex = 2; // summary
      await this.reloadTask();
      this.switchActivity = false;
      return;
    }
    // Disabled, values are updated for every input

    this.activities.selectNextActivity();
    this.switchActivity = false;
    if (!!document.querySelector('.mat-tab-body-content')) {
      document.querySelector('.mat-tab-body-content').scrollTop = 0; // scroll to top
    }
    
    this.cd.detectChanges();
  }

  public async finishTaskActivities() {
    const formActivities = this.form.get('taskActivitiesUpsert') as FormArray;
    if (!!formActivities.valid) {
      this.selectNextActivity();
      return;
    }

    this.dialogOver.open(TaskActivityErrorComponent, { data: formActivities });
  }

  public setVisible( visible: boolean ) {
    this.visible = visible;
    this.dialogRef.disableClose = !visible;
    this.cd.detectChanges();
  }

  addPhoto($event: Event) {
    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }

    this.inputPhotoEnabled = true;
    this.cd.detectChanges();
    const inpPhoto = this.inputPhotoRef;
    inpPhoto.change = ($inpEvent: Event) => {
      this.fileUploading$ = true;
      const f = ($inpEvent.target as HTMLInputElement).files[0];
      this.cd.markForCheck();

      return this.fileControllerCustom.UploadFile(f, $event.target as HTMLElement)
      .subscribe(p => {
        this.taskPhotos.push({
          recordUid: p.recordUID,
          fileName: p.fileName
        });
        this.cd.detectChanges();
      });
    };
    inpPhoto.open();
  }

  addAttachment($event: Event) {

    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }

    const inpAttachment = this.inputAttachmentRef;
    inpAttachment.change = ($inpEvent: Event) => {
      this.fileUploading$ = true;
      const f = ($inpEvent.target as HTMLInputElement).files[0];
      this.cd.markForCheck();
      return this.fileControllerCustom.UploadFile(f, $event.target as HTMLElement)
      .subscribe(p => {
        this.taskAttachments.push({
          documentUid: p.recordUID,
          name: p.fileName
        });
        this.cd.detectChanges();
      });
    };
    inpAttachment.open();
  }

  showSnack(message: string, options?: MatSnackBarConfig) {
    this.snack.open(this.trans.instant(message),
        this.trans.instant('tasker.common.close'),
        Object.assign({ duration: 3000 }, options) as MatSnackBarConfig);
  }

  async sequenceDeleted(items: number[]) {
    if (Array.isArray(items) && items.findIndex(i => i === this.task.taskID) > -1) {
      if (!!this.dialogRef.close) { // null on detail only)
        this.dialogRef.close();
      }
    }
    await this.refreshSequence();
    this.returnValue.forceReload = true;
  }

  async sequenceRefresh(done: any) {
    await this.refreshSequence();
    this.returnValue.forceReload = true;
  }

  removeAttachment(attachment) {
    this.task.taskAttachments = this.task.taskAttachments.filter(ta => ta.documentUid !== attachment.documentUid);
  }

  removeAttachmentTemp(attachment) {
    this.taskAttachments = this.taskAttachments.filter(ta => ta.documentUid !== attachment.documentUid);
  }

  getTempImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/TenantTasker/File/gettempfile', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  zoomAttachmentImage(photo: any) {
    zoomScrollable(this.getImageUrl(photo.taskerFileUid, 1920, 1080), this.trans.instant('tasker.common.close'),
                  this.hasPermission('add-photo') ? this.trans.instant('tasker.common.delete') : '',
                   () => {
                    this.task.taskPhotos = this.task.taskPhotos.filter(tp => tp.taskerFileUid !== photo.taskerFileUid);
                    this.imageUID = null;
                    this.cd.detectChanges();
                   } );
  }

  zoomAttachmentImageTemp(photo: any) {
    zoomScrollable(this.getTempImageUrl(photo.recordUid, 1920, 1080), this.trans.instant('tasker.common.close'),
                  this.hasPermission('add-photo') ? this.trans.instant('tasker.common.delete') : '',
                   () => {
                    this.taskPhotos = this.taskPhotos.filter(tp => tp.recordUid !== photo.recordUid);
                    this.imageUID = null;
                    this.cd.detectChanges();
                   } );
  }

  trackByTaskerFileUid(index, photo) {
    return photo.taskerFileUid;
  }

  trackByRecordUid(index, photo) {
    return photo.recordUid;
  }

  public async createSubsequentTask(
    input: Honeycomb.Tenant.Tasker.IService.Model.TaskValue|Honeycomb.Tenant.Tasker.IService.Model.ActivityInput|any
  )
  {
  this.saveDialogStatus();
  this.setVisible(false);

  const tus = [];
  if (!!this.task.locationID) {
    const usrs = await lastValueFrom(this.jobController.LocationJobUsers( this.task.locationID ));
    usrs.forEach( u => {
      tus.push({ userId: u.id, taskRelation: Honeycomb.Common.Enums.TaskRelation.assignee, name: u.name });
    });
  }

  let parentTaskValueID: number = null;
  
  let parentSnapshotInputID: number = null;

  // Is snapshot
  if (!!input.isSnapshot) {
    parentSnapshotInputID = (input as Honeycomb.Tenant.Tasker.IService.Model.ActivityInput).inputID;
  } else {
    // Is NOT snapshot
    parentTaskValueID = (input as Honeycomb.Tenant.Tasker.IService.Model.TaskValue).taskValueID;
  }

  // var configDefaults = await firstValueFrom(this.http.get<any>('configuration/configuration.task-default.json'))

  var configDefaultsString = await lastValueFrom(this.configController.GetByName('tasker.subsequent-task').pipe(catchError(_ => of({
    value: JSON.stringify({}) // fallback if configuration is missing
  }))));
  var configDefaults = JSON.parse(configDefaultsString.value);

  let name = !!configDefaults['inheritName'] ? (input as any)[configDefaults['inheritName']] : (input as any).name;
  let description = !!configDefaults['inheritDescription'] ? (input as any)[configDefaults['inheritDescription']] : (input as Honeycomb.Tenant.Tasker.IService.Model.TaskValue).note;
  const deadlineDate = new Date();
  let deadline = !!configDefaults['deadlineAddDays'] ? new Date(deadlineDate.setDate(deadlineDate.getDate() + configDefaults['deadlineAddDays'])) : null;
  let priority = configDefaults['priority'] || null;

  let photos: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPhoto> = [];
  if (!!configDefaults['inheritPhotos'] && !!input.taskValuePhotos) {
    photos = input.taskValuePhotos.map((vp: Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhoto) => {
      let a = new Honeycomb.Tenant.Tasker.IService.Model.TaskPhoto();
      a.taskerFileUid = vp.taskerFileUid;
      return a;
    });
  }

  let newTaskDefaults = { parentTask: this.task,
    defaults: {
      taskUsers: tus,
      newItemType: configDefaults.taskType || null,
      name: name,
      priority : priority,
      description: description,
      startDate: new Date(),
      deadline: deadline,
      taskPhotos: photos,
      taskID: this.task.taskID,
      parentTaskValueID: parentTaskValueID,
      parentSnapshotInputID: parentSnapshotInputID,
      operationID: configDefaults.operationID,
      locationID: this.task.locationID
    } };

    const taskCreated = await lastValueFrom(this.dialogOver.open(TaskNewComponent,
      { data: newTaskDefaults})
        .afterClosed());
    this.setVisible(true);
    if (taskCreated) {
      this.reloadTask();
    } else {
      this.cd.detectChanges();
    }
}

  forceReload() {
    console.log('force reload list');
    this.returnValue.forceReload = true;
  }

  ngOnDestroy(): void {

    this.destroyed$.next(true);
    this.destroyed$.complete();

    if (!!this.taskSubscription) {
      this.taskSubscription.unsubscribe();
      this.taskSubscription = null;
    }

    if (!!this.saveSubscription) {
      this.saveSubscription.unsubscribe();
      this.saveSubscription = null;
    }

    if (!!this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
