import { createAction, props } from '@ngrx/store';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';

export enum NotificationBellTypes {
  BellConnectionStatusUpdate = '[Bell] Connection status update',
  BellConnectionStatusUpdateFinished = '[Bell] Connection status finished',
  
  BellDataUpdate = '[Bell] Data update',
  BellDataUpdateFinished = '[Bell] Data update finished',

  BellItemUpdate = '[Bell] Item update',
  BellItemUpdateFinished = '[Bell] Data Item update finished',

  BellSetRead = '[Bell] Set read',
  BellSetReadFinished = '[Bell] Set read finished',
}

// tslint:disable: max-line-length

export const bellConnectionStatusUpdateAction = createAction(NotificationBellTypes.BellConnectionStatusUpdate, props<{connectionState: boolean, online: boolean, error: string}>());

export const bellConnectionStatusUpdateFinishedAction = createAction(NotificationBellTypes.BellConnectionStatusUpdateFinished);

export const bellDataUpdatedAction = createAction(NotificationBellTypes.BellDataUpdate, props<{data: any}>());

export const bellDataUpdatedFinishedAction = createAction(NotificationBellTypes.BellDataUpdateFinished);

export const bellItemUpdatedAction = createAction(NotificationBellTypes.BellItemUpdate, props<{item: HoneycombCustom.Tenant.Tasker.IService.Model.NotificationBellModel}>());

export const bellItemUpdatedFinishedAction = createAction(NotificationBellTypes.BellItemUpdateFinished);

export const bellSetReadAction = createAction(NotificationBellTypes.BellSetRead, props<{ids: number[]}>());

export const bellSetReadFinishedAction = createAction(NotificationBellTypes.BellSetReadFinished);