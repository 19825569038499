import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import outlineCheckBox from '@iconify/icons-ic/outline-check-box';
import outlineCheckBoxOutlineBlank from '@iconify/icons-ic/outline-check-box-outline-blank';
import { FormGroup } from '@angular/forms';
import { PromptDialogService } from '../../common/prompt-dialog/prompt-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskDetailComponent } from '../task-detail/task-detail.component';
import { AppState } from 'src/app/app.states';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import baselineAssignment from '@iconify/icons-ic/baseline-assignment';
import { BehaviorSubject, catchError, firstValueFrom, lastValueFrom, of, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class TaskSummaryComponent implements OnInit, AfterViewInit {


  @Output() public forceReload: EventEmitter<any> = new EventEmitter();

  baselineAssignment = baselineAssignment;
  outlineCheckBox = outlineCheckBox;
  outlineCheckBoxOutlineBlank = outlineCheckBoxOutlineBlank;
  userID = 0;
  zeroPointsItems: BehaviorSubject<Array<{
      value: any,
      input: any,
      followUpTask: any,
      caption: string
  }>> = new BehaviorSubject([]);


  updatingZeroPoints: BehaviorSubject<boolean> = new BehaviorSubject(false);
  zeroPointsItemsUpdated: Subject<any> = new Subject();
  
  // _task: Observable<Honeycomb.Tenant.Tasker.IService.Model.Task>;
  _task: Honeycomb.Tenant.Tasker.IService.Model.Task;

  ratingType = Honeycomb.Common.Enums.UiType.rating;
  inputNumber = Honeycomb.Common.Enums.UiType.inputNumber;
  textType = Honeycomb.Common.Enums.UiType.inputText;
  textAreaType = Honeycomb.Common.Enums.UiType.textarea;

  @Input()
  set task(taskInput: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    this._task = taskInput;
  }

  get task(): Honeycomb.Tenant.Tasker.IService.Model.Task { return this._task; }

  @Input() form: FormGroup;
  @Input() detailComponent: TaskDetailComponent;

  constructor(private store: Store<AppState>,
              private translate: TranslateService,
              private cd: ChangeDetectorRef,
              private dialogOver: MatDialog,
              private snackbar: MatSnackBar,
              private http: HttpClient,
              @Inject('OperationController') private operationController: Honeycomb.Tenant.Tasker.IService.Controller.OperationController,
              @Inject('ContactJobController') private jobController: Honeycomb.Tenant.Contact.IService.Controller.JobController,
              @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
              @Inject('FileController') private fileController: Honeycomb.Tenant.Tasker.IService.Controller.FileController,
              @Inject('ConfigController') private configController: Honeycomb.Tenant.Admin.IService.NamedConfigurationsController,
              private promptDialog: PromptDialogService)
  {
    this.store.select(s => s.auth.userID).subscribe(s => this.userID = s);
  }

  hasFollowUpTask(activity: Honeycomb.Tenant.Tasker.IService.Model.Activity, task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    if (!task || !task.followUpTaskTemplates || !activity) {
      return false;
    }
    return task.followUpTaskTemplates.findIndex((f) => activity.activityID === f.activityID) > -1;
  }

  isActivityTask(activity: Honeycomb.Tenant.Tasker.IService.Model.Activity, task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    return task.followUpTaskTemplates.findIndex((f) => activity.activityID === f.activityID && !f.taskValueID) > -1;
  }

  isInputTask(activityInput: Honeycomb.Tenant.Tasker.IService.Model.ActivityInput, task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    return task.followUpTaskTemplates.findIndex((f) => activityInput.input.inputID === f.taskValueID) > -1;
  }

  getActivityAvg(taskactivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivity) {
    if (taskactivity.taskActivityResults.length === 0) {
      return null;
    }
    const sum = taskactivity.taskActivityResults.map(r => r.value)
                                                .filter(v => v !== null)
                                                .reduce((acc, cur) => acc + cur);
    return sum / taskactivity.taskActivityResults.length;
  }

  sortInputs(activityInputs: Array<Honeycomb.Tenant.Tasker.IService.Model.ActivityInput>) {
    return activityInputs.sort((c, d) => c.sortingOrder - d.sortingOrder);
  }

  getTemplateName(activity: Honeycomb.Tenant.Tasker.IService.Model.Activity, task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    return task.followUpTaskTemplates.find((f) => activity.activityID === f.activityID && !f.taskValueID).name;
  }

  pointsSum(taskActivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivity) {

    if (taskActivity.activity.activityInputs.length === 0) {
      return 0;
    }

    return taskActivity.activity.activityInputs.map(ai =>
          !!ai.input.value &&
          (typeof ai.input.value === 'number') && ai.input.value > -1
            ? ai.input.value : 0)
          .reduce((a, b) => a + b, 0);
  }

  pointsTotal(taskActivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivity) {

    if (taskActivity.activity.activityInputs.length === 0) {
      return 0;
    }

    let subTotal = 0;
    taskActivity.activity.activityInputs
    // DEV-425 - include untouched inputs
      .filter(ai =>
        (ai.input.value === null && !ai.input.required) ||
        (ai.input.value > -1 && !ai.input.required) ||
        // (ai.input.value !==  null && ai.input.value > -1) ||
        ai.input.required)
      .forEach(ai => {
      if (!!ai.input.inputParams && ai.input.inputParams.length > 0) {
        try {
          const a = JSON.parse(ai.input.inputParams);
          if (typeof(a[0]) === 'number') {
            const maxVal = a[a.length - 1];
            subTotal += maxVal;
            return;
          }

          if (Array.isArray(a) && a.length > 0 || typeof(a[0]) === 'object') {
            const maxObjVal = Math.max(...a.map(a => a.value));
            subTotal += maxObjVal;
            return;
          }
          return;
       } catch {
       }
      }
    });
    return subTotal;
  }

  sumAll(task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    let sum = 0;
    task.taskActivities.forEach(ta => sum += this.pointsSum(ta));
    return sum;
  }

  sumTotal(task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    let sum = 0;
    task.taskActivities.forEach(ta => sum += this.pointsTotal(ta));
    return sum;
  }

  public updateZeroPointsItems(): any[] {
    const r = [];
    if (!this.task || !this.task.taskActivities) {
      return r;
    }
    const formTask = this.form.value;
    formTask.taskActivitiesUpsert.forEach( a => {
      a.activityInputs.forEach( ai => {

        if (!this.task.taskActivities) {
          return;
        }
        const va = ai.inputValue;
        if (ai.inputValue === 0 || this.getFollowUpTask(ai)) {
          r.push( {
              value: va,
              input: ai,
              followUpTask: this.getFollowUpTask(ai),
              caption: `${ai.name} (${ai.description})`
            });
        }
        ai.note = ai.inputNote; // unify from <any> to <Honeycomb.Tenant.Tasker.IService.Model.Input>
      });
    });
    this.zeroPointsItems.next(r);
    return r;
  }

  public getFollowUpTask( ai: any ) {
    if (!this.task || !this.task.children) {
      return null;
    }
    return this.task.children.find( c => 
      (!!c.parentTaskValueID && c.parentTaskValueID === ai.taskValueID) ||
      (!!c.parentSnapshotInputID && c.parentSnapshotInputID === ai.inputID));
  }

  public async createSubsequentTask(
      input: Honeycomb.Tenant.Tasker.IService.Model.TaskValue|Honeycomb.Tenant.Tasker.IService.Model.ActivityInput|any
    )
  {
      await this.detailComponent.createSubsequentTask(input);
  }

  async ngOnInit() {
    await this.reload();
  }

  public async reload() {
    this.updateZeroPointsItems();
    const operations = await lastValueFrom(this.operationController.List());
    this.zeroPointsItemsUpdated?.next(true);
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  public async createAllTasksSucessCallback(params: any) {
    this.updatingZeroPoints.next(true);
    console.log('createAllTasksSucessCallback', params);
    this.task = await lastValueFrom(this.taskController.Detail(this.task.taskID))
    await this.reload();
    this.updatingZeroPoints.next(false);
    this.forceReload.emit();
  }

  public async createAllTasksErrorCallback(params: any) {
    this.updatingZeroPoints.next(true);
    console.log('createAllTasksErrorCallback', params);
    this.task = await lastValueFrom(this.taskController.Detail(this.task.taskID))
    await this.reload();
    this.updatingZeroPoints.next(false);
    this.snackbar.open(this.translate.instant('tasker.input.update-failed'), null, { duration: 2000 });
    this.forceReload.emit();
  }
}
