import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { JwtToken } from './auth.model';
import { isNullOrWhitespace } from 'src/app/common/functions';
import { Observable, tap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { BuildConfigService } from 'src/app/services/build-config.service';
import { Globals } from 'src/app/common/globals';

const jwtHelper = new JwtHelperService();
export const STORAGE_TOKEN_KEY = 'token';
const STORAGE_TENANTID_KEY = 'tenantid';

export const ContentHeaders = new HttpHeaders();
ContentHeaders.append('Accept', 'application/json');
ContentHeaders.append('Content-Type', 'application/json');

// https://mherman.org/blog/authentication-in-angular-with-ngrx/

@Injectable()
export class AuthService {

    token: string;

    servicePrefix = '/api/GlobalAuth';

    avoidMsalLogin = false;

    msalAccountID: string = null;

    constructor(
        private http: HttpClient,
        public router: Router,
        public route: ActivatedRoute,
        private msalService: MsalService,
        private buildConfig: BuildConfigService) {
    }

    multilogin(username: string, password: string): Observable<string[]> {

        const isLocalhost = window.location.hostname.indexOf('localhost') > -1 || window.location.hostname === 'tasker.local';
        const isDevRemoteDebug = window.location.hostname.indexOf('192.168.') > -1;


        if (isLocalhost) {
            return this.http.post<string[]>('//localhost:8381/api/multilogin',
                { Username: username, Password: password, scope: 'tasker' },
                { headers: ContentHeaders }
            );
        }

        if (isDevRemoteDebug) {
            return this.http.post<string[]>(`//${window.location.hostname}/api/TenantTasker/multilogin`,
                { Username: username, Password: password, scope: 'tasker' },
                { headers: ContentHeaders }
            );
        }

        return this.http.post<string[]>('/api/multilogin',
                { Username: username, Password: password, scope: 'tasker' },
                { headers: ContentHeaders }
            );
    }

    externalLogin(token: string, provider: string): Observable<string[]> {

        const isLocalhost = window.location.hostname.indexOf('localhost') > -1 || window.location.hostname === 'tasker.local';
        const isDevRemoteDebug = window.location.hostname.indexOf('192.168.') > -1;
        
        var formData = new FormData();
        formData.append('accessToken', token);
        formData.append('scope', 'tasker-sso');


        if (isLocalhost) {
            return this.http.post<string[]>(`//localhost/api/GlobalAuth/external/${this.buildConfig.tenantID}/${provider}`,
                formData
            );
        }

        if (isDevRemoteDebug) {
            return this.http.post<string[]>(`//${window.location.hostname}/api/GlobalAuth/external/${this.buildConfig.tenantID}/${provider}`,
                formData
            );
        }

        return this.http.post<string[]>(`${this.buildConfig.externalAuthApiUri}/api/GlobalAuth/external/${this.buildConfig.tenantID}/${provider}`,
                formData
            );
    }

    setToken(token: string, showTenantChangedMsg: boolean = false, rememberMe: boolean = false) {
        if (isNullOrWhitespace(token)) {
            return;
        }
        const decodedToken = jwtHelper.decodeToken(token) as JwtToken;
        this.token = token;
        if (rememberMe) {
            localStorage.setItem(STORAGE_TOKEN_KEY, token);
        } else {
            sessionStorage.setItem(STORAGE_TOKEN_KEY, token);
        }
        localStorage[STORAGE_TENANTID_KEY] = decodedToken.TenantID;
    }

    getToken(): string {

        const localStorageToken = localStorage.getItem(STORAGE_TOKEN_KEY);
        if (localStorageToken) {
            return localStorageToken;
        }

        if (!isNullOrWhitespace(this.token)) { return this.token; }
        return sessionStorage.getItem(STORAGE_TOKEN_KEY);
    }

    getDecodedToken(): JwtToken {
        if (!this.loggedIn()) return null;
        return jwtHelper.decodeToken(this.getToken()) as JwtToken;
    }

    public setMsalAccountID(accountID: string) {
        this.msalAccountID = accountID;
    }

    // Logout the user
    async logout() {
        if (this.buildConfig.azureLogin.enabled && !!this.msalAccountID) {
            localStorage.setItem('avoidMsalLogin', 'true');
            let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
            await this.msalService.logout({ logoutHint: this.msalAccountID })
                    .pipe(tap(_ => {
                        if (!!sessionStorage.getItem("msal.interaction.status")) {
                            sessionStorage.removeItem("msal.interaction.status");
                        }
                    }));
            this.msalAccountID = null;
            
            // msalInstance["browserStorage"].clear();
        }

        if (!!this.token) {
            this.token = null;
        }
    }

    public externalLoginEnabled(): boolean {
        return this.avoidMsalLogin && this.buildConfig.azureLogin.enabled;
    }

    // Check whether the user is logged in or not
    loggedIn() {
        const rememberToken = localStorage.getItem(STORAGE_TOKEN_KEY);
        if (rememberToken) {
            return !jwtHelper.isTokenExpired(rememberToken);
        }

        return !jwtHelper.isTokenExpired(sessionStorage.getItem(STORAGE_TOKEN_KEY));
    }
}

export class TenantShortModel {
    tenantHash: string;

    tenantName: string;

    tenantID: number;

    selected: boolean;
}
