import { Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Inject, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { Globals } from 'src/app/common/globals';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isNullOrWhitespace, zoomScrollable } from 'src/app/common/functions';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AppState } from 'src/app/app.states';
import { TranslateService } from '@ngx-translate/core';
import { FileInputComponent } from 'ngx-material-file-input';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';

import twotoneBorderColor from '@iconify/icons-ic/twotone-border-color';
import twotoneSwapVerticalCircle from '@iconify/icons-ic/twotone-swap-vertical-circle';
import twotoneCreateNewFolder from '@iconify/icons-ic/twotone-create-new-folder';
import twotoneDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import twotoneInfo from '@iconify/icons-ic/twotone-info';
import twotoneEdit from '@iconify/icons-ic/twotone-edit';
import roundDoneOutline from '@iconify/icons-ic/round-done-outline';
import twotoneImageSearch from '@iconify/icons-ic/twotone-image-search';
import twotoneAddAPhoto from '@iconify/icons-ic/twotone-add-a-photo';
import roundAddCircleOutline from '@iconify/icons-ic/round-add-circle-outline';
import { filter, tap } from 'rxjs/operators';
import { ValidationFn } from 'ngx-permissions';
import { TaskInputButtonsComponent } from '../task-input-buttons/task-input-buttons.component';
import { TaskDetailComponent } from '../task-detail/task-detail.component';

@Component({
  selector: 'task-input',
  templateUrl: './task-input.component.html',
  styleUrls: ['./task-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class TaskInputComponent implements OnInit, AfterViewInit, OnDestroy {

  twotoneBorderColor = twotoneBorderColor;
  twotoneSwapVerticalCircle = twotoneSwapVerticalCircle;
  twotoneCreateNewFolder = twotoneCreateNewFolder;
  twotoneDeleteForever = twotoneDeleteForever;
  twotoneInfo = twotoneInfo;
  twotoneEdit = twotoneEdit;
  roundDoneOutline = roundDoneOutline;
  twotoneImageSearch = twotoneImageSearch;
  twotoneAddAPhoto = twotoneAddAPhoto;
  roundAddCircleOutline = roundAddCircleOutline;

  inputType = Honeycomb.Common.Enums.DataType;
  uitype = Honeycomb.Common.Enums.UiType;

  fileUploaded$: Observable<any> = of({});
  public fileUploading$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  show = { note: false, info: false, photo: false };

  public noteRequired = false;

  public photoRequired = false;

  inputParams = null;

  tenantHash: string = null;

  statuses$: Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>> = of([]);

  subscriptions: Array<Subscription> = [];

  public imageUrlValue: string = null;
  public imageUrlRecordUid: string = null;

  @Input('activityInputControl') activityInput: FormGroup;
  @Input() task: Honeycomb.Tenant.Tasker.IService.Model.Task;
  @Input() detailComponent: TaskDetailComponent;


  @Input()
  inputUpdating: boolean;

  @ViewChild('inputPhoto') inputPhotoRef: FileInputComponent;

  @ViewChild('inputButtonText') inputButtonText: TaskInputButtonsComponent;
  @ViewChild('inputButtonTextArea') inputButtonTextArea: TaskInputButtonsComponent;
  @ViewChild('inputButtonNumber') inputButtonNumber: TaskInputButtonsComponent;
  @ViewChild('inputButtonFile') inputButtonFile: TaskInputButtonsComponent;
  @ViewChild('inputButtonCheckbox') inputButtonCheckbox: TaskInputButtonsComponent;
  @ViewChild('inputButtonRating') inputButtonRating: TaskInputButtonsComponent;

  @Output()
  noteUpdated: EventEmitter<string> = new EventEmitter();

  @Output()
  valueUpdated: EventEmitter<{ newValue: any, origValue: any, resetDelegate: (oldVal) => void}> = new EventEmitter();

  @Output()
  valuePhotoInsert: EventEmitter<any> = new EventEmitter();

  @Output()
  valuePhotoRemove: EventEmitter<any> = new EventEmitter();

  fileLoadingSelector = (state: AppState) => state.file.loading;
  fileRecordUidSelector = (state: AppState) => state.file.recordUid;
  fileActivitySelector = (state: AppState) => state.file.activityValue;

  resetFn = (v) => { this.origValue = v; };

  origValue: any;

  constructor(
    @Inject('InputController') private inputController: Honeycomb.Tenant.Tasker.IService.Controller.InputController,
    @Inject('FileControllerCustom') private fileController: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
    private globals: Globals,
    private store: Store<AppState>,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private element: ElementRef
  ) {
    this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.origValue = this.activityInput.get('inputValue').value;

    const s = this.activityInput.get('inputValue')
      .valueChanges
      // Filter file for FileInput - valueUpdated must be fired after file upload
      // But do not filter when file is removed (set to null)
      .pipe(
        //tap(v => console.log('inputValue changed', v)),
        filter(v => !v || this.activityInput.value.uitype !== Honeycomb.Common.Enums.UiType.file),
        filter(v => v !== this.origValue)
      )
      .subscribe(v => {
        this.valueUpdated.emit({ newValue: v, origValue: this.origValue, resetDelegate: this.resetFn });
        this.activityInput.get('inputNote').updateValueAndValidity({ emitEvent: false });
        this.activityInput.get('taskValuePhotos').updateValueAndValidity({ emitEvent: false });
        this.updateCustomButtons(this.activityInput.get('uitype').value, v);
        if (this.activityInput.get('uitype').value === Honeycomb.Common.Enums.UiType.file) {
          if (v.recordUid !== this.origValue.recordUid || v.inputValue !== this.origValue.inputValue) {
            this.updateImgSrc(this.activityInput.value);
          }
        }
        this.origValue = v;
    });

    this.subscriptions.push(s);

    if (this.activityInput.get('uitype').value === Honeycomb.Common.Enums.UiType.file) {
      this.updateImgSrc(this.activityInput.value);
    }


    const s2 = this.activityInput.get('inputValue')
      .valueChanges.subscribe(vc => {
        if (this.inputParams) {
          let param = this.inputParams.find(p => p.value === vc);
          this.photoRequired = (!!param && !!param.photoRequired) || false;
          this.noteRequired = (!!param && !!param.noteRequired) || false;

          if (!this.show.photo && this.photoRequired) {
            this.show.photo = true;
          }
          if (!this.show.note && this.noteRequired) {
            this.show.note = true;
          }
        }
        this.activityInput.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      });

      this.subscriptions.push(s2);

      const s3 = this.activityInput.get('inputNote')
        .valueChanges.subscribe(vc => {
          if (!isNullOrWhitespace(vc)) {
            this.show.note = true;
          }
          
          if (this.inputParams && this.noteRequired) {
            this.activityInput.get('inputValue').updateValueAndValidity();
            this.activityInput.updateValueAndValidity();
            this.activityInput.parent.updateValueAndValidity();
          }
        });

      this.subscriptions.push(s3);

      const s4 = this.activityInput.get('taskValuePhotos')
        .valueChanges.subscribe(vc => {
          if (!isNullOrWhitespace(vc)) {
            this.show.photo = true;
          }
          if (this.inputParams && this.photoRequired) {
            this.activityInput.get('inputValue').updateValueAndValidity();
            this.activityInput.updateValueAndValidity();
            this.activityInput.parent.updateValueAndValidity();
          }
        });

      this.subscriptions.push(s3);
  }

  ngAfterViewInit(): void {
    this.show.note = !isNullOrWhitespace(this.activityInput.controls.inputNote.value);
    this.show.photo = this.activityInput.value.taskValuePhotos && this.activityInput.value.taskValuePhotos.length > 0;

    try {
      this.inputParams = JSON.parse(this.activityInput.value.inputParams);
    } catch {
      this.inputParams = null;
    }

    if (this.inputParams) {
      let param = this.inputParams.find(p => p.value === this.activityInput.value.inputValue);
      this.photoRequired = (!!param && !!param.photoRequired) || false;
      this.noteRequired = (!!param && !!param.noteRequired) || false;
    }
  }

  getImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/TaskerFile/GetImage', imgGuid].join('/')
      + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  getTempImageUrl(imgGuid: string, maxWidth: number = 80, maxHeight: number = 80): string {
    return [this.globals.GetUrlPrefix(), 'api/TenantTasker/File/gettempfile', imgGuid].join('/')
      + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  getSanitizedHtml(): SafeHtml {
    const infoText = this.activityInput.value.infoText;
    if (isNullOrWhitespace(infoText)) { return ''; }
    return this.sanitizer.bypassSecurityTrustHtml(infoText);
  }

  async toggleInfoText() {
    const activityInput = this.activityInput.value;

    // Ugly way how to detect snapshot
    if (this.activityInput.parent.parent.value.isSnapshot) {
      const inputText = await this.inputController.InfoTextSnapshot(activityInput.inputID).toPromise();
      activityInput.infoText = inputText.infoText;
    }

    if (!activityInput.infoText) { // avoid reload
      const inputText = await this.inputController.InfoText(activityInput.inputID).toPromise();
      activityInput.infoText = inputText.infoText;
    }
    this.show.info = !this.show.info;
    this.cd.detectChanges();
  }

  toggleNote() {
    this.show.note = !this.show.note;
    this.cd.detectChanges();
  }

  togglePhotoRow() {
    this.show.photo = !this.show.photo;
    this.cd.detectChanges();
    if (this.show.photo && !this.activityInput.value.taskValuePhotos.length) {
      const photoAddElem = (this.element.nativeElement as HTMLElement).querySelector('.photo-add-btn');
      this.addPhoto({ target: photoAddElem } as any);
    }
  }

  public updateCustomButtons(uitype, v) {
    switch (uitype) {
      case Honeycomb.Common.Enums.UiType.inputText:
        this.inputButtonText.updateVisibility(v);
        break;
      case Honeycomb.Common.Enums.UiType.textarea:
          this.inputButtonTextArea.updateVisibility(v);
          break;
      case Honeycomb.Common.Enums.UiType.checkbox:
          this.inputButtonCheckbox.updateVisibility(v);
          break;
      case Honeycomb.Common.Enums.UiType.rating:
          this.inputButtonRating.updateVisibility(v);
          break;
      case Honeycomb.Common.Enums.UiType.file:
          this.inputButtonFile.updateVisibility(v);
          break;
      case Honeycomb.Common.Enums.UiType.inputNumber:
          this.inputButtonNumber.updateVisibility(v);
          break;

      default:
        break;
    }
  }

  private updateImgSrc(v) {
    if (v && v.inputValue && !v.recordUid) {
      this.imageUrlValue = this.getImageUrl(v.inputValue, 120, 80);
    } else {
      this.imageUrlValue = null;
    }
    if (v && v.recordUid) {
      this.imageUrlRecordUid = this.getTempImageUrl(v.recordUid, 120, 80);
    } else {
      this.imageUrlRecordUid = null;
    }
  }

  addPhoto($event: Event) {

    const progressContainer = ($event.target as HTMLElement).closest('.progress-container');
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }

    const activityInput = this.activityInput.value;
    const inpPhoto = this.inputPhotoRef;
    inpPhoto.change = ($inpEvent: Event) => {
      this.fileUploading$.next(true);
      const f = ($inpEvent.target as HTMLInputElement).files[0];
      activityInput.file = f;
      this.cd.markForCheck();

      return this.fileController.UploadFile(f, $event.target as HTMLElement)
        .subscribe(p => {
          activityInput.taskValuePhotos.push({
            recordUid: p.recordUID,
            fileName: p.fileName
          });
          this.valuePhotoInsert.emit(p.recordUID);
          this.cd.detectChanges();
        });
    };
    inpPhoto.open();
  }

  async fileSet($event: Event) {
    this.fileUploading$.next(true);

    this.cd.detectChanges();

    const progressContainer = ($event.target as HTMLElement).closest('.mat-mdc-form-field') as HTMLElement;
    if (!!progressContainer && progressContainer.getAttribute('data-uploading')) {
      return;
    }
    const f = ($event.target as HTMLInputElement).files[0];

    if (!f) {
      return;
    }
    
    this.imageUrlRecordUid = null;
    this.imageUrlValue = null;

    this.inputUpdating = true; 
    this.activityInput.controls.inputValue.setValue(f);

    const uploadedFile = await lastValueFrom(this.fileController.UploadFile(f, progressContainer));

    this.activityInput.controls.recordUid.setValue(uploadedFile.recordUID);
    this.valueUpdated.emit({newValue: uploadedFile.recordUID, origValue: this.origValue, resetDelegate: this.resetFn});

    this.updateImgSrc(this.activityInput.value);

    
    this.fileUploading$.next(false);
    this.cd.markForCheck();
  }

  zoomImage() {
    const input = this.activityInput;
    const imageUrl = !input.value.recordUid ?
      this.getImageUrl(input.value.inputValue, null, null) :
      this.getImageUrl(input.value.recordUid, null, null);
    zoomScrollable(imageUrl,
      this.translate.instant('tasker.task-activities.image-close-btn'),
      this.translate.instant('tasker.common.delete'),
      (event) => this.removeImage(input.value.inputValue));
  }

  zoomAttachmentImage(photo: any) {

    const value = this.activityInput.value;
    const imageUrl = !photo.recordUid ?
      this.getImageUrl(photo.taskerFileUid, null, null) :
      this.getTempImageUrl(photo.recordUid, null, null);

    const callBack = (event) => {
      value.taskValuePhotos = value.taskValuePhotos
        .filter((p: any) =>
          (!!photo.taskerFileUid && p.taskerFileUid !== photo.taskerFileUid) ||
          (!!photo.recordUid && p.recordUid !== photo.recordUid));
      this.valuePhotoRemove.emit(photo);
      this.cd.detectChanges();
    };

    zoomScrollable(imageUrl,
      this.translate.instant('tasker.task-activities.image-close-btn'),
      this.translate.instant('tasker.common.delete'),
        !!photo.taskerFileUid ? (evt) => callBack(evt) : null
      );
  }

  removeImage(recordUid: string): any {
    this.activityInput.controls.recordUid.setValue(null);
    this.activityInput.controls.inputValue.setValue(null);
    this.cd.detectChanges();
  }

  onBlurInput($event: FocusEvent) {
    this.valueUpdated.emit({
      newValue: ($event.currentTarget as HTMLInputElement).value as string,
      origValue: this.origValue,
      resetDelegate: this.resetFn});
  }

  trackByTaskerFileUid(index, photo) {
    return photo.taskerFileUid;
  }

  onBlurNote($event: FocusEvent) {
    this.noteUpdated.emit(($event.currentTarget as HTMLInputElement).value as string);
  }

  change($event) {
    console.log($event);
  }

  hidePictureView(sender) {
    document.body.removeChild(sender);
  }
}
