import { Injectable, Inject, ChangeDetectorRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { loadCurrentUserAction,
         loadCurrentUserCompletedAction,
         loadCurrentUserFailedAction,
         loadUIRolesAction,
         loadUIRolesCompletedAction,
         loadUIRolesFailedAction,
         jobSelectAction,
         jobSelectedAction,
         positionRecorderAction,
         positionRecorderCompletedAction,
         positionRecorderFailedAction,

         attendanceLoadAction,
         attendanceLoadCompletedAction,
         attendanceLoadFailedAction,

         attendanceSaveAction
        } from './user.actions';
import { AppState } from 'src/app/app.states';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { lastValueFrom, of, tap } from 'rxjs';
import { RoleAttributeTaskerValueModel, AttendanceModel } from './roleAttributeTaskerValue.model';
import { isNullOrUndefined } from 'src/app/common/functions';
import { LocalStorage } from 'src/app/common/localStorage';
import { Geolocation } from 'src/app/common/model/geolocation';
import { NgxPermissionsService } from 'ngx-permissions';
import { Globals } from 'src/app/common/globals';
import { AuthService } from '../auth/auth.service';
import { pushGetPubKeyAction } from '../notification/push/notification-push.actions';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/@vex/services/config.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    @Inject('UserController')
    private userController: Honeycomb.Tenant.Contact.IService.UserController,
    @Inject('ContactJobController')
    private jobController: Honeycomb.Tenant.Contact.IService.Controller.JobController,
    @Inject('AdminUserController')
    private adminUserController: Honeycomb.Tenant.Admin.IService.UserController,
    @Inject('TaskerLocationController')
    private locationController: Honeycomb.Tenant.Tasker.IService.Controller.LocationController,
    @Inject('AttendanceController')
    private attendanceController: Honeycomb.Tenant.Contact.IService.Controller.AttendanceController,
    private permissionService: NgxPermissionsService,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private globals: Globals,
    private store: Store<AppState>
  ) {
  }

  loadingRoles = false;

  loadCurrentUserEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrentUserAction),
      mergeMap(
        () => this.userController.GetCurrentUserInfo()
        .pipe(
          tap(user => {
            if (!localStorage.getItem('lang')) {
              this.configService.setLanguage(user.languageISOCode || 'cs');
            } else {
              this.configService.setLanguage(localStorage.getItem('lang'));
            }
          }),
          map(user => {
            this.store.dispatch(positionRecorderAction());
            this.store.dispatch(pushGetPubKeyAction());
            
            return loadCurrentUserCompletedAction({ result: user });
          }),
          catchError(error => of(loadCurrentUserFailedAction({ error }))
          )
        )
      )));

    loadUIRolesEffect = createEffect(() => this.actions$.pipe(
        ofType(loadUIRolesAction),
        withLatestFrom(this.store),
        mergeMap(([data, store]) => {
          if (!isNullOrUndefined(data) && !isNullOrUndefined(store.auth.userID)) {
            const selectedJob = store.user.currentUser.userJobs.find(j => j.jobID === data.jobID);
            if (selectedJob) {
              return this.jobController.UIRoles(selectedJob.jobID)
                .pipe(
                  map(
                    uiroles => {
                      this.permissionService.flushPermissions();
                      this.permissionService.loadPermissions(uiroles,
                        (permissionName, permissionStore) => {
                          return !!permissionStore[permissionName];
                      });
                      return loadUIRolesCompletedAction({ result: uiroles });
                    }
                  ),
                  catchError(error => of(loadUIRolesFailedAction({ error })))
                );
            }
          }
        })
      ));

      loadCurrentUserFailedEffect = createEffect(() =>
      this.actions$.pipe(
          ofType(loadCurrentUserFailedAction),
          map(async (err) => {
            console.error(err);
            await this.authService.logout();
            this.router.navigate(['/login']);
          })
        ),
      { dispatch: false }
    );

      loadAttendanceEffect = createEffect(() => this.actions$.pipe(
        ofType(attendanceLoadAction),
        mergeMap(() => {
              return this.attendanceController.GetActive()
                .pipe(
                  map(
                    attendance => {
                      if (!!attendance) {
                        const ar = attendance.attendanceRecords.sort((a, b) => b.recordTime.getTime() - a.recordTime.getTime());
                        if (ar.length > 0) {
                          const lastRecord = ar[0];
                          return attendanceLoadCompletedAction({ result: {
                              attendanceID: attendance.attendanceID,
                              locationID: attendance.locationID,
                              attendanceSubType: lastRecord.attendanceRecordType.attendanceSubType,
                              attendanceType: lastRecord.attendanceRecordType.attendanceType,
                              loading: false
                          } as AttendanceModel});
                        }
                      }
                      return attendanceLoadCompletedAction({ result: {
                          attendanceID: null,
                          locationID: null,
                          attendanceType: null,
                          attendanceSubType: null,
                          loading: false
                        } as AttendanceModel
                      });
                    }
                  ),
                  catchError(error => of(attendanceLoadFailedAction({ error })))
              )
        })
      ));


      saveAttendanceEffect = createEffect(() => this.actions$.pipe(
        ofType(attendanceSaveAction),
        withLatestFrom(this.store),
        mergeMap(([data, store]) => {
          const newAttendance = {} as Honeycomb.Tenant.Contact.IService.Attendance;
          newAttendance.locationID = data.locationID;
          let attendanceType = Honeycomb.Common.Enums.AttendanceType.enter;
          let attendanceSubType = Honeycomb.Common.Enums.AttendanceSubType.standard;
          if (!!store.user && !!store.user.attendance && store.user.attendance.attendanceType) {
            const a = store.user.attendance;
            if (a.attendanceType === Honeycomb.Common.Enums.AttendanceType.enter) {
              attendanceType = Honeycomb.Common.Enums.AttendanceType.exit;
              newAttendance.attendanceID = a.attendanceID;
            }
            attendanceSubType = a.attendanceSubType;
          }
          const attendanceRecord = {
            accurancy: data.location.coords.accuracy,
            recordTime: new Date(),
            attendanceID: 0,
            latitude: data.location.coords.latitude,
            longitude: data.location.coords.longitude,
            positionTimestamp: data.location.timestamp,
          } as Honeycomb.Tenant.Contact.IService.AttendanceRecord;
          attendanceRecord.attendanceRecordType = {
            attendanceType,
            attendanceSubType,
          } as any;
          newAttendance.attendanceRecords = [attendanceRecord];

            if (attendanceType === Honeycomb.Common.Enums.AttendanceType.exit) {
              return this.attendanceController.AddAttendanceRecord(newAttendance.attendanceID, attendanceRecord)
              .pipe(map(attendanceID => { return attendanceLoadAction(); }),
                catchError(error => of(attendanceLoadFailedAction({ error })))
            )
            } else {
              return this.attendanceController.AddAttendance(newAttendance)
              .pipe(map(attendanceID => { return attendanceLoadAction(); }),
                catchError(error => of(attendanceLoadFailedAction({ error })))
            )
            }
          })
      ));

      setPositionRecorderEffect = createEffect(() => this.actions$.pipe(
        ofType(positionRecorderAction),
        withLatestFrom(this.store),
        map(([data, store]) => {
          if (!!store.user.locationWatchId) {
            navigator.geolocation.clearWatch(store.user.locationWatchId);
          }
          const watchId = navigator.geolocation.watchPosition(async (p) =>
            await this.locationSuccess(p, store.auth.userID),
            err => this.locationError(err),
            { enableHighAccuracy: true,
              // timeout: 1000 * 60 * 5, // 5 minutes to get location
              timeout: 1000 * 30, // 30s to get location
              maximumAge: 0
            });
          return positionRecorderCompletedAction({ locationWatchId: watchId });
        }),
            catchError(error => of(positionRecorderFailedAction({ error }))
          )
      ));

  jobSelectEffect = createEffect(() => this.actions$.pipe(
    ofType(jobSelectAction),
    withLatestFrom(this.store),
    map(([data, store]) => {
      if (!isNullOrUndefined(data.jobID) && !isNullOrUndefined(store.user.currentUser)) {
        const selectedJob = store.user.currentUser.userJobs.find(j => j.jobID === data.jobID);
        if (selectedJob) {
          const selectedRole = store.user.currentUser.contactRoles.find(r => r.roleID === selectedJob.job.roleID);
          if (!selectedRole) {
            console.error(`Selected job roleID ${selectedJob.job.roleID} not found in contact.roles`)
            return;
          }
          this.store.dispatch(loadUIRolesAction({ jobID: data.jobID }));
          this.store.dispatch(attendanceLoadAction());

          const taskerDashboard = selectedRole.attributes['tasker-dashboard'];
          if (taskerDashboard) {
            const dashboard = JSON.parse(taskerDashboard) as RoleAttributeTaskerValueModel;
            return jobSelectedAction({ dashboard });
          }
        }
      }
    })
  ));


  private async locationSuccess(position:Geolocation.GeolocationPosition, userID: number) {
    if (!userID || !this.authService.loggedIn()) {
      return;
    }
    this.globals.lastPosition = position;
    this.globals.positionErrorCode = null;

    await lastValueFrom(this.locationController.Record({
      latitude: position.coords.latitude,
      accuracy: position.coords.accuracy,
      longitude: position.coords.longitude,
      altitude: position.coords.altitude,
      altitudeAccuracy: position.coords.altitudeAccuracy,
      heading: position.coords.heading,
      positionTimestamp: position.timestamp,
      speed: position.coords.speed,
      userID,
      data: window.location.href,
      dateCreated: new Date(),
      locationRecordID: -1
    }));
  }

  private locationError(error: Geolocation.GeolocationPositionError) {
    this.globals.positionErrorCode = error.code;
    this.globals.lastPosition = null;
    if (error.code === Geolocation.PERMISSION_DENIED) {
      console.error('Position permission DENIED 💣');
      return;
    }
    if (error.code === Geolocation.POSITION_UNAVAILABLE) {
      console.error('Position UNAVAILABLE 📡🛰');
      return;
    }
    if (error.code === Geolocation.TIMEOUT) {
      console.error('Position TIMEOUT ⏳');
      return;
    }
  }
}
