import { createAction, props } from '@ngrx/store';

export enum NotificationPushTypes {
  PushGetPubKey = '[Push] get public key',
  PushGetPubKeyFinished = '[Push] get public key finished',
  PushGetPubKeyFailed = '[Push] get public key failed',

  PushConnectionSubscribe = '[Push] subscribe',
  PushConnectionStatusUpdateFinished = '[Push] subscribe finished',
  PushConnectionStatusUpdateFailed = '[Push] subscribe failed',

  PushDataUpdate = '[Push] Data update',
  PushDataUpdateFinished = '[Push] Data update finished',
}

// tslint:disable: max-line-length


export const pushGetPubKeyAction = createAction(NotificationPushTypes.PushGetPubKey);

export const pushGetPubKeyFinishedAction = createAction(NotificationPushTypes.PushGetPubKeyFinished, props<{ pubKey: string }>());

export const pushGetPubKeyFailedAction = createAction(NotificationPushTypes.PushGetPubKeyFailed, props<{ error: string }>());


export const pushSubscribeAction = createAction(NotificationPushTypes.PushConnectionSubscribe, props<{ pubKey: string }>());

export const pushSubscribeFinishedAction = createAction(NotificationPushTypes.PushConnectionStatusUpdateFinished, props<{ registration: ServiceWorkerRegistration }>());

export const pushSubscribeFailedAction = createAction(NotificationPushTypes.PushConnectionStatusUpdateFailed, props<{ error: string }>());


export const pushDataUpdatedAction = createAction(NotificationPushTypes.PushDataUpdate, props<{data: any}>());

export const pushDataUpdatedFinishedAction = createAction(NotificationPushTypes.PushDataUpdateFinished);
