import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardTaskerFileActions from './taskerfiles.actions';
import { TaskerFilesState } from './taskerfiles.state';

const TaskerFilesReducer = createReducer(TaskerFilesState(),

// tslint:disable: max-line-length
  on(DashboardTaskerFileActions.uploadTaskerFileAction, (state) => ({...state, loading: true, error: null})),
  on(DashboardTaskerFileActions.uploadTaskerFileCompletedAction, (state, { recordUid, fileName, activityValue }) =>
      ({ ...state, recordUid, fileName, activityValue, loading: false, error: null })),
  on(DashboardTaskerFileActions.uploadTaskerFileFailedAction, (state, { error }) => ({ ...TaskerFilesState(), error })),
);

export function reducer(state: TaskerFilesState | undefined, action: Action) {
  return TaskerFilesReducer(state, action);
}
