import { Component, ViewEncapsulation, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { lastValueFrom, Subject } from 'rxjs';
import { Globals } from 'src/app/common/globals';
import { BuildConfigService } from 'src/app/services/build-config.service';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { CustomSpTypes } from './custom-sp-types';

@Component({
  selector: 'custom-sp-button',
  templateUrl: './custom-sp-button.component.html',
  styleUrls: ['./custom-sp-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomSpButtonComponent implements OnInit {


  @Input() public label: string = '';
  
  @Input() public type: string = null;

  @Input() public data: any = null;

  @Input() public refreshVisibility: Subject<any>;

  @Output() public success: EventEmitter<any> = new EventEmitter();

  @Output() public error: EventEmitter<any> = new EventEmitter();

  public visible: boolean = false;

  private paramsFn: (...input: any) => any;

  private visibleFn: (...input: any) => boolean;


  constructor(
    private cd: ChangeDetectorRef,
    private config: BuildConfigService,
    @Inject('TaskController') private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController
  ) {
    
  }

  public async onClick($event) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    $event.preventDefault();

    const params = this.paramsFn(this.data);

    await lastValueFrom(this.taskController.RunCustomSP({ spName: this.type, params }));

    this.success.emit(this.data);

    this.updateVisibility();

    this.cd.detectChanges();
  }

  ngOnInit(): void {

    var types = CustomSpTypes();
    const btnType = types.find(t => t.type === this.type);
    if (!!btnType) {
      this.visibleFn = btnType.visibleFn;
      this.paramsFn = btnType.paramsFn;
    }

    if (this.refreshVisibility) {
      this.refreshVisibility.subscribe(_ => this.updateVisibility());
    }

    this.updateVisibility();
  }

  private updateVisibility() {
    this.visible = !!this.config.customButtons?.find(a => a === this.type) && !(!this.visibleFn || !this.visibleFn(this.data));
    this.cd.detectChanges();
  }
}
