import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskItemComponent } from './task-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { TaskStatusModule } from '../task-status/task-status.module';


@NgModule({
  declarations: [TaskItemComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatBadgeModule,
    TranslateModule,
    TaskStatusModule,
    IconModule
  ],
  exports: [TaskItemComponent]
})
export class TaskItemModule {
}
