import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import icClose from '@iconify/icons-ic/twotone-close';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.states';

import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
import { TasksTableColumnsModel } from './tasks-table-columns.model';
import { DefaultTaskFilter } from 'src/app/api/user/roleAttributeTaskerValue.model';
import { setVisibleCols } from '../task-common';
import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';

@Component({
  selector: 'tasks-table-columns',
  templateUrl: './tasks-table-columns.component.html',
  styleUrls: ['./tasks-table-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TasksTableColumnsComponent implements OnInit, OnDestroy {

  model: {
    allColumns: Array<TasksTableColumnsModel>,
    visibleColumns: Array<TasksTableColumnsModel>,
    agenda: TaskAgenda
  };
  taskFilters$: Observable<Array<DefaultTaskFilter>>;
  filterForm: FormGroup;
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;

  icClose = icClose;

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<TasksTableColumnsComponent>) {
    this.model = popoverRef.data as any;

    this.model.allColumns.forEach(ac => {
      let foundVisible = this.model.visibleColumns.find(vc => vc.columnName === ac.columnName);
      if (foundVisible) {
        ac.visible = foundVisible.visible;
      } else {
        ac.visible = false;
      }
    });
  }

  async ngOnInit() {
    this.cd.detectChanges();
  }

  cancel() {
    this.popoverRef.close();
  }

  async handleChange() {
  }

  apply() {
    setVisibleCols(this.model.allColumns.filter(ac => !!ac.visible), this.model.agenda);
    this.popoverRef.close();
    this.cd.detectChanges();
  }

  close() {
    this.popoverRef.close();
  }

  ngOnDestroy(): void {
  }
}
