import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeoInputComponent } from './geo-input.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GeoInputComponent],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    TranslateModule
  ],
  exports: [GeoInputComponent]
})
export class GeoInputModule {
}
