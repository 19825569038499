import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, tap, mapTo, withLatestFrom, switchMap } from 'rxjs/operators';
import { EMPTY, of, forkJoin } from 'rxjs';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  loadOperationsCompletedAction,
  loadOperationsAction,
  loadOperationsFailedAction,
} from './operations.actions';
import { AppState } from 'src/app/app.states';

@Injectable()
export class OperationsEffects {
  constructor(
    private actions$: Actions,
    @Inject('OperationController') private operationController: Honeycomb.Tenant.Tasker.IService.Controller.OperationController,
    private router: Router,
    private store: Store<AppState>
  ) {}

  loadOperationsEffect = createEffect(() => this.actions$.pipe(
      ofType(loadOperationsAction),
      withLatestFrom(this.store),
      mergeMap(([data, s]) => {

        if (!s.auth.jwtToken) { /* unauthorized => no ApiURL => 404 */
          return null;
        }

        const operationsListRequest = this.operationController.ActiveList(null);

        return operationsListRequest.pipe(
           map((res) => {
             return loadOperationsCompletedAction(
               { result: res as Honeycomb.Tenant.Tasker.IService.Model.Operation[] });
           }),
           catchError(error => of(loadOperationsFailedAction(error)))
        );
      })
    )
);
}
