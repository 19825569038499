<div class="dropdown">
  <div class="dropdown-header" fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="filterForm">
    <div fxFlex="1 0 0%" fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
      </div>
      <div class="dropdown-heading">{{ 'tasker.common.filter' | translate }}</div>
    </div>
  </div>

  <div class="dropdown-content">
    <form fxFlex="1 0 100%" [formGroup]="filterForm">
      <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">

      <mat-form-field class="full">
        <mat-label>{{ 'tasker.common.assignee' | translate }}</mat-label>
        <input matInput #assigneeInput formControlName="assigneeControl" [matAutocomplete]="assigneeAuto">
        <button *ngIf="hasAssigned()" matSuffix mat-icon-button aria-label="Clear" (click)="clearAssingneeInput()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #assigneeAuto="matAutocomplete" (optionSelected)="addAssignee($event)">
          <mat-option *ngFor="let assignee of assignees$ | async" [value]="assignee">
            {{ assignee.user.name }} ({{ assignee.job.name }}) 
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      

        <mat-form-field class="full">
          <mat-label>{{ 'tasker.common.read' | translate }}</mat-label>
          <mat-select placeholder="{{ 'tasker.common.read' | translate }}" formControlName="taskRead"
            name="taskRead" fxFlex>
            <mat-option *ngFor="let read of readList" [value]="read.id">
              {{ 'tasker.read.' + read.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full">
          <mat-label>{{ 'tasker.common.priority' | translate }}</mat-label>
          <mat-select multiple placeholder="{{ 'tasker.common.priority' | translate }}" formControlName="priorities"
            name="priority" fxFlex>
            <mat-option *ngFor="let priority of priorityList" [value]="priority.id">
              {{ 'tasker.priority.' + priority.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        
        <!-- <mat-form-field class="full">
          <mat-label>{{ 'tasker.common.taskType' | translate }}</mat-label>
          <mat-select multiple placeholder="{{ 'tasker.common.task-type' | translate }}" formControlName="taskTypes" name="priority" [(ngModel)]="filterForm.value.taskTypes" fxFlex>
            <mat-option *ngFor="let taskType of taskTypeList" [value]="taskType.id">
              {{ 'tasker.taskType.' + taskType.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="full">
          <mat-label>{{ 'tasker.common.operation' | translate }}</mat-label>
          <mat-select multiple placeholder="{{ 'tasker.common.operation' | translate }}" formControlName="operations" name="operations" [(ngModel)]="filterForm.value.operations" fxFlex>

            <div class="select-all mat-mdc-option" *ngIf="operations && filterForm.value.operations">
              <mat-checkbox (change)="toggleAllSelection(operations, filterForm, 'operations', 'operationID')" 
                            [checked]="operations.length === filterForm.value.operations.length"
                            [indeterminate]="operations.length != filterForm.value.operations.length && filterForm.value.operations.length > 0" >
                            {{ 'tasker.common.select-all' | translate }}
              </mat-checkbox>
            </div>

            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.operationID">
              {{ operation.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field class="full">
          <mat-label>{{ 'tasker.common.task-status' | translate }}</mat-label>
          <mat-select multiple placeholder="{{ 'tasker.common.state' | translate }}" formControlName="taskStates"
            name="state" fxFlex>
            <mat-option *ngFor="let state of baseStatusList" [value]="state.id">
              {{ 'tasker.task-status.' + state.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="full">
          <mat-label>{{ 'tasker.common.task-status' | translate }}</mat-label>
          <mat-select multiple placeholder="{{ 'tasker.common.state' | translate }}" formControlName="taskStatusIDs" name="state" fxFlex>
            <div class="select-all mat-mdc-option" *ngIf="statuses && filterForm.value.taskStatusIDs">
              <mat-checkbox (change)="toggleAllSelection(statuses$, filterForm, 'taskStatusIDs', 'taskStatusID')" 
                            [checked]="(statuses$ | async)?.length === filterForm.value.taskStatusIDs.length"
                            [indeterminate]="(statuses$ | async)?.length != filterForm.value.taskStatusIDs.length && filterForm.value.taskStatusIDs.length > 0" >
                            {{ 'tasker.common.select-all' | translate }}
              </mat-checkbox>

            </div>
            <mat-option *ngFor="let state of statuses$ | async" [value]="state.taskStatusID">
              {{ state.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-form-field class="full">
          <mat-label>{{ 'tasker.common.date-range' | translate }}</mat-label>
          <mat-select placeholder="{{ 'tasker.common.date-range' | translate }}"  fxFlex (selectionChange)="dateTimeFilteChange($event)">
            <mat-option value="this_week">
              {{ 'tasker.filter.this-week' | translate }}
            </mat-option>
            <mat-option value="next_week">
              {{ 'tasker.filter.next-week' | translate }}
            </mat-option>
            <mat-option value="this_month">
              {{ 'tasker.filter.this-month' | translate }}
            </mat-option>
            <mat-option value="next_month">
              {{ 'tasker.filter.next-month' | translate }}
            </mat-option>
            <mat-option value="custom">
              {{ 'tasker.filter.custom-date-range' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div fxFlex fxLayoutGap="10px">
          <mat-form-field>
            <input matInput [matDatepicker]="filterDateFrom" formControlName="dateFrom">
            <mat-datepicker-toggle matSuffix [for]="filterDateFrom"></mat-datepicker-toggle>
            <mat-datepicker #filterDateFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="filterDateTo" formControlName="dateTo">
            <mat-datepicker-toggle matSuffix [for]="filterDateTo"></mat-datepicker-toggle>
            <mat-datepicker #filterDateTo></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>


  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="cancel()">{{ 'tasker.common.cancel' | translate }}</button>
    <button mat-button (click)="apply()">{{ 'tasker.common.apply' | translate }}</button>
  </div>

</div>