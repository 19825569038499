export interface DashboardInfo {
  tileBadges: { [key: string]: string };
}

export interface DashboardTasksInfoState {
  tileBadges: { [key: string]: string };
  loading: boolean;
  error: string;
}

export const DashboardTasksInfoStateState = () => {
  return {
    tileBadges: {},
    loading: false,
    error: null
  } as DashboardTasksInfoState;
};
