import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../@vex/layout/layout.component';
import { AuthGuardDevService } from './services/auth-guard-service-dev.service';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { Style } from 'src/@vex/services/style.service';
import { TaskAgenda } from './api/tasks/my-tasks/my-tasks.state';
import { BrowserUtils } from '@azure/msal-browser';

const childrenRoutes: VexRoutes = [
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewModule)
  },
  {
    path: 'my-tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule),
    data: { style: Style.myTasks, agenda: TaskAgenda.MyTasks }
  },
  {
    path: 'tasks-by-me',
    loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule),
    data: { style: Style.tasksByme, agenda: TaskAgenda.TasksByMe }
  },
  {
    path: 'my-tasks/repairs',
    loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule),
    data: { style: Style.repairs, agenda: TaskAgenda.Repairs }
  },
  {
    path: 'stores',
    loadChildren: () => import('./pages/stores/stores.module').then(m => m.StoresModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('./pages/reporting/reporting.module').then(m => m.ReportingModule)
  },
  {
    path: 'attendance',
    loadChildren: () => import('./pages/attendance/attendance.module').then(m => m.AttendanceModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  }
];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ AuthGuardDevService ],
    children: childrenRoutes
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled',
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
