import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoresMainModule } from './stores-main/stores-main.module';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { StoresMainComponent } from './stores-main/stores-main.component';
import { MyStoresListComponent } from './my-stores-list/my-stores-list.component';

const routes: Routes = [
  {
    path: 'main',
    component: StoresMainComponent
  },{
    path: 'my-stores',
    component: MyStoresListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoresRoutingModule { }
