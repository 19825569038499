<div class="table-wrapper">
  <!-- <mat-table [dataSource]="dataSource"  -->
  <mat-table [dataSource]="tasks"
             matSort 
             [matSortActive]="viewSort.column"
             [matSortDirection]="viewSort.direction"
             [matSortStart]="viewSort.direction"
             [ngClass]="{'sticky-scroll': stickyHorizontalScroll}"
             [ngStyle]="{'display': (loading$ | async) ? 'none' : ''}"
             >
    <ng-container matColumnDef="dateRead">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('dateRead')" mat-sort-header>{{ 'tasker.common.read' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <div [class.unread]="!task.dateRead" [title]="task.dateRead"></div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskID">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('taskID')" mat-sort-header>#</mat-header-cell>
      <mat-cell *matCellDef="let task"><div class="value">{{task.taskID}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskStatus">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('taskStatus')" mat-sort-header>{{ 'tasker.common.task-status' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task" [title]="getStateName(task)">
         <task-status [task]="task"></task-status>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('name')" mat-sort-header>{{ 'tasker.common.name' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task"><div>{{task.name}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="childTasksCount">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('childTasksCount')" mat-sort-header>
        <!-- {{ 'tasker.tasks-table.child-tasks-tount' | translate }} -->
        <mat-icon [icIcon]="certificateIcon"></mat-icon>
      </mat-header-cell>
      <mat-cell *matCellDef="let task">
        <span  *ngIf="task.childTasksCount > 1"  matBadge="{{ task.childTasksCount }}">
          <mat-icon [icIcon]="certificateIcon" color="primary"></mat-icon>
        </span>
        <span  *ngIf="task.childTasksCount === 1"  matBadge="{{ task.childTasksCount }}">
          <mat-icon [icIcon]="certificateIcon" color="primary" (click)="updateTask($event, task.childTaskIDs[0])"></mat-icon>
        </span>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="parentTaskID">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('parentTaskID')" mat-sort-header>
        <!-- {{ 'tasker.tasks-table.parent-task' | translate }} -->
        <mat-icon [icIcon]="roundSubdirectoryArrowRight" data-rotate="180deg"></mat-icon>
      </mat-header-cell>
      <mat-cell *matCellDef="let task">
        <span  *ngIf="task.parentTaskID">
          <mat-icon [icIcon]="roundSubdirectoryArrowRight" color="primary" data-rotate="180deg"></mat-icon>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskSequenceID">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('taskSequenceID')" mat-sort-header>
        <mat-icon [icIcon]="roundRepeat"></mat-icon>
      </mat-header-cell>
      <mat-cell *matCellDef="let task">
        <span  *ngIf="task.taskSequenceID">
          <mat-icon [icIcon]="roundRepeat" color="primary"></mat-icon>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('description')" mat-sort-header>{{ 'tasker.common.description' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task" class="desc-col" [title]="task.description">
        <div class="desc-col-content">{{task.description}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('address')" mat-sort-header>{{ 'tasker.common.address' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task"><div>{{task.address}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('locationName')" mat-sort-header>{{ 'tasker.common.location' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task"><div>{{task.locationName}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('created')" mat-sort-header>{{ 'tasker.common.created' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task"><div>{{task.created | date: 'd.M.yy H:mm'}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('startDate')" mat-sort-header> {{ 'tasker.common.start' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let task"><div>{{task.startDate | date: 'd.M.yy H:mm'}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="deadline">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('deadline')" mat-sort-header>{{ 'tasker.common.deadline' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task"><div>{{task.deadline | date: 'd.M.yy H:mm'}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('priority')" mat-sort-header>{{ 'tasker.common.priority' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task"> 
        <div title="{{ 'tasker.priority.' + getPriorityName(task.priority) | translate }}">
          <span [class]="'priority-' + getPriorityName(task.priority)">{{ 'tasker.priority.' + getPriorityName(task.priority) | translate }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="creator">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('creator')" mat-sort-header>{{ 'tasker.common.creator' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <div class="name-cell">
          <span *ngFor="let user of getUser(taskRelation.creator, task)" [title]="user.name" class="name-box">{{ user.name }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignee">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('assignee')">{{ 'tasker.common.assigned' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <div class="name-cell">
          <span *ngFor="let user of getUser(taskRelation.assignee, task)" [title]="user.name" class="name-box">{{ user.name }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="externalSupplier">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('ExternalSupplier')" mat-sort-header>{{ 'tasker.common.external-supplier' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <div>{{ getAttributeValue('ExternalSupplier', task) }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="repairType">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('RepairType')" mat-sort-header>{{ 'tasker.common.repair-type' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <div>{{ getAttributeValue('RepairType', task) }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="messagesTotal">
      <mat-header-cell *matHeaderCellDef (click)="sortBy('messages')" mat-sort-header>
        <!-- {{ 'tasker.common.messages' | translate }} -->
        <mat-icon [icIcon]="outlineMessage" class="read"></mat-icon>
      </mat-header-cell>
      <mat-cell *matCellDef="let task">
        <span *ngIf="task.messagesTotal > 0" class="message-icon" [title]="task.messagesTotal + '/' + (task.messagesTotal - task.messagesRead)">
          <mat-icon [icIcon]="outlineMessage" class="read" *ngIf="task.messagesTotal === task.messagesRead"></mat-icon>
          <mat-icon [icIcon]="twotoneMessage" class="unread" *ngIf="task.messagesTotal > task.messagesRead">{{ task.messagesTotal - task.messagesRead }}</mat-icon>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="updateTask($event, row.taskID)"></mat-row>

    <tr class="mat-row" *matNoDataRow>
      <td class="no-results" colspan="99">{{ 'tasker.common.no-results' | translate }}</td>
    </tr>
  </mat-table>

  <div id="scrollbar" *ngIf="stickyHorizontalScroll">
    <div id="scrollcontent"></div>
  </div>
  <mat-paginator stylePaginator showFirstLastButtons #paginator [ngStyle]="{'display': (showPaginator | async) ? 'block' : 'none'}"
    [length]="itemsTotal"
    [pageSize]="pageSize"
    [disabled]="(loading$ | async)"
    (page)="pageChangedHandler($event)"
    [pageSizeOptions]="[10, 20, 50, 100]">
  </mat-paginator>
</div>
