import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, DialogResult } from './attendance-dialog.model';
import { AttendanceDialogComponent } from './attendance-dialog.component';

@Injectable({
    providedIn: 'root'
 })
export class AttendanceDialogService
{
    constructor(private matDialog: MatDialog)
    {
    }

    public async showDialog(): Promise<DialogResult>
    {
        const dialogRef = this.matDialog.open(AttendanceDialogComponent);
        return await dialogRef.afterClosed().toPromise() as DialogResult;
    }
}
