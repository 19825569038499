import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, tap, mapTo, withLatestFrom, switchMap } from 'rxjs/operators';
import { EMPTY, of, forkJoin } from 'rxjs';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  loadPbiTokenCompletedAction as loadPbiTokenCompletedAction,
  loadPbiTokenAction as loadPbiTokenAction,
  loadPbiTokenFailedAction as loadPbiTokenFailedAction,
} from './pbi.actions';
import { AppState } from 'src/app/app.states';

@Injectable()
export class PbiEffects {
  constructor(
    private actions$: Actions,
    @Inject('PBIController') private pbiController: Honeycomb.Tenant.Reports.IService.PBIController,
    private router: Router,
    private store: Store<AppState>
  ) {}

  loadPbiTokenEffect = createEffect(() => this.actions$.pipe(
      ofType(loadPbiTokenAction),
      withLatestFrom(this.store),
      mergeMap(([data, s]) => {
        const tokenRequest = this.pbiController.GetToken();

        return tokenRequest.pipe(
           map((aad) => {
             return loadPbiTokenCompletedAction(
               { aad });
           }),
           catchError(error => of(loadPbiTokenFailedAction(error)))
        );
      })
    )
);
}
