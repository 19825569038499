import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  catchError,
  map,
  tap,
  mapTo,
  withLatestFrom,
  switchMap,
  exhaustMap
} from 'rxjs/operators';
import { EMPTY, of, forkJoin, Observable } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import {
  //loadMyTasksCompletedAction,
  //loadMyTasksAction,
  //loadMyTasksFailedAction,
  //loadMyTasksSetFilterAction,
  loadMyTaskDetailAction,
  loadMyTaskDetailComletedAction,
  loadMyTaskDetailFailedAction,
  dialogMyTaskOpenAction,
  dialogMyTaskCancelAction,
  dialogMyTaskSaveAction,
  dialogSaveFailedAction,
  dialogSaveSuccessAction,
  dialogMyTaskClosedAction,
  //loadAssignedByMeTasksAction,
  //loadTasksAction,
  taskFilterSetDefaultAction,
  taskFilterSetFinishedAction,
  //loadMyTasksSetFilterFinishedAction,
  setMyTaskReadAction
} from './my-tasks.actions';
import { AppState } from 'src/app/app.states';
import { MatDialog } from '@angular/material/dialog';
import { TaskDetailComponent } from 'src/app/pages/tasks/task-detail/task-detail.component';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'src/app/common/functions';
import { TasksService } from 'src/app/services/tasks.service';

@Injectable()
export class MyTasksEffects {
  constructor(
    private actions$: Actions,
    @Inject('TaskController')
    private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
    private router: Router,
    private dialog: MatDialog,
    private tasksService: TasksService,
    private store: Store<AppState>
  ) {}

  // loadMyTasksEffect = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loadMyTasksAction),
  //     withLatestFrom(this.store),
  //     mergeMap(([data, s]) => {
  //       if (!s.myTasks.filter) {
  //         s.myTasks.filter = new Honeycomb.Tenant.Tasker.IService.TaskListFilter();
  //       }
  //       const dataRequest = this.taskController.List(s.myTasks.filter);

  //       return dataRequest.pipe(
  //         map(res => {
  //           return loadMyTasksCompletedAction({
  //             result: res as Honeycomb.Tenant.Tasker.IService.Model.Task[]
  //           });
  //         }),
  //         catchError(error => of(loadMyTasksFailedAction(error)))
  //       );
  //     })
  //   )
  // );

  // loadTasksEffect = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loadTasksAction),
  //     withLatestFrom(this.store),
  //     mergeMap(([data, s]) => {
  //       const dataRequest = this.taskController.List(s.myTasks.filter);
  //       return dataRequest.pipe(
  //         map(res => {
  //           return loadMyTasksCompletedAction({
  //             result: res as Honeycomb.Tenant.Tasker.IService.Model.Task[]
  //           });
  //         }),
  //         catchError(error => of(loadMyTasksFailedAction(error)))
  //       );
  //     })
  //   )
  // );


  // setFilterEffect = createEffect(() => this.actions$.pipe(
  //   ofType(loadMyTasksSetFilterAction),
  //   withLatestFrom(this.store),
  //   map(([data, store]) => {
  //       return loadMyTasksSetFilterFinishedAction({ filter: store.myTasks.filter });
  //   })
  // ));

  loadDetailEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMyTaskDetailAction),
      withLatestFrom(this.store),
      mergeMap(([prop, s]) => {
        return this.taskController.Detail(prop.taskID).pipe(
          map(res => {
            return loadMyTaskDetailComletedAction({
              result: res as Honeycomb.Tenant.Tasker.IService.Model.Task
            });
          }),
          catchError(error => of(loadMyTaskDetailFailedAction(error)))
        );
      })
    )
  );

  saveDetailEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(dialogMyTaskSaveAction),
      withLatestFrom(this.store),
      mergeMap(([prop, s]) => {
        return this.taskController.updateTask(prop.task.taskID, prop.task).pipe(
          map((res: any) => {
            return dialogSaveSuccessAction({ taskID: res.taskID });
          }),
          catchError((error: HttpErrorResponse) => of(dialogSaveFailedAction({ detailError: error.message })))
        );
      })
    )
  );

  dialogOpenDetailEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(dialogMyTaskOpenAction),
      withLatestFrom(this.store),
      mergeMap(([taskID, s]) => {
        const dialogRef = this.dialog.open(TaskDetailComponent, {
          data: taskID
        });
        return dialogRef.afterClosed();
      }),
      switchMap(([result, s]) => {
        console.log(result);
        if (!result) {
          return of(dialogMyTaskCancelAction());
        }

        if (!!result.taskID) {
            this.tasksService.load();
        }
        // if (!!result.taskID) {
        //   return this.taskController.Update(result.taskID, result).pipe(
        //     map(res => {
        //        this.tasksService.load();
        //        return loadMyTasksSetFilterFinishedAction({ filter: store.myTasks.filter });
        //       //return loadMyTasksAction();
        //     })
        //   );
        // }
      }),
      catchError(err => {
        console.log(err);
        return of(dialogSaveFailedAction({ detailError: err }));
      })
    )
  );

  dialogSaveFailedEffect = createEffect(() =>
      this.actions$.pipe(
        ofType(dialogSaveFailedAction),
        map((err) => {
          console.error(err);
        })
      ),
    { dispatch: false }
  );

  dialogClosedEffect = createEffect(() =>
      this.actions$.pipe(
        ofType(dialogMyTaskClosedAction)
      ),
    { dispatch: false }
  );

  setMyTaskReadEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(setMyTaskReadAction),
      withLatestFrom(this.store),
      map(([prop, s]) => {
        // reducer things
      })
    ), { dispatch: false }
  );


  defaultFilterSelectEffect = createEffect(() => this.actions$.pipe(
    ofType(taskFilterSetDefaultAction),
    withLatestFrom(this.store),
    map(([data, store]) => {
      if (!isNullOrUndefined(data.filterName)) {
        const dashboard = Object.assign({}, store.user.dashboard);
        if (dashboard) {
          const filterToSetAsDefault = dashboard.taskFilters.find(tf => tf.name === data.filterName);
          if (filterToSetAsDefault) {
            const defFilter = [];
            dashboard.taskFilters.forEach(tf => {
              defFilter.push({
                name: tf.name,
                default: tf.name === data.filterName
              });
            });
            dashboard.taskFilters = defFilter;
          }
        }
        return taskFilterSetFinishedAction();
      }
    })
  ));
}


