
export class SelectOption {
    value: string;
    text: string;
}

export enum DialogButtonType {
    ok = 1,
    cancel = 2,
    close = 3,
    yes = 4,
    no = 5,
    save = 6,
    delete = 7
}

export class ButtonGroups {
    public static yesNo = [DialogButtonType.no, DialogButtonType.yes];
    public static yesNoCancel = [DialogButtonType.cancel, DialogButtonType.no, DialogButtonType.yes];
    public static saveCancel = [DialogButtonType.cancel, DialogButtonType.save];
    public static saveCancelDelete = [DialogButtonType.cancel, DialogButtonType.save, DialogButtonType.delete];
    public static close = [DialogButtonType.close];
    public static okCancel = [DialogButtonType.cancel, DialogButtonType.ok];
    public static ok = [DialogButtonType.ok];
}

export class DialogData {
    class?: string;
    title: string;
    message?: string;
    inputTitle?: string;
    inputType?: DialogDataInputType = DialogDataInputType.none;
    options?: SelectOption[] = [];
    buttons: DialogButtonType[];
    isError?: boolean;
    structuredData?: StructuredDialogContent[];
}

export class StructuredDialogContent {
    title: string;
    items: string[];
}

export class DialogResult {
    button: DialogButtonType;
    value: any;
}

export enum DialogDataInputType {
    none = 0,
    text = 1,
    number = 2,
    select = 3
}
