<div class="dialog-content-wrapper task-activity-error" fxLayout="column" fxFlex>

    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <div fxLayout="column" fxFlex>
            <h2 class="headline m-0" fxFlex="auto">{{ 'tasker.task-activity-error.title' | translate }}</h2>
        </div>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <div *ngFor="let activity of model">
            <h3>{{ activity.name }}</h3>
            <div>
                <ng-container *ngFor="let input of activity.inputs;let last = last">
                    <span *ngIf="!last">{{ input }}, </span>
                    <span *ngIf="!!last">{{ input }}</span>
                </ng-container>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <div fxLayoutAlign="end center" fxFlex>
            <button mat-button mat-dialog-close type="button">{{ 'tasker.common.close' | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>