import { Component, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';

import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.states';
//import { loadTasksAction } from 'src/app/api/tasks/my-tasks/my-tasks.actions';

import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
import { TaskListSortModel } from './task-list-sort.model';
import { DefaultTaskFilter } from 'src/app/api/user/roleAttributeTaskerValue.model';
import { filter } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

import outlineArrowDropDownCircle from '@iconify/icons-ic/outline-arrow-drop-down-circle';
import { TasksService } from 'src/app/services/tasks.service';


@Component({
  selector: 'task-list-sort',
  templateUrl: './task-list-sort.component.html',
  styleUrls: ['./task-list-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TaskListSortComponent implements OnInit, OnDestroy {

  outlineArrowDropDownCircle = outlineArrowDropDownCircle;

  priority = Honeycomb.Common.Enums.Priority;
  priorityList = Object.entries(Honeycomb.Common.Enums.Priority).filter(e => !isNaN(e[0]as any)).map(e => ({ id: e[0], name: e[1] }));
  stateList = Object.entries(Honeycomb.Common.Enums.TaskState).filter(e => !isNaN(e[0]as any)).map(e => ({ id: e[0], name: e[1] }));

  sortLocations$: Observable<Array<{ locationID: string, name: string }>>;

  model: TaskListSortModel;
  filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter;
  subscriptions: Array<Subscription> = [];

  taskSorts$: Observable<Array<DefaultTaskFilter>>;

  sortForm: FormGroup;

  columns = ['taskID', 'priority', 'deadline', 'name'];
  direction = Honeycomb.Common.SortDirection;

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<TaskListSortComponent>,
              private store: Store<AppState>,
              private tasksService: TasksService,
              private fb: FormBuilder) {
    this.model = popoverRef.data as any;

    this.sortForm = this.fb.group({
      sortRows: this.fb.array([])
    });

    const filterSubscr = store.select(s => s.myTasks.filter)
                              .pipe(filter(f => !!f))
                              .subscribe(f => {
        this.filter = Object.assign({}, f) as Honeycomb.Tenant.Tasker.IService.TaskListFilter;
        if (!this.filter.sortParams || this.filter.sortParams.length === 0) {
          this.filter.sortParams = [...TaskListSortModel.sortItemsDefault];
        }

        // this.sortForm.controls.sortRows = this.fb.array([...this.filter.sortParams.map(p => Object.assign({}, p))]);

        var paramsArray = [...this.filter.sortParams.map(p => Object.assign({}, p))][0];
        paramsArray.column = this.tasksService.getSort().column;
        paramsArray.direction = Honeycomb.Common.SortDirection[Honeycomb.Common.SortDirection.asc] == this.tasksService.getSort().direction ? 
                                  Honeycomb.Common.SortDirection.asc : 
                                  Honeycomb.Common.SortDirection.desc;

        this.sortForm.controls.sortRows = this.fb.array([paramsArray]);
      });
    this.subscriptions.push(filterSubscr);
  }

  ngOnInit() {
  }

  cancel() {
    this.popoverRef.close();
  }

  toggleSortDirection(sortRow: Honeycomb.Tenant.Tasker.IService.SortParam) {

    const origDirection = this.sortForm.controls.sortRows.value.find(c => c.column === sortRow.column).direction;
    this.sortForm.controls.sortRows.value.find(c => c.column === sortRow.column).direction =
        origDirection === Honeycomb.Common.SortDirection.asc ?
        Honeycomb.Common.SortDirection.desc : Honeycomb.Common.SortDirection.asc;
  }

  apply() {
    this.filter.sortParams = this.sortForm.value.sortRows;
    let sortRows = this.sortForm.get("sortRows").value as Honeycomb.Tenant.Tasker.IService.SortParam[];
    if (!!sortRows && sortRows.length > 0) {
      var sortRow = sortRows[0];
      this.tasksService.setSort(sortRow.column, Honeycomb.Common.SortDirection[sortRow.direction]);
    }

    this.popoverRef.close();
  }

  sortPriorityChanged(event: MatSelectChange, index: number) {
    const sortModel = this.sortForm.controls.sortRows.value as Honeycomb.Tenant.Tasker.IService.SortParam[]
    const notFoundColumn = this.columns.filter(c => (sortModel.map(sm => sm.column).indexOf(c) < 0))[0];
    sortModel.forEach((sm, i) => {
      if (event.value === sm.column && i !== index) {
        sm.column = notFoundColumn;
      }
    });
  }

  setStatus() {
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if (s)  {
        s.unsubscribe();
      }
    });
  }
}
