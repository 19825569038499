import { HttpClient } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, catchError, firstValueFrom, lastValueFrom, map, Observable, of, tap } from "rxjs";
import { TaskAgenda } from "../api/tasks/my-tasks/my-tasks.state";
import { AppState } from "../app.states";
import { isNullOrUndefined, isNullOrWhitespace } from "../common/functions";
import { BuildConfigService } from "./build-config.service";
import { Honeycomb } from "./honeycomb-api/honeycomb-api";
import FilterInit, { TaskSearchProperties } from "./tasks-filter";

@Injectable({
    providedIn: 'root'
})

export class TasksService {

    public initialized = false;

    public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    
    private callIndex: number = 0; // for sznchronization possible parallel calls

    public jobIdChangeEvent: number = null;

    @Output()
    public data: EventEmitter<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> = new EventEmitter<[]>();

    @Output()
    public total: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public loadingData: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public forcePagerUpdate: EventEmitter<void> = new EventEmitter();

    public totalStatic: number = 0;

    private operations: Array<Honeycomb.Tenant.Tasker.IService.Model.Operation> = null;

    private taskStatuses: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus> = null;

    private lastAgenda: TaskAgenda = TaskAgenda.Dashboard;

    public pageSize(agendaKey?: string): number {

        console.log('pageSize', agendaKey, this.taskSearchProperties.pageSizeMap.get(agendaKey));

        if (!isNullOrWhitespace(agendaKey)) {
            return this.taskSearchProperties.pageSizeMap.get(agendaKey);
        }
        return this.taskSearchProperties.listFilter.pageSize || 10;
    }

    public pageIndex(agendaKey?: string): number {

        console.log('pageIndex', agendaKey, this.taskSearchProperties.pageIndexMap.get(agendaKey));

        if (!isNullOrWhitespace(agendaKey)) {
            return this.taskSearchProperties.pageIndexMap.get(agendaKey);
        }
        return this.taskSearchProperties.listFilter.pageIndex || 10;
    }

    private taskSearchProperties: TaskSearchProperties;

    constructor(
        private http: HttpClient,
        @Inject('TaskController')
        private taskController: Honeycomb.Tenant.Tasker.IService.Controller.TaskController,
        @Inject('OperationController') private operationController: Honeycomb.Tenant.Tasker.IService.Controller.OperationController,
        @Inject('TaskStatusController') private taskStatusController: Honeycomb.Tenant.Tasker.IService.Controller.TaskStatusController,
        private buildConfig: BuildConfigService
        ) {
        this.taskSearchProperties = FilterInit();
    }

    private async loadOperations() {
        this.operations = await firstValueFrom(this.operationController.ActiveList(null));
        return this.operations;
    }

    public async setDefaultAgendaFilter(reset?: boolean, agenda?: TaskAgenda, jobID?: number) {
        if (isNullOrUndefined(agenda) || reset || this.lastAgenda !== agenda) {
            this.taskSearchProperties.listFilter = new Honeycomb.Tenant.Tasker.IService.TaskListFilter();
        }
        if (!isNullOrUndefined(agenda) && this.lastAgenda !== agenda && agenda !== TaskAgenda.Calendar) {
            await this.setDefaultAttributesByAgenda(agenda);
            await this.setDefaultOperationsByAgenda(agenda);
        }
        if (!!jobID && this.lastAgenda !== agenda && agenda !== TaskAgenda.Calendar) {
            await this.setDefaultTaskStatuses(jobID);
        }
        if (agenda == TaskAgenda.Calendar) {
            this.setSort('dateFrom', 'asc');
        }
    }

    public async setDefaultOperationsByAgenda(agenda: TaskAgenda) {

        if (!this.operations) {
            await this.loadOperations();
        }
        var preselectedOperations: Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>;

        if (agenda !== TaskAgenda.Repairs) {
            preselectedOperations = this.operations.filter(p => p.taskType !== Honeycomb.Common.Enums.TaskType.repairRequest);
        }
        else {
            preselectedOperations = this.operations.filter(p => p.taskType === Honeycomb.Common.Enums.TaskType.repairRequest);
        }
        this.taskSearchProperties.listFilter.operations =  preselectedOperations.map(o => o.operationID);
    }

    public async setDefaultAttributesByAgenda(agenda: TaskAgenda) {

        let filter = this.taskSearchProperties.listFilter
        if (agenda === TaskAgenda.Repairs) {
            filter.includeAttributes = this.buildConfig.taskList.repair.attributes;
        } else {
            filter.includeAttributes = this.buildConfig.taskList.default.attributes;
        }
    }

    public async setDefaultTaskStatuses(selectedJobID: number) {
        this.taskSearchProperties.listFilter.jobID = selectedJobID;
        this.taskStatuses = await firstValueFrom(this.taskStatusController.List(null, selectedJobID));
        let filter = this.taskSearchProperties.listFilter;
        filter.taskStatusIDs = this.taskStatuses.filter(s => s.defaultFilter).map(a => a.taskStatusID);
    }

    public async loadPage(agendaKey?: string) {

        console.log('loadPage', agendaKey);

        this.lastAgenda = this.taskSearchProperties.agenda;

        if (!this.taskSearchProperties.listFilter.taskRelationAdditional) {
            this.taskSearchProperties.listFilter.taskRelationAdditional = [];
        }

        if (this.jobIdChangeEvent)
        {
            this.taskSearchProperties.listFilter.jobID = this.jobIdChangeEvent;
        }

        const filter = {
            userID: -1,
            jobID: this.taskSearchProperties.listFilter.jobID,
            pageIndex: this.taskSearchProperties.pageIndexMap.get(agendaKey),
            pageSize: this.taskSearchProperties.pageSizeMap.get(agendaKey),
            filter: {
                fulltext: this.taskSearchProperties.listFilter.fulltext,
                assignedUsers: this.taskSearchProperties.listFilter.taskRelationAdditional.filter(r => r.taskRelation === Honeycomb.Common.Enums.TaskRelation.assignee).map(r => r.userID), // TODO
                assignerUsers: this.taskSearchProperties.listFilter.taskRelationAdditional.filter(r => r.taskRelation === Honeycomb.Common.Enums.TaskRelation.assigner).map(r => r.userID), // TODO
                creatorUsers: this.taskSearchProperties.listFilter.taskRelationAdditional.filter(r => r.taskRelation === Honeycomb.Common.Enums.TaskRelation.creator).map(r => r.userID),  // TODO
                name: null,
                description: null,
                mainFilter: this.taskSearchProperties.listFilter.mainFilter,
                locationIDs: this.taskSearchProperties.listFilter.locations,
                operationIDs: this.taskSearchProperties.listFilter.operations,
                priorities: this.taskSearchProperties.listFilter.priorities,
                taskStatusIDs: this.taskSearchProperties.listFilter.taskStatusIDs,
                dateFrom: this.taskSearchProperties.listFilter.dateFrom,
                dateTo: this.taskSearchProperties.listFilter.dateTo,
                taskRead: this.taskSearchProperties.listFilter.taskRead === Honeycomb.Common.Enums.TaskRead.read ? true : 
                                this.taskSearchProperties.listFilter.taskRead === Honeycomb.Common.Enums.TaskRead.notRead ? false : null,

            } as Honeycomb.Tenant.Tasker.IService.Model.TaskPageFilter,
            sort: this.taskSearchProperties.sort,
        } as Honeycomb.Tenant.Tasker.IService.Model.TaskPage;

        console.log('PageRequest - filter', filter);

        const dataRequest = this.taskController.Page(filter);

        var pageResult = dataRequest.pipe(
            map(res => {
              return res; // getting badly generated honeycomb-api.ts
            }),
            catchError(error => {
                console.error('task-service', error);
                let emptyResult = { pageIndex:0, pageSize: this.pageSize(agendaKey), total: 0, results: [] };
                return of(emptyResult);
            } )
          );
        return await lastValueFrom(pageResult);
    }

    public async load(agendaKey?: string, useMutipleCalls?: boolean, callIndex?: number): Promise<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> {
        this.loadingData.emit(true);
        if (!!useMutipleCalls) {
            this.callIndex = callIndex;
        } 
        this.callIndex = callIndex;
        if (this.loading.getValue() && !useMutipleCalls) {
            return;
        };

        this.loading.next(true);

        this.taskSearchProperties.listFilter.pageIndex = this.taskSearchProperties.pageIndexMap.get(agendaKey) || 0;
        this.taskSearchProperties.listFilter.pageSize = this.taskSearchProperties.pageSizeMap.get(agendaKey) || 10;

        const pageResult = await this.loadPage(agendaKey);

        if (pageResult.total === 0) {
            var newTotal =  (this.taskSearchProperties.listFilter.pageSize * this.taskSearchProperties.listFilter.pageIndex) + pageResult.results.length;
            if (!isNullOrWhitespace(agendaKey) && pageResult.results.length === this.taskSearchProperties.pageSizeMap.get(agendaKey)) {
                newTotal = ((this.taskSearchProperties.listFilter.pageSize * this.taskSearchProperties.listFilter.pageIndex) || 0) + 2 * this.taskSearchProperties.listFilter.pageSize;
                this.total.next(newTotal);
            } else if (pageResult.results.length < this.taskSearchProperties.listFilter.pageSize) {
                    var newTotal = (this.taskSearchProperties.listFilter.pageSize * this.taskSearchProperties.listFilter.pageIndex) + pageResult.results.length;
            }
            pageResult.total = newTotal;
            this.totalStatic = newTotal;
        }


        // another call is in progress, just ignore previous resukl
        if (useMutipleCalls && this.callIndex > callIndex) {
            return;
        }

        this.totalStatic = pageResult.total;
        this.total.next(pageResult.total);

        this.loading.next(false);
        
        this.taskSearchProperties.results = pageResult.results;
        this.data.emit(pageResult.results);

        this.loadingData.emit(false);

        return pageResult.results;
    }


    public setPager = (agendaKey: string, pageIndex: number, pageSize?: number) => {

        console.log(`agendaKey: ${agendaKey}, pageIndex: ${pageIndex}, pageSize: ${pageSize}`);

        if (!isNullOrUndefined(pageSize)) {
            this.taskSearchProperties.pageSizeMap.set(agendaKey, pageSize);
        } else {
            pageSize = this.taskSearchProperties.pageSizeMap.get(agendaKey) || 10;
        }

        if (!isNullOrUndefined(pageIndex)) {
            this.taskSearchProperties.pageIndexMap.set(agendaKey, pageIndex);
        } else {
            pageIndex = this.taskSearchProperties.pageIndexMap.get(agendaKey) || 0;
        }
      
        if (!isNullOrUndefined(pageSize)) {
            this.taskSearchProperties.listFilter.offset = pageSize * pageIndex;
            this.taskSearchProperties.listFilter.limit = pageSize;
            this.taskSearchProperties.listFilter.pageSize = pageSize;
        }
    }

    public setSort(sortColumn: string, direction: string, isAttribute?: boolean) {
        console.log(`Sort by: ${sortColumn}`);
        this.taskSearchProperties.sort.column = sortColumn;
        this.taskSearchProperties.sort.direction = direction;
        this.taskSearchProperties.sort.isAttribute = isAttribute || false;
    }

    public getSort(): Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort {
        return Object.assign({}, this.taskSearchProperties.sort);
    }

    public async setFilter(filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter) {
        var lf = this.taskSearchProperties.listFilter;
        lf.taskRelationAdditional = filter.taskRelationAdditional;
        lf.userTaskRelations = filter.userTaskRelations;
        lf.includeAttributes = filter.includeAttributes;
        lf.mainFilter = filter.mainFilter;
        lf.jobID = filter.jobID;
        lf.locations = filter.locations;
        lf.locationID = filter.locationID;
        lf.taskSequenceID = filter.taskSequenceID;
        lf.baseTaskStatuses = filter.baseTaskStatuses;
        lf.taskRead = filter.taskRead;
        lf.taskStatusIDs = filter.taskStatusIDs;
        lf.taskTypes = filter.taskTypes;
        lf.dateFrom = filter.dateFrom;
        lf.dateTo = filter.dateTo;
        lf.priority = filter.priority;
        lf.priorities = filter.priorities;

        if (!!filter.pageIndex && !!filter.pageSize) {
            lf.pageIndex = filter.pageIndex;
            lf.pageSize = filter.pageSize;
        }
        if (!lf.pageIndex) {
            lf.pageIndex = 0;
        }

        if (!lf.pageSize) {
            lf.pageSize = 10;
        }

        lf.fulltext = filter.fulltext;
        lf.operations = filter.operations;
        lf.sortParams = filter.sortParams;
    }

    public getFilterRef(): Honeycomb.Tenant.Tasker.IService.TaskListFilter {
        return this.taskSearchProperties.listFilter;
    }

    public getFilterCopy(): Honeycomb.Tenant.Tasker.IService.TaskListFilter {
        var res =  Object.assign({}, this.taskSearchProperties.listFilter);
        return res;
    }
}