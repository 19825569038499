import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface RoleAttributeTaskerValueModel {
    sections: DashboardSection[];
    taskFilters: DefaultTaskFilter[];
}

export interface DashboardSection {
    displayOrder: number;
    type: SectionType;
}

export interface BIDashboardSection extends DashboardSection {
    bIReportName: string;
}

export interface TileDashboardSection extends DashboardSection {
    rows: number;
    cols: number;
    tileKeys: TileDetail[];
}

export interface TileDetail {
    displayOrder: number;
    key: string;
    url: string;
    locKey: string;
}

export interface ListDashboardSection extends DashboardSection {
    nameLocKey: string;
    queryParams: { [key: string]: string; };
}

export interface DefaultTaskFilter {
    name: string;
    default?: boolean;
}

export interface AttendanceModel {
    attendanceID?: number;
    locationID?: number;
    attendanceType: Honeycomb.Common.Enums.AttendanceType;
    attendanceSubType: Honeycomb.Common.Enums.AttendanceSubType;
    loading?: boolean;
}

export enum SectionType {
    BISection = 1,
    TileSection = 2,
    ListSection = 3
}