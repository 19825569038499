import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskTableComponent } from './task-table.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { TranslateModule } from '@ngx-translate/core';
import { TaskItemModule } from '../task-item/task-item.module';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [TaskTableComponent],
  imports: [
    CommonModule,

    FlexLayoutModule,
    MatDividerModule,
    MatIconModule,

    TranslateModule,
    IconModule,
    TaskItemModule,
  ],
  exports: [TaskTableComponent]
})
export class TaskTableModule {
}
