import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

export interface OperationsState {
    loading: boolean;
    error: string;
    results: Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>;
}

export const OperationsState = () => {
    return {
        loading: false,
        error: null,
        results: null,
    } as OperationsState;
  };
