import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import * as signalR from "@microsoft/signalr";
import { Globals } from 'src/app/common/globals';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { Observable, of, Subscription } from 'rxjs';
import { BellNotificationService } from 'src/@vex/services/bell-notification.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;
  subscriptions: Array<Subscription> = [];
  badge: Observable<string> = of(null);
  
  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,
              private globals: Globals,
              private store: Store<AppState>,
              private bellService: BellNotificationService
              ) {}

  ngOnInit() {
    var bell = this.store.select(s => s.bell);
    this.store.select(s => s.auth)
        .pipe(filter(a => !!a && !!a.jwtToken),
          map(async a => { 
            await this.start(a.jwtToken.apiUrl + 'api/TenantTasker/signalr/bell');
          }))
        .subscribe();

    this.badge =  bell.pipe(map(b => b.data.filter(d => !d.read).length), map(c => {
      if (c > 9) {
        return "9+"
      }
      if (c === 0) { return null; }
      return c.toString();
    }));
        
  }

  async start(signalRUrl: string) {
    try {
        await this.bellService.subscribeToBell(signalRUrl);
    } catch (err) {
        console.log(err);
        setTimeout(this.start, 5000);
    }
  };

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open<any>({
      origin: this.originRef,
      content: ToolbarNotificationsDropdownComponent,
      offsetY: 12,
      data: {
        close: () => {
          this.popover.close()
        }
      },
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    // if (!popoverRef.data) {
    //   popoverRef.data = {};
    // }

    // popoverRef.data.close = () => {
    //   this.popover.close();
    // };

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
