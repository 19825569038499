import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import twotoneCall from '@iconify/icons-ic/twotone-call';
import baselineKeyboardArrowLeft from '@iconify/icons-ic/baseline-keyboard-arrow-left';
import { BehaviorSubject, firstValueFrom, lastValueFrom, of, Subscription, tap } from 'rxjs';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/api/auth/auth.service';
import { ConfigService } from 'src/@vex/services/config.service';


@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FooterComponent implements OnInit, OnDestroy {

  icFooter = twotoneCall;
  baselineKeyboardArrowLeft = baselineKeyboardArrowLeft;
  version$: BehaviorSubject<string> = null;
  subscriptions = new Array<Subscription>();

  constructor(
    @Inject('ApiController') private apiController: Honeycomb.Tenant.Tasker.IService.Controller.ApiController,
    private configService: ConfigService,
    private cd: ChangeDetectorRef
  ) {
    const s = this.configService.languageChanged.subscribe(l => {
      console.log('footer - language changed: ', l);
      this.cd.detectChanges();
    });

    this.subscriptions.push(s);
  }

  async ngOnInit() {
    if (this.version$ === null) {
      const assemblyInfo = await firstValueFrom(this.apiController.Version()
                                      // if something happen, don't crash whole app
                                      .pipe(catchError((err) => of({ assemblyVersion: '0.0.0.0' }))));
      const printVersion = assemblyInfo.assemblyVersion;
      this.version$ = new BehaviorSubject(printVersion);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
