<div class="overflow-hidden">
  <div class="bg-app-bar bluebar h-16 sticky left-0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
    style="padding-left: 1.25rem;padding-right: 1.25rem;">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
      <span>{{ (title || 'tasker.menu.stores') | translate}}</span>
    </h2>
    <div class="bg-card rounded-full border px-4 search-field" fxFlex="400px" fxFlex.lt-md="1 1 100%" fxLayout="row" fxLayoutAlign="start center">
      <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
      <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
        (focus)="searchFocus($event)" (blur)="searchBlur($event)"
        placeholder="{{ 'tasker.common.search-placeholder' | translate}}" type="search" />
    </div>
    <div class="hide-on-focus">
        <mat-checkbox class="search-panel-text" [formControl]="activeOnly">{{ 'tasker.common.active-only' | translate}}</mat-checkbox>
    </div>
    <span fxFlex="1 1 0%" class="hide-on-focus"></span>
    <div *ngIf="showMap" fxLayout="row">
      <button class="search-panel-text hide-on-focus" style="padding-left: 0; padding-right: 0;" mat-button type="button" (click)="openMapDialog()">{{ 'tasker.common.map' | translate }}</button>
    </div>
  </div>
</div>
<vex-page-layout mode="simple">
  <vex-page-layout-content class="store-list">
    <div *ngIf="showReport">
      <div #pbiview *ngFor="let section of pbiSections" class="fixed-panel disable-scroll"
        [attr.data-report-name]="reportName" style="width: 100%; height: 30vh;min-height: 30vh;">
      </div>
    </div>
    <ng-container>
      <div *ngFor="let item of data" class="task-row">
        <store-item [store]="item" 
                    [storeAdditionalInfo]="locAddInfo[item.locationID]"
                    (selected)="openDetail($event)">
        </store-item>
        <mat-divider></mat-divider>
      </div>
      <div class="ball-scale-multiple blue task-row" *ngIf="false && loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </ng-container>
  </vex-page-layout-content>
</vex-page-layout>
<mat-paginator stylePaginator showFirstLastButtons #paginator
[length]="itemsTotal"
[pageSize]="pageSize"
[pageIndex] = "pageIndex"
(page)="handlePageEvent($event)"
[disabled]="(loading$ | async)"
[pageSizeOptions]="[5, 10, 20, 50, 100]">
</mat-paginator>

