import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { Store } from '@ngrx/store';
import {
  uploadTaskerFileAction,
  uploadTaskerFileCompletedAction,
  uploadTaskerFileFailedAction,
} from './taskerfiles.actions';
import { AppState } from 'src/app/app.states';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import { HttpErrorResponse } from '@angular/common/http';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';

@Injectable()
export class TaskerFilesEffects {
  constructor(
    private actions$: Actions,
    @Inject('FileControllerCustom') private fileController: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
    private store: Store<AppState>
  ) {}

  saveFileEffect = createEffect(() =>
  this.actions$.pipe(
    ofType(uploadTaskerFileAction),
    withLatestFrom(this.store),
    mergeMap(([prop, s]) => {
      return this.fileController.UploadFile(prop.file).pipe(
        map((res: Honeycomb.Common.DbFileMetadata) => {
          return uploadTaskerFileCompletedAction({
            recordUid: res.recordUID,
            fileName: res.fileName,
            activityValue: prop.activityValue
          });
        }),
        catchError((error: HttpErrorResponse) => of(uploadTaskerFileFailedAction({ error: error.message})))
      );
    })
  )
);

}
