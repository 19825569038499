import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskStatusComponent } from './task-status.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';


@NgModule({
  declarations: [TaskStatusComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatBadgeModule,
    TranslateModule,
    IconModule
  ],
  exports: [TaskStatusComponent]
})
export class TaskStatusModule {
}
