import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

@Component({
  selector: 'my-stores-list',
  templateUrl: './my-stores-list.component.html',
  styleUrls: ['./my-stores-list.component.scss']
})
export class MyStoresListComponent {

  constructor(
  ) {
  }

}
