import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, pipe, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import twotoneIndeterminateCheckBox from '@iconify/icons-ic/twotone-indeterminate-check-box';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import twoTonePushPin from '@iconify/icons-ic/twotone-push-pin';
import twoToneEmail from '@iconify/icons-ic/twotone-email';
import { MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'vex-notification-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {

  loading = true;
  subscriptions: Array<Subscription> = [];
  twotoneIndeterminateCheckBox = twotoneIndeterminateCheckBox;
  icNotificationsActive = icNotificationsActive;
  twoTonePushPin = twoTonePushPin;
  twoToneEmail = twoToneEmail;

  userSettings: Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationUserSetting[]>;
  public dataSource: MatTableDataSource<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationUserSetting>;

  public columns: string[] = [ 'name', 'bell', 'push', 'email' ];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<NotificationSettingsComponent>,
    @Inject('NotificationController') private notificationController: Honeycomb.Tenant.Tasker.IService.Controller.NotificationController
  ) {
  }

  async ngOnInit() {
    this.userSettings = this.notificationController.UserSettings();
    this.dataSource = new MatTableDataSource(await this.userSettings.toPromise());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  public async save() {
    await this.notificationController
              .SetUserSettings(this.dataSource.data)
              .toPromise();

    this.dialogRef.close(true)
  }
}
