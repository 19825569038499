import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import twotoneCheckBox from '@iconify/icons-ic/twotone-check-box';
import twotoneIndeterminateCheckBox from '@iconify/icons-ic/twotone-indeterminate-check-box';
import twotoneCheckBoxOutlineBlank from '@iconify/icons-ic/twotone-check-box-outline-blank';
import twotoneBuildCircle from '@iconify/icons-ic/twotone-build';
import twoToneArchive from '@iconify/icons-ic/twotone-archive';

@Component({
  selector: 'task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatusComponent implements OnInit, OnDestroy {

  twotoneCheckBoxOutlineBlank = twotoneCheckBoxOutlineBlank;
  twotoneIndeterminateCheckBox = twotoneIndeterminateCheckBox;
  twotoneCheckBox = twotoneCheckBox;
  twotoneBuildCircle = twotoneBuildCircle;
  twotoneArchive = twoToneArchive;

  taskType = Honeycomb.Common.Enums.TaskType;

  @Input()
  task: Honeycomb.Tenant.Tasker.IService.Model.Task; // Report task

  constructor(
  ) {}

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  getStateName(task: Honeycomb.Tenant.Tasker.IService.Model.Task): string {
    if (task.taskInfo.taskStatusCode === 'inprogress') {
      return 'inProgress';
    }
    return task.taskInfo.taskStatusCode;
  }

  getStateNameBase(task: Honeycomb.Tenant.Tasker.IService.Model.Task): string {
    const stateName = Honeycomb.Common.Enums.TaskState[task.taskInfo.taskStatusBase];
    return stateName;
  }
}
