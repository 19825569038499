import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigName, ConfigService } from '../@vex/services/config.service';
import { MatIconRegistry } from '@angular/material/icon';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.states';
import { loadCurrentUserAction } from './api/user/user.actions';
import { AuthService } from './api/auth/auth.service';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Globals } from './common/globals';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDialogComponent } from './pages/common/update-dialog/update-dialog.component';
import { RouterHistoryService } from 'src/@vex/services/route-history.service';
import { BuildConfigService } from './services/build-config.service';
import { Subject, tap } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CRS';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(private configService: ConfigService,
              private buildConfigService: BuildConfigService,
              private styleService: StyleService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService, // keep it here
              private route: ActivatedRoute,
              private store: Store<AppState>,
              private authService: AuthService,
              private updates: SwUpdate,
              private swPush: SwPush,
              private translate: TranslateService,
              private globals: Globals,
              private dialog: MatDialog,
              private router: Router,
              private navigationService: NavigationService,
              private broadcastService: MsalBroadcastService,
              private routeHistoryService: RouterHistoryService,
              private splashScreenService: SplashScreenService // keep it here
    ) {


    this.isIframe = window !== window.parent && !window.opener;
    console.log(`Build target: 🏗 %c ${buildConfigService.info}`, `font-weight: bold; color: #bada55`);

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    ).subscribe((s) => {
      console.log("MSAL broadcastService: InteractionStatus", s);
    })

    this.broadcastService.msalSubject$
    .pipe(
      takeUntil(this._destroying$)
    ).subscribe((s) => {
      console.log("MSAL msalSubject", s);
    })


    this.configService.config$.pipe(map(c => c.language), distinctUntilChanged(), tap(language => {
      this.translate.setDefaultLang(language);
      this.translate.reloadLang(language);
    })).subscribe(_ => null);

    console.log(`Updates Enabled: ${updates.isEnabled}`);

    updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).subscribe(event => {
        console.log('current version is', event.current);
        console.log('available version is', event.available);
        this.dialog.open(UpdateDialogComponent);
      });

    updates.activateUpdate().catch(c => console.log('activateUpdate error', c));

    updates.unrecoverable.subscribe(ur => console.log(`unrecoverable: `, ur));

    updates.versionUpdates.subscribe(ur => console.log(`versionUpdates: `, ur));

    // UPDATES - BEGIN
    if (!this.updates.isEnabled) {
      console.error('Updates disabled 🙁');
    } else {
      updates.checkForUpdate();
    }

    // UPDATES - END

    // GEOLOCATION CHECK
    if ('geolocation' in navigator) {
      this.globals.geoLocationAvailable = true;
      console.log('Geolocation available: 🌍🆗');
    } else {
      this.globals.geoLocationAvailable = false;
      console.error('Geolocation not available: 🌍⛔');
    }

    if (this.authService.loggedIn()) {
      this.store.dispatch(loadCurrentUserAction());
    }

    this.iconRegistry.setDefaultFontSetClass('iconify');
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.swPush.notificationClicks.subscribe( event => {    
      if (!!event.notification.data.actions && !!event.notification.data.actions) {
        var action = event.notification.data.actions[event.action];
        if (!!action && action.length > 0) {
          this.router.navigate([action]);
          return;
        }
        else {
          console.log(event, action);
        }
      }

      if (event.notification.data.url) {
        const url = event.notification.data.url;
        this.router.navigate([url]);
      }
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
    ).subscribe(queryParamMap => {
      this.document.body.dir = 'rtl';
      this.configService.updateConfig({
        rtl: true
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));
  }
  ngOnInit(): void {
   
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
