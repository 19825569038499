import { createAction, props } from '@ngrx/store';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AttendanceModel, RoleAttributeTaskerValueModel } from './roleAttributeTaskerValue.model';
import { Geolocation } from 'src/app/common/model/geolocation';

export enum CurrentUserActionTypes {
  LoadCurrentUser = '[CurrentUser] Load Current User',
  LoadCurrentUserCompleted = '[CurrentUser] Load Current User Completed',
  LoadCurrentUserFailed = '[CurrentUser] Load Current User Failed',

  LoadUIRoles = '[UIRoles] Load UI roles',
  LoadUIRolesCompleted = '[UIRoles] Load UI roles completed',
  LoadUIRolesFailed = '[UIRoles] Load UI roles failed',

  SetUpPositionRecorder = '[Position] Set up position recorder',
  SetUpPositionRecorderCompleted = '[Position] Set up position recorder completed',
  SetUpPositionRecorderFailed = '[Position] Set up position recorder failed',

  AttendanceLoad = '[Attendance] Attendance load',
  AttendanceLoadCompleted = '[Attendance] Attendance load completed',
  AttendanceLoadFailed = '[Attendance] Attendance load failed',

  AttendanceSave = '[Attendance] Attendance save',
  AttendanceSaveCompleted = '[Attendance] Attendance save completed',
  AttendanceSaveFailed = '[Attendance] Attendance save failed',

  JobSelect = '[CurrentUser] Job Select',
  JobSelected = '[CurrentUser] Job Selected',
  // TaskFilterSetDefault = '[CurrentUser] Default Filter Selected',
  // TaskFilterSetFinished = '[CurrentUser] Default Filter Finished',
}

// tslint:disable: max-line-length
export const loadCurrentUserAction = createAction(CurrentUserActionTypes.LoadCurrentUser);

export const loadCurrentUserCompletedAction = createAction(CurrentUserActionTypes.LoadCurrentUserCompleted, props<{result: Honeycomb.Tenant.Contact.IService.UserInfo}>());

export const loadCurrentUserFailedAction = createAction(CurrentUserActionTypes.LoadCurrentUserFailed, props<{error: string}>());


export const loadUIRolesAction = createAction(CurrentUserActionTypes.LoadUIRoles, props<{ jobID: number }>());

export const loadUIRolesCompletedAction = createAction(CurrentUserActionTypes.LoadUIRolesCompleted, props<{result: Array<string>}>());

export const loadUIRolesFailedAction = createAction(CurrentUserActionTypes.LoadUIRolesFailed, props<{error: string}>());


export const positionRecorderAction = createAction(CurrentUserActionTypes.SetUpPositionRecorder);

export const positionRecorderCompletedAction = createAction(CurrentUserActionTypes.SetUpPositionRecorderCompleted, props<{ locationWatchId: number }>());

export const positionRecorderFailedAction = createAction(CurrentUserActionTypes.SetUpPositionRecorderFailed, props<{error: string}>());


export const attendanceLoadAction = createAction(CurrentUserActionTypes.AttendanceLoad);

export const attendanceLoadCompletedAction = createAction(CurrentUserActionTypes.AttendanceLoadCompleted, props<{ result: AttendanceModel }>());

export const attendanceLoadFailedAction = createAction(CurrentUserActionTypes.AttendanceLoadFailed, props<{error: string}>());


export const attendanceSaveAction = createAction(CurrentUserActionTypes.AttendanceSave, props<{ locationID: number, location: Geolocation.GeolocationPosition  }>());

export const attendanceSaveCompletedAction = createAction(CurrentUserActionTypes.AttendanceSaveCompleted, props<{ result: AttendanceModel }>());

export const attendanceSaveFailedAction = createAction(CurrentUserActionTypes.AttendanceSaveFailed, props<{error: string}>());



export const jobSelectAction = createAction(CurrentUserActionTypes.JobSelect, props<{ jobID: number }>());

export const jobSelectedAction = createAction(CurrentUserActionTypes.JobSelected, props<{ dashboard: RoleAttributeTaskerValueModel}>());

// export const taskFilterSetDefaultAction = createAction(CurrentUserActionTypes.TaskFilterSetDefault, props<{ filterName: string, dashboard: RoleAttributeTaskerValueModel}>());

// export const taskFilterSetFinishedAction = createAction(CurrentUserActionTypes.TaskFilterSetFinished, props<{ dashboard: RoleAttributeTaskerValueModel}>());


