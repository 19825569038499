import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../api/auth/auth.service';
import { AppState } from '../app.states';
import { isNullOrWhitespace } from '../common/functions';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthGuardDevService implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.authService.loggedIn()) {
      return of (true);
    }

    if (state.url === '/' || isNullOrWhitespace(state.url)) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    }
    
    return of(false);
  }
}
