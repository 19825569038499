

import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ButtonGroups, DialogButtonType, DialogData, DialogDataInputType, DialogResult, SelectOption, StructuredDialogContent } from './prompt-dialog.model';
import { PromptDialogComponent } from './prompt-dialog.component';

@Injectable({
    providedIn: 'root'
 })
export class PromptDialogService
{
    constructor(private matDialog: MatDialog
                )
    {
    }

    public async showOkDialog( message: string, title: string = null ): Promise<DialogButtonType>
    {
        return await this.showMessageDialog( title, message, ButtonGroups.ok );
    }

    public async showErrorDialog( message: string, title: string = null ): Promise<DialogButtonType>
    {
        return await this.showMessageDialog( title, message, ButtonGroups.ok, null, true);
    }

    // tslint:disable-next-line: max-line-length
    public async showYesNoDialog( message: string, title: string = null, structuredData?: StructuredDialogContent[]): Promise<DialogButtonType>
    {
        return await this.showMessageDialog( title, message, ButtonGroups.yesNo, structuredData);
    }

    public async showYesNoDialogStructured( title: string = null, message: string, structuredData?: StructuredDialogContent[]): Promise<DialogButtonType>
    {
        return await this.showMessageDialog( title, message, ButtonGroups.yesNo, structuredData);
    }

    public async showMessageDialog( title: string,
                                    message: string,
                                    buttons: DialogButtonType[],
                                    structuredData?: StructuredDialogContent[],
                                    isError?: boolean): Promise<DialogButtonType>
    {
        if (!title) {
            title = 'common.warning';
        }

        let styleClass = 'prompt-dialog-message-container';

        if (!!isError) {
            styleClass = 'prompt-dialog-message-container error';
        }

        const data = {
            title,
            message,
            buttons,
            class: styleClass,
            inputType: DialogDataInputType.none,
            structuredData,
            isError: !!isError
        } as DialogData;
        const r = (await this.showDialog( {data} ));
        if (!r) {
            return DialogButtonType.cancel;
        }
        return r.button;
    }

    public async showInputDialog( title: string,
                                  message: string,
                                  inputTitle: string,
                                  inputType: DialogDataInputType,
                                  options: SelectOption[],
                                  buttons: DialogButtonType[],
                                  structuredData?: StructuredDialogContent[],
                                  ): Promise<DialogResult> 
    {
        const data = {
            title,
            message,
            buttons,
            class: 'prompt-dialog-input-container',
            inputTitle,
            inputType,
            options,
            structuredData
        } as DialogData;
        return await this.showDialog( {data} );
    }


    public async showDialog( data: MatDialogConfig<DialogData> ): Promise<DialogResult>
    {
        const dialogRef = this.matDialog.open(PromptDialogComponent, data);

        return await dialogRef.afterClosed().toPromise() as DialogResult;
    }
}
