
<ng-container *ngIf="activityInput" [formGroup]="activityInput">
    <div fxLayout="column" [ngSwitch]="activityInput.value.uitype" class="input-row" 
        [ngClass]="{'invalid': !activityInput.valid, 'valid': activityInput.valid, 'required': activityInput.value.required}">
        <ng-container *ngSwitchCase="uitype.inputText">
            <mat-form-field class="mt-3 action-input-text">
                <mat-label>{{ activityInput.value.name }}</mat-label>
                <input type="text" matInput formControlName="inputValue" [readonly]="inputUpdating">
                <mat-icon [icIcon]="twotoneBorderColor" class="mr-3" matPrefix></mat-icon>
                <div fxLayout="row" matSuffix>
                    <task-input-buttons #inputButtonText [activityInputControl]="activityInput" [detailComponent]="detailComponent" [task]="task"></task-input-buttons>
                    <mat-icon [icIcon]="twotoneAddAPhoto" class="mr-3 cursor-pointer" matSuffix (click)="togglePhotoRow()"></mat-icon>
                    <mat-icon [icIcon]="twotoneInfo" class="mr-3 cursor-pointer" matSuffix *ngIf="activityInput.value.hasInfoText" (click)="toggleInfoText()"></mat-icon>
                </div>
                <mat-hint *ngIf="activityInput.value.description">{{ activityInput.value.description }}</mat-hint>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="uitype.textarea">
            <mat-form-field class="mt-3 action-input-text">
                <mat-label>{{ activityInput.value.name }}</mat-label>
                <textarea matInput formControlName="inputValue" [readonly]="inputUpdating"></textarea>
                <mat-icon [icIcon]="twotoneBorderColor" class="mr-3" matPrefix></mat-icon>
                <div fxLayout="row" matSuffix>
                    <task-input-buttons #inputButtonTextArea [activityInputControl]="activityInput" [detailComponent]="detailComponent" [task]="task"></task-input-buttons>
                    <mat-icon [icIcon]="twotoneAddAPhoto" class="mr-3 cursor-pointer" matSuffix (click)="togglePhotoRow()"></mat-icon>
                    <mat-icon [icIcon]="twotoneInfo" class="mr-3 cursor-pointer" matSuffix *ngIf="activityInput.value.hasInfoText" (click)="toggleInfoText()"></mat-icon>
                </div>
                <mat-hint *ngIf="activityInput.value.description">{{ activityInput.value.description }}</mat-hint>
            </mat-form-field>
        </ng-container>                
        <ng-container *ngSwitchCase="uitype.inputNumber">
            <mat-form-field class="mt-3" fxFlex>
                <mat-label>{{ activityInput.value.name }}</mat-label>
                <input type="number" matInput formControlName="inputValue" (blur)="onBlurInput($event)">
                <mat-icon [icIcon]="twotoneSwapVerticalCircle" class="mr-3" matPrefix></mat-icon>
                <div fxLayout="row" matSuffix>
                    <task-input-buttons #inputButtonNumber [activityInputControl]="activityInput" [detailComponent]="detailComponent" [task]="task"></task-input-buttons>
                    <mat-icon [icIcon]="twotoneAddAPhoto" class="mr-3 cursor-pointer" matSuffix (click)="togglePhotoRow()"></mat-icon>
                    <mat-icon [icIcon]="twotoneBorderColor" class="mr-3" matSuffix (click)="toggleNote()"></mat-icon>
                    <mat-icon [icIcon]="twotoneInfo" class="mr-3" matSuffix *ngIf="activityInput.value.hasInfoText" (click)="toggleInfoText()"></mat-icon>
                </div>
                <mat-hint *ngIf="activityInput.value.description">{{ activityInput.value.description }}</mat-hint>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="uitype.file">
            <mat-form-field class="mt-3 progress-container" fxFlex>
                <mat-label>{{ activityInput.value.name }}</mat-label>
                <ngx-mat-file-input formControlName="inputValue" placeholder="" accept="image/*,text/plain" capture="environment" (change)="fileSet($event)">
                </ngx-mat-file-input>
                <task-input-buttons #inputButtonFile [activityInputControl]="activityInput" [detailComponent]="detailComponent" [task]="task"></task-input-buttons>
                <mat-icon [icIcon]="twotoneCreateNewFolder" class="mr-3" matPrefix></mat-icon>
                <mat-icon [icIcon]="twotoneInfo" class="mr-3" matSuffix *ngIf="activityInput.value.hasInfoText" (click)="toggleInfoText()"></mat-icon>
                <mat-hint *ngIf="activityInput.value.description">{{ activityInput.value.description }}</mat-hint>
            </mat-form-field>
            <div style="display: flex;">
                <div style="position: relative;display: flex;">
                    <div class="ball-scale-multiple blue task-row photo-ball" *ngIf="fileUploading$ | async">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div *ngIf="imageUrlValue" style="width: 120px; height: 95px;" (click)="zoomImage()">
                        <img src="{{imageUrlValue}}" style="margin: auto;max-width: 120px; max-height: 95px;">
                        <div class="time">{{ activityInput.value.valueUpdated | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
                    </div>
                    <div *ngIf="imageUrlRecordUid" style="width: 120px; height: 95px;" (click)="zoomImage()">
                        <img src="{{imageUrlRecordUid}}" style="margin: auto;max-width: 120px; max-height: 95px;">
                        <div class="time">{{ activityInput.value.valueUpdated | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="uitype.checkbox">
            <div class="checkbox-input" fxLayout="column">
                <div style="flex: 1 1 100%; flex-direction: row;display: flex;">
                    <mat-slide-toggle class="mt-3" formControlName="inputValue" style="flex: 1 1 100%;" (change)="change($event)">{{ activityInput.value.name }}
                    </mat-slide-toggle>
                    <div fxLayout="row" matSuffix class="mat-mdc-form-field-icon-suffix">
                        <task-input-buttons #inputButtonCheckbox [activityInputControl]="activityInput" [detailComponent]="detailComponent" [task]="task"></task-input-buttons>
                        <mat-icon [icIcon]="twotoneAddAPhoto" class="mr-3" matSuffix (click)="togglePhotoRow()"></mat-icon>
                        <mat-icon [icIcon]="twotoneBorderColor" class="mr-3" matSuffix (click)="toggleNote()"></mat-icon>
                        <mat-icon [icIcon]="twotoneInfo" class="mr-3" matSuffix *ngIf="activityInput.value.hasInfoText" (click)="toggleInfoText()"></mat-icon>
                    </div>
                </div>
                <mat-hint *ngIf="activityInput.value.description">{{ activityInput.value.description }}</mat-hint>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="uitype.rating">
            <div class="rating-input" style="position: relative;flex: 1 1 100%; display: flex; box-sizing: border-box;flex-direction: column;">
                <div style="position: relative;flex: 1 1 100%; display: flex; flex-direction: row; align-content: start; align-items: center;">
                    <button-rating formControlName="inputValue" [ratingValues]="activityInput.value.inputParams" fxFlex="0 1 auto"></button-rating>
                    <label fxFlex="1 1 0%">{{ activityInput.value.name }}</label>
                    <div class="star-icons">
                        <task-input-buttons #inputButtonRating [activityInputControl]="activityInput" [detailComponent]="detailComponent" [task]="task"></task-input-buttons>
                        <mat-icon [icIcon]="twotoneAddAPhoto" class="mr-3 cursor-pointer" matSuffix (click)="togglePhotoRow()"></mat-icon>
                        <mat-icon [icIcon]="twotoneBorderColor" class="mr-3 cursor-pointer" matSuffix (click)="toggleNote()"></mat-icon>
                        <mat-icon [icIcon]="twotoneInfo" class="mr-3 cursor-pointer" matSuffix *ngIf="activityInput.value.hasInfoText" (click)="toggleInfoText()"></mat-icon>
                    </div>
                </div>
                <mat-hint style="font-size: 0.8em; top: -5px; position: relative;" *ngIf="activityInput.value.description">{{ activityInput.value.description }}</mat-hint>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="uitype.geoLocation">
            <div class="rating-input" style="position: relative;flex: 1 1 100%; display: flex; box-sizing: border-box;flex-direction: column;">
                <div style="position: relative;flex: 1 1 100%; display: flex; flex-direction: row; align-content: start; align-items: center;">
                    <geo-input formControlName="inputValue" fxFlex="1 1 100%"></geo-input>
                    <div class="star-icons">
                        <mat-icon [icIcon]="twotoneBorderColor" class="mr-3 cursor-pointer" matSuffix (click)="toggleNote()"></mat-icon>
                        <mat-icon [icIcon]="twotoneInfo" class="mr-3 cursor-pointer" matSuffix *ngIf="activityInput.value.hasInfoText" (click)="toggleInfoText()"></mat-icon>
                    </div>
                </div>
                <mat-hint style="font-size: 0.8em; top: -5px; position: relative;" *ngIf="activityInput.value.description">{{ activityInput.value.description }}</mat-hint>
            </div>
        </ng-container>

        <div *ngIf="show.info" [innerHtml]="getSanitizedHtml()"></div>

        <mat-form-field *ngIf="show.note || noteRequired">
            <mat-label>{{ 'tasker.common.note' | translate }}</mat-label>
            <input type="text" matInput formControlName="inputNote" (blur)="onBlurNote($event)" [readonly]="inputUpdating">
        </mat-form-field>

        <div *ngIf="show.photo || photoRequired" class="photo-row" fxLayoutGap="10px">
            <div *ngFor="let photo of activityInput.value.taskValuePhotos; trackBy: trackByTaskerFileUid" class="photo-thumbnail" (click)="zoomAttachmentImage(photo)">
                <div class="ball-scale-multiple blue task-row photo-ball" *ngIf="photo.recordUid">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <img *ngIf="photo.taskerFileUid" [src]="getImageUrl(photo.taskerFileUid)">
                <div class="time">{{ photo.validFromUTC | dateFromUtc | date: 'd.M.yyyy HH:mm' }}</div>
            </div>
            <div class="photo-add-btn progress-container" (click)="addPhoto($event)">
                <ic-icon [icIcon]="roundAddCircleOutline" size="40px"></ic-icon>
                <div class="photo-add-text">{{ 'tasker.task-activities.add-photo' | translate }}<span *ngIf="photoRequired"><b>*</b></span></div>
                <div class="photo-uploading-text">{{ 'tasker.common.uploading' | translate }}</div>
            </div>
        </div>

    </div>
    <ngx-mat-file-input #inputPhoto style="display: none;" placeholder="" accept="image/*,text/plain" capture="environment">
    </ngx-mat-file-input>
</ng-container>
