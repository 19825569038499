<div class="dropdown" *ngIf="!!(notifications | async)">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading">{{ 'tasker.notification.Notifications' | translate }}</div>
      <!-- <div class="dropdown-subheading">You have {{ (notifications | async).length }} new notifications.</div> -->
    </div>

    <button mat-icon-button class="header-button" type="button" (click)="showNotificationSettings()">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content">
    <div *ngFor="let notification of notifications | async; trackBy: trackById"
      (click)="notificationClick(notification)"
       [class.read]="notification.read"
       [routerLink]="[notification.route]"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <ic-icon [icon]="getIcon(notification.bellType)"
               class="notification-icon"
               fxFlex="none"
               size="24px"></ic-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ notification.title }}</div>
        <!-- <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div> -->
        <div class="notification-description" *ngFor="let item of notification.descriptionRows">{{ item.content }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
      <mat-icon [icIcon]="icEyeRead" *ngIf="notification.read" class="notification-chevron" fxFlex="none"></mat-icon>
      <mat-icon [icIcon]="icEyeUnread" *ngIf="!notification.read" class="notification-chevron" fxFlex="none"></mat-icon>
      
    </div>
    
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
    <button mat-button type="button" class="btn-set-all-read" (click)="setAllAsRead()">{{ 'tasker.notification.mark-all-read' | translate }}</button>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item >
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <!-- <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>Disable Notifications</span>
  </button> -->
</mat-menu>
