import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';



@Component({
  selector: 'task-activity-error',
  templateUrl: './task-activity-error.component.html',
  styleUrls: ['./task-activity-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskActivityErrorComponent implements OnInit {

  taskActivities: FormArray;
  icClose = icClose;
  model = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<TaskActivityErrorComponent>,
              private cd: ChangeDetectorRef) {

    this.taskActivities = this.data;
  }

  async ngOnInit() {
    const invalidActions = this.data.controls.filter(c => !c.valid);

    this.model = [];

    for (const action of invalidActions) {
      const agroup = {
        name: action.value.name,
        inputs: action.controls.activityInputs.controls
                      .filter(c => !c.valid)
                      .map(ai => ai.value.name)
      };
      this.model.push(agroup);
    }
  }
}
